import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import PreviewIcon from '@mui/icons-material/Preview';
import TvOffIcon from '@mui/icons-material/TvOff';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import { storylineStatuses } from '../../../utils/utils';

export default function SettingsFooter({
  save,
  onClickDelete,
  itemStatus,
  saveAndNext,
  canActivate,
  canSave,
  showPreview,
  setShowPreview,
  disabledLive,
}) {
  const lang = useLanguage();

  return (
    <Box
      style={{
        height: 'auto',
        position: 'fixed',
        bottom: '0px',
        right: '0px',
        left: '116px',
        textAlign: 'center',
        flex: '0 0 auto',
        padding: '4px 16px',
        borderTop: '1px solid rgb(237, 237, 238)',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: 1,
      }}
      sx={{ backgroundColor: 'background.paper' }}
    >
      <Box style={{ marginLeft: '0px', marginRight: 'auto' }}>
        <Button onClick={onClickDelete}>
          <img src="/images/icons/bin-icon.svg" alt="" />
          {translationsStoryline.videoFunnel.deleteItem[lang]}
        </Button>
      </Box>
      <Box style={{ marginLeft: 'auto', marginRight: '0px' }}>
        <Button onClick={() => setShowPreview(!showPreview)}>
          <PreviewIcon fontSize="small" />
          {!showPreview
            ? translationsStoryline.videoFunnel.showPreview[lang]
            : translationsStoryline.videoFunnel.hidePreview[lang]}
        </Button>
        <Button
          variant="contained"
          disableElevation
          disabled={!canActivate || (disabledLive && itemStatus !== storylineStatuses.LIVE)}
          onClick={() =>
            save({
              newStatus:
                itemStatus === storylineStatuses.EDIT
                  ? storylineStatuses.LIVE
                  : storylineStatuses.EDIT,
            })
          }
        >
          {itemStatus === storylineStatuses.EDIT ? (
            <>
              <LiveTvIcon style={{ height: '18px' }} />
              {translationsStoryline.activate[lang]}
            </>
          ) : (
            <>
              <TvOffIcon style={{ height: '18px' }} />
              {translationsStoryline.deactivate[lang]}
            </>
          )}
        </Button>
        <Button disabled={!canSave} onClick={() => save({})} variant="contained" disableElevation>
          {translationsStoryline.save[lang]}
        </Button>
      </Box>
    </Box>
  );
}
