import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import ThemeContext from '../../../../utils/ThemeContext';

interface ErrorProps {
  error: string | null;
}

function Error({ error }: ErrorProps) {
  const theme = useContext(ThemeContext);

  if (!error) return null;
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.transparent,
        borderRadius: '8px',
        zIndex: 2,
      }}
    >
      <Typography variant="titleLarge" gutterBottom sx={{ mt: 2, textAlign: 'center' }}>
        {error}
      </Typography>
    </Box>
  );
}

export default Error;
