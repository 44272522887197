const translations = {
  title: {
    en: 'Configure Enterprise Connection',
    sv: 'Konfigurera Enterprise Connection',
  },
  saveConnection: {
    en: 'Save Connection',
    sv: 'Spara Connection',
  },
  connectionType: {
    en: 'Connection Type',
    sv: 'Anslutningstyp',
  },
  selectConnectionType: {
    en: 'Select Connection Type',
    sv: 'Välj anslutningstyp',
  },
  connectionName: {
    en: 'Connection Name',
    sv: 'Anslutningsnamn',
  },
  connectionDomain: {
    en: 'Microsoft Azure AD Domain',
    sv: 'Microsoft Azure AD Domän',
  },
  errorMessage: {
    en: 'An error occurred. Please reload the page and try again.',
    sv: 'Ett fel uppstod. Ladda om sidan och försök igen.',
  },
  successMessage: {
    en: 'Connection saved successfully!',
    sv: 'Anslutningen sparades framgångsrikt!',
  },
};

export default translations;
