import { PremiumFeatures } from './types';

export interface InputConfig {
  key: keyof PremiumFeatures;
  label: string;
  defaultValue: boolean | string | number;
  description?: string;
  disabled?: boolean;
  dependsOn?: Array<{ key: keyof PremiumFeatures; value: boolean | string | number }>;
}
export interface InputConfigGroup {
  label: string;
  inputs: Array<InputConfig>;
}
export const inputGroups: Array<InputConfigGroup> = [
  {
    label: 'Lead Generation',
    inputs: [
      {
        key: 'interactionOption',
        label: 'LeadGen',
        defaultValue: false,
      },
      {
        key: 'textMessage',
        label: 'Send Text Message Reminders',
        defaultValue: false,
        description: 'Send reminders via SMS for scheduled requests.',
      },
    ],
  },
  {
    label: 'Styling',
    inputs: [
      {
        key: 'advancedWidgetStyling',
        label: 'Advanced Widget Styling',
        defaultValue: false,
      },
      {
        key: 'advancedWidgetStylingMobile',
        label: 'Advanced Widget Styling Mobile',
        defaultValue: false,
      },
      {
        key: 'advancedWidgetBehavior',
        label: 'Advanced Widget Behavior',
        defaultValue: false,
      },
      {
        key: 'advStyleVidTitleDialog',
        label: 'Advanced Video Title Dialog Styling',
        defaultValue: false,
      },
      //   {
      //     key: 'advStyleStories',
      //     label: 'Advanced Stories Styling',
      //     defaultValue: false,
      //   },
    ],
  },
  {
    label: 'Subtitles',
    inputs: [
      {
        key: 'subtitleAllowed',
        label: 'Allowing Subtitle Request',
        defaultValue: false,
        description: 'Organizations can request subtitles for individual videos.',
      },
      {
        key: 'includeSubtitle',
        label: 'Auto order subtitles on all new Video Uploads',
        defaultValue: false,
        description: 'Automatically order subtitles for every new video upload.',
      },
      {
        key: 'orgSubtitleUpload',
        label: 'Organization Subtitle Upload',
        defaultValue: false,
        description: 'Allows organizations to upload, download, and manage their subtitles.',
      },
    ],
  },
  {
    label: 'Video Features',
    inputs: [
      {
        key: 'videoFunnel',
        label: 'Video Funnel',
        defaultValue: false,
        description: 'Create dynamic video funnels using an intuitive drag-and-drop interface.',
      },
      {
        key: 'videoFunnelEndScreen',
        label: 'Video Funnel End Screen',
        defaultValue: false,
        description:
          'Adds an end screen feature for videos in a funnel, enhancing user experience.',
      },
      {
        key: 'videoCollector',
        label: 'Video Collector',
        defaultValue: false,
      },
      {
        key: 'widgetThree',
        label: 'Widget 3.0.0',
        defaultValue: true,
        description: 'Enables the 3.0 version of the widget with new and advanced features.',
        disabled: true,
      },
    ],
  },
  {
    label: 'Package Details',
    inputs: [
      {
        key: 'packageVariant',
        label: 'Package Variant',
        defaultValue: 'none',
      },
      {
        key: 'widgetVersion',
        label: 'Widget Version',
        defaultValue: 'default',
      },
    ],
  },
  {
    label: 'Permissions',
    inputs: [
      {
        key: 'organizationPermissionsSettings',
        label: 'Organization Permissions Settings',
        defaultValue: false,
      },
      {
        key: 'userLimitPerOrganization',
        label: 'Limit Number of Users Per Organization',
        defaultValue: 3,
        description: 'Set a user limit for the organization. If 0, there is no upper limit.',
        dependsOn: [{ key: 'organizationPermissionsSettings', value: true }],
      },
      {
        key: 'videoRequestEmailCustomization',
        label: 'Video Request Email Customization',
        defaultValue: false,
        description: 'Customize the content and layout of video request emails sent to users.',
      },
    ],
  },
  {
    label: 'Other Settings',
    inputs: [
      {
        key: 'editableConsentTexts',
        label: 'Custom consent texts for video upload',
        defaultValue: false,
        description: 'Allows organizations to edit and manage the consent text displayed to users.',
      },
      {
        key: 'autoReminders',
        label: 'Automatically send reminders',
        defaultValue: false,
        description:
          'Automatically send reminders for manually sent requests based on given intervals.',
      },
      {
        key: 'autoRequests',
        label: 'Automatically send requests',
        defaultValue: false,
        description: 'Automatically send video requests at predefined intervals.',
      },
      {
        key: 'excludeFromVimeo',
        label: 'Only upload videos to AWS',
        defaultValue: true,
        description:
          'Ensure videos are only uploaded to AWS, not Vimeo. Disabled for new organizations.',
        disabled: true,
      },
      {
        key: 'hideWatermark',
        label: 'Hide Watermark on all Videos and images',
        defaultValue: false,
        description:
          'Removes the Life Inside watermark from all videos, animated thumbnails, and static thumbnail images.',
      },
    ],
  },
  {
    label: 'Beta Features',
    inputs: [
      {
        key: 'conversationalAvatar',
        label: 'Conversational Avatar Beta',
        defaultValue: false,
        description: 'Enable the beta version of the Conversational Avatar feature.',
      },
      {
        key: 'requestsLibraryBeta',
        label: 'Requests Library Beta',
        defaultValue: false,
        description:
          'Activate the beta version of the Requests Library. Requires "Only upload videos to AWS" to be enabled.',
      },
    ],
  },
];
