import React, { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import PremiumFeatureCheckbox from './PremiumFeatureCheckbox';

import { PremiumFeatures } from './types';
import { inputGroups } from './inputGroups';

interface EditPremiumFeaturesViewProps {
  createNewOrg?: boolean;
  premiumFeatures: PremiumFeatures | undefined;
  setPremiumFeatures: React.Dispatch<React.SetStateAction<PremiumFeatures | undefined>>;
  orgData: {
    users: [{ id: number }];
  };
}

function EditPremiumFeaturesView({
  premiumFeatures,
  setPremiumFeatures,
  createNewOrg = false,
  orgData,
}: EditPremiumFeaturesViewProps) {
  // Initialize premiumFeatures with default values when createNewOrg is true

  useEffect(() => {
    if (createNewOrg && !premiumFeatures) {
      const defaultFeatures: PremiumFeatures = inputGroups.reduce((acc, group) => {
        group.inputs.forEach((input) => {
          const key = input.key as keyof PremiumFeatures;

          // Ensure that undefined values are handled properly
          if (input.defaultValue !== undefined) {
            // Cast defaultValue based on the key's type in PremiumFeatures
            const defaultValue = input.defaultValue as PremiumFeatures[typeof key];

            // Assign the default value if it's not undefined and matches the expected type
            (acc[key] as PremiumFeatures[typeof key]) = defaultValue;
          }
        });
        return acc;
      }, {} as PremiumFeatures);

      // Update premiumFeatures state
      setPremiumFeatures(defaultFeatures);
    }
  }, [createNewOrg, premiumFeatures, setPremiumFeatures]);

  const handleFeatureUpdate = useCallback(
    (updatedFeatures: Partial<PremiumFeatures>) => {
      setPremiumFeatures((prevFeatures) => {
        const safePrevFeatures = prevFeatures || ({} as PremiumFeatures);

        // Function to remove __typename if it exists
        const removeTypename = (features: Partial<PremiumFeatures>) => {
          const newFeatures = { ...features };
          if ('__typename' in newFeatures) {
            delete newFeatures.__typename;
          }
          return newFeatures;
        };

        // Ensure no undefined values for required fields
        return {
          ...removeTypename(safePrevFeatures),
          ...removeTypename(updatedFeatures),
        } as PremiumFeatures;
      });
    },
    [setPremiumFeatures],
  );

  // Prevent rendering until premiumFeatures is initialized
  if (createNewOrg && !premiumFeatures) {
    return <LoadingIndicator />;
  }

  if (!premiumFeatures) {
    return <LoadingIndicator />;
  }

  return (
    <Box>
      <Typography variant="titleLarge">Edit Premium Features</Typography>
      <PremiumFeatureCheckbox
        premiumFeatures={premiumFeatures}
        handleFeatureUpdate={handleFeatureUpdate}
        createNewOrg={createNewOrg}
        orgData={orgData}
      />
    </Box>
  );
}

export default EditPremiumFeaturesView;
