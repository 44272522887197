import { useState, useCallback, useRef, useEffect } from 'react';
import ReactFlow, {
  Controls,
  Background,
  MiniMap,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  useViewport,
  ReactFlowProvider,
  useReactFlow,
  useStore,
} from 'reactflow';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

import Fab from '@mui/material/Fab';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import arrowEndMarker from './arrowEndMarker';
import VideoFunnelVideoNode from './VideoFunnelVideoNode';
import VideoFunnelStartNode from './VideoFunnelStartNode';
import VideoFunnelAddNode from './VideoFunnelAddNode';
import VideoFunnelEmptyVideoNode from './VideoFunnelEmptyVideoNode';
import VideoFunnelAddVideoDialog from './VideoFunnelAddVideoDialog';
import VideoFunnelAddEndScreenDialog from './VideoFunnelAddEndScreenDialog';
import VideoFunnelEndScreenNode from './VideoFunnelEndScreenNode';
import EndScreenDialog from '../EndScreen/EndScreenDialog';
import SplitButton from '../../common/SplitButton/SplitButton';
import './video-funnel-video-node.scss';
import 'reactflow/dist/style.css';

function ViewportLogger({ setViewPort }) {
  const { x, y, zoom } = useViewport();

  useEffect(() => {
    setViewPort({ x, y, zoom });
  }, [x, y, zoom]);

  return null;
}

function SetSelection({
  selection,
  nodes,
  edges,
  selectedEdgesToAdd,
  setSelectedEdgesToAdd,
  selectedNodesToAdd,
  setSelectedNodesToAdd,
}) {
  const store = useStore();
  const { addSelectedNodes, addSelectedEdges, unselectNodesAndEdges } = store;

  if (selectedEdgesToAdd?.length > 0) {
    const [olEdges, newEdges] =
      selectedEdgesToAdd?.length > 0
        ? selectedEdgesToAdd.reduce(
            ([p, f], e) =>
              selection?.edges?.find((ed) => ed.id === e.id) ? [[...p, e], f] : [p, [...f, e]],
            [[], []],
          )
        : [[], []];

    if (olEdges?.length > 0) {
      unselectNodesAndEdges({ nodes: [], edges: olEdges });
      setSelectedEdgesToAdd([]);
    }

    if (newEdges?.length > 0) {
      addSelectedEdges(newEdges?.flatMap((e) => e.id));
      setSelectedEdgesToAdd([]);
    }
  }

  return null;
}

const nodeTypes = {
  videoFunnelVideoNode: VideoFunnelVideoNode,
  videoFunnelEmptyVideoNode: VideoFunnelEmptyVideoNode,
  videoFunnelStartNode: VideoFunnelStartNode,
  videoFunnelAddNode: VideoFunnelAddNode,
  videoFunnelEndScreenNode: VideoFunnelEndScreenNode,
};

function VideoFunnelReactFlowInner({
  basicOrgData,
  nodes,
  setNodes,
  edges,
  setEdges,
  setReactFlowInstance,
  isFullscreen,
  setIsFullscreen,
  selection,
  setSelection,
}) {
  const lang = useLanguage();
  const orgHasEndScreen = basicOrgData?.premiumFeatures?.interactionOption ?? false;
  const orgHasVideoFunnelEndScreen = basicOrgData?.premiumFeatures?.videoFunnelEndScreen ?? false;

  const [selectedEdgesToAdd, setSelectedEdgesToAdd] = useState([]);
  const [selectedNodesToAdd, setSelectedNodesToAdd] = useState([]);

  const [openAddVideo, setOpenAddVideo] = useState(false);
  const [openAddVideoEmpty, setOpenAddVideoEmpty] = useState(false);
  const [openEndScreen, setOpenEndScreen] = useState(false);
  const [openAddEndScreen, setOpenAddEndScreen] = useState(false);
  const [endScreenEdit, setEndScreenEdit] = useState(null);
  const containerRef = useRef(null);
  const [viewPort, setViewPort] = useState({ x: 0, y: 0, zoom: 0 });
  const connectingNode = useRef(null);
  const newNodeRef = useRef(null);
  const reactFlowWrapper = useRef(null);
  const { project } = useReactFlow();

  const [isConnecting, setIsConnecting] = useState(false);

  const dragRef = useRef(null);

  const [target, setTarget] = useState(null);

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const onNodeDragStart = (evt, node) => {
    dragRef.current = node;
    setNodes((nodes) =>
      nodes.map((n) => {
        if (n.id === node.id) {
          return { ...n, data: { ...n?.data, isDragging: true } };
        }
        return n;
      }),
    );
  };

  const onNodeDrag = (evt, node) => {
    // calculate the center point of the node from position and dimensions
    const centerX = node.position.x + node.width / 2;
    const centerY = node.position.y + node.height / 2;

    // find a node where the center point is inside
    const targetNode = nodes?.find(
      (n) =>
        centerX > n?.position?.x &&
        centerX < n?.position?.x + n?.width &&
        centerY > n?.position?.y &&
        centerY < n?.position?.y + n?.height &&
        n?.id !== node?.id && // this is needed, otherwise we would always find the dragged node
        (n?.type === 'videoFunnelAddNode' || n?.type === 'videoFunnelEmptyVideoNode') &&
        node?.type === 'videoFunnelVideoNode',
    );
    if (targetNode) {
      setTarget(targetNode);
      setNodes((nodes) =>
        nodes.map((n) => {
          if (n.id === targetNode.id) {
            return { ...n, data: { ...n?.data, isTarget: true } };
          }
          return n;
        }),
      );
    } else {
      setTarget(null);
      setNodes((nodes) =>
        nodes.map((n) => {
          return { ...n, data: { ...n?.data, isTarget: false } };
        }),
      );
    }
  };

  const onNodeDragStop = (evt, node) => {
    if (
      node?.type === 'videoFunnelVideoNode' &&
      (target?.type === 'videoFunnelAddNode' || target?.type === 'videoFunnelEmptyVideoNode')
    ) {
      setEdges((eds) => eds?.filter((e) => !(e?.source === node?.id || e?.target === node?.id)));
      setNodes((nodes) =>
        nodes
          ?.filter((n) => n?.id !== node?.id)
          ?.map((n) => {
            if (n.id === target?.id) {
              return {
                ...n,
                type: 'videoFunnelVideoNode',
                data: {
                  ...n?.data,
                  isDragging: false,
                  isTarget: false,
                  videoCard: node?.data?.videoCard,
                },
              };
            }
            return { ...n, data: { ...n?.data, isDragging: false, isTarget: false } };
          }),
      );

      setTarget(null);
      dragRef.current = null;
      return;
    }

    setNodes((nodes) =>
      nodes.map((n) => {
        return { ...n, data: { ...n?.data, isDragging: false, isTarget: false } };
      }),
    );

    setTarget(null);
    dragRef.current = null;
  };

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            ...arrowEndMarker(),
            newEdge: true,
          },
          eds,
        ),
      );
    },
    [setEdges],
  );

  const addEndScreenNode = useCallback(
    ({ endScreen, viewPortValue, index, isFullscreenView }) => {
      const nodeId = _.uniqueId(`node-end-screen-${endScreen.id}-`);

      const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
      const position = project({
        x: left + (isFullscreenView ? 30 : -200) + (index ? index * 450 * viewPortValue.zoom : 0),
        y: top + (isFullscreenView ? 70 : -250),
      });

      setNodes((els) => {
        return [
          ...els,
          {
            id: nodeId,
            type: 'videoFunnelEndScreenNode',
            position,
            data: { id: nodeId, endScreen },
            newNode: true,
          },
        ];
      });
    },
    [project],
  );

  const addVideoNode = useCallback(
    ({ newVideoCard, viewPortValue, index, isFullscreenView }) => {
      const nodeId = _.uniqueId(`node-videoCard-${newVideoCard.id}-`);
      const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
      const position = project({
        x: left + (isFullscreenView ? 30 : -200) + (index ? index * 350 * viewPortValue.zoom : 0),
        y: top + (isFullscreenView ? 70 : -20),
      });

      setNodes((els) => {
        return [
          ...els,
          {
            id: nodeId,
            type: 'videoFunnelVideoNode',
            position,
            data: { label: newVideoCard?.question?.[lang], id: nodeId, videoCard: newVideoCard },
            newNode: true,
          },
        ];
      });
    },
    [project],
  );

  const handleOpenAddVideo = ({}) => {
    setOpenAddVideo(true);
  };

  const handleOnCloseAddVideo = ({ newVideoCards }) => {
    setOpenAddVideo(false);
    if (newVideoCards?.length > 0) {
      newVideoCards?.forEach((newVideoCard, index) =>
        addVideoNode({
          newVideoCard,
          viewPortValue: viewPort,
          index,
          isFullscreenView: isFullscreen,
        }),
      );
    }
  };

  const handleOnCloseAddVideoEmptyNode = ({ newVideoCards }) => {
    if (newVideoCards?.length === 1) {
      const currentNode = nodes?.find((n) => selection?.nodes?.[0]?.id === n?.id);
      if (newVideoCards?.length === 1 && currentNode) {
        const videoCard = newVideoCards[0];
        setNodes((els) => {
          const nodes = els;

          const newNodes = nodes?.filter((n) => n.id !== currentNode.id);

          return [
            ...(newNodes?.length
              ? newNodes?.map((n) => ({ ...n, data: { ...n?.data, shouldCheckConnections: true } }))
              : []),
            {
              ...currentNode,
              type: 'videoFunnelVideoNode',
              data: {
                label: videoCard?.question?.[lang],
                id: currentNode.id,
                videoCard,
                shouldCheckConnections: true,
              },
              newNode: true,
            },
          ];
        });
      }
    }
    setOpenAddVideoEmpty(false);
  };

  const handleCloseEndScreen = ({ newEndScreen, editEndScreen, deletedId }) => {
    setOpenEndScreen(false);
    if (newEndScreen) {
      addEndScreenNode({
        endScreen: newEndScreen,
        viewPortValue: viewPort,
        index: 0,
        isFullscreenView: isFullscreen,
      });
    }
  };

  const handleCloseAddEndScreen = ({ newEndScreens }) => {
    setOpenAddEndScreen(false);
    if (newEndScreens?.length > 0) {
      newEndScreens?.forEach((newEndScreen, index) =>
        addEndScreenNode({
          endScreen: newEndScreen,
          viewPortValue: viewPort,
          index,
          isFullscreenView: isFullscreen,
        }),
      );
    }
  };

  const onLoad = (rf) => {
    setReactFlowInstance(rf);
  };

  const onConnectStart = useCallback((_, { nodeId, handleId }) => {
    setIsConnecting(true);
    connectingNode.current = { nodeId, handleId };
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains('react-flow__pane');
      if (targetIsPane) {
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();

        newNodeRef.current = {
          top,
          left,
          clientX: event.clientX,
          clientY: event.clientY,
        };
        const id = _.uniqueId('videoFunnel-empty-video-node-');
        const newNode = {
          id,
          position: project({ x: event.clientX - left + 0, y: event.clientY - top - 90 }),
          data: {},
          type: 'videoFunnelEmptyVideoNode',
          newNode: true,
        };
        setNodes((nds) => {
          return _.concat(...(nds?.length > 0 ? nds : []), newNode);
        });
        setEdges((eds) => {
          return _.concat(...(eds?.length > 0 ? eds : []), {
            id,
            source: connectingNode.current.nodeId,
            target: id,
            sourceHandle: connectingNode.current.handleId,
            ...arrowEndMarker(),
            newEdge: true,
            targetHandle: 'a',
          });
        });
      }
      setIsConnecting(false);
    },

    [project],
  );

  const endScreenOptions = [
    {
      label: translationsStoryline.videoFunnel.selectEndScreen[lang],
      type: 'selectEndScreen',
    },
    {
      label: translationsStoryline.videoFunnel.createEndScreen[lang],
      type: 'createEndScreen',
    },
  ];

  const videoOptions = [
    {
      label: translationsStoryline.videoFunnel.addVideo[lang],
      type: 'selectVideo',
      muiIcon: <AddIcon style={{ width: '20px', height: '20px' }} />,
    },
  ];

  const handleClickVideo = ({ type }) => {
    if (type === 'selectVideo') {
      handleOpenAddVideo({});
    } else if (type === 'uploadVideo') {
      handleOpenUploadVideo();
    }
  };

  const handleClickEndScreen = ({ type }) => {
    if (type === 'createEndScreen') {
      setOpenEndScreen(true);
    } else if (type === 'selectEndScreen') {
      setOpenAddEndScreen(true);
    }
  };

  const handleOnSelectionChange = (e) => {
    const selectedNodes = e?.nodes?.filter((node) => node.type !== 'videoFunnelStartNode');
    let newSetNodes = [];
    let allNodesSame = true;

    let newSetEdges = [];
    let allEdgesSame = true;

    const sallNodesSame = _.isEqual(_.sortBy(selectedNodes), _.sortBy(selection?.nodes));
    if (!sallNodesSame) {
      allNodesSame = false;
      newSetNodes = selectedNodes;
    } else {
      newSetNodes = selection?.nodes;
    }

    const sallEdgesSame = _.isEqual(_.sortBy(e?.edges), _.sortBy(selection?.edges));
    if (!sallEdgesSame) {
      allEdgesSame = false;
      newSetEdges = e.edges;
    } else {
      newSetEdges = selection?.edges;
    }

    if (allNodesSame && allEdgesSame) {
    } else {
      setSelection({
        nodes: newSetNodes?.filter((node) => node.type !== 'videoFunnelStartNode'),
        edges: newSetEdges,
      });
    }
  };
  const handleOnNodeClick = (e, node) => {
    if (node?.type === 'videoFunnelVideoNode' || node?.type === 'videoFunnelEmptyVideoNode') {
      const handleId = e.target?.attributes?.['data-handleid']?.value;
      if (handleId) {
        if (['a', 'b', 'c', 'd'].includes(handleId)) {
          const edge = edges?.find(
            (edge) => edge?.source === node?.id && edge?.sourceHandle === handleId,
          );
          if (edge) {
            setSelectedEdgesToAdd([edge]);
          }
        }
      }
    } else if (node?.type === 'videoFunnelStartNode') {
    }
  };

  return (
    <div
      ref={reactFlowWrapper}
      style={{
        height: isFullscreen ? '100%' : '100%',
        minHeight: isFullscreen ? '100%' : '600px',
        width: '100%',
        position: 'relative',
        marginBottom: isFullscreen ? '0px' : '0px',
        paddingRight: '20px',
      }}
    >
      <ReactFlow
        fitView
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        attributionPosition="bottom-right"
        className={`videoFunnel-flow${isConnecting ? ' connecting' : ''}`}
        onInit={onLoad}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        minZoom={0.05}
        nodesDraggable={!nodes?.find((node) => node?.data?.focus)}
        panOnDrag={!nodes?.find((node) => node?.data?.focus)}
        onSelectionChange={handleOnSelectionChange}
        onNodeDragStart={onNodeDragStart}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        onNodeClick={handleOnNodeClick}
      >
        <Background />
        <Controls />
        <MiniMap zoomable pannable />
        <ViewportLogger setViewPort={setViewPort} />
        <SetSelection
          selection={selection}
          edges={edges}
          nodes={nodes}
          selectedEdgesToAdd={selectedEdgesToAdd}
          setSelectedEdgesToAdd={setSelectedEdgesToAdd}
          selectedNodesToAdd={selectedNodesToAdd}
          setSelectedNodesToAdd={setSelectedNodesToAdd}
        />
      </ReactFlow>

      <Box className="videoFunnel-flow-add-menu" ref={containerRef}>
        <SplitButton
          variant="contained"
          color="primary"
          asFab
          disableElevation
          options={videoOptions}
          handleClick={handleClickVideo}
        />
        {orgHasEndScreen && orgHasVideoFunnelEndScreen && (
          <SplitButton
            variant="contained"
            color="primary"
            asFab
            disableElevation
            options={endScreenOptions}
            handleClick={handleClickEndScreen}
          />
        )}

        <VideoFunnelAddVideoDialog
          open={openAddVideo}
          handleClose={handleOnCloseAddVideo}
          containerRef={containerRef}
          basicOrgData={basicOrgData}
        />
        <VideoFunnelAddVideoDialog
          open={openAddVideoEmpty}
          handleClose={handleOnCloseAddVideoEmptyNode}
          containerRef={containerRef}
          basicOrgData={basicOrgData}
          maxVideos={selection?.nodes?.length === 1 && !(selection?.edges > 0) ? 1 : null}
        />
        {openEndScreen && orgHasEndScreen && (
          <EndScreenDialog
            open={openEndScreen}
            handleClose={handleCloseEndScreen}
            basicOrgData={basicOrgData}
            orgHasEndScreen={orgHasEndScreen}
            saveAndActivate
          />
        )}
        {openAddEndScreen && orgHasEndScreen && (
          <VideoFunnelAddEndScreenDialog
            open={openAddEndScreen}
            handleClose={handleCloseAddEndScreen}
            onConfirm={() => {}}
            containerRef={containerRef}
            basicOrgData={basicOrgData}
            dialogTitle={translationsStoryline.videoFunnel.addEndScreen[lang]}
          />
        )}
      </Box>
      <Box className="videoFunnel-flow-fullscreen" ref={containerRef}>
        <Fab
          color="primary"
          onClick={() => setIsFullscreen(!isFullscreen)}
          aria-label={
            isFullscreen
              ? translationsStoryline.videoFunnel.exitFullScreenAriaLabel[lang]
              : translationsStoryline.videoFunnel.enterFullScreenAriaLabel[lang]
          }
        >
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </Fab>
      </Box>
    </div>
  );
}

export default function VideoFunnelReactFlow({
  basicOrgData,
  nodes,
  setNodes,
  edges,
  setEdges,
  setReactFlowInstance,
  isFullscreen,
  setIsFullscreen,
  selection,
  setSelection,
}) {
  return (
    <ReactFlowProvider>
      <VideoFunnelReactFlowInner
        basicOrgData={basicOrgData}
        nodes={nodes}
        setNodes={setNodes}
        edges={edges}
        setEdges={setEdges}
        setReactFlowInstance={setReactFlowInstance}
        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}
        selection={selection}
        setSelection={setSelection}
      />
    </ReactFlowProvider>
  );
}

VideoFunnelReactFlow.propTypes = {};
