import React, { useState, useRef, useCallback } from 'react';
import { Position, Handle, useReactFlow } from 'reactflow';
import { useOutletContext } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import VideoFunnelAddVideoDialog from './VideoFunnelAddVideoDialog';

const MAX_VIDEOS = 1;

function VideoFunnelAddNode({ isConnectable, id }) {
  const lang = useLanguage();
  const { basicOrgData } = useOutletContext();
  const containerRef = useRef(null);
  const [openAddVideo, setOpenAddVideo] = useState(false);
  const { setNodes } = useReactFlow();

  const addVideoCTA = translationsStoryline.videoFunnel.addVideoCTAEndScreen[lang];
  const selectVideoText = translationsStoryline.videoFunnel.selectVideo[lang];

  const handleOnCloseAddVideo = useCallback(
    ({ newVideoCards }) => {
      setOpenAddVideo(false);

      if (newVideoCards?.length === MAX_VIDEOS) {
        const videoCard = newVideoCards[0];

        setNodes((prevNodes) => {
          const existingNode = prevNodes.find((node) => node.id === id);
          if (!existingNode) return prevNodes;

          const updatedNodes = prevNodes
            .filter((node) => node.id !== id)
            .map((node) => ({
              ...node,
              data: {
                ...node.data,
                shouldCheckConnections: true,
              },
            }));

          const newNode = {
            ...existingNode,
            type: 'videoFunnelVideoNode',
            data: {
              label: videoCard.question[lang],
              id,
              videoCard,
              shouldCheckConnections: true,
            },
            newNode: true,
          };

          return [...updatedNodes, newNode];
        });
      }
    },
    [id, lang, setNodes],
  );

  return (
    <div className="videoFunnel-add-node">
      <Handle id="a" type="target" position={Position.Left} isConnectable={isConnectable} />
      <div className="videoFunnel-add-node-inner">
        <b>{addVideoCTA}</b>
        <Button color="secondary" startIcon={<AddIcon />} onClick={() => setOpenAddVideo(true)}>
          {selectVideoText}
        </Button>
      </div>
      <VideoFunnelAddVideoDialog
        open={openAddVideo}
        handleClose={handleOnCloseAddVideo}
        containerRef={containerRef}
        basicOrgData={basicOrgData}
        maxVideos={MAX_VIDEOS}
      />
    </div>
  );
}

export default VideoFunnelAddNode;
