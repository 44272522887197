import { Handle, Position, useReactFlow } from 'reactflow';

import { useLanguage } from 'src/context/LanguageContext';
import useConnectable from './useConnectable';
import { translationsStoryline } from '../../../utils/translationsStoryline';

function VideoFunnelStartNode({ data, isConnectable }) {
  const lang = useLanguage();

  const hasSourceConnections = useConnectable({ id: data?.id, direction: 'source' });
  const { getEdges, getNodes } = useReactFlow();

  const isValidConnection = (connection) => {
    if (connection.sourceHandle === 'a' && hasSourceConnections) {
      return false;
    }
    const targetNode = getNodes()?.find((node) => node.id === connection.target);
    if (
      !(
        targetNode?.type === 'videoFunnelVideoNode' ||
        targetNode?.type === 'videoFunnelEmptyVideoNode'
      )
    ) {
      return false;
    }

    return (
      connection.target !== connection.source &&
      getEdges()?.filter(
        (edge) => edge.target === connection.target && edge.source === connection.source,
      )?.length === 0
    );
  };

  return (
    <div className="videoFunnel-start-node">
      <div>
        <label htmlFor="text">{translationsStoryline.videoFunnel.start[lang]}</label>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable && !hasSourceConnections}
        className="start-handle-source"
        isValidConnection={isValidConnection}
      />
    </div>
  );
}

export default VideoFunnelStartNode;
