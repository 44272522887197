import { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import Grid2 from '@mui/material/Grid2';
import PhoneNumberInput from './PhoneNumberInput';
import { translations } from '../../../utils/translations';
import { isValidEmail, isValidPhoneNumberInput } from '../../../utils/utils';
import translationsSettings from '../../../utils/translationsSettings';
import { useRecipientActions } from './actions';
import { StyleDialog } from '../../common/SimpleStyledDialog/StyleDialog';
import SplitButton from '../../common/SplitButton/SplitButton';
import AlertDialog from '../../common/AlertDialog';

export default function CreateRecipientDialog({
  onClose,
  open,
  textMessageAllowed,
  basicOrgData,
  orgRecipients,
  setRecipientOptions,
  settingsView = false,
  editRecipient,
  hasAutoRequest,
  setError,
  setInfo = () => {},
}) {
  const recipientActions = useRecipientActions();
  const [recipientFirstName, setRecipientFirstName] = useState(editRecipient?.firstName ?? '');
  const [recipientLastName, setRecipientLastName] = useState(editRecipient?.lastName ?? '');
  const [recipientTitle, setRecipientTitle] = useState(editRecipient?.title ?? '');
  const [recipientEmail, setRecipientEmail] = useState(editRecipient?.email ?? '');
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState(
    editRecipient?.phoneNumber ?? '',
  );
  const [recipientLeader, setRecipientLeader] = useState(editRecipient?.leader ?? false);
  const [recipientReceiveAutoRequest, setRecipientReceiveAutoRequest] = useState(
    editRecipient?.receiveAutoRequests ?? true,
  );
  const [recipientId] = useState(editRecipient?.id ?? '');
  const [emailErrorLabel, setEmailErrorLabel] = useState('');
  const [simpleAlertDialogOpen, setSimpleAlertDialogOpen] = useState(false);
  const [simpleAlertDialogOpenDelete, setSimpleAlertDialogOpenDelete] = useState(false);

  const hasChanges = () => {
    if (editRecipient) {
      return (
        (editRecipient.firstName
          ? recipientFirstName !== editRecipient.firstName
          : recipientFirstName !== '') ||
        (editRecipient.lastName
          ? recipientLastName !== editRecipient.lastName
          : recipientLastName !== '') ||
        (editRecipient.title ? recipientTitle !== editRecipient.title : recipientTitle !== '') ||
        (editRecipient.email ? recipientEmail !== editRecipient.email : recipientEmail !== '') ||
        (editRecipient.phoneNumber
          ? recipientPhoneNumber !== editRecipient.phoneNumber
          : recipientPhoneNumber !== '') ||
        (editRecipient.leader
          ? recipientLeader !== editRecipient.leader
          : recipientLeader !== false) ||
        (editRecipient.receiveAutoRequests
          ? recipientReceiveAutoRequest !== editRecipient.receiveAutoRequests
          : recipientReceiveAutoRequest !== true)
      );
    }
    return (
      recipientFirstName !== '' ||
      recipientLastName !== '' ||
      recipientTitle !== '' ||
      recipientEmail !== '' ||
      recipientPhoneNumber !== '' ||
      recipientLeader !== false ||
      recipientReceiveAutoRequest !== true
    );
  };

  const handleClose = () => {
    if (hasChanges()) {
      setSimpleAlertDialogOpen(true);
    } else {
      onClose();
    }
  };
  const lang = useLanguage();

  let options = [{ label: translations.videoCard.createRecipient[lang], type: 'create' }];
  if (!settingsView && !editRecipient) {
    options = [
      {
        label: translations.videoCard.createAndAddRecipient[lang],
        type: 'createAdd',
      },
      { label: translations.videoCard.createRecipient[lang], type: 'create' },
    ];
  } else if (editRecipient) {
    options = [
      {
        label: translations.videoCard.saveRecipient[lang],
        type: 'update',
      },
    ];
  } else if (settingsView) {
    options = [
      {
        label: translations.videoCard.createRecipient[lang],
        type: 'create',
      },
    ];
  }

  const handleEmailChange = (emailInput) => {
    setRecipientEmail(emailInput);
    if (emailInput?.length > 0) {
      if (orgRecipients?.some((recipient) => recipient?.email === emailInput)) {
        setEmailErrorLabel(translations.errors.emailAlreadyExists[lang]);
      } else if (recipientEmail?.length > 0 && !isValidEmail(emailInput)) {
        setEmailErrorLabel(translations.errors.INVALID_EMAIL[lang]);
      } else {
        setEmailErrorLabel('');
      }
    } else {
      setEmailErrorLabel('');
    }
  };

  const disabledCreate = () => {
    const phoneNumberValidator = isValidPhoneNumberInput({
      phoneNumber: recipientPhoneNumber,
      lang,
      orgRecipients,
      editRecipient,
    });

    if (recipientFirstName === '') {
      return true;
    }
    if (recipientLastName === '') {
      return true;
    }
    if (recipientTitle === '') {
      return true;
    }
    if (recipientEmail === '') {
      return true;
    }
    if (
      recipientPhoneNumber?.length > 4 &&
      (typeof phoneNumberValidator === 'string' || !phoneNumberValidator)
    ) {
      return true;
    }
    if (emailErrorLabel !== '') {
      return true;
    }
    return false;
  };

  const handleCreate = (option) => {
    if (option.type === 'create' || option.type === 'createAdd') {
      recipientActions
        .createRecipient({
          encodedOrgId: basicOrgData.encodedId,
          orgId: basicOrgData.id,
          firstName: recipientFirstName?.trim(),
          lastName: recipientLastName?.trim(),
          email: recipientEmail?.trim(),
          title: recipientTitle?.trim(),
          phoneNumber: recipientPhoneNumber?.trim(),
          receiveAutoRequests: recipientReceiveAutoRequest,
          leader: recipientLeader,
        })
        .then((res) => {
          setRecipientOptions(res.data.createRecipient.recipients);
          if (option?.type === 'create') {
            onClose();
          }
          if (option?.type === 'createAdd') {
            onClose(res.data.createRecipient.recipient);
          }
          setInfo(translationsSettings.recipient.createSuccess[lang]);
        })
        .catch((err) => {
          recipientActions
            .recipientsByOrgId({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
            .then((res) => {
              setRecipientOptions(res.data.recipientsByOrgId);
            })
            .catch((err) => {});
          setError(translationsSettings.recipient.createError[lang]);
        });
    } else if (option.type === 'update') {
      recipientActions
        .updateRecipient({
          encodedOrgId: basicOrgData.encodedId,
          orgId: basicOrgData.id,
          firstName: recipientFirstName?.trim(),
          lastName: recipientLastName?.trim(),
          email: recipientEmail?.trim(),
          title: recipientTitle?.trim(),
          phoneNumber: recipientPhoneNumber?.trim(),
          receiveAutoRequests: recipientReceiveAutoRequest,
          leader: recipientLeader,
          id: recipientId,
        })
        .then((res) => {
          setRecipientOptions(res.data.updateRecipient.recipients);
          onClose();
          setInfo(translationsSettings.recipient.updateSuccess[lang]);
        })
        .catch((err) => {
          setError(translationsSettings.recipient.updateError[lang]);
        });
    }
  };

  let dialogTitle = translations.videoCard.createRecipient[lang];

  if (editRecipient) {
    dialogTitle = translations.videoCard.editRecipient[lang];
  }

  const handleDelete = () => {
    recipientActions
      .deleteRecipient({
        encodedOrgId: basicOrgData.encodedId,
        orgId: basicOrgData.id,
        id: recipientId,
      })
      .then((res) => {
        setSimpleAlertDialogOpenDelete(false);
        setRecipientOptions(res.data.deleteRecipient);
        onClose();
        setInfo(translationsSettings.recipient.deleteSuccess[lang]);
      })
      .catch((err) => {
        setError(translationsSettings.recipient.deleteError[lang]);
      });
  };

  return (
    <>
      <AlertDialog
        open={simpleAlertDialogOpen}
        title={translations.videoCard.closeBeforeSave[lang]}
        description={translations.videoCard.areYouSureClose[lang]}
        confirmBtnText={translations.videoCard.confirm[lang]}
        cancelBtnText={translations.videoCard.noCancel[lang]}
        onConfirm={() => {
          setSimpleAlertDialogOpen(false);
          onClose();
        }}
        onCancel={() => {
          setSimpleAlertDialogOpen(false);
        }}
      />

      <AlertDialog
        open={simpleAlertDialogOpenDelete}
        title={translations.videoCard.deleteRecipientTitle[lang]}
        description={translations.videoCard.deleteRecipientDescription[lang]}
        confirmBtnText={translations.videoCard.confirm[lang]}
        cancelBtnText={translations.videoCard.noCancel[lang]}
        onConfirm={() => {
          handleDelete();
          setSimpleAlertDialogOpenDelete(false);
        }}
        onCancel={() => {
          setSimpleAlertDialogOpenDelete(false);
        }}
      />

      <StyleDialog onClose={handleClose} open={open}>
        <DialogTitle>
          {dialogTitle}
          <IconButton
            aria-label={translations.videoCard.closeAriaLabel[lang]}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={(e) => {
              handleClose();
            }}
          >
            <i className="icon">close</i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid2 className="section">
            <label htmlFor="recipientFirstName">
              <Tooltip title={translations.videoCard.publicVisibility[lang]}>
                <Typography variant="titleSmall" className="required-field">
                  {translations.videoCard.firstName[lang]}
                </Typography>
              </Tooltip>
              <input
                name="recipientFirstName"
                id="recipientFirstName"
                type="text"
                value={recipientFirstName}
                onChange={(e) => setRecipientFirstName(e.target.value)}
                required
              />
            </label>
            <label htmlFor="recipientLastName">
              <Tooltip title={translations.videoCard.publicVisibility[lang]}>
                <Typography variant="titleSmall" className="required-field">
                  {translations.videoCard.lastName[lang]}
                </Typography>
              </Tooltip>
              <input
                name="recipientLastName"
                id="recipientLastName"
                type="text"
                value={recipientLastName}
                onChange={(e) => setRecipientLastName(e.target.value)}
                required
              />
            </label>
            <label htmlFor="recipientTitle">
              <Tooltip title={translations.videoCard.publicVisibility[lang]}>
                <Typography variant="titleSmall" className="required-field">
                  {translations.videoCard.jobTitle[lang]}
                </Typography>
              </Tooltip>
              <input
                name="recipientTitle"
                id="recipientTitle"
                type="text"
                value={recipientTitle}
                onChange={(e) => setRecipientTitle(e.target.value)}
                required
              />
            </label>
            <label htmlFor="recipientEmail" style={{ position: 'relative' }}>
              <Tooltip title={translations.videoCard.internalVisibility[lang]}>
                <Typography variant="titleSmall" className="required-field">
                  {translations.videoCard.email[lang]}
                </Typography>
              </Tooltip>
              <Typography
                variant="bodySmall"
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  left: '20px',
                  top: '17px',
                  background: '#fff',
                  padding: '0 5px',
                }}
              >
                {emailErrorLabel}
              </Typography>
              <input
                className={`${emailErrorLabel ? 'error' : ''}`}
                type="email"
                name="recipientEmail"
                id="recipientEmail"
                value={recipientEmail}
                onChange={(e) => handleEmailChange(e.target.value)}
              />
            </label>
            <Typography variant="titleSmall" className="premium-feature-info">
              {!textMessageAllowed && translations.premiumFeature[lang]}
            </Typography>
            <label htmlFor="recipientPhoneNumber">
              <Tooltip title={translations.videoCard.internalVisibilityPhone[lang]}>
                <Typography variant="titleSmall">
                  {translations.videoCard.phoneNumber[lang]}
                </Typography>
              </Tooltip>
              <PhoneNumberInput
                disabled={!textMessageAllowed}
                actorPhoneNumber={recipientPhoneNumber}
                setActorPhoneNumber={setRecipientPhoneNumber}
                orgRecipients={orgRecipients}
                editRecipient={editRecipient}
              />
            </label>

            <label>
              <Typography variant="titleSmall">
                {translations.videoCard.rollLeader[lang]}
              </Typography>
              <label
                htmlFor="recipientLeader"
                className="checkbox-label"
                style={{ display: 'block' }}
              >
                <input
                  type="checkbox"
                  name="recipientLeader"
                  id="recipientLeader"
                  checked={recipientLeader}
                  onChange={() => {
                    setRecipientLeader(!recipientLeader);
                  }}
                />
                <Tooltip title={translations.videoCard.internalVisibilityLeader[lang]}>
                  <Typography variant="titleSmall">
                    {translations.videoCard.leader[lang]}
                  </Typography>
                </Tooltip>
              </label>
            </label>
            <label>
              <Typography variant="titleSmall" className="premium-feature-info">
                {!hasAutoRequest && translations.premiumFeature[lang]}
              </Typography>
              <Typography variant="titleSmall">
                {translations.videoCard.automation[lang]}
              </Typography>
              <label
                htmlFor="recipientAuto"
                className="checkbox-label"
                style={{ display: 'block' }}
              >
                <input
                  type="checkbox"
                  name="recipientAuto"
                  id="recipientAuto"
                  disabled={!hasAutoRequest}
                  checked={recipientReceiveAutoRequest}
                  onChange={() => {
                    setRecipientReceiveAutoRequest(!recipientReceiveAutoRequest);
                  }}
                />
                <Tooltip title={translations.videoCard.internalVisibility[lang]}>
                  <Typography variant="titleSmall">
                    {translations.videoCard.receiveAutoRequest[lang]}
                  </Typography>
                </Tooltip>
              </label>
            </label>
          </Grid2>
        </DialogContent>
        <div className="dialog-footer">
          {editRecipient && (
            <Button
              disableElevation
              onClick={() => {
                setSimpleAlertDialogOpenDelete(true);
              }}
            >
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translations.videoCard.delete[lang]}
            </Button>
          )}
          <SplitButton
            style={{
              ...(editRecipient ? null : { marginLeft: 'auto', marginRight: 'auto' }),
            }}
            options={options}
            menuPlacement="top-end"
            handleClick={(option) => handleCreate(option)}
            disabled={disabledCreate()}
            disableElevation
          />
        </div>
      </StyleDialog>
    </>
  );
}
