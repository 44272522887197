import { useState, useEffect } from 'react';

import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAdminApolloClient } from 'src/context/ApolloClientContext';
import EditPremiumFeaturesView from './EditPremiumFeaturesView/index';
import { FREEMIUM_FEATURES_FRAGMENT } from './Organizations';
import LoadingIndicator from '../common/LoadingIndicator';

const getOrg = (adminClient, id) => {
  return adminClient.query({
    query: gql`
    ${FREEMIUM_FEATURES_FRAGMENT}
        query {
          organization(id: ${id}) {
            id
            encodedId
            orgName
            premiumFeatures {
              interactionOption
              textMessage
              subtitleAllowed
              includeSubtitle
              autoReminders
              autoRequests
              editableConsentTexts
              excludeFromVimeo
              advancedWidgetStyling
              intervalURLUpdate
              advancedWidgetBehavior
              hideWatermark
              requestsLibraryBeta
              orgSubtitleUpload
              advancedWidgetStylingMobile
              widgetThree
              videoFunnel
              videoFunnelEndScreen
              widgetVersion
              packageVariant
              freemiumFeatures {
                ...FreemiumDetails
              }
              advStyleVidTitleDialog
              advStyleStories
              videoCollector
              organizationPermissionsSettings
              videoRequestEmailCustomization
              conversationalAvatar
              userLimitPerOrganization
            }
            promoteRecordVideoPortrait
            users {
              id
              superAdmin
              email
              firstName
              lastName
              jobTitle {
                en
                sv
              }
            }
          }
        }
      `,
  });
};

export const updateOrgPremium = async (adminClient, encodedOrgId, orgId, premiumFeatures) => {
  try {
    const { data, errors } = await adminClient.mutate({
      mutation: gql`
        mutation updateOrgPremium($input: OrganizationPremiumInput!) {
          updateOrgPremium(encodedOrgId: "${encodedOrgId}", input: $input) {
            statusMessage
          }
        }
      `,
      variables: {
        input: {
          orgId,
          premiumFeatures,
        },
      },
    });

    if (errors) {
      throw new Error(errors);
    }
    return data.updateOrgPremium;
  } catch (error) {
    throw new Error(error);
  }
};

export default function EditOrg() {
  const adminClient = useAdminApolloClient();
  const [orgData, setOrgData] = useState();
  const params = useParams();

  useEffect(() => {
    const orgId = Number(params.orgid);
    getOrg(adminClient, orgId).then((res) => {
      setOrgData(res.data.organization);
    });
  }, [params.orgid, adminClient]);

  if (!orgData) return <LoadingIndicator />;

  return (
    <div className="org-box">
      <div className="org-box-inner">
        <PremiumFeatures orgData={orgData} />
      </div>
    </div>
  );
}

function PremiumFeatures({ orgData }) {
  const adminClient = useAdminApolloClient();
  const [premiumFeatures, setPremiumFeatures] = useState(orgData?.premiumFeatures);

  const saveChanges = () => {
    updateOrgPremium(adminClient, orgData.encodedId, orgData.id, premiumFeatures)
      .then(() => {
        // eslint-disable-next-line no-alert
        alert('Saved!');
      })
      .catch((e) => {
        // eslint-disable-next-line no-alert
        alert(`Something went wrong ${e.message}`);
      });
  };

  return (
    <div className="create-new-page">
      <h1>
        Edit Organization #{orgData.id} ({orgData.orgName})
      </h1>
      <EditPremiumFeaturesView
        premiumFeatures={premiumFeatures}
        setPremiumFeatures={setPremiumFeatures}
        orgData={orgData}
      />
      <div>
        <Button variant="contained" color="primary" onClick={saveChanges} disableElevation>
          Save
        </Button>
      </div>
    </div>
  );
}
