import React, { useContext } from 'react';
import { AreaChart, XAxis, YAxis, Area, Tooltip, ResponsiveContainer } from 'recharts';
import { useLanguage } from 'src/context/LanguageContext';
import ToolTip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { translations } from '../../utils/translations';
import ThemeContext from '../../utils/ThemeContext';
import { fieldToEventType } from './Stats';

interface StatsData {
  current: number;
}

interface DailyData {
  date: string;
  count: number;
}

interface StatsBoxByTypeProps {
  field: keyof typeof fieldToEventType;
  statsData: Record<string, StatsData>;
  dailyData: Record<string, DailyData[]>;
  fullWidth?: boolean;
  displayStatsOverview?: boolean;
}

function StatsBoxByType({
  field,
  statsData,
  dailyData,
  fullWidth = false,
  displayStatsOverview = true,
}: StatsBoxByTypeProps) {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);

  if (!statsData || !dailyData) return null;

  const eventType = fieldToEventType[field];
  const values = statsData[field];

  const eventTitle =
    eventType in translations.statsTypes
      ? translations.statsTypes[eventType as keyof typeof translations.statsTypes][lang]
      : eventType;

  const eventDescription =
    eventType in translations.statsTypesInfo
      ? translations.statsTypesInfo[eventType as keyof typeof translations.statsTypesInfo][lang]
      : null;

  const formatYAxisTick = (value: number): string => {
    const formatter = new Intl.NumberFormat(lang, {
      notation: 'compact',
      compactDisplay: 'short',
      maximumFractionDigits: 1,
    });

    return formatter.format(value);
  };

  return (
    <div className={`stats-box ${fullWidth ? 'full-width' : ''}`}>
      {displayStatsOverview && (
        <div className="stats-overview">
          <Typography variant="titleMedium">
            {eventTitle}{' '}
            <ToolTip title={eventDescription}>
              <i className="icon" style={{ fontSize: '20px' }}>
                info
              </i>
            </ToolTip>
          </Typography>
          <div className="stats-result-wrapper">
            <Typography variant="headlineLarge" color="primary">
              {values.current}
            </Typography>
          </div>
        </div>
      )}
      <ResponsiveContainer width="100%" height={150}>
        <AreaChart
          width={730}
          height={250}
          data={dailyData[field]}
          margin={{ top: 5, right: 30, left: -20, bottom: 5 }}
        >
          <XAxis
            type="category"
            dataKey="date"
            interval="preserveStartEnd"
            axisLine={false}
            tickMargin={10}
            minTickGap={60}
            tick={{
              fontSize: 14,
            }}
            tickLine={false}
            tickFormatter={(tick) => tick}
          />
          <YAxis
            type="number"
            tickMargin={10}
            tick={{
              fontSize: 14,
            }}
            axisLine={false}
            tickLine={false}
            tickFormatter={formatYAxisTick}
            width={80}
          />
          <defs>
            <linearGradient id="count" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.palette.secondary.main} />
              <stop offset="85%" stopColor="rgba(145, 70, 255, 0)" />
            </linearGradient>
          </defs>
          <Area
            strokeWidth={2}
            type="monotone"
            dataKey="count"
            stroke={theme.palette.secondary.main}
            fill="url(#count)"
          />
          <Tooltip
            contentStyle={{
              background: theme.palette.background.paper,
              borderRadius: '10px',
              color: theme.palette.text.main,
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
              border: '0.5px solid #D8D8D8',
            }}
            formatter={(count) => [count, translations.analyticsCount[lang]]}
            labelFormatter={(label) => [label]}
            labelStyle={{
              color: theme.palette.text.main,
            }}
            itemStyle={{
              color: theme.palette.text.main,
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default StatsBoxByType;
