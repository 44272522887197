import { useCallback, useEffect, useMemo, useState } from 'react';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { matchSorter } from 'match-sorter';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import styled from '@mui/material/styles/styled';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';
import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../../utils/translations';
import { statuses } from '../../../utils/utils';
import { useRecipientActions } from './actions';
import CreateRecipientDialog from './CreateRecipientDialog';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-qamplw-MuiInputBase-root-MuiOutlinedInput-root':
    {
      paddingTop: '0px',
      paddingRight: '9px',
      paddingBottom: '0px',
      paddingLeft: '9px',
    },
  '& .MuiAutocomplete-inputRoot': {
    borderWidth: '1px',
    borderColor: 'red',

    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontSize: '14px',
    letterSpacing: '-0.5px',
    minHeight: '40px',
    fontWeight: '400',

    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      padding: '0px',
      margin: '0px',
      lineHeight: '40px',
      backgroundColor: theme.palette.background.paper,
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(219, 219, 221)',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(219, 219, 221)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(219, 219, 221)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(128, 189, 255)',
      borderWidth: '1px',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
    },
  },
}));

function RecipientInputField({
  videoCard = {},
  recipients = [],
  setRecipients,
  orgRecipients,
  textMessageAllowed,
  internalNote,
  setInternalNote,
  basicOrgData,
  hasAutoRequest,
}) {
  const lang = useLanguage();
  const recipientActions = useRecipientActions();
  const disabledReadOnly = !(!videoCard || videoCard.status === statuses.SUGGESTION);
  const [recipientOptions, setRecipientOptions] = useState(orgRecipients ?? []);
  useEffect(() => {
    recipientActions
      .recipientsByOrgId({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
      .then((res) => {
        setRecipientOptions(res.data.recipientsByOrgId);
      })
      .catch(() => {
        //
      });
  }, [recipientActions, basicOrgData.encodedId, basicOrgData.id]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(
    (value) => {
      setOpen(false);
      if (value) {
        const selectedRecipients = [...recipients, value];
        setRecipients(selectedRecipients);
      }
    },
    [recipients, setRecipients],
  );

  const filterOptions = useCallback(
    (options, { inputValue }) => {
      let filtered =
        recipients?.length > 0
          ? options.filter((r) => !recipients.some((rec) => rec.id === r.id))
          : options;

      filtered = matchSorter(filtered, inputValue, {
        keys: ['name', 'title', 'email', 'phoneNumber'],
      });

      return [{ type: 'createNew' }, ...filtered];
    },
    [recipients],
  );

  const selectRecipientContent = useMemo(() => {
    return (
      <label htmlFor="selectRecipient">
        <Typography variant="titleMedium" className="required-field">
          {translations.videoCard.selectRecipient[lang]}
        </Typography>
        <StyledAutocomplete
          sx={{
            backgroundColor: 'background.paper',
          }}
          disabled={disabledReadOnly}
          id="selectRecipient"
          multiple
          value={recipients}
          filterOptions={filterOptions}
          options={recipientOptions}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => {
            return `${option?.name} - ${option?.title}, ${option?.email}, ${option?.phoneNumber}`;
          }}
          onChange={(event, value) => {
            const [createNewOption, newValues] = _.partition(value, function (o) {
              return o?.type === 'createNew';
            });
            if (createNewOption?.length > 0) {
              handleClickOpen();
            }
            setRecipients(newValues);
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                key={`recipient-option-${option?.id}`}
                label={option?.name}
                {...getTagProps({ index })}
              />
            ));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            if (option.type === 'createNew') {
              return (
                <MenuItem {...props} style={{ fontWeight: 700 }}>
                  {translations.videoCard.createNewRecipient[lang]}
                </MenuItem>
              );
            }

            const renderHighlightText = (text, query) => {
              if (!text) return [<span key="default">-</span>]; // Handling null or undefined text

              const matches = match(text, query);
              const parts = parse(text, matches);

              return parts.map((part) => (
                <span
                  key={`${option.id}-part-${option.id}`}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {part.text}
                </span>
              ));
            };

            return (
              <MenuItem {...props}>
                <div style={{ display: 'grid' }}>
                  <div>
                    {renderHighlightText(option?.name, inputValue)}
                    {' - '}
                    {renderHighlightText(option?.title, inputValue)}
                  </div>
                  <div>
                    {renderHighlightText(option?.email, inputValue)}
                    {', '}
                    {renderHighlightText(option?.phoneNumber, inputValue)}
                  </div>
                </div>
              </MenuItem>
            );
          }}
        />
      </label>
    );
  }, [
    recipients,
    recipientOptions,
    lang,
    setRecipients,
    handleClickOpen,
    filterOptions,
    disabledReadOnly,
  ]);

  const content = useMemo(() => {
    return (
      <Stack>
        {selectRecipientContent}
        {open && (
          <CreateRecipientDialog
            open={open}
            onClose={handleClose}
            basicOrgData={basicOrgData}
            textMessageAllowed={textMessageAllowed ?? false}
            orgRecipients={recipientOptions}
            setRecipientOptions={setRecipientOptions}
            handleUpdateBoard={() => {}}
            hasAutoRequest={hasAutoRequest}
          />
        )}
        <Tooltip title={translations.videoCard.internalVisibility[lang]}>
          <label htmlFor="internalNote">
            <Typography variant="titleMedium">
              {translations.videoCard.messageToTeamMember[lang]}
            </Typography>
            <textarea
              name="internalNote"
              id="internalNote"
              readOnly={disabledReadOnly}
              disabled={disabledReadOnly}
              value={internalNote}
              onChange={(e) => setInternalNote(e.target.value)}
            />
          </label>
        </Tooltip>
      </Stack>
    );
  }, [
    selectRecipientContent,
    open,
    handleClose,
    basicOrgData,
    lang,
    textMessageAllowed,
    recipientOptions,
    setRecipientOptions,
    internalNote,
    setInternalNote,
    disabledReadOnly,
    hasAutoRequest,
  ]);
  return content;
}

export default RecipientInputField;

RecipientInputField.propTypes = {
  videoCard: PropTypes.shape({
    status: PropTypes.string,
  }),
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};
