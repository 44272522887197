import { useMemo } from 'react';
import { gql } from '@apollo/client/index';
import axios from 'axios';

import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { VIDEO_CARD_DETAILS_FRAGMENT } from '../../fragments/videoCards';
import { VIDEO_FUNNEL_DETAILS_FRAGMENT } from '../../fragments/videoFunnels';

export class Actions {
  /**
   * @type {import('src/context/ApolloClientContext').ApolloClient}
   */
  #backOfficeClient;

  constructor(backOfficeClient) {
    this.#backOfficeClient = backOfficeClient;
  }

  createVideoCard(
    encodedOrgId,
    lang,
    orgId,
    status,
    questionObj,
    actorName,
    actorEmail,
    actorTitle,
    internalNote,
    tags,
    contactForm,
    callToAction,
    hideFromLibrary,
    actorPhoneNumber,
    storylineInclude,
    recipientIds,
    acceptedUploadConsent = null,
    acceptedSoMeConsent = null,
  ) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        mutation createVideoCard(
          $encodedOrgId: String
          $orgId: Int!
          $status: String
          $lang: String
          $questionObj: QuestionInput
          $actorName: String
          $actorEmail: String
          $actorTitle: TranslationInput
          $internalNote: TranslationInput
          $tags: [Int]
          $contactForm: ContactFormInput
          $callToAction: CallToActionInput
          $hideFromLibrary: Boolean
          $actorPhoneNumber: String
          $storylineInclude: [Int]
          $recipientIds: [Int]
          $acceptedUploadConsent: String
          $acceptedSoMeConsent: String
        ) {
          createVideoCard(
            encodedOrgId: $encodedOrgId
            input: {
              orgId: $orgId
              status: $status
              lang: $lang
              question: $questionObj
              actorName: $actorName
              actorEmail: $actorEmail
              actorTitle: $actorTitle
              internalNote: $internalNote
              tags: $tags
              contactForm: $contactForm
              callToAction: $callToAction
              hideFromLibrary: $hideFromLibrary
              actorPhoneNumber: $actorPhoneNumber
              storylineInclude: $storylineInclude
              recipientIds: $recipientIds
              uploadConsentText: $acceptedUploadConsent
              soMeConsentText: $acceptedSoMeConsent
            }
          ) {
            ...VideoCardDetails
          }
        }
      `,
      variables: {
        encodedOrgId,
        orgId,
        status,
        lang,
        questionObj,
        actorName: encodeURIComponent(actorName),
        actorEmail,
        actorTitle: { [lang]: encodeURIComponent(actorTitle) },
        internalNote: { [lang]: encodeURIComponent(internalNote) },
        tags,
        contactForm,
        callToAction,
        hideFromLibrary,
        actorPhoneNumber,
        storylineInclude,
        recipientIds,
        acceptedUploadConsent: acceptedUploadConsent
          ? `"""${encodeURIComponent(acceptedUploadConsent)}"""`
          : null,
        acceptedSoMeConsent: acceptedSoMeConsent
          ? `"""${encodeURIComponent(acceptedSoMeConsent)}"""`
          : null,
      },
    });
  }

  createVideoCardAndPrepareVideoUpload(
    encodedOrgId,
    lang,
    orgId,
    status,
    questionObj,
    actorName,
    actorEmail,
    actorTitle,
    internalNote,
    tags,
    contactForm,
    callToAction,
    acceptedUploadConsent = null,
    acceptedSoMeConsent = null,
  ) {
    const uploadConsentText = acceptedUploadConsent
      ? `"""${encodeURIComponent(acceptedUploadConsent)}"""`
      : null;
    const soMeConsentText = acceptedSoMeConsent
      ? `"""${encodeURIComponent(acceptedSoMeConsent)}"""`
      : null;
    const tagIds = tags.join(',');
    return this.#backOfficeClient.mutate({
      mutation: gql`
            ${VIDEO_CARD_DETAILS_FRAGMENT}
            mutation {
              createVideoCardAndPrepareVideoUpload(encodedOrgId: "${encodedOrgId}", input: {
                orgId: ${orgId},
                status: "${status}",
                question: ${questionObj},
                actorName: "${encodeURIComponent(actorName)}",
                actorEmail: "${actorEmail}",
                actorTitle: {${lang}: "${encodeURIComponent(actorTitle)}"},
                internalNote: {${lang}: """${encodeURIComponent(internalNote)}"""},
                tags: [${tagIds}],
                contactForm: ${contactForm},
                callToAction: ${callToAction},
                uploadConsentText: ${uploadConsentText},
                soMeConsentText: ${soMeConsentText},
              }) {
                videoCard {
                  ...VideoCardDetails
                }
                token
              }
            }
          `,
    });
  }

  getSubtitleStatus(encodedOrgId, videoCardId) {
    return this.#backOfficeClient.query({
      query: gql`
        query GetSubtitleStatus($encodedOrgId: String!, $videoCardId: Int!) {
          getSubtitleStatus(encodedOrgId: $encodedOrgId, videoCardId: $videoCardId) {
            statusMessage
          }
        }
      `,
      variables: { encodedOrgId, videoCardId },
    });
  }

  createSubtitleVideoCard(encodedOrgId, orgId, videoCardId) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation CreateSubtitleTable($encodedOrgId: String, $input: SubtitleTableDomainInput) {
          createSubtitleTable(encodedOrgId: $encodedOrgId, input: $input) {
            statusMessage
          }
        }
      `,
      variables: {
        encodedOrgId,
        input: {
          orgId,
          videoCardId,
        },
      },
    });
  }

  getVideoCardPreSignedUrlAWS({ encodedOrgId, encodedVideoCardId, path, key }) {
    return this.#backOfficeClient.query({
      query: gql`
        query GetVideoCardPreSignedUrlAWS(
          $encodedOrgId: String!
          $encodedVideoCardId: String!
          $path: String!
          $key: String!
        ) {
          getVideoCardPreSignedUrlAWS(
            encodedOrgId: $encodedOrgId
            encodedVideoCardId: $encodedVideoCardId
            path: $path
            key: $key
          )
        }
      `,
      variables: {
        encodedOrgId,
        encodedVideoCardId,
        path,
        key,
      },
    });
  }

  updateVideoCard(
    encodedOrgId,
    lang,
    videoCardId,
    orgId,
    status,
    questionObj,
    actorName,
    actorEmail,
    actorTitle,
    internalNote,
    tags,
    contactForm,
    callToAction,
    hideFromLibrary,
    actorPhoneNumber,
    storylineInclude,
    recipientIds,
  ) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        mutation updateVideoCard(
          $encodedOrgId: String
          $videoCardId: Int
          $orgId: Int!
          $status: String
          $lang: String
          $questionObj: QuestionInput
          $actorName: String
          $actorEmail: String
          $actorTitle: TranslationInput
          $internalNote: TranslationInput
          $tags: [Int]
          $contactForm: ContactFormInput
          $callToAction: CallToActionInput
          $hideFromLibrary: Boolean
          $actorPhoneNumber: String
          $storylineInclude: [Int]
          $recipientIds: [Int]
        ) {
          updateVideoCard(
            encodedOrgId: $encodedOrgId
            input: {
              id: $videoCardId
              orgId: $orgId
              status: $status
              lang: $lang
              question: $questionObj
              actorName: $actorName
              actorEmail: $actorEmail
              actorTitle: $actorTitle
              internalNote: $internalNote
              tags: $tags
              contactForm: $contactForm
              callToAction: $callToAction
              hideFromLibrary: $hideFromLibrary
              actorPhoneNumber: $actorPhoneNumber
              storylineInclude: $storylineInclude
              recipientIds: $recipientIds
            }
          ) {
            ...VideoCardDetails
          }
        }
      `,
      variables: {
        encodedOrgId,
        videoCardId,
        orgId,
        status,
        lang,
        questionObj,
        actorName: encodeURIComponent(actorName),
        actorEmail,
        actorTitle: { [lang]: encodeURIComponent(actorTitle) },
        internalNote: { [lang]: encodeURIComponent(internalNote) },
        tags,
        contactForm,
        callToAction,
        hideFromLibrary,
        actorPhoneNumber,
        storylineInclude,
        recipientIds,
      },
    });
  }

  deleteVideoCard(encodedOrgId, videoCardId, orgId) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation DeleteVideoCard($encodedOrgId: String, $input: VideoCardInput) {
          deleteVideoCard(encodedOrgId: $encodedOrgId, input: $input) {
            statusMessage
          }
        }
      `,
      variables: {
        encodedOrgId,
        input: { id: videoCardId, orgId },
      },
    });
  }

  sendUploadLink(encodedOrgId, orgId, sendTo, sendToName, uploadLink, lang, question, message) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
          mutation {
            sendUploadLink(encodedOrgId: "${encodedOrgId}", input: {
              orgId: ${orgId},
              uploadLink: "${uploadLink}",
              sendTo: "${sendTo}",
              sendToName: "${encodeURIComponent(sendToName)}",
              question: """${encodeURIComponent(question)}""",
              message: """${encodeURIComponent(message)}""",
              lang: "${lang}",
            }) {
               statusMessage
            }
          }
      `,
    });
  }

  sendTextMsgUploadLink(encodedOrgId, orgId, phoneNumber, videoCardId) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation sendTextMsgUploadLink($encodedOrgId: String, $input: VideoTextMsgUploadLinkInput) {
          sendTextMsgUploadLink(encodedOrgId: $encodedOrgId, input: $input) {
            statusMessage
          }
        }
      `,
      variables: {
        encodedOrgId,
        input: { orgId, videoCardId, phoneNumber },
      },
    });
  }

  sendEmailReminderLink(encodedOrgId, videoCardId) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation SendReminderLink($encodedOrgId: String, $videoCardId: Int) {
          sendReminderLink(encodedOrgId: $encodedOrgId, videoCardId: $videoCardId) {
            statusMessage
          }
        }
      `,
      variables: { encodedOrgId, videoCardId },
    });
  }

  sendTextMsgReminderLink(encodedOrgId, videoCardId, actorPhoneNumber) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation SendTextMsgReminderLink(
          $encodedOrgId: String
          $videoCardId: Int
          $actorPhoneNumber: String
        ) {
          sendTextMsgReminderLink(
            encodedOrgId: $encodedOrgId
            videoCardId: $videoCardId
            actorPhoneNumber: $actorPhoneNumber
          ) {
            statusMessage
          }
        }
      `,
      variables: {
        encodedOrgId,
        videoCardId,
        actorPhoneNumber: encodeURIComponent(actorPhoneNumber),
      },
    });
  }

  async getVideoCardVideoFunnelStatus({ encodedOrgId, orgId, id }) {
    try {
      const { data, errors } = await this.#backOfficeClient.query({
        query: gql`
          ${VIDEO_FUNNEL_DETAILS_FRAGMENT}
          query videoCardVideoFunnelStatus($encodedOrgId: String!, $orgId: Int!, $id: Int!) {
            videoCardVideoFunnelStatus(encodedOrgId: $encodedOrgId, orgId: $orgId, id: $id) {
              ...VideoFunnelDetails
            }
          }
        `,
        variables: {
          encodedOrgId,
          orgId,
          id,
        },
      });

      if (errors) {
        throw new Error(errors);
      }
      return data.videoCardVideoFunnelStatus;
    } catch (error) {
      // handle error
      throw new Error(error);
    }
  }

  getOrgStats(encodedOrgId, startDate, endDate) {
    return this.#backOfficeClient.query({
      query: gql`
        fragment stats on AnalyticsValue {
          current
          currentMonthDaily {
            date
            count
          }
        }

        query OrgStats($encodedOrgId: String, $startDate: String!, $endDate: String!) {
          orgStats(
            encodedOrgId: $encodedOrgId
            selectedStartDate: $startDate
            selectedEndDate: $endDate
          ) {
            analytics {
              presentedVideos {
                ...stats
              }
              playedVideos {
                ...stats
              }
              pausedVideos {
                ...stats
              }
              completedVideos {
                ...stats
              }
              minimizedWidget {
                ...stats
              }
              watchAgain {
                ...stats
              }
              showNext {
                ...stats
              }
              openLibrary {
                ...stats
              }
            }
          }
        }
      `,
      variables: { encodedOrgId, startDate, endDate },
      fetchPolicy: 'no-cache',
    });
  }
}

export const useActions = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  const axiosClient = axios;
  return useMemo(() => new Actions(backOfficeClient, axiosClient), [backOfficeClient, axiosClient]);
};
