import { gql } from '@apollo/client/index';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { useLanguage } from 'src/context/LanguageContext';
import { VIDEO_REQUEST_DETAILS_FRAGMENT } from '../../fragments/videoRequests';
import { translations } from '../../utils/translations';
import ConfirmPopup from '../common/ConfirmPopup';
import { useVideoRequestActions } from './VideoCardSettings/VideoRequest/actions';
import VideoRequest from './VideoRequest/VideoRequest';
import VideoStorySettings from './VideoStorySettings';

const getVideoRequest = (backOfficeClient, encodedOrgId, videoRequestId) => {
  return backOfficeClient.query({
    query: gql`
      ${VIDEO_REQUEST_DETAILS_FRAGMENT}
      query VideoRequest($encodedOrgId: String!, $videoRequestId: Int!) {
        videoRequest(encodedOrgId: $encodedOrgId, id: $videoRequestId) {
          ...VideoRequestDetails
        }
      }
    `,
    variables: { encodedOrgId, videoRequestId },
  });
};

export default function VideoRequestStory({
  item = {},
  orgData = null,
  setAffectedItem,
  affectedId = null,
  setInfo,
  suggestedQuestions = [],
  handleDuplicateItemClick,
  handleUpdateBoard,
  orgRecipients = [],
  updateVideoRequest,
  setError,
}) {
  const lang = useLanguage();
  const videoRequestActions = useVideoRequestActions();
  const backOfficeClient = useBackOfficeApolloClient();
  const [videoRequest, setVideoRequest] = useState(item);
  const [cardIsOpen, setCardIsOpen] = useState(false);
  const [affected, setAffected] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [confirmPopData, setConfirmPopData] = useState(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    if (affected) {
      setTimeout(function () {
        if (isMounted) setAffectedItem(null);
      }, 2000);
    }
    return () => {
      isMounted = false;
    };
  }, [affected, setAffectedItem]);

  useEffect(() => {
    let isMounted = true;
    if (
      !videoRequest?.lastPullFromDatabase ||
      (videoRequest?.lastPullFromDatabase &&
        Math.floor(Math.abs(new Date() - videoRequest.lastPullFromDatabase) / 1000) > 120)
    ) {
      getVideoRequest(backOfficeClient, orgData.encodedId, videoRequest.id)
        .then((res) => {
          if (isMounted) {
            setVideoRequest({ ...res.data.videoRequest, lastPullFromDatabase: new Date() });
            updateVideoRequest({ ...res.data.videoRequest, lastPullFromDatabase: new Date() });
          }
        })
        .catch(() => {});
    }
    return () => {
      isMounted = false;
    };
  }, [
    videoRequest.lastPullFromDatabase,
    orgData.encodedId,
    videoRequest.id,
    updateVideoRequest,
    backOfficeClient,
  ]);

  useEffect(() => {
    if (affectedId === videoRequest.id) {
      setAffected(true);
    } else {
      setAffected(false);
    }
  }, [affectedId, videoRequest.id]);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  const prevOpen = useRef(openMenu);

  useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  const deleteItem = () => {
    if (videoRequest.id) {
      videoRequestActions
        .deleteVideoRequest(orgData.encodedId, videoRequest.id, orgData.id)
        .then(() => {
          handleUpdateBoard();
          setInfo(translations.videoCard.deleteSuccessMessage[lang]);
        })
        .catch(() => {
          setError(translations.videoCard.deleteErrorMessage[lang]);
        });
    }
    setConfirmPopData(null);
  };

  const recipientCount = videoRequest?.recipients?.length ?? 0;
  const firstRecipientName = videoRequest?.recipients?.[0]?.name ?? '';
  const additionalCount = recipientCount > 1 ? ` + ${recipientCount - 1}` : '';
  const recipientNames =
    recipientCount > 0
      ? `${firstRecipientName}${additionalCount}`
      : videoRequest?.recipient?.name ?? videoRequest?.actorName ?? '';

  const deleteItemConfirm = () => {
    setConfirmPopData({
      title: translations.videoCard.deleteItem[lang],
      description: translations.videoCard.areYouSureDelete[lang],
      onCancel: () => {
        setConfirmPopData(null);
      },
      onConfirm: () => deleteItem(),
    });
  };

  const updateCardIsOpen = (value) => {
    let isMounted = true;
    if (isMounted) {
      setCardIsOpen(value);
    }
    return () => {
      isMounted = false;
    };
  };

  if (videoRequest) {
    return (
      <>
        <Card
          role="button"
          tabIndex={0}
          elevation={0}
          onClick={() => updateCardIsOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              updateCardIsOpen(true);
            }
          }}
          sx={{
            m: 1,
            width: '100%',
            height: '100%',
            background: 'white',
            borderRadius: 1,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            ':hover': {
              transform: 'scale(1.03)',
              cursor: 'pointer',
            },
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 0, width: '100%', height: '100%' }}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              sx={{
                m: 0.5,
                p: 0.5,
                width: '100%',
                height: '100%',
              }}
            >
              <Grid item container xs justifyContent="space-between">
                <Grid item xs={10} xl={10} sm={10} md={10} lg={10}>
                  <Typography
                    variant="titleSmall"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      maxHeight: `${2 * 1.43}em`,
                    }}
                  >
                    {videoRequest?.questions &&
                    videoRequest?.questions[0] &&
                    videoRequest?.questions[0][lang]
                      ? videoRequest?.questions[0][lang]
                      : ''}
                  </Typography>
                </Grid>
                <Grid item xs={2} xl={2} sm={2} md={2} lg={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      height: '100%',
                      mt: 0.5,
                    }}
                  >
                    <VideoStorySettings
                      anchorRef={anchorRef}
                      openMenu={openMenu}
                      handleToggle={handleToggle}
                      handleClose={handleClose}
                      handleDuplicateItemClick={() => handleDuplicateItemClick(videoRequest)}
                      videoRequest={videoRequest}
                      deleteItemConfirm={deleteItemConfirm}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs>
                <Typography variant="bodySmall">{recipientNames}</Typography>
              </Grid>
              <Grid item xs>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    height: '100%',
                  }}
                >
                  <Typography variant="bodySmall">{videoRequest?.user?.firstName ?? ''}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>

        {cardIsOpen && (
          <VideoRequest
            item={videoRequest}
            open={cardIsOpen}
            handleClose={() => updateCardIsOpen(false)}
            setInfo={setInfo}
            setError={setError}
            orgRecipients={orgRecipients}
            suggestedQuestions={suggestedQuestions}
            orgData={orgData}
            handleUpdateBoard={handleUpdateBoard}
            setAffected={(id) => setAffectedItem(id)}
            setVideoRequest={(newItem) => {
              setVideoRequest({ ...newItem, lastPullFromDatabase: new Date() });
              updateVideoRequest({ ...newItem, lastPullFromDatabase: new Date() });
            }}
            handleDuplicateItemClick={(itemToDuplicate) => {
              handleDuplicateItemClick(itemToDuplicate);
              setCardIsOpen(false);
            }}
          />
        )}

        {confirmPopData && (
          <ConfirmPopup
            open={!!confirmPopData}
            title={confirmPopData.title}
            description={confirmPopData.description}
            onCancel={confirmPopData.onCancel}
            onConfirm={confirmPopData.onConfirm}
          />
        )}
      </>
    );
  }
}

VideoRequestStory.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
  }),
  orgData: PropTypes.shape({
    encodedId: PropTypes.string,
    id: PropTypes.number,
  }),
  setAffectedItem: PropTypes.func.isRequired,
  affectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setInfo: PropTypes.func.isRequired,
  suggestedQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  handleDuplicateItemClick: PropTypes.func.isRequired,
  handleUpdateBoard: PropTypes.func.isRequired,
  orgRecipients: PropTypes.arrayOf(PropTypes.shape({})),
  updateVideoRequest: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};
