import { Grid, Typography } from '@mui/material';

import translations from './SelectBackOfficeTranslations';

interface PendingAccessProps {
  lang: Language;
}
function PendingAccess({ lang }: PendingAccessProps) {
  return (
    <Grid container spacing={2}>
      <Typography variant="bodyLarge" align="center">
        {translations.requestAccessPending[lang]}
      </Typography>
    </Grid>
  );
}

export default PendingAccess;
