import { useQuery } from '@apollo/client';
import GET_ORGANIZATION_DATA from './organizationQuery';

const useOrganizationData = (orgNameSlug: string) => {
  const { data, loading, error, refetch } = useQuery(GET_ORGANIZATION_DATA, {
    variables: { orgNameSlug },
    skip: !orgNameSlug,
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
};

export default useOrganizationData;
