import React, { useState, useEffect, useRef, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, Outlet, useParams } from 'react-router';

import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { ProductFruits } from 'react-product-fruits';
import { useMutation } from '@apollo/client';
import { LanguageProvider, useLanguage } from 'src/context/LanguageContext';
import { CurrentUserProvider, User } from 'src/context/CurrentUserContext';
import { VideoCard } from 'src/utils/video-card/utils';
import { frontendPath } from '../../../utils/environment';
import Navigator from '../Navigator';
import ThemeContext from '../../../utils/ThemeContext';
import { useDashboardApi } from '../Dashboard/DashboardApi';
import PendingAccess from '../SelectBackOffice/PendingAccess';
import RequestAccess from '../SelectBackOffice/RequestAccess';
import translationsSelectBackOffice from '../SelectBackOffice/SelectBackOfficeTranslations';
import { REQUEST_ACCESS_MUTATION } from '../SelectBackOffice/queries';
import { PermissionType } from '../settings/Permissions/types';
import { BasicOrgData, VideoRequest } from './types';
import RenderUserStatus from './RenderUserStatus';
import useOrganizationData from './useOrganizationData';

const removeEmpty = <T extends object>(obj: T): Partial<T> =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null)) as Partial<T>;

function Template() {
  const appLang = useLanguage();
  const dashboardApi = useDashboardApi();
  const [lang, setLang] = useState(appLang);
  const navigate = useNavigate();
  const { orgnameslug: orgNameSlug } = useParams<{ orgnameslug: string }>();
  const [basicOrgData, setBasicOrgData] = useState<BasicOrgData | null>(null);

  const [videoCards, setVideoCards] = useState<VideoCard[]>([]);
  const [videoRequests, setVideoRequests] = useState<VideoRequest[]>([]);
  const suggestedQuestions = useRef<unknown[]>([]);
  const orgRecipients = useRef<unknown[]>([]);
  const storylineObjects = useRef<unknown[]>([]);
  const videoFunnelObjects = useRef<unknown[]>([]);
  const users = useRef<unknown[]>([]);
  const tagCategories = useRef<unknown[]>([]);
  const signedInUser = useRef<User | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const dashboardStats = useRef<Record<string, unknown>>({});
  const endScreens = useRef<unknown[]>([]);
  const theme = useContext(ThemeContext);
  const [showNavBar, setShowNavBar] = useState(true);
  const [userStatus, setUserStatus] = useState<PermissionType | null>(null);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = () => setSnackbar((prev) => ({ ...prev, open: false }));

  const { data, loading, error, refetch } = useOrganizationData(orgNameSlug || '');
  const [loadingComplete, setLoadingComplete] = useState(false);
  useEffect(() => {
    const retryFetchOrganizationData = async (retry = false) => {
      try {
        if (error || (!loading && !data?.organization?.organization)) {
          if (!data && !loading && !error) {
            if (!retry) {
              await retryFetchOrganizationData(true);
              return;
            }
            throw new Error('No organization data found after retry');
          }
        }

        if (data && !loading) {
          const orgData = data.organization.organization;
          const fetchedUserStatus = data.organization.userStatus;

          if (
            (fetchedUserStatus === 'self_editor' || fetchedUserStatus === 'org_admin') &&
            !orgData
          ) {
            if (!retry) {
              await retryFetchOrganizationData(true);
              return;
            }

            throw new Error('No organization data found after retry');
          }

          setUserStatus(fetchedUserStatus);
          setBasicOrgData(orgData);
          setLang(orgData?.lang || 'en');
          setLoadingComplete(true);
        }
      } catch (e) {
        navigate('/login');
      }
    };

    if (!orgNameSlug) {
      navigate('/login');
      return;
    }

    retryFetchOrganizationData();
  }, [data, loading, error, orgNameSlug, navigate, refetch]);

  useEffect(() => {
    if (!basicOrgData?.encodedId) return;

    dashboardApi
      .getUserData(basicOrgData.encodedId)
      .then((res) => {
        signedInUser.current = res.data.me;
        setUser(res?.data?.me);
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Failed to fetch user data',
          severity: 'error',
        });
        navigate('/login');
      });
  }, [dashboardApi, basicOrgData?.encodedId, navigate]);

  const isLocalhost = window.location.href.includes('localhost');
  const isStaging = window.location.href.includes('app.staging');
  const environmentPrefix = `${isLocalhost ? 'localhost-' : ''}${isStaging ? 'staging-' : ''}`;

  const [requestAccess, { loading: mutationLoading }] = useMutation(REQUEST_ACCESS_MUTATION);

  const handleRequestAccess = async () => {
    try {
      const response = await requestAccess();

      if (response.data?.requestAccess.success) {
        setUserStatus('requested_access');
        setSnackbar({
          open: true,
          message: translationsSelectBackOffice.requestAccessSuccess[lang],
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: translationsSelectBackOffice.requestAccessFailed[lang],
          severity: 'error',
        });
      }
    } catch (err) {
      setSnackbar({
        open: true,
        message: translationsSelectBackOffice.requestAccessError[lang],
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    if (snackbar.open) {
      setTimeout(() => {
        setSnackbar((prev) => ({ ...prev, open: false }));
      }, 6000);
    }
  }, [snackbar]);

  const renderUserStatus = () => {
    if (userStatus === 'can_request_access' && !basicOrgData) {
      return (
        <RenderUserStatus>
          <Typography variant="headlineLargeBoldRecoleta" align="center">
            {translationsSelectBackOffice.requestAccessTitle[lang]}
          </Typography>
          <RequestAccess
            lang={lang}
            loading={mutationLoading}
            handleRequestAccess={handleRequestAccess}
          />
        </RenderUserStatus>
      );
    }

    if (userStatus === 'requested_access' && !basicOrgData) {
      return (
        <RenderUserStatus>
          <Typography variant="headlineLargeBoldRecoleta" align="center">
            {translationsSelectBackOffice.pendingApprovalTitle[lang]}
          </Typography>
          <PendingAccess lang={lang} />
        </RenderUserStatus>
      );
    }

    if (loadingComplete && !basicOrgData && !userStatus) {
      navigate('/login');
    }

    return null;
  };

  const setVideoRequestState = (updatedVideoRequest: VideoRequest) => {
    setVideoRequests((prev) =>
      prev
        .map((videoRequest) =>
          Number(videoRequest.id) === Number(updatedVideoRequest.id)
            ? { ...videoRequest, ...removeEmpty(updatedVideoRequest) }
            : videoRequest,
        )
        .sort((a, b) => Number(b.id) - Number(a.id)),
    );
  };

  const setVideoRequestsState = (updatedVideoRequests: VideoRequest[]) => {
    setVideoRequests((prev) => {
      const merged = updatedVideoRequests.map((updated) => {
        const existing = prev.find((p) => Number(p.id) === Number(updated.id));
        return existing ? { ...existing, ...removeEmpty(updated) } : updated;
      });
      return merged.sort((a, b) => Number(b.id) - Number(a.id));
    });
  };

  const setVideoCardState = (updatedVideoCard: VideoCard) => {
    setVideoCards((prev) =>
      prev
        .map((videoCard) =>
          Number(videoCard.id) === Number(updatedVideoCard.id)
            ? { ...videoCard, ...removeEmpty(updatedVideoCard) }
            : videoCard,
        )
        .sort((a, b) => Number(b.id) - Number(a.id)),
    );
  };

  const setVideoCardsState = (updatedVideoCards: VideoCard[]) => {
    setVideoCards((prev) => {
      const merged = updatedVideoCards.map((updated) => {
        const existing = prev.find((p) => Number(p.id) === Number(updated.id));
        return existing ? { ...existing, ...removeEmpty(updated) } : updated;
      });
      return merged.sort((a, b) => Number(b.id) - Number(a.id));
    });
  };

  const renderContent = () => {
    if (!basicOrgData || !lang || !user) return <div />;

    const userProductTour = `user:${environmentPrefix}${user.encodedId}`;
    const organizationGroupID = `org:${environmentPrefix}${basicOrgData.encodedId}`;

    return (
      <div className="template back-office" style={{ backgroundColor: theme.palette.primary.main }}>
        <ProductFruits
          workspaceCode="NAJM1TZWjCR1znu2"
          language={lang}
          user={{
            username: userProductTour,
            props: { orgNameSlug: basicOrgData.orgNameSlug },
            group: { groupId: organizationGroupID },
          }}
        />
        {!frontendPath.includes('localhost') && (
          <Helmet>
            <script
              type="text/javascript"
              id="hs-script-loader"
              async
              defer
              src="//js-eu1.hs-scripts.com/25100100.js"
            />
          </Helmet>
        )}

        <header
          className="header"
          id="navigation-header-bar-left-side"
          style={{ ...(!showNavBar && { display: 'none' }) }}
        >
          <div className="header-inner">
            <div className="company-name">
              <NavLink
                to={`/${basicOrgData.orgNameSlug}/backoffice`}
                className="logo-link"
                target="_blank"
              >
                <img src="/images/lifeinside-logo.svg" alt="Company Logo" className="logo" />
              </NavLink>
            </div>
            <div className="menu primary-menu">
              <Navigator basicOrgData={basicOrgData} setShowNavBar={setShowNavBar} />
            </div>
            <div className="menu secondary-menu" />
          </div>
        </header>

        <main className="main" style={{ backgroundColor: theme.palette.background.default }}>
          <Outlet
            context={{
              basicOrgData,
              videoCards,
              setVideoCardsState,
              setVideoCardState,
              suggestedQuestions,
              orgRecipients,
              users,
              storylineObjects,
              videoFunnelObjects,
              videoRequests,
              setVideoRequestsState,
              setVideoRequestState,
              tagCategories,
              signedInUser,
              dashboardStats,
              endScreens,
              theme,
            }}
          />
        </main>
      </div>
    );
  };

  const content = user ? (
    <CurrentUserProvider currentUser={user}>
      <LanguageProvider setDetectedLanguage={setLang} setSelectedLanguage={setLang} language={lang}>
        {renderUserStatus()}
        {renderContent()}
      </LanguageProvider>
    </CurrentUserProvider>
  ) : (
    <LanguageProvider setDetectedLanguage={setLang} setSelectedLanguage={setLang} language={lang}>
      {renderUserStatus()}
      {renderContent()}
    </LanguageProvider>
  );

  return (
    <>
      {content}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Template;
