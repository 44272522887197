import { Box, Typography, Stack } from '@mui/material';
import { FreemiumFeatures } from 'src/utils/freemiumSettings';
import { PremiumFeatures } from './types';
import RenderInputField from './InputField';
import { inputGroups } from './inputGroups';

interface PremiumFeatureCheckboxProps {
  premiumFeatures: PremiumFeatures;
  handleFeatureUpdate: (updatedFeatures: Partial<PremiumFeatures>) => void;
  createNewOrg: boolean;
  orgData: { users: [{ id: number }] };
}

function PremiumFeatureCheckbox({
  premiumFeatures,
  handleFeatureUpdate,
  createNewOrg,
  orgData,
}: PremiumFeatureCheckboxProps) {
  const handleCheckboxChange = (key: keyof PremiumFeatures) => {
    const currentValue = premiumFeatures[key] !== undefined ? premiumFeatures[key] : false;
    handleFeatureUpdate({ [key]: !currentValue });
  };

  const handleTextFieldChange = (key: keyof PremiumFeatures, value: string) => {
    handleFeatureUpdate({ [key]: value });
  };

  const handleNumberFieldChange = (key: keyof PremiumFeatures, value: number) => {
    handleFeatureUpdate({ [key]: value });
  };

  const handleFreemiumFeaturesChange = (
    key: keyof PremiumFeatures,
    value: FreemiumFeatures | undefined,
  ) => {
    handleFeatureUpdate({ [key]: value });
  };

  const handleFeaturesUpdate = (features: PremiumFeatures) => {
    handleFeatureUpdate({ ...premiumFeatures, ...features });
  };

  return (
    <Box>
      {inputGroups.map((group) => (
        <Box key={`group-${group.label}`} mt={3}>
          <Stack spacing={0} key={`group-stack-${group.label}`}>
            <Typography variant="titleMedium" gutterBottom key={`group-typography-${group.label}`}>
              {group.label}
            </Typography>
            {group.inputs.map(({ key, label, defaultValue, description, disabled, dependsOn }) => (
              <Stack
                key={`group-input-stack-${group.label}-${key}`}
                spacing={0}
                sx={{
                  mb: 2,
                }}
              >
                <RenderInputField
                  componentKey={key}
                  label={label}
                  defaultValue={defaultValue}
                  premiumFeatures={premiumFeatures}
                  handleCheckboxChange={handleCheckboxChange}
                  handleTextFieldChange={handleTextFieldChange}
                  handleNumberFieldChange={handleNumberFieldChange}
                  handleFreemiumFeaturesChange={handleFreemiumFeaturesChange}
                  handleFeaturesUpdate={handleFeaturesUpdate}
                  createNewOrg={createNewOrg}
                  description={description}
                  disabled={disabled || false}
                  dependsOn={dependsOn}
                  users={orgData?.users || []}
                />
              </Stack>
            ))}
          </Stack>
        </Box>
      ))}
    </Box>
  );
}

export default PremiumFeatureCheckbox;
