import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, MenuItem, Snackbar, Alert } from '@mui/material';
import { useLanguage } from 'src/context/LanguageContext';
import { Stack } from '@mui/system';
import { gql, useQuery, useMutation } from '@apollo/client';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import translations from './translations';
import { ConnectionFormProps, ConnectionType } from './types';

const ORG_CONNECTION_QUERY = gql`
  query OrganizationConnection($orgId: Int!) {
    organizationConnection(orgId: $orgId) {
      orgId
      connectionType
      connectionName
      connectionDomain
    }
  }
`;

const SAVE_ENTERPRISE_CONNECTION_MUTATION = gql`
  mutation SaveEnterpriseConnection($input: OrganizationConnectionInput!) {
    saveEnterpriseConnection(input: $input) {
      success
      message
      connection {
        orgId
        connectionType
        connectionName
        connectionDomain
      }
    }
  }
`;

function ConnectionForm({ orgId }: ConnectionFormProps) {
  const [connectionType, setConnectionType] = useState<ConnectionType | undefined>();
  const [connectionName, setConnectionName] = useState<string>();
  const [connectionDomain, setConnectionDomain] = useState<string>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbackSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const { data, loading, error } = useQuery(ORG_CONNECTION_QUERY, {
    variables: { orgId },
  });

  const [saveEnterpriseConnection, { loading: saving, error: saveError }] = useMutation(
    SAVE_ENTERPRISE_CONNECTION_MUTATION,
  );

  const lang = useLanguage();

  useEffect(() => {
    if (data && data.organizationConnection) {
      setConnectionType(data.organizationConnection.connectionType as ConnectionType);
      setConnectionName(data.organizationConnection.connectionName);
      setConnectionDomain(data.organizationConnection.connectionDomain);
    }
  }, [data]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await saveEnterpriseConnection({
        variables: {
          input: {
            orgId,
            connectionType: connectionType || 'NONE',
            connectionName,
            connectionDomain,
          },
        },
      });

      if (response.data.saveEnterpriseConnection.success) {
        // Update state with the returned values from the backend
        const updatedConnection = response.data.saveEnterpriseConnection.connection;
        setConnectionType(updatedConnection?.connectionType as ConnectionType | undefined);
        setConnectionName(updatedConnection?.connectionName);
        setConnectionDomain(updatedConnection?.connectionDomain);

        setSnackbarSeverity('success');
        setSnackbarMessage(
          response.data.saveEnterpriseConnection.message || 'Connection saved successfully',
        );
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(
          response.data.saveEnterpriseConnection.message || 'Failed to save connection',
        );
      }

      setOpenSnackbar(true);
    } catch (e) {
      console.error('Error saving connection:', e);
      setSnackbarSeverity('error');
      setSnackbarMessage('Error saving connection');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const canSubmit = () => {
    if (connectionType === 'MicrosoftEntraId') return connectionName && connectionDomain;
    return true;
  };

  if (loading || saving) {
    return <LoadingIndicator />;
  }

  if (error || saveError) {
    return <Typography color="error">{translations.errorMessage[lang]}</Typography>;
  }

  return (
    <Box>
      <Stack spacing={2}>
        <Typography variant="titleLarge" gutterBottom>
          {translations.title[lang]}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Stack spacing={0}>
              <Typography variant="titleSmall" color="textSecondary">
                {translations.connectionType[lang]}
              </Typography>
              <TextField
                select
                value={connectionType || ''}
                onChange={(e) => setConnectionType(e.target.value as ConnectionType)}
              >
                <MenuItem value="">{translations.selectConnectionType[lang]}</MenuItem>
                <MenuItem value="MicrosoftEntraId">
                  Microsoft Entra ID (Microsoft Azure AD)
                </MenuItem>
              </TextField>
            </Stack>

            {connectionType === 'MicrosoftEntraId' && (
              <>
                <Stack spacing={0}>
                  <Typography variant="titleSmall" color="textSecondary">
                    {translations.connectionName[lang]}
                  </Typography>
                  <TextField
                    value={connectionName || ''}
                    onChange={(e) => setConnectionName(e.target.value)}
                    required
                  />
                </Stack>
                <Stack spacing={0}>
                  <Typography variant="titleSmall" color="textSecondary">
                    {translations.connectionDomain[lang]}
                  </Typography>
                  <TextField
                    value={connectionDomain || ''}
                    onChange={(e) => setConnectionDomain(e.target.value)}
                    required
                  />
                </Stack>
              </>
            )}

            <Button type="submit" variant="contained" color="primary" disabled={!canSubmit()}>
              {translations.saveConnection[lang]}
            </Button>
          </Stack>
        </form>
      </Stack>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbackSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ConnectionForm;
