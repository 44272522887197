import React, { useEffect, useCallback, useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Collapse, Divider, Grid, List, Typography, Stack } from '@mui/material';

import { useLanguage } from 'src/context/LanguageContext';
import { FreemiumTypeSettings } from 'src/utils/freemiumSettings';
import ConditionEditor from './ConditionEditor';
import { packageVariant } from '../../../utils/utils';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import { StoriesURLRulesManagerProps, UrlJsonb } from './types';
import { getTranslationKey } from './utils';

function StoriesURLRulesManager({
  basicOrgData,
  urlsJsonb = [],
  setUrlsJsonb,
  orgUrlsJsonb = [],
  otherStorylinesUrls = [],
}: StoriesURLRulesManagerProps) {
  const lang = useLanguage();
  const { premiumFeatures } = basicOrgData;

  const isFreemium = premiumFeatures?.packageVariant === packageVariant.freemium;
  const freemiumStoriesLimit: FreemiumTypeSettings | undefined = isFreemium
    ? premiumFeatures?.freemiumFeatures?.stories
    : undefined;

  const handleAddNewRule = useCallback((): void => {
    setUrlsJsonb((prevState) => [
      ...prevState,
      {
        conditionWhen: getTranslationKey(
          translationsStoryline.availableWhenConditions.exactMatch[lang],
        ),
        index: prevState.length,
      },
    ]);
  }, [setUrlsJsonb, lang]);

  const handleAddNewExclusionRule = useCallback((): void => {
    setUrlsJsonb((prevState) => [
      ...prevState,
      {
        excludedWhen: getTranslationKey(
          translationsStoryline.availableWhenConditions.exactMatch[lang],
        ),
        index: prevState.length,
      },
    ]);
  }, [setUrlsJsonb, lang]);

  useEffect(() => {
    if (urlsJsonb.length === 0) {
      handleAddNewRule();
    }
  }, [urlsJsonb.length, handleAddNewRule]);

  const whenConditions = useMemo<UrlJsonb[]>(
    () => urlsJsonb.filter((ob) => !!ob.conditionWhen),
    [urlsJsonb],
  );

  const excludeConditions = useMemo<UrlJsonb[]>(
    () => urlsJsonb.filter((ob) => !!ob.excludedWhen),
    [urlsJsonb],
  );

  const hasWhenConditions = whenConditions.length > 0;
  const hasExcludeConditions = excludeConditions.length > 0;

  const hasWhenConditionValue = whenConditions.every((ob) => ob.url && ob.url?.length > 0);
  const hasExcludeConditionValue = excludeConditions.every((ob) => ob.url && ob.url?.length > 0);

  const canAddWhenCondition =
    !freemiumStoriesLimit?.whenCondition ||
    whenConditions.length < freemiumStoriesLimit.whenCondition;

  const canAddExcludeCondition =
    !freemiumStoriesLimit?.excludeWhenCondition ||
    excludeConditions.length < freemiumStoriesLimit.excludeWhenCondition;

  return (
    <Box
      data-testid="stories-url-rules-manager"
      sx={{ width: '100%', backgroundColor: 'transparent' }}
    >
      <Grid container direction="column" spacing={2} data-testid="stories-url-rules-grid">
        {/* Show Storyline Widget Section */}
        <Grid item xs={12} data-testid="show-storyline-widget-section">
          <Typography variant="titleLarge" data-testid="show-storyline-widget-title">
            {translationsStoryline.showStorylineWidget[lang]}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <List data-testid="when-conditions-list">
            <TransitionGroup data-testid="when-conditions-transition-group">
              <Collapse data-testid="when-conditions-collapse">
                <Stack spacing={1} data-testid="when-conditions-stack">
                  {whenConditions.map((ob, index) => (
                    <ConditionEditor
                      key={`condition-${ob.index}-${ob.conditionWhen}`}
                      urlObj={ob}
                      basicOrgData={basicOrgData}
                      indexInList={index}
                      urlsJsonb={urlsJsonb}
                      setUrlsJsonb={setUrlsJsonb}
                      orgUrlsJsonb={orgUrlsJsonb}
                      otherStorylinesUrls={otherStorylinesUrls}
                      data-testid={`condition-editor-${index}`}
                    />
                  ))}
                </Stack>
              </Collapse>
            </TransitionGroup>
          </List>
        </Grid>

        {/* Buttons Section */}
        <Grid item xs={12} data-testid="buttons-section">
          <Box sx={{ pl: 2 }}>
            <Stack direction="row" spacing={2} data-testid="buttons-stack">
              {/* Add Rule Button */}
              <Button
                data-testid="add-rule-button"
                color="primary"
                onClick={handleAddNewRule}
                disabled={(hasWhenConditions && !hasWhenConditionValue) || !canAddWhenCondition}
                startIcon={<AddIcon />}
              >
                {translationsStoryline.addRule[lang]}
              </Button>

              {/* Conditionally render Add Exclusion Rule Button on the same row */}
              {!hasExcludeConditions && (
                <Button
                  data-testid="add-exclusion-rule-button"
                  color="primary"
                  onClick={handleAddNewExclusionRule}
                  disabled={
                    (hasWhenConditions && !hasWhenConditionValue) || !canAddExcludeCondition
                  }
                  startIcon={<AddIcon />}
                >
                  {translationsStoryline.addExclusionRule[lang]}
                </Button>
              )}
            </Stack>
          </Box>
        </Grid>

        {/* Exclusion Rules Section */}
        {hasExcludeConditions && (
          <>
            <Grid item xs={12} data-testid="exclusion-rules-divider">
              <Divider sx={{ width: '100%', bgcolor: '#e9edfd' }} />
              <Box sx={{ mt: 2 }}>
                <Typography variant="titleLarge" data-testid="hide-storyline-widget-title">
                  {translationsStoryline.hideStorylineWidget[lang]}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} data-testid="exclusion-conditions-list-grid">
              <List data-testid="exclude-conditions-list">
                <TransitionGroup data-testid="exclude-conditions-transition-group">
                  <Collapse data-testid="exclude-conditions-collapse">
                    <Stack spacing={1} data-testid="exclude-conditions-stack">
                      {excludeConditions.map((ob, index) => (
                        <ConditionEditor
                          key={`exclude-${ob.index}-${ob.excludedWhen}`}
                          urlObj={ob}
                          basicOrgData={basicOrgData}
                          indexInList={index}
                          urlsJsonb={urlsJsonb}
                          setUrlsJsonb={setUrlsJsonb}
                          orgUrlsJsonb={orgUrlsJsonb}
                          otherStorylinesUrls={[]}
                          data-testid={`exclude-condition-editor-${index}`}
                        />
                      ))}
                    </Stack>
                  </Collapse>
                </TransitionGroup>
              </List>
            </Grid>

            {/* Add Exclusion Rule Button on a different row when exclusion rules exist */}
            <Grid item xs={12} sx={{ pl: 2 }} data-testid="add-exclusion-rule-button-grid">
              <Button
                data-testid="add-exclusion-rule-button-secondary"
                color="primary"
                onClick={handleAddNewExclusionRule}
                disabled={
                  (hasExcludeConditions && !hasExcludeConditionValue) || !canAddExcludeCondition
                }
                startIcon={<AddIcon />}
              >
                {translationsStoryline.addExclusionRule[lang]}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default StoriesURLRulesManager;
