import { useMemo } from 'react';
import { useStore, getConnectedEdges, useReactFlow } from 'reactflow';

export default function useConnectable({ id, direction, sourceHandle }) {
  const idString = id != null ? String(id) : '';

  const { getNode } = useReactFlow();
  const node = getNode(idString);

  const edges = useStore((store) => store.edges);

  const hasConnections = useMemo(() => {
    if (!node) return false;

    const connectedEdges = getConnectedEdges([node], edges).filter((edge) => {
      const matchesDirection = edge[direction] === idString;
      const matchesSourceHandle = !sourceHandle || edge.sourceHandle === sourceHandle;
      return matchesDirection && matchesSourceHandle;
    });

    return connectedEdges.length > 0;
  }, [node, edges, idString, direction, sourceHandle]);

  return hasConnections;
}
