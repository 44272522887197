import { translationsStoryline } from 'src/utils/translationsStoryline';
import { isValidHttpUrl } from 'src/utils/utils';
import { UrlJsonb } from './types';

type AvailableWhenConditionKey = keyof typeof translationsStoryline.availableWhenConditions;

export const getTranslationKey = (value: string): AvailableWhenConditionKey => {
  return Object.keys(translationsStoryline.availableWhenConditions).find((key) => {
    const condition =
      translationsStoryline.availableWhenConditions[key as AvailableWhenConditionKey];
    return condition.en === value || condition.sv === value;
  }) as AvailableWhenConditionKey;
};

export const getPlaceholderText = ({
  selectedConditionValue,
  lang,
}: {
  selectedConditionValue: string;
  lang: Language;
}) => {
  switch (selectedConditionValue) {
    case translationsStoryline.availableWhenConditions.exactMatch[lang]:
      return 'https://lifeinside.io/article';
    case translationsStoryline.availableWhenConditions.beginsWith[lang]:
      return `https://lifeinside.io/lifeinside/article ${translationsStoryline.or[lang].toLowerCase()} /lifeinside/article`;
    case translationsStoryline.availableWhenConditions.endsWith[lang]:
      return '/lifeinside/article';
    case translationsStoryline.availableWhenConditions.contains[lang]:
      return '/lifeinside/';
    case translationsStoryline.availableWhenConditions.wildCard[lang]:
      return `https://lifeinside.io/*/lifeinside/*/product/ ${translationsStoryline.or[lang].toLowerCase()} */lifeinside/*/product/`;
    default:
      return 'https://lifeinside.io/article';
  }
};

export const getTooltipHelp = (selectedConditionValue: string, lang: Language) => {
  const condition = Object.keys(translationsStoryline.availableWhenConditions).find(
    (cond) => cond === selectedConditionValue,
  );

  return translationsStoryline.availableWhenConditions[condition as AvailableWhenConditionKey]
    .tooltipHelpText[lang];
};

export const checkIfUrlWhenConditionIsUsed = ({
  urlsJsonb,
  otherStorylinesUrls,
}: {
  urlsJsonb: UrlJsonb[];
  otherStorylinesUrls: UrlJsonb[];
}) =>
  urlsJsonb
    ?.filter((urlJsonb) => !!urlJsonb.conditionWhen)
    ?.some((urlJsonb) =>
      otherStorylinesUrls?.some(
        (otherStorylinesUrlJsonb) =>
          urlJsonb.conditionWhen === otherStorylinesUrlJsonb.conditionWhen &&
          urlJsonb.url === otherStorylinesUrlJsonb.url,
      ),
    );
export const checkIfUrlWhenConditionIsUsedByThis = ({ urlsJsonb }: { urlsJsonb: UrlJsonb[] }) =>
  urlsJsonb?.some((urlJsonb) =>
    urlsJsonb?.some((otherStorylinesUrlJsonb) => {
      const condition1 = urlJsonb?.conditionWhen || urlJsonb?.excludedWhen;
      const condition2 =
        otherStorylinesUrlJsonb?.conditionWhen || otherStorylinesUrlJsonb?.excludedWhen;
      return (
        urlJsonb.index !== otherStorylinesUrlJsonb.index &&
        condition1 === condition2 &&
        urlJsonb.url === otherStorylinesUrlJsonb.url
      );
    }),
  );

export const checkIfHasAnyConditionWhen = ({ urlsJsonb }: { urlsJsonb: UrlJsonb[] }) => {
  return urlsJsonb?.some(
    (urlJsonb) => !!urlJsonb?.conditionWhen && urlJsonb?.url && urlJsonb.url.length > 0,
  );
};

export const checkIfHasAnyExactMatchInvalid = ({ urlsJsonb }: { urlsJsonb: UrlJsonb[] }) => {
  return urlsJsonb?.some((urlJsonb) => {
    const condition = urlJsonb?.conditionWhen || urlJsonb?.excludedWhen;

    if (condition === 'exactMatch') {
      if (urlJsonb?.url && urlJsonb.url.length > 0) {
        return !isValidHttpUrl(urlJsonb.url);
      }
      return true;
    }
    return false;
  });
};
