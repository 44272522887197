/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

import { END_SCREEN_DETAIL_FRAGMENT } from './endScreens';
import { VIDEO_CARD_DETAILS_FRAGMENT } from './videoCards';

const VIDEO_FUNNEL_EDGE_DETAILS_FRAGMENT = gql`
  fragment VideoFunnelEdgeDetails on VideoFunnelEdge {
    id
    source
    sourceHandle
    target
    targetHandle
  }
`;

const VIDEO_FUNNEL_NODE_DETAILS_FRAGMENT = gql`
  ${VIDEO_CARD_DETAILS_FRAGMENT}
  ${END_SCREEN_DETAIL_FRAGMENT}
  fragment VideoFunnelNodeDetails on VideoFunnelNode {
    data {
      videoTitle
      videoCard {
        ...VideoCardDetails
      }
      endScreen {
        ...EndScreenDetails
      }
      isStartNode
      handleATitle
      handleBTitle
      handleCTitle
      handleDTitle
      ctaTiming {
        start
        end
      }
    }
    id
    position {
      x
      y
    }
    type
  }
`;

export const VIDEO_FUNNEL_DETAILS_FRAGMENT = gql`
  ${VIDEO_FUNNEL_NODE_DETAILS_FRAGMENT}
  ${VIDEO_FUNNEL_EDGE_DETAILS_FRAGMENT}
  fragment VideoFunnelDetails on VideoFunnel {
    id
    user {
      id
      firstName
      lastName
    }
    title
    orgId
    status
    userId
    urlsJsonb {
      url
      srcImage
      imageUpdatedAt
      srcImageFullPage
      conditionWhen
      excludedWhen
      storylineId
      override
    }
    createdAt
    updatedAt
    startDate
    endDate
    videoFunnelNodes {
      ...VideoFunnelNodeDetails
    }
    videoFunnelEdges {
      ...VideoFunnelEdgeDetails
    }
    type
  }
`;
