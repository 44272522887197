import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import translations from './SelectBackOfficeTranslations';

interface RequestAccessProps {
  lang: Language;
  loading: boolean;
  handleRequestAccess: () => void;
}
function renderRequestAccess({ lang, loading, handleRequestAccess }: RequestAccessProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="bodyLarge" align="center">
          {translations.requestAccessText[lang]}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleRequestAccess}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                color="secondary"
                data-testid="loading-indicator-progress"
                size="24px"
              />
            )}
            {loading
              ? translations.requestAccessButtonRequesting[lang]
              : translations.requestAccessButtonTitle[lang]}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default renderRequestAccess;
