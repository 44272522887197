import React from 'react';
import { TablePagination } from '@mui/material';

interface VideoTablePaginationProps {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  lang: Language;
  disabled?: boolean;
}

const translations = {
  rowsPerPage: {
    en: 'Rows per page',
    sv: 'Rader per sida',
  },
  ariaLabel: {
    en: 'Table pagination',
    sv: 'Tabellpaginering',
  },
};

function VideoTablePagination({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  lang,
  disabled = false,
}: VideoTablePaginationProps) {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      aria-label={translations.ariaLabel[lang]}
      data-testid="table-pagination"
      labelRowsPerPage={`${translations.rowsPerPage[lang]}:`}
      disabled={disabled}
    />
  );
}

export default VideoTablePagination;
