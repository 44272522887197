import React, { useContext, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import ThemeContext from 'src/utils/ThemeContext';
import { frontendPath } from 'src/utils/environment';
import { BasicOrgData, TabData } from './types';

import Tab from './Tab';
import getAvailableTabs from './getAvailableTabs';
import useUserData from '../settings/userDataHook/userDataHook';

// bestTabMatchIndex returns index of the tab in tabs that best matches currentPath.
const bestTabMatchIndex = (tabs: TabData[], currentPath: string) => {
  let bestMatch = -1;
  let bestIndex = -1;
  for (let tabIndex = 0; tabIndex < tabs.length; tabIndex += 1) {
    const tab = tabs[tabIndex];
    if (!tab.path || tab.openInNewTab || !currentPath.startsWith(tab.path)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    let match = 0;
    for (let c = 0; c < tab.path.length; c += 1) {
      if (tab.path[c] !== currentPath[c]) break;
      match += 1;
    }
    if (match > bestMatch) {
      bestMatch = match;
      bestIndex = tabIndex;
    }
  }
  return bestIndex;
};

interface NavigatorProps {
  basicOrgData: BasicOrgData;
  setShowNavBar: (showNavBar: boolean) => void;
}

function Navigator({ basicOrgData, setShowNavBar }: NavigatorProps) {
  const location = useLocation();
  const theme = useContext(ThemeContext);
  const auth0 = useAuth0();
  const logout = () => {
    const { orgNameSlug } = basicOrgData;
    const params = new URLSearchParams({ orgNameSlug });
    const returnTo = `${frontendPath}/login?${params.toString()}`;
    auth0.logout({ logoutParams: { returnTo } });
  };

  const { data: userData, isSuperAdmin } = useUserData(basicOrgData.encodedId);

  const availableTabs: TabData[] = getAvailableTabs({
    basicOrgData,
    userData: userData?.me,
    isSuperAdmin: isSuperAdmin ?? false,
    logout,
    theme,
  });

  const activeTab = useMemo(
    () => bestTabMatchIndex(availableTabs, location.pathname),
    [availableTabs, location.pathname],
  );

  if (availableTabs[activeTab]?.hideNavBar) {
    setShowNavBar(false);
  } else {
    setShowNavBar(true);
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        mt: 2,
        mb: 3,
      }}
    >
      {availableTabs.map((tab, index) => (
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12} key={`${tab.label}-container`}>
          <Tab key={`${tab.label}-tab`} tab={tab} isActive={activeTab === index} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Navigator;
