import { Box, Stack } from '@mui/material';
import Layout from 'src/components/admin/ConversationalAvatar/Layout';

function RenderUserStatus({ children }: { children: React.ReactNode }) {
  return (
    <Layout>
      <Box
        sx={{
          border: '0px',
          borderRadius: '16px',
          ml: 1,
          mr: 1,
          p: 2,
          pt: 3,
          bgcolor: 'background.default',
          maxWidth: 'sm',
        }}
      >
        <Stack justifyContent="center" alignItems="center" spacing={2} maxWidth="sm">
          {children}
        </Stack>
      </Box>
    </Layout>
  );
}

export default RenderUserStatus;
