import { createTheme, ThemeOptions } from '@mui/material/styles';
import componentsStyleDefinitions from './componentsStyleDefinitions';
import typographyDefinition from './typographyDefinition';

import fontFamily from './fontFamily';

const defaultTheme = createTheme();

const typography = {
  ...typographyDefinition,
  fontFamily,
};

const shape = {
  button: {
    borderRadius: 20,
  },
  borderRadius: 8,
};

const lightThemeOptions: ThemeOptions = {
  ...defaultTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#121212',
      dark: '#414141',
      light: '#414141',
    },
    secondary: {
      main: '#9400FE',
      light: '#A933FE',
      dark: '#6700B1',
    },
    tertiary: {
      main: '#FFC601',
      light: '#FFD133',
      dark: '#B28A00',
    },
    background: {
      default: '#F5F5F5',
      paper: '#FFFFFF',
      disabled: '#00000025',
      transparent: '#FFFFFFCC',
    },
    text: {
      primary: '#121212',
      secondary: '#535353',
      light: '#FFFFFF',
    },
    error: {
      main: '#D62D20',
      light: '#DE574C',
      dark: '#951F16',
    },
    info: {
      main: '#0057E7',
      light: '#3378EB',
      dark: '#003CA1',
    },
    success: {
      main: '#008744',
      light: '#339F69',
      dark: '#005E2F',
    },
    warning: {
      main: '#FFA700',
      light: '#FFB833',
      dark: '#B27400',
    },
    divider: '#EBEBEB',
    navbar: {
      main: '#E3E3E4',
      light: '#FFFFFF',
      button: '#4E5057',
    },
    softGray80: '#D9D9D9CC',
    neutralShade: {
      main: '#E3E3E4',
      light: '#FFFFFF',
      dark: '#4E5057',
    },
  },
  typography,
  shape,
  components: componentsStyleDefinitions,
};

export default lightThemeOptions;
