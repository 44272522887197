import React, { useState, useEffect, useMemo } from 'react';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import { matchSorter } from 'match-sorter';

import { useStorylinesApi } from '../../api/storylines';

import StorylineHead from './Storyline/StorylineHead';
import StorylineGrid from './Storyline/StorylineGrid';
import useUserData from './settings/userDataHook/userDataHook';
import useOrgUsers from './settings/Permissions/useOrgUsers';

export default function Stories() {
  const navigate = useNavigate();
  const location = useLocation();
  const { storylineData, successMsg } = location.state || {};

  const storylinesApi = useStorylinesApi();

  const [affectedItem, setAffectedItem] = useState(storylineData);
  const { basicOrgData } = useOutletContext();
  const [storylines, setStorylines] = useState([]);
  const [urlsJsonb, setUrlsJsonb] = useState([]);
  const [info, setInfo] = useState(successMsg || null);
  const [storylineSearchField, setStoryLineSearchField] = useState('');

  const { data: userData, isSuperAdmin } = useUserData(basicOrgData.encodedId);
  const hasAccess =
    isSuperAdmin ||
    userData?.me?.userPermissions?.some(
      (up) => basicOrgData.id === up.orgId && up.name === 'org_admin',
    );

  const { orgUsers } = useOrgUsers({
    encodedOrgId: basicOrgData.encodedId,
    userDataExists: Boolean(userData),
    skip: !hasAccess,
  });

  useEffect(() => {
    if (info) {
      const infoTimeout = setTimeout(() => {
        setInfo(null);
        if (affectedItem) {
          setAffectedItem(null);
        }
      }, 3000);

      return () => clearTimeout(infoTimeout);
    }

    return undefined;
  }, [info, affectedItem]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const minimalResult = await storylinesApi.getStorylinesMinimal(basicOrgData.encodedId);
        const storylinesData = minimalResult?.data?.orgStorylinesMinimal?.storylines || [];
        const videoFunnelsData = minimalResult?.data?.orgStorylinesMinimal?.videoFunnels || [];
        const combinedData = [...storylinesData, ...videoFunnelsData];

        const extendedResult = await storylinesApi.getStorylinesExtended(basicOrgData.encodedId);
        const fetchedUsers = extendedResult?.data?.orgStorylinesExtended?.users || [];
        setUrlsJsonb(extendedResult.data.orgStorylinesExtended?.urlsJsonb || []);

        // Combine storylines with user data
        const enrichedData = combinedData.map((story) => ({
          ...story,
          user: fetchedUsers.find((u) => u.id === story.userId),
        }));

        // Sort and set storylines
        enrichedData.sort((a, b) => (a.title || '').localeCompare(b.title || ''));
        setStorylines(enrichedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [basicOrgData.encodedId, storylinesApi]);

  useEffect(() => {
    if (hasAccess && orgUsers && orgUsers?.length > 0 && storylines && storylines?.length > 0) {
      setStorylines((prev) => {
        if (!prev || prev?.length === 0) {
          return prev;
        }

        const newStoriesList = [...prev];

        const stories = newStoriesList.map((endScreen) => {
          const user = orgUsers?.find((u) => u.id === endScreen.userId);
          return {
            ...endScreen,
            user,
          };
        });

        return stories;
      });
    }
  }, [storylines, hasAccess, orgUsers, userData]);

  const handleSelected = (item) => {
    if (item?.type === 'videoFunnel') {
      navigate(`/${basicOrgData.orgNameSlug}/backoffice/video-funnel`, {
        state: { videoFunnelData: item, urlsJsonb },
      });
    } else {
      navigate(`/${basicOrgData.orgNameSlug}/backoffice/storyline`, {
        state: { storylineData: item, urlsJsonb },
      });
    }
  };

  const handleCreateStoryline = () => {
    navigate(`/${basicOrgData.orgNameSlug}/backoffice/storyline`, {
      state: { storylineData: null, urlsJsonb },
    });
  };

  const handleCreateNewVideoFunnel = () => {
    navigate(`/${basicOrgData.orgNameSlug}/backoffice/video-funnel`, {
      state: { videoFunnelData: null, urlsJsonb },
    });
  };

  const filteredStorylines = useMemo(() => {
    if (!storylineSearchField) {
      return [...(storylines ?? [])].sort((a, b) => {
        const titleA = a.title?.toLowerCase() || '';
        const titleB = b.title?.toLowerCase() || '';

        if (!titleA && titleB) return 1;
        if (!titleB && titleA) return -1;
        return titleA.localeCompare(titleB);
      });
    }

    const keys = hasAccess
      ? [
          'title',
          (item) => item.user?.firstName || '',
          (item) => item.user?.lastName || '',
          (item) => item.user?.email || '',
          (item) => `${item.user?.firstName} ${item.user?.lastName}`,
          (item) => item.urls?.join(' ') || '',
          (item) => item.urlsJsonb?.map((urlObj) => urlObj.url).join(' ') || '',
        ]
      : [
          'title',
          (item) => item.urls?.join(' ') || '',
          (item) => item.urlsJsonb?.map((urlObj) => urlObj.url).join(' ') || '',
        ];

    return matchSorter(storylines ?? [], storylineSearchField, {
      keys,
      threshold: matchSorter.rankings.CONTAINS,
    });
  }, [storylineSearchField, storylines, hasAccess]);

  return (
    <div className="storyline">
      <div className="admin-library">
        {info && <div className="info-pop">{info}</div>}
        <StorylineHead
          onCreateStoryline={handleCreateStoryline}
          storylineSearchField={storylineSearchField}
          setStoryLineSearchField={setStoryLineSearchField}
          basicOrgData={basicOrgData}
          onCreateNewVideoFunnel={handleCreateNewVideoFunnel}
        />
        <StorylineGrid
          storylineObjects={filteredStorylines}
          handleSelected={handleSelected}
          basicOrgData={basicOrgData}
          affectedItem={affectedItem}
          hasAccess={hasAccess}
        />
      </div>
    </div>
  );
}
