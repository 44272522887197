import React from 'react';
import ThemeContext from 'src/utils/ThemeContext';
import MyPageOutlined from 'src/components/common/icons/myPage/MyPageOutlined';
import MyPageFilled from 'src/components/common/icons/myPage/MyPageFilled';
import { BasicOrgData, TabData } from './types';
import DashboardOutlinedIcon from '../../common/icons/dashboard/DashboardOutlined';
import DashboardFilledIcon from '../../common/icons/dashboard/DashboardFilled';
import PlannerOutlinedIcon from '../../common/icons/planner/PlannerOutlined';
import PlannerFilledIcon from '../../common/icons/planner/PlannerFilled';
import StoriesOutlinedIcon from '../../common/icons/stories/StoriesOutlined';
import StoriesFilledIcon from '../../common/icons/stories/StoriesFilled';
import LibraryOutlinedIcon from '../../common/icons/library/LibraryOutlined';
import LibraryFilledIcon from '../../common/icons/library/LibraryFilled';
import EndScreensOutlinedIcon from '../../common/icons/endScreens/EndScreensOutlined';
import EndScreensFilledIcon from '../../common/icons/endScreens/EndScreensFilled';
import AnalyticsOutlinedIcon from '../../common/icons/analytics/AnalyticsOutlined';
import AnalyticsFilledIcon from '../../common/icons/analytics/AnalyticsFilled';
import PublicLibraryOutlinedIcon from '../../common/icons/publicLibrary/PublicLibraryOutlined';
import PublicLibraryFilledIcon from '../../common/icons/publicLibrary/PublicLibraryFilled';
import SettingsFilledIcon from '../../common/icons/settings/SettingsFilled';
import SettingsOutlinedIcon from '../../common/icons/settings/SettingsOutlined';
import LogOutOutlinedIcon from '../../common/icons/logOut/LogOutOutlined';
import LogOutFilledIcon from '../../common/icons/logOut/LogOutFilled';
import VideoCollectorOutlinedIcon from '../../common/icons/videoCollector/VideoCollectorOutlined';
import VideoCollectorFilledIcon from '../../common/icons/videoCollector/VideoCollectorFilled';
import NavigatorTranslations from './NavigatorTranslations';
import { UserResult } from '../settings/userDataHook/types';

// Helper function to determine if user has permission to see certain tabs
const userHasPermissions = (
  basicOrgData: BasicOrgData,
  isSuperAdmin: boolean,
  userData?: UserResult,
) => {
  if (!userData) {
    return false;
  }
  return (
    isSuperAdmin ||
    userData?.userPermissions?.some(
      (permission) => permission.orgId === basicOrgData.id && permission.name === 'org_admin',
    )
  );
};

function getAvailableTabs({
  basicOrgData,
  userData,
  isSuperAdmin,
  logout,
  theme,
}: {
  basicOrgData: BasicOrgData;
  userData?: UserResult;
  isSuperAdmin: boolean;
  logout: () => void;
  theme: React.ContextType<typeof ThemeContext>;
}): TabData[] {
  const orgHasWidgetThree = basicOrgData?.premiumFeatures?.widgetThree ?? false;
  const orgHasVideoFunnel = basicOrgData?.premiumFeatures?.videoFunnel ?? false;
  const orgHasVideoFunnelEndScreen = basicOrgData?.premiumFeatures?.videoFunnelEndScreen ?? false;
  const orgHasVideoCollector = basicOrgData?.premiumFeatures?.videoCollector ?? false;
  const excludeFromVimeo = basicOrgData?.premiumFeatures?.excludeFromVimeo ?? false;
  const orgHasConversationalAvatar = basicOrgData?.premiumFeatures?.conversationalAvatar ?? false;

  const hasPermission = userHasPermissions(basicOrgData, isSuperAdmin, userData);

  const tabs: (TabData | boolean | undefined)[] = [
    excludeFromVimeo && {
      label: NavigatorTranslations.home[basicOrgData.lang],
      path: `/${basicOrgData.orgNameSlug}/backoffice`,
      iconOutlined: <DashboardOutlinedIcon />,
      iconHover: <DashboardFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <DashboardFilledIcon fill={theme.palette.background.paper} />,
      index: 0, // Add index for sorting
    },
    {
      label: NavigatorTranslations.videoCollector[basicOrgData.lang],
      path: `/${basicOrgData.orgNameSlug}/backoffice/video-collector`,
      iconOutlined: <VideoCollectorOutlinedIcon />,
      iconHover: <VideoCollectorFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <VideoCollectorFilledIcon fill={theme.palette.navbar.light} />,
      disabled: !orgHasVideoCollector,
      isPremium: true,
      index: 1,
    },
    {
      label: basicOrgData?.premiumFeatures?.requestsLibraryBeta
        ? NavigatorTranslations.videoPlanner[basicOrgData.lang]
        : NavigatorTranslations.videoLibrary[basicOrgData.lang],
      path: `/${basicOrgData.orgNameSlug}/backoffice/planner`,
      iconOutlined: <PlannerOutlinedIcon />,
      iconHover: <PlannerFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <PlannerFilledIcon fill={theme.palette.navbar.light} />,
      index: 2,
    },

    basicOrgData?.premiumFeatures?.requestsLibraryBeta && {
      label: `${NavigatorTranslations.planner[basicOrgData.lang]} (beta)`,
      path: `/${basicOrgData.orgNameSlug}/backoffice/planner-beta`,
      iconOutlined: <PlannerOutlinedIcon />,
      iconHover: <PlannerFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <PlannerFilledIcon fill={theme.palette.navbar.light} />,
      index: 3,
    },

    orgHasVideoFunnel &&
      orgHasWidgetThree && {
        label:
          orgHasVideoFunnel && orgHasWidgetThree
            ? NavigatorTranslations.stories[basicOrgData.lang]
            : NavigatorTranslations.storyline[basicOrgData.lang],
        path: `/${basicOrgData.orgNameSlug}/backoffice/stories`,
        iconOutlined: <StoriesOutlinedIcon />,
        iconHover: <StoriesFilledIcon fill={theme.palette.navbar.main} />,
        iconFilled: <StoriesFilledIcon fill={theme.palette.navbar.light} />,
        index: 4,
      },
    basicOrgData?.premiumFeatures?.requestsLibraryBeta && {
      label: NavigatorTranslations.videoLibrary[basicOrgData.lang],
      path: `/${basicOrgData.orgNameSlug}/backoffice/library`,
      iconOutlined: <LibraryOutlinedIcon />,
      iconHover: <LibraryFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <LibraryFilledIcon fill={theme.palette.navbar.light} />,
      index: 5,
    },
    orgHasVideoFunnelEndScreen && {
      label: NavigatorTranslations.endScreens[basicOrgData.lang],
      path: `/${basicOrgData.orgNameSlug}/backoffice/end-screens`,
      iconOutlined: <EndScreensOutlinedIcon />,
      iconHover: <EndScreensFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <EndScreensFilledIcon fill={theme.palette.navbar.light} />,
      index: 6,
    },
    {
      label: NavigatorTranslations.analytics[basicOrgData.lang],
      path: `/${basicOrgData.orgNameSlug}/backoffice/analytics`,
      iconOutlined: <AnalyticsOutlinedIcon />,
      iconHover: <AnalyticsFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <AnalyticsFilledIcon fill={theme.palette.navbar.light} />,
      index: 6,
    },
    hasPermission && {
      label: NavigatorTranslations.library[basicOrgData.lang],
      path: `/${basicOrgData.orgNameSlug}/`,
      openInNewTab: true,
      iconOutlined: <PublicLibraryOutlinedIcon />,
      iconHover: <PublicLibraryFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <PublicLibraryFilledIcon fill={theme.palette.navbar.light} />,
      index: 7,
    },
    orgHasConversationalAvatar && {
      label: NavigatorTranslations.conversationalAvatar[basicOrgData.lang],
      path: `/${basicOrgData.orgNameSlug}/backoffice/conversational-avatar`,
      iconOutlined: <MyPageOutlined />,
      iconHover: <MyPageFilled fill={theme.palette.navbar.main} />,
      iconFilled: <MyPageFilled fill={theme.palette.navbar.light} />,
      disabled: !orgHasConversationalAvatar,
      hideNavBar: true,
      isPremium: true,
      index: 8,
    },
    {
      label: NavigatorTranslations.orgSettings[basicOrgData.lang],
      path: `/${basicOrgData.orgNameSlug}/backoffice/settings`,
      iconOutlined: <SettingsOutlinedIcon />,
      iconHover: <SettingsFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <SettingsFilledIcon fill={theme.palette.navbar.light} />,
      index: 9,
    },
    {
      label: NavigatorTranslations.logout[basicOrgData.lang],
      action: logout,
      iconOutlined: <LogOutOutlinedIcon />,
      iconHover: <LogOutFilledIcon fill={theme.palette.navbar.main} />,
      iconFilled: <LogOutFilledIcon fill={theme.palette.navbar.light} />,
      index: 10,
    },
  ];

  // Filter out undefined tabs and sort by index
  return tabs
    .filter((tab): tab is TabData => !!tab)
    .sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
}

export default getAvailableTabs;
