import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // MenuItem,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import validator from 'validator';
import translations from './translations';
import { User } from './types';

interface InviteUserFormProps {
  isMobile: boolean;
  inviteAnchorEl: HTMLElement | null;
  inviteDialogOpen: boolean;
  inviteEmail: string;
  // invitePermission: PermissionType;
  lang: Language;
  onInviteEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // onInvitePermissionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePopoverClose: () => void;
  handleInviteUser: () => void;
  setInviteDialogOpen: (open: boolean) => void;
  orgUsers: User[];
  orgDomain: string;
}

function InviteUserForm({
  isMobile,
  inviteAnchorEl,
  inviteDialogOpen,
  inviteEmail,
  // invitePermission,
  lang,
  onInviteEmailChange,
  // onInvitePermissionChange,
  handlePopoverClose,
  handleInviteUser,
  setInviteDialogOpen,
  orgUsers,
  orgDomain,
}: InviteUserFormProps) {
  const [emailInvalidMessage, setEmailInvalidMessage] = useState<string>('');

  const domain = orgDomain.trim().toLowerCase();
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onInviteEmailChange(e);
    const email = e.target.value.trim().toLowerCase();

    if (email === '') {
      setEmailInvalidMessage(translations.enterAnEmail[lang]);
      return;
    }

    const isValidAt = !email.includes('@');

    if (!isValidAt) {
      setEmailInvalidMessage(translations.invalidAtEmail[lang]);
      return;
    }

    const fullEmail = `${email}@${domain}`.trim().toLowerCase();

    const emailExists = orgUsers.some((user) => user.email.toLowerCase() === fullEmail);

    if (emailExists) {
      setEmailInvalidMessage(translations.emailExists[lang]);
      return;
    }

    const validEmil = validator.isEmail(fullEmail);
    if (!validEmil) {
      setEmailInvalidMessage(translations.invalidEmail[lang]);
      return;
    }

    setEmailInvalidMessage('');
  };

  const closeDialog = () => {
    handlePopoverClose();
    setInviteDialogOpen(false);
    setEmailInvalidMessage('');
  };

  const buttonContent = (
    <>
      <Button
        onClick={closeDialog}
        color="primary"
        data-testid="popover-cancel-button"
        aria-label="Cancel invite"
      >
        {translations.declineInvite[lang]}
      </Button>
      <Button
        onClick={handleInviteUser}
        color="secondary"
        disabled={!!emailInvalidMessage || inviteEmail.trim() === ''}
        data-testid="popover-invite-button"
        aria-label="Send invite"
      >
        {translations.sendInvite[lang]}
      </Button>
    </>
  );

  const inviteFormContent = (
    <Stack spacing={2}>
      <Typography variant="headlineSmallBoldRecoleta" data-testid="invite-form-title">
        {translations.inviteNewUser[lang]}
      </Typography>
      <Stack spacing={0}>
        <Stack
          spacing={1}
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
          }}
        >
          <Typography variant="titleSmall" data-testid="email-label">
            {translations.email[lang]}
          </Typography>
          {emailInvalidMessage && (
            <Typography variant="bodySmall" color="error" sx={{ pb: 0.1 }}>
              {emailInvalidMessage}
            </Typography>
          )}
        </Stack>
        <Box display="flex" alignItems="center">
          <TextField
            autoFocus
            margin="dense"
            id="email"
            type="text"
            fullWidth
            variant="outlined"
            value={inviteEmail}
            onChange={handleEmailChange}
            sx={{ minWidth: '240px', m: 0, p: 0 }}
            inputProps={{
              'aria-label': translations.email[lang],
              'data-testid': 'email-input',
            }}
            error={!!emailInvalidMessage}
          />
          <Typography
            variant="titleSmall"
            sx={{ ml: 1 }}
            color={emailInvalidMessage ? 'error' : 'primary'}
          >
            @{domain}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <>
      {/* Popover for larger devices */}
      {!isMobile && (
        <Popover
          open={Boolean(inviteAnchorEl)}
          anchorEl={inviteAnchorEl}
          onClose={closeDialog}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          data-testid="invite-popover"
        >
          <Box sx={{ p: 2 }}>
            <Stack spacing={2}>
              {inviteFormContent}
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                {buttonContent}
              </Stack>
            </Stack>
          </Box>
        </Popover>
      )}

      {/* Dialog for smaller devices */}
      {isMobile && (
        <Dialog
          open={inviteDialogOpen}
          onClose={() => setInviteDialogOpen(false)}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          data-testid="invite-dialog"
        >
          <DialogTitle id="dialog-title" data-testid="dialog-title">
            {translations.inviteUser[lang]}
          </DialogTitle>
          <DialogContent dividers data-testid="dialog-content">
            {inviteFormContent}
          </DialogContent>
          <DialogActions>{buttonContent}</DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default InviteUserForm;
