import { useState, useCallback, useEffect } from 'react';
import {
  Alert,
  Backdrop,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useLanguage } from 'src/context/LanguageContext';

import { isValidPhoneNumberInput } from 'src/utils/utils';
import validator from 'validator';
import { translations } from '../../utils/translations';
import PhoneNumberInput from '../back-office/Recipient/PhoneNumberInput';
import LoadingIndicator from '../common/LoadingIndicator';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input)
  }
`;

interface CreateUserInput {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  orgId: number;
  unsubscribeEmails: boolean;
}

export default function CreateNewOrgUser() {
  const { orgid } = useParams<{ orgid: string }>();
  const lang = useLanguage();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [unsubscribeEmails, setUnsubscribeEmails] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const orgId = Number(orgid);

  const [createUser, { data, loading, error: createUserError }] = useMutation<
    { createUser: string },
    { input: CreateUserInput }
  >(CREATE_USER_MUTATION);

  useEffect(() => {
    if (createUserError) {
      setError(`Error creating user: ${createUserError.message}`);
    }
  }, [createUserError]);

  useEffect(() => {
    setError(null);
  }, [email, firstName, lastName, phoneNumber]);

  const handleCreateUser = useCallback(
    async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();

      if (!validator.isEmail(email)) {
        setError('Please enter a valid email address.');
        return;
      }

      if (
        phoneNumber &&
        !isValidPhoneNumberInput({ phoneNumber, lang, orgRecipients: null, editRecipient: null })
      ) {
        setError('Please enter a valid phone number.');
        return;
      }

      try {
        await createUser({
          variables: {
            input: {
              email,
              firstName,
              lastName,
              phoneNumber: phoneNumber || undefined,
              orgId,
              unsubscribeEmails,
            },
          },
        });
      } catch (err) {
        setError('Failed to create user.');
      }
    },
    [email, firstName, lastName, phoneNumber, orgId, unsubscribeEmails, createUser, lang],
  );

  const isValidPhone = isValidPhoneNumberInput({
    phoneNumber,
    lang,
    orgRecipients: null,
    editRecipient: null,
    phoneNumberLength: 0,
  });

  const isFormValid = (): boolean => {
    return (
      validator.isEmail(email) &&
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      (!phoneNumber || typeof isValidPhone === 'boolean' ? isValidPhone : false)
    );
  };

  if (!orgId) {
    return <Alert severity="error">Invalid organization ID.</Alert>;
  }

  if (data) {
    return (
      <div className="create-new-page">
        <Alert severity="success">
          User created successfully!
          <div>{data.createUser}</div>
        </Alert>
      </div>
    );
  }

  return (
    <>
      <Backdrop open={loading}>
        <LoadingIndicator />
      </Backdrop>
      <div className="create-new-page">
        {error && <Alert severity="error">{error}</Alert>}
        <h1>{`${translations.admin.Organizations.createnewUser[lang]} ${orgId}`}</h1>
        <form onSubmit={handleCreateUser}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={0}>
                <Typography variant="titleSmall" color="textSecondary">
                  Email *
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!email && !validator.isEmail(email)}
                  helperText={email && !validator.isEmail(email) ? 'Must be a valid email' : ''}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={0}>
                <Typography variant="titleSmall" color="textSecondary">
                  {translations.admin.Organizations.UserPhoneNumber[lang]}
                </Typography>
                <PhoneNumberInput
                  actorPhoneNumber={phoneNumber}
                  setActorPhoneNumber={setPhoneNumber}
                  disabled={false}
                  orgRecipients={null}
                  editRecipient={null}
                />
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={0}>
                <Typography variant="titleSmall" color="textSecondary">
                  {translations.admin.Organizations.UserFirstName[lang]}
                </Typography>
                <TextField
                  fullWidth
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={0}>
                <Typography variant="titleSmall" color="textSecondary">
                  {translations.admin.Organizations.UserLastName[lang]}
                </Typography>
                <TextField
                  fullWidth
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={unsubscribeEmails}
                    onChange={() => setUnsubscribeEmails((prev) => !prev)}
                    id="unsubscribeEmails"
                  />
                }
                label="Unsubscribe emails"
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" type="submit" disabled={!isFormValid()} fullWidth>
                {translations.admin.Organizations.create[lang]}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
}
