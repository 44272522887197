import React, { useContext } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';

import ThemeContext from '../../../utils/ThemeContext';

export default function StorylineHead({
  onCreateStoryline,
  storylineSearchField,
  setStoryLineSearchField,
  basicOrgData,
  onCreateNewVideoFunnel,
}) {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);

  return (
    <Box
      className="board-header"
      sx={{
        ml: 3,
        mt: 1,
      }}
    >
      <div>
        <Stack direction="row" spacing={3}>
          <Typography variant="headlineLargeBoldRecoleta">
            {basicOrgData?.premiumFeatures?.videoFunnel
              ? translationsStoryline.allStories[lang]
              : translationsStoryline.allStorylines[lang]}
          </Typography>

          <Button
            variant="text"
            disableElevation
            startIcon={<AddIcon />}
            onClick={onCreateStoryline}
          >
            {translationsStoryline.newStoryline[lang]}
          </Button>

          {basicOrgData?.premiumFeatures?.videoFunnel && (
            <Button
              variant="text"
              disableElevation
              startIcon={<AddIcon />}
              onClick={onCreateNewVideoFunnel}
            >
              {translationsStoryline.newVideoFunnel[lang]}
            </Button>
          )}
        </Stack>
      </div>
      <div className="board-header-filters">
        <div className="search-field">
          <TextField
            fullWidth
            variant="outlined"
            placeholder={translationsStoryline.storylineSearchField[lang]}
            value={storylineSearchField}
            onChange={(e) => setStoryLineSearchField(e.target.value)}
            autoComplete="off"
            InputProps={{
              startAdornment: <SearchIcon />,
              style: {
                borderRadius: 50,
                paddingRight: 12,
                backgroundColor: theme.palette.background.paper,
              },
            }}
            name={`userInput_${Math.random().toString(36).substring(2, 9)}`} // Dynamic field name
            sx={{
              background: 'transparent',
              padding: '0',
              minWidth: '300px',
            }}
          />
        </div>
      </div>
    </Box>
  );
}
