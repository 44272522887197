import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LazyLoad from 'react-lazy-load';

import { useLanguage } from 'src/context/LanguageContext';
import OnOffButton from '../../../common/special/OnOffButton';
import VideoCardOverlay from '../VideoActionSection/VideoCardOverlay';
import SplitButton from '../../../common/SplitButton/SplitButton';
import { statuses, getVimeoVideoIdFromLink } from '../../../../utils/utils';
import VideoStats from './VideoStats';
import { translations } from '../../../../utils/translations';
import { useActions } from '../../actions';
import FileDownloadIcon from '../../../common/icons/fileDownload/FileDownloadIcon';

function VideoSection({
  item,
  videoLink,
  videoError,
  setVideoError,
  videoCardId,
  uploadLink,
  doNotTrack,
  textColor,
  interactionOption,
  contactForm,
  callToAction,
  question,
  orgId,
  InteractionOptionAllowed,
  handleSubtitleButtonClick,
  encodedOrgId,
  subtitleOption,
  itemUser,
  directUpload,
  uploadComplete,
  hasVideoLink,
  excludeFromVimeo,
  basicOrgData,
  manageSubtitleOpen,
  setManageSubtitleOpen,
  subtitles,
  setSubtitles,
}) {
  const lang = useLanguage();
  const actions = useActions();
  const [videoLoaded, setVideoLoaded] = useState();
  const [previewInteraction, setPreviewInteraction] = useState(true);
  const togglePreview = () => setPreviewInteraction(!previewInteraction);
  const vimeoErrorMessage = (error = videoError) => {
    if (!hasVideoLink && !directUpload && item?.status === statuses.REVIEW) {
      return (
        <div className="video-not-ready">
          <i className="icon">cloud_queue</i>
          <Typography variant="bodySmall">{translations.videoNotReady[lang]}</Typography>
        </div>
      );
    }
    if (!error) return null;

    if ('message' in error && error?.message?.indexOf('not found') !== -1) {
      return (
        <div className="video-not-ready">
          <i className="icon">cloud_queue</i>
          <Typography variant="bodySmall">{translations.videoNotReady[lang]}</Typography>
        </div>
      );
    }
  };

  const [isSubtitleButtonClicked, setIsSubtitleButtonClicked] = useState(false);

  useEffect(() => {
    if (videoCardId) {
      actions
        .getSubtitleStatus(encodedOrgId, videoCardId)
        .then((res) => {
          const results = res.data.getSubtitleStatus.statusMessage;
          setIsSubtitleButtonClicked(results);
        })
        .catch((e) => {
          alert(`Something went wrong, ${e.message}`);
        });
    }
  }, [actions, encodedOrgId, videoCardId]);
  const infoBox = ({ header, infoDetails }) => {
    return (
      <div className="info-box">
        <Typography variant="titleMedium">{header}</Typography>
        {infoDetails &&
          infoDetails.map((detail) => (
            <Typography variant="bodyMedium" key={_.uniqueId(header)}>
              {' '}
              {detail}{' '}
            </Typography>
          ))}
      </div>
    );
  };

  const stringToUpperCase = (textToUppCase) => {
    if (!textToUppCase) {
      return textToUppCase;
    }
    const modified = textToUppCase
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
    return modified || textToUppCase;
  };

  const videoInfoBox = () => {
    if (item) {
      if (item.status === statuses.PENDING) {
        if (item.lastReminderTime) {
          return infoBox({
            header: translations.videoCard.reminderHeader[lang],
            infoDetails: [
              translations.videoCard.reminderSentDateTime[lang]({
                date: new Date(item.lastReminderTime),
              }),
              item?.lastReminderByUser
                ? translations.videoCard.reminderSentByUser[lang]({
                    userName: stringToUpperCase(item?.lastReminderByUser),
                  })
                : translations.videoCard.reminderSentBySystem[lang],
            ],
          });
        }
        if (item.requestedByUser) {
          return infoBox({
            header: translations.videoCard.requestSent[lang],
            infoDetails: [
              translations.videoCard.reminderSentDateTime[lang]({
                date: new Date(item.requestTime),
              }),
              translations.videoCard.reminderSentByUser[lang]({
                userName: stringToUpperCase(item.requestedByUser),
              }),
            ],
          });
        }
      }
      if (item.status === statuses.SUGGESTION) {
        return infoBox({
          header: translations.videoCard.notYetSentOut[lang],
          infoDetails: [
            translations.videoCard.createdByUser[lang]({
              userName: stringToUpperCase(
                `${itemUser?.firstName || ''} ${itemUser?.lastName || ''}`,
              ),
            }),
          ],
        });
      }
    }
    return null;
  };

  const videoUploadInfo = (
    <div>
      {directUpload && uploadComplete && !hasVideoLink && (
        <div className="video-not-ready">
          <i className="icon">cloud_queue</i>
          <Typography variant="bodySmall">{translations.fileUploaded[lang]}</Typography>
        </div>
      )}
    </div>
  );
  const handleDownload = (option) => {
    actions
      .getVideoCardPreSignedUrlAWS({
        encodedOrgId,
        encodedVideoCardId: item.encodedId,
        path: option.value,
        key: option.type,
      })
      .then((res) => {
        const signedUrl = res.data.getVideoCardPreSignedUrlAWS;
        const link = document.createElement('a');
        link.href = signedUrl;
        link.download = `video-${item?.id}.mp4`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const optionsVideosAWS =
    item?.videoLink &&
    Object.keys(item?.videoLink)
      ?.filter(
        (key) => item?.videoLink[key] && key !== '__typename' && key !== 'en' && key !== 'sv',
      )
      ?.map((key) => {
        return {
          muiIcon: <FileDownloadIcon />,
          type: key,
          value: item.videoLink[key],
          label: `${translations.videoCard.downloadVideo[lang]} ${
            translations.videoCard.downloadVideoQuality[key] &&
            translations.videoCard.downloadVideoQuality[key][lang]
              ? translations.videoCard.downloadVideoQuality[key][lang]
              : key
          }`,
        };
      });

  const optionsThumbnailsAWS =
    item?.animatedThumbnails &&
    Object.keys(item?.animatedThumbnails)
      ?.filter(
        (key) =>
          item?.animatedThumbnails[key] && key !== '__typename' && key !== 'en' && key !== 'sv',
      )
      ?.map((key) => {
        return {
          muiIcon: <FileDownloadIcon />,
          type: key,
          value: item.animatedThumbnails[key],
          label: `${translations.videoCard.downloadThumbnail[lang]} ${
            translations.videoCard.downloadVideoQuality[key] &&
            translations.videoCard.downloadVideoQuality[key][lang]
              ? translations.videoCard.downloadVideoQuality[key][lang]
              : key
          }`,
        };
      });

  return (
    <div className={`video ${videoLoaded ? 'video-loaded' : ''}`}>
      {interactionOption ? (
        <div>
          <OnOffButton
            isOn={previewInteraction}
            onClick={togglePreview}
            text={translations.videoCard.previewInteraction[lang]}
          />
        </div>
      ) : (
        <div style={{ height: '27px' }} />
      )}
      <div className={`video-wrapper ${!hasVideoLink && 'no-video'}`}>
        {InteractionOptionAllowed && interactionOption && previewInteraction && (
          <div>
            <VideoCardOverlay
              textColor={textColor}
              interactionOption={interactionOption}
              contactForm={contactForm}
              callToAction={callToAction}
              question={question}
              orgId={orgId}
            />
          </div>
        )}
        {!videoLoaded && vimeoErrorMessage()}
        {videoUploadInfo}

        {videoLink && !excludeFromVimeo ? (
          <Vimeo
            onReady={() => setVideoLoaded(true)}
            video={getVimeoVideoIdFromLink(videoLink)}
            width="239"
            height="398"
            onError={(e) => {
              if (!excludeFromVimeo) {
                setVideoError(e);
              }
            }}
            dnt={doNotTrack}
          />
        ) : (
          (videoLink?.FHD ||
            videoLink?.HD ||
            videoLink?.qHD ||
            videoLink?.nHD ||
            videoLink?.ntsc) && (
            <video width="239" height="398" controls crossOrigin="anonymous">
              <source src={videoLink?.FHD} type="video/mp4" />
              <source src={videoLink?.HD} type="video/mp4" />
              <source src={videoLink?.qHD} type="video/mp4" />
              <source src={videoLink?.nHD} type="video/mp4" />
              <source src={videoLink?.ntsc} type="video/mp4" />
              {subtitles?.map((sub) => {
                return (
                  <track
                    key={_.uniqueId(sub.srclang)}
                    src={sub.src}
                    label={sub.label}
                    kind="captions"
                    srcLang={sub.srclang}
                    default={sub.default}
                  />
                );
              })}
            </video>
          )
        )}

        {!hasVideoLink && item.status === statuses.PENDING && (
          <>
            <img src="/images/icons/film-camera-icon.svg" alt="" />
            {item?.status === statuses.PENDING && (
              <span>{translations.videoCard.waitingOnCollegue[lang]}</span>
            )}
          </>
        )}
      </div>
      <Stack alignItems="center">
        {!hasVideoLink && (
          <Button
            variant="outlined"
            disableElevation
            startIcon={<FileUploadOutlinedIcon />}
            fullWidth
            target="_blank"
            rel="noreferrer"
            href={uploadLink}
          >
            {translations.videoCard.goToUpload[lang]}
          </Button>
        )}{' '}
        {!hasVideoLink && (
          <Button
            variant="outlined"
            disableElevation
            startIcon={<ContentPasteOutlinedIcon />}
            fullWidth
            onClick={() => navigator.clipboard.writeText(uploadLink)}
          >
            {translations.videoCard.copyUploadLink[lang]}
          </Button>
        )}
      </Stack>

      {optionsVideosAWS?.length > 0 && (
        <SplitButton
          variant="outlined"
          disableElevation
          options={optionsVideosAWS}
          handleClick={(option) => {
            handleDownload(option);
          }}
          style={{ paddingTop: '10px' }}
          fullWidth
        />
      )}
      {optionsThumbnailsAWS?.length > 0 && (
        <SplitButton
          variant="outlined"
          disableElevation
          options={optionsThumbnailsAWS}
          handleClick={(option) => {
            handleDownload(option);
          }}
          style={{ paddingTop: '10px' }}
          fullWidth
        />
      )}

      <Stack spacing={1} alignItems="center">
        {(basicOrgData?.premiumFeatures?.subtitleAllowed ||
          basicOrgData?.premiumFeatures?.includeSubtitle ||
          basicOrgData?.premiumFeatures?.orgSubtitleUpload) && (
          <Button
            variant="text"
            disableElevation
            fullWidth
            onClick={() => {
              setManageSubtitleOpen(!manageSubtitleOpen);
            }}
          >
            {translations.videoCard.manageSubtitle[lang]}
          </Button>
        )}
        {videoInfoBox()}
        {subtitleOption && hasVideoLink && (
          <Button
            variant="text"
            disableElevation
            fullWidth
            disabled={isSubtitleButtonClicked}
            onClick={handleSubtitleButtonClick}
          >
            {isSubtitleButtonClicked
              ? translations.videoCard.SubtitleProgress[lang]
              : translations.videoCard.orderSubtitle[lang]}
          </Button>
        )}
      </Stack>
      <LazyLoad offset={600} width="260px">
        <VideoStats video={item} encodedOrgId={basicOrgData.encodedId} />
      </LazyLoad>
    </div>
  );
}

export default VideoSection;
