import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import {
  Button,
  Typography,
  Container,
  Divider,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useLanguage } from 'src/context/LanguageContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import { Stack } from '@mui/system';
import { translations as translationsSettings } from '../../../../utils/translationsSettings';
import { translations } from '../../../../utils/translations';
import useCheckPermissionsAndNavigate from '../Permissions/CheckPermissionsAndNavigate';
import { OrganizationInput, OutletContextType, StyleSettings } from './Types';
import { UPDATE_ORG_SETTINGS_STYLE, UPDATE_ORG_LOGO } from './graphql';
import LabeledTextField from './LabeledTextField';
import ColorPreview from './ColorPreview';
import PreviewButton, {
  defaultButtonBackgroundColor,
  defaultButtonTextColor,
} from './PreviewButton';

function isVersionGreaterOrEqualThan(
  versionString: string,
  compareString: string,
  includeEqual: boolean,
): boolean {
  if (!versionString) {
    return false;
  }

  if (versionString === 'default' || versionString === 'latest') {
    return false;
  }

  // Create a new variable for the potentially modified version string
  let modifiedVersionString = versionString?.trim()?.toLowerCase();

  // Remove leading 'v' from version string if it exists
  if (modifiedVersionString.startsWith('v')) {
    modifiedVersionString = modifiedVersionString.substring(1);
  }

  // Split the version strings into parts and convert them to numbers
  const versionParts: number[] = modifiedVersionString.split('-')[0].split('.').map(Number);
  const compareParts: number[] = compareString.split('.').map(Number);

  // Get the maximum length between the two version arrays
  const maxLength = Math.max(versionParts.length, compareParts.length);

  // Compare each part of the version numbers
  for (let i = 0; i < maxLength; i += 1) {
    const vPart = versionParts[i] ?? 0; // If the part is undefined, treat it as 0
    const cPart = compareParts[i] ?? 0; // If the part is undefined, treat it as 0

    if (vPart > cPart) {
      return true;
    }
    if (vPart < cPart) {
      return false;
    }
  }

  // If all parts are equal, check if we want to include equality
  return includeEqual;
}

function StylingSettings() {
  const lang = useLanguage();
  const { basicOrgData } = useOutletContext<OutletContextType>();

  const [updateOrgSettingsStyle, { loading }] = useMutation(UPDATE_ORG_SETTINGS_STYLE);
  const [updateOrgLogo] = useMutation(UPDATE_ORG_LOGO);

  const { loading: userLoading } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/recipients',
  });

  const { style = {}, logoPath, encodedId, premiumFeatures } = basicOrgData;

  const advancedWidgetStyling = premiumFeatures?.advancedWidgetStyling ?? false;
  const advancedWidgetBehavior = premiumFeatures?.advancedWidgetBehavior ?? false;
  const advStyleVidTitleDialog = premiumFeatures?.advStyleVidTitleDialog ?? false;
  const advancedWidgetStylingMobile = premiumFeatures?.advancedWidgetStylingMobile ?? false;
  const widgetVersion = premiumFeatures?.widgetVersion ?? 'default';
  const widgetFourOrHigher = isVersionGreaterOrEqualThan(widgetVersion, '4', true);

  const [backgroundColor, setBackgroundColor] = useState<string>(style.backgroundColor || '');
  const [backgroundColorValid, setBackgroundColorValid] = useState<boolean>(true);
  const [textColor, setTextColor] = useState<string>(style.textColor || '');
  const [textColorValid, setTextColorValid] = useState<boolean>(true);
  const [btnColor, setBtnColor] = useState<string>(style.btnColor || '');
  const [btnColorValid, setBtnColorValid] = useState<boolean>(true);
  const [btnTextColor, setBtnTextColor] = useState<string>(style.btnTextColor || '');
  const [btnTextColorValid, setBtnTextColorValid] = useState<boolean>(true);
  const [ctaLabel, setCtaLabel] = useState<string>(style.ctaLabel || '');
  const ctaLabelMaxLength = 33;
  const [ctaLabelValid, setCtaLabelValid] = useState<boolean>(true);

  const [excludeWidgetLibraryBtn, setExcludeWidgetLibraryBtn] = useState<boolean>(
    style.excludeWidgetLibraryBtn || false,
  );

  const [enableHideWidget, setEnableHideWidget] = useState<boolean>(
    !!(advancedWidgetBehavior && style.enableHideWidget),
  );

  const [widgetPositionLeft, setWidgetPositionLeft] = useState<boolean>(
    style.widgetPositionLeft || false,
  );

  const [widgetPositionTop, setWidgetPositionTop] = useState<boolean>(
    !!(advancedWidgetStyling && style.widgetPositionTop),
  );

  const [widgetProcentBasedMargin, setWidgetProcentBasedMargin] = useState<boolean>(
    !!(advancedWidgetStyling && style.widgetProcentBasedMargin),
  );

  const bottomMarginMax = 100;
  const bottomMarginMin = 0;
  const defaultBottomMargin = 10;
  const [bottomMargin, setBottomMargin] = useState<number>(
    advancedWidgetStyling && style.widgetBottomMargin !== undefined
      ? style.widgetBottomMargin
      : defaultBottomMargin,
  );
  const [bottomMarginValid, setBottomMarginValid] = useState<boolean>(true);

  const sideMarginMax = 100;
  const sideMarginMin = 0;
  const defaultSideMargin = 15;
  const [sideMargin, setSideMargin] = useState<number>(
    advancedWidgetStyling && style.widgetSideMargin !== undefined
      ? style.widgetSideMargin
      : defaultSideMargin,
  );
  const [sideMarginValid, setSideMarginValid] = useState<boolean>(true);

  const [separateDesktopMobile, setSeparateDesktopMobile] = useState<boolean>(
    !!(advancedWidgetStylingMobile && style.separateDesktopMobile),
  );

  const [widgetPositionLeftMobile, setWidgetPositionLeftMobile] = useState<boolean>(
    style.widgetPositionLeftMobile || false,
  );

  const [widgetPositionTopMobile, setWidgetPositionTopMobile] = useState<boolean>(
    !!(advancedWidgetStylingMobile && style.widgetPositionTopMobile),
  );

  const [widgetProcentBasedMarginMobile, setWidgetProcentBasedMarginMobile] = useState<boolean>(
    !!(advancedWidgetStylingMobile && style.widgetProcentBasedMarginMobile),
  );

  const [bottomMarginMobile, setBottomMarginMobile] = useState<number>(
    advancedWidgetStylingMobile && style.widgetBottomMarginMobile !== undefined
      ? style.widgetBottomMarginMobile
      : defaultBottomMargin,
  );
  const [bottomMarginValidMobile, setBottomMarginValidMobile] = useState<boolean>(true);

  const [sideMarginMobile, setSideMarginMobile] = useState<number>(
    advancedWidgetStylingMobile && style.widgetSideMarginMobile !== undefined
      ? style.widgetSideMarginMobile
      : defaultSideMargin,
  );
  const [sideMarginValidMobile, setSideMarginValidMobile] = useState<boolean>(true);

  const [widgetHideDialogTitle, setWidgetHideDialogTitle] = useState<boolean>(
    !!(advStyleVidTitleDialog && style.widgetHideDialogTitle),
  );

  const [widgetHideDialogTitleMobile, setWidgetHideDialogTitleMobile] = useState<boolean>(
    !!(advStyleVidTitleDialog && style.widgetHideDialogTitleMobile),
  );

  const [logo, setLogo] = useState<string | null>(logoPath || null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [showUpload, setShowUpload] = useState<boolean>(!logoPath);

  const [notification, setNotification] = useState<{
    type: 'success' | 'error' | 'info';
    message: string;
  } | null>(null);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const validateColor = (color: string, allowAlpha: boolean = false): boolean => {
    const hexPattern = allowAlpha ? /^#[0-9A-F]{6}([0-9A-F]{2})?$/i : /^#[0-9A-F]{6}$/i;
    return hexPattern.test(color);
  };

  const validateMargin = (value: number, min: number, max: number): boolean =>
    value >= min && value <= max;

  const validateTextLength = (text: string, max: number): boolean => text.length <= max;

  useEffect(() => {
    const isModified =
      backgroundColor !== (style.backgroundColor || '') ||
      textColor !== (style.textColor || '') ||
      btnColor !== (style.btnColor || '') ||
      btnTextColor !== (style.btnTextColor || '') ||
      ctaLabel !== (style.ctaLabel || '') ||
      excludeWidgetLibraryBtn !== (style.excludeWidgetLibraryBtn || false) ||
      enableHideWidget !== (style.enableHideWidget || false) ||
      logoFile !== null ||
      sideMargin !==
        (style.widgetSideMargin !== undefined ? style.widgetSideMargin : defaultSideMargin) ||
      bottomMargin !==
        (style.widgetBottomMargin !== undefined ? style.widgetBottomMargin : defaultBottomMargin) ||
      widgetPositionLeft !== (style.widgetPositionLeft || false) ||
      widgetPositionTop !== (style.widgetPositionTop || false) ||
      widgetProcentBasedMargin !== (style.widgetProcentBasedMargin || false) ||
      separateDesktopMobile !== (style.separateDesktopMobile || false) ||
      widgetPositionLeftMobile !== (style.widgetPositionLeftMobile || false) ||
      widgetPositionTopMobile !== (style.widgetPositionTopMobile || false) ||
      widgetProcentBasedMarginMobile !== (style.widgetProcentBasedMarginMobile || false) ||
      sideMarginMobile !==
        (style.widgetSideMarginMobile !== undefined
          ? style.widgetSideMarginMobile
          : defaultSideMargin) ||
      bottomMarginMobile !==
        (style.widgetBottomMarginMobile !== undefined
          ? style.widgetBottomMarginMobile
          : defaultBottomMargin) ||
      widgetHideDialogTitle !== (style.widgetHideDialogTitle || false) ||
      widgetHideDialogTitleMobile !== (style.widgetHideDialogTitleMobile || false);

    setIsEdit(isModified);
  }, [
    backgroundColor,
    textColor,
    btnColor,
    btnTextColor,
    ctaLabel,
    excludeWidgetLibraryBtn,
    enableHideWidget,
    logoFile,
    sideMargin,
    bottomMargin,
    widgetPositionLeft,
    widgetPositionTop,
    widgetProcentBasedMargin,
    separateDesktopMobile,
    widgetPositionLeftMobile,
    widgetPositionTopMobile,
    widgetProcentBasedMarginMobile,
    sideMarginMobile,
    bottomMarginMobile,
    widgetHideDialogTitle,
    widgetHideDialogTitleMobile,
    style,
  ]);

  const onUploadImg = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      if (file.size > 10000000) {
        setNotification({
          type: 'error',
          message: 'The image is too large, please select another one',
        });
        setLogoFile(null);
      } else {
        setLogoFile(file);
      }
    }
  };

  const saveLogo = async () => {
    if (logoFile) {
      try {
        const response = await updateOrgLogo({
          variables: {
            encodedOrgId: encodedId,
            file: logoFile,
          },
        });
        setNotification({
          type: 'success',
          message: translations.savedSuccess[lang],
        });
        setLogo(response.data.updateOrgLogo.logoPath);
        setShowUpload(false);
        setLogoFile(null);
      } catch {
        setNotification({
          type: 'error',
          message: translations.savedError[lang],
        });
      }
    }
  };

  const saveChanges = async () => {
    if (
      backgroundColorValid &&
      textColorValid &&
      btnColorValid &&
      btnTextColorValid &&
      bottomMarginValid &&
      sideMarginValid &&
      ctaLabelValid
    ) {
      const inputStyle: StyleSettings = {
        backgroundColor,
        textColor,
        btnColor,
        btnTextColor,
        ctaLabel,
        excludeWidgetLibraryBtn,
        enableHideWidget,
        widgetPositionLeft,
        widgetPositionTop,
        widgetProcentBasedMargin,
        widgetBottomMargin: bottomMargin,
        widgetSideMargin: sideMargin,
        separateDesktopMobile,
        widgetPositionLeftMobile,
        widgetPositionTopMobile,
        widgetProcentBasedMarginMobile,
        widgetBottomMarginMobile: bottomMarginMobile,
        widgetSideMarginMobile: sideMarginMobile,
        widgetHideDialogTitle,
        widgetHideDialogTitleMobile,
      };

      const input: OrganizationInput = {
        style: inputStyle,
      };

      try {
        await updateOrgSettingsStyle({
          variables: {
            encodedOrgId: encodedId,
            input,
          },
        });
        setNotification({
          type: 'success',
          message: translations.savedSuccess[lang],
        });
      } catch {
        setNotification({
          type: 'error',
          message: translations.savedError[lang],
        });
      }
    } else {
      setNotification({
        type: 'error',
        message: translationsSettings.styling.correctErrorsBeforeSaving[lang],
      });
    }
  };

  if (userLoading) return <LoadingIndicator />;

  return (
    <Container maxWidth={separateDesktopMobile ? undefined : 'md'} sx={{ mt: 4 }}>
      <Snackbar open={!!notification} autoHideDuration={4000} onClose={() => setNotification(null)}>
        <Alert
          onClose={() => setNotification(null)}
          severity={notification?.type}
          sx={{ width: '100%' }}
        >
          {notification?.message}
        </Alert>
      </Snackbar>

      <Typography variant="titleLarge">
        {translationsSettings.styling.widgetStyling[lang]}
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{
          mt: 2,
        }}
      >
        <Grid
          container
          size={12}
          spacing={2}
          columns={16}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
          }}
        >
          {/* Background Color */}
          <Grid size={{ xs: 12, sm: 5 }}>
            <LabeledTextField
              label={translationsSettings.styling.backColor[lang]}
              value={backgroundColor}
              onChange={(e) => {
                const { value } = e.target;
                setBackgroundColor(value?.toLocaleUpperCase()?.trim());
                setBackgroundColorValid(validateColor(value?.toLocaleUpperCase()?.trim()));
              }}
              error={!backgroundColorValid}
              helperText={
                backgroundColorValid ? null : translationsSettings.incorrectColorError[lang]
              }
              fullWidth
              startAdornment={
                <ColorPreview
                  color={backgroundColor}
                  onChange={(newColor) => {
                    setBackgroundColor(newColor?.toLocaleUpperCase()?.trim());
                    setBackgroundColorValid(validateColor(newColor?.toLocaleUpperCase()?.trim()));
                  }}
                />
              }
            />
          </Grid>

          {/* Text Color */}

          <Grid size={{ xs: 12, sm: 5 }}>
            <LabeledTextField
              label={translationsSettings.styling.textColor[lang]}
              value={textColor}
              onChange={(e) => {
                const { value } = e.target;
                setTextColor(value?.toLocaleUpperCase()?.trim());
                setTextColorValid(validateColor(value?.toLocaleUpperCase()?.trim()));
              }}
              error={!textColorValid}
              helperText={textColorValid ? null : translationsSettings.incorrectColorError[lang]}
              fullWidth
              startAdornment={
                <ColorPreview
                  color={textColor}
                  onChange={(newColor) => {
                    setTextColor(newColor?.toLocaleUpperCase()?.trim());
                    setTextColorValid(validateColor(newColor?.toLocaleUpperCase()?.trim()));
                  }}
                />
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          size={12}
          spacing={2}
          sx={{ pt: 2, justifyContent: 'flex-start', alignItems: 'center' }}
        >
          {widgetFourOrHigher && (
            <Grid size={12}>
              <Typography variant="titleMedium">
                {translationsSettings.styling.buttonColors[lang]}
              </Typography>
            </Grid>
          )}
          <Grid
            container
            size={12}
            spacing={2}
            columns={16}
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
            }}
          >
            <Grid size={{ xs: 12, sm: 5 }}>
              <LabeledTextField
                label={
                  widgetFourOrHigher
                    ? translationsSettings.styling.ctaButtonBackgroundColor[lang]
                    : translationsSettings.styling.accentBackColor[lang]
                }
                value={btnColor}
                onChange={(e) => {
                  const { value } = e.target;
                  setBtnColor(value?.toLocaleUpperCase()?.trim());
                  setBtnColorValid(validateColor(value?.toLocaleUpperCase()?.trim()));
                }}
                error={!btnColorValid}
                helperText={btnColorValid ? null : translationsSettings.incorrectColorError[lang]}
                fullWidth
                startAdornment={
                  <ColorPreview
                    color={btnColor}
                    onChange={(newColor) => {
                      setBtnColor(newColor?.toLocaleUpperCase()?.trim());
                      setBtnColorValid(validateColor(newColor?.toLocaleUpperCase()?.trim()));
                    }}
                  />
                }
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 5 }}>
              <LabeledTextField
                label={
                  widgetFourOrHigher
                    ? translationsSettings.styling.ctaButtonTextColor[lang]
                    : translationsSettings.styling.accentTextColor[lang]
                }
                value={btnTextColor}
                onChange={(e) => {
                  const { value } = e.target;
                  setBtnTextColor(value?.toLocaleUpperCase()?.trim());
                  setBtnTextColorValid(validateColor(value?.toLocaleUpperCase()?.trim()));
                }}
                error={!btnTextColorValid}
                helperText={
                  btnTextColorValid ? null : translationsSettings.incorrectColorError[lang]
                }
                fullWidth
                startAdornment={
                  <ColorPreview
                    color={btnTextColor}
                    onChange={(newColor) => {
                      setBtnTextColor(newColor?.toLocaleUpperCase()?.trim());
                      setBtnTextColorValid(validateColor(newColor?.toLocaleUpperCase()?.trim()));
                    }}
                  />
                }
              />
            </Grid>
            {widgetFourOrHigher && (
              <Grid size={{ xs: 12, sm: 4 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setBtnColor(defaultButtonBackgroundColor?.toLocaleUpperCase()?.trim());
                    setBtnTextColor(defaultButtonTextColor?.toLocaleUpperCase()?.trim());
                  }}
                  sx={{ m: 0 }}
                >
                  {translationsSettings.styling.resetToDefault[lang]}
                </Button>
              </Grid>
            )}

            {widgetFourOrHigher && (
              <Grid size={12}>
                <PreviewButton
                  text="Preview"
                  buttonBackgroundColor={btnColor}
                  textColor={btnTextColor}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* CTA Label */}
        <Grid size={{ xs: 12 }} sx={{ pt: 2 }}>
          <LabeledTextField
            label={translationsSettings.styling.customCTLLabel[lang]}
            value={ctaLabel}
            onChange={(e) => {
              const { value } = e.target;
              setCtaLabel(value);
              setCtaLabelValid(validateTextLength(value, ctaLabelMaxLength));
            }}
            error={!ctaLabelValid}
            helperText={
              ctaLabelValid
                ? null
                : translationsSettings.styling.customCTLLabelError[lang]({
                    maxLength: ctaLabelMaxLength,
                  })
            }
          />
        </Grid>

        {/* Exclude Widget Library Button */}
        <Grid size={{ xs: 12 }} sx={{ pt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeWidgetLibraryBtn}
                onChange={() => setExcludeWidgetLibraryBtn(!excludeWidgetLibraryBtn)}
              />
            }
            label={translationsSettings.styling.hideCTA[lang]}
          />
        </Grid>

        {/* Advanced Widget Behavior */}
        {advancedWidgetBehavior && (
          <Grid size={{ xs: 12 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableHideWidget}
                  onChange={() => setEnableHideWidget(!enableHideWidget)}
                />
              }
              label={translationsSettings.styling.enableHideWidget[lang]}
            />
          </Grid>
        )}
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Stack>
        {/* Widget Placement */}
        <Typography variant="titleLarge" gutterBottom>
          {translationsSettings.styling.widgetPlacement[lang]}
        </Typography>

        {advancedWidgetStylingMobile && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={separateDesktopMobile}
                  onChange={() => setSeparateDesktopMobile(!separateDesktopMobile)}
                />
              }
              label={translationsSettings.styling.separateDesktopMobilePlacement[lang]}
            />
            <Divider sx={{ my: 2 }} />
          </>
        )}
      </Stack>

      {/* Placement Settings */}
      <Grid container spacing={2}>
        {/* Desktop Settings */}
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack spacing={2}>
            {advancedWidgetStylingMobile && separateDesktopMobile && (
              <Typography variant="titleMedium" gutterBottom>
                {translationsSettings.styling.desktop[lang]}
              </Typography>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={widgetPositionLeft}
                  onChange={() => setWidgetPositionLeft(!widgetPositionLeft)}
                />
              }
              label={translationsSettings.styling.widgetPlacementLabel[lang]}
            />

            {advancedWidgetStyling && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={widgetPositionTop}
                      onChange={() => setWidgetPositionTop(!widgetPositionTop)}
                    />
                  }
                  label={translationsSettings.styling.widgetPlacementLabelTop[lang]}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={widgetProcentBasedMargin}
                      onChange={() => setWidgetProcentBasedMargin(!widgetProcentBasedMargin)}
                    />
                  }
                  label={translationsSettings.styling.widgetBasedMarginLabel[lang]}
                />

                {/* Bottom Margin */}
                <LabeledTextField
                  label={translationsSettings.styling.widgetTopMarginLabel[lang]({
                    placement: widgetPositionTop
                      ? translationsSettings.styling.top[lang]
                      : translationsSettings.styling.bottom[lang],
                    marginType: widgetProcentBasedMargin ? '%' : 'px',
                  })}
                  type="number"
                  value={bottomMargin}
                  onChange={(e) => {
                    const parsedValue = parseInt(e.target.value, 10);
                    setBottomMargin(parsedValue);
                    setBottomMarginValid(
                      validateMargin(parsedValue, bottomMarginMin, bottomMarginMax),
                    );
                  }}
                  error={!bottomMarginValid}
                  helperText={
                    bottomMarginValid
                      ? null
                      : `${translationsSettings.styling.widgetBottomMarginError[lang]} ${translationsSettings.styling.range[lang]} ${bottomMarginMin}-${bottomMarginMax}`
                  }
                  placeholder={`${translationsSettings.styling.default[lang]}: ${defaultBottomMargin}, ${translationsSettings.styling.range[lang]} ${bottomMarginMin}-${bottomMarginMax}`}
                  fullWidth
                />

                {/* Side Margin */}
                <LabeledTextField
                  label={translationsSettings.styling.widgetSideMarginLabel[lang]({
                    placement: widgetPositionLeft
                      ? translationsSettings.styling.left[lang]
                      : translationsSettings.styling.right[lang],
                    marginType: widgetProcentBasedMargin ? '%' : 'px',
                  })}
                  type="number"
                  value={sideMargin}
                  onChange={(e) => {
                    const parsedValue = parseInt(e.target.value, 10);
                    setSideMargin(parsedValue);
                    setSideMarginValid(validateMargin(parsedValue, sideMarginMin, sideMarginMax));
                  }}
                  error={!sideMarginValid}
                  helperText={
                    sideMarginValid
                      ? null
                      : `${translationsSettings.styling.widgetSideMarginError[lang]} ${translationsSettings.styling.range[lang]} ${sideMarginMin}-${sideMarginMax}`
                  }
                  placeholder={`${translationsSettings.styling.default[lang]}: ${defaultSideMargin}, ${translationsSettings.styling.range[lang]} ${sideMarginMin}-${sideMarginMax}`}
                  fullWidth
                />

                {advStyleVidTitleDialog && (
                  <Stack>
                    <Typography variant="titleMedium" sx={{ mt: 2 }}>
                      {translationsSettings.styling.customWidgetStyling[lang]}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={widgetHideDialogTitle}
                          onChange={() => setWidgetHideDialogTitle(!widgetHideDialogTitle)}
                        />
                      }
                      label={translationsSettings.styling.hideDialogTitle[lang]}
                    />
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </Grid>

        {/* Mobile Settings */}
        {advancedWidgetStylingMobile && separateDesktopMobile && (
          <Grid size={{ xs: 12, md: 6 }}>
            <Stack spacing={2}>
              <Typography variant="titleMedium" gutterBottom>
                {translationsSettings.styling.mobile[lang]}
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={widgetPositionLeftMobile}
                    onChange={() => setWidgetPositionLeftMobile(!widgetPositionLeftMobile)}
                  />
                }
                label={translationsSettings.styling.widgetPlacementLabel[lang]}
              />

              {advancedWidgetStyling && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={widgetPositionTopMobile}
                        onChange={() => setWidgetPositionTopMobile(!widgetPositionTopMobile)}
                      />
                    }
                    label={translationsSettings.styling.widgetPlacementLabelTop[lang]}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={widgetProcentBasedMarginMobile}
                        onChange={() =>
                          setWidgetProcentBasedMarginMobile(!widgetProcentBasedMarginMobile)
                        }
                      />
                    }
                    label={translationsSettings.styling.widgetBasedMarginLabel[lang]}
                  />

                  {/* Bottom Margin Mobile */}
                  <LabeledTextField
                    label={translationsSettings.styling.widgetTopMarginLabel[lang]({
                      placement: widgetPositionTopMobile
                        ? translationsSettings.styling.top[lang]
                        : translationsSettings.styling.bottom[lang],
                      marginType: widgetProcentBasedMarginMobile ? '%' : 'px',
                    })}
                    type="number"
                    value={bottomMarginMobile}
                    onChange={(e) => {
                      const parsedValue = parseInt(e.target.value, 10);
                      setBottomMarginMobile(parsedValue);
                      setBottomMarginValidMobile(
                        validateMargin(parsedValue, bottomMarginMin, bottomMarginMax),
                      );
                    }}
                    error={!bottomMarginValidMobile}
                    helperText={
                      bottomMarginValidMobile
                        ? null
                        : `${translationsSettings.styling.widgetBottomMarginError[lang]} ${translationsSettings.styling.range[lang]} ${bottomMarginMin}-${bottomMarginMax}`
                    }
                    placeholder={`${translationsSettings.styling.default[lang]}: ${defaultBottomMargin}, ${translationsSettings.styling.range[lang]} ${bottomMarginMin}-${bottomMarginMax}`}
                    fullWidth
                  />

                  {/* Side Margin Mobile */}
                  <LabeledTextField
                    label={translationsSettings.styling.widgetSideMarginLabel[lang]({
                      placement: widgetPositionLeftMobile
                        ? translationsSettings.styling.left[lang]
                        : translationsSettings.styling.right[lang],
                      marginType: widgetProcentBasedMarginMobile ? '%' : 'px',
                    })}
                    type="number"
                    value={sideMarginMobile}
                    onChange={(e) => {
                      const parsedValue = parseInt(e.target.value, 10);
                      setSideMarginMobile(parsedValue);
                      setSideMarginValidMobile(
                        validateMargin(parsedValue, sideMarginMin, sideMarginMax),
                      );
                    }}
                    error={!sideMarginValidMobile}
                    helperText={
                      sideMarginValidMobile
                        ? null
                        : `${translationsSettings.styling.widgetSideMarginError[lang]} ${translationsSettings.styling.range[lang]} ${sideMarginMin}-${sideMarginMax}`
                    }
                    placeholder={`${translationsSettings.styling.default[lang]}: ${defaultSideMargin}, ${translationsSettings.styling.range[lang]} ${sideMarginMin}-${sideMarginMax}`}
                    fullWidth
                  />

                  {advStyleVidTitleDialog && (
                    <Stack spacing={0}>
                      <Typography variant="titleMedium" sx={{ mt: 2 }}>
                        {translationsSettings.styling.customWidgetStyling[lang]}
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={widgetHideDialogTitleMobile}
                            onChange={() =>
                              setWidgetHideDialogTitleMobile(!widgetHideDialogTitleMobile)
                            }
                          />
                        }
                        label={translationsSettings.styling.hideDialogTitle[lang]}
                      />
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Logo Upload */}
      <Typography variant="titleLarge" gutterBottom>
        {translationsSettings.styling.logo[lang]}
      </Typography>

      {logo && (
        <Box sx={{ mb: 2 }}>
          <img src={logo} alt="Logo" width={100} style={{ backgroundColor }} />
        </Box>
      )}

      {showUpload ? (
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" component="label" sx={{ mb: 1 }}>
            {translations.backOffice.SelectLogo[lang]}
            <input type="file" accept="image/png, image/jpeg" hidden onChange={onUploadImg} />
          </Button>
          <Button variant="contained" color="secondary" disabled={!logoFile} onClick={saveLogo}>
            {translations.backOffice.UploadLogo[lang]}
          </Button>
        </Box>
      ) : (
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="secondary" onClick={() => setShowUpload(true)}>
            {translations.backOffice.ChangeLogo[lang]}
          </Button>
        </Box>
      )}

      {/* Save Button */}
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={saveChanges}
          disabled={
            !backgroundColorValid ||
            !textColorValid ||
            !btnColorValid ||
            !btnTextColorValid ||
            !bottomMarginValid ||
            !sideMarginValid ||
            !ctaLabelValid ||
            !isEdit ||
            loading
          }
        >
          {translationsSettings.styling.save[lang]}
        </Button>
      </Box>
    </Container>
  );
}

export default StylingSettings;
