import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { Handle } from 'reactflow';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';

function HandleToolTip({
  type,
  position,
  id,
  isConnectable = true,
  isConnectableStart = true,
  isConnectableEnd = true,
  isValidConnection = () => true,
  className = '',
  hasSourceConnectionHandle = false,
  children = null,
}) {
  const lang = useLanguage();

  const tooltipTitle = hasSourceConnectionHandle
    ? ''
    : translationsStoryline.videoFunnel.tooltips.handleTooltip[lang];

  return (
    <Tooltip title={tooltipTitle} enterDelay={500} enterNextDelay={100}>
      <Handle
        type={type}
        position={position}
        id={id}
        isConnectable={isConnectable}
        isConnectableStart={isConnectableStart}
        isConnectableEnd={isConnectableEnd}
        isValidConnection={isValidConnection}
        className={className}
      >
        {children}
      </Handle>
    </Tooltip>
  );
}

HandleToolTip.propTypes = {
  type: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']).isRequired,
  id: PropTypes.string.isRequired,
  isConnectable: PropTypes.bool,
  isConnectableStart: PropTypes.bool,
  isConnectableEnd: PropTypes.bool,
  isValidConnection: PropTypes.func,
  className: PropTypes.string,
  hasSourceConnectionHandle: PropTypes.bool,
  children: PropTypes.node,
};

export default memo(HandleToolTip);
