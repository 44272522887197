import React, { useState, useMemo, useCallback } from 'react';
import {
  IconButton,
  Select,
  Tooltip,
  Typography,
  Checkbox,
  MenuItem,
  TextField,
  Box,
  Stack,
  SelectChangeEvent,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HelpIcon from '@mui/icons-material/Help';

import { useLanguage } from 'src/context/LanguageContext';
import { AvailableWhenConditionKey } from 'src/utils/freemiumSettings';
import { outlinedInput } from '../../common/SplitButton/SplitButtonThemes';
import { isValidHttpUrl, packageVariant } from '../../../utils/utils';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import { getTranslationKey, getTooltipHelp, getPlaceholderText } from './utils';
import { ErrorType, ConditionEditorProps, UrlJsonb } from './types';

function ConditionEditor({
  basicOrgData,
  urlsJsonb = [],
  setUrlsJsonb,
  urlObj,
  indexInList,
  otherStorylinesUrls = [],
}: ConditionEditorProps) {
  const lang = useLanguage();
  const { premiumFeatures } = basicOrgData;

  const isFreemium = premiumFeatures?.packageVariant === packageVariant.freemium;
  const freemiumStoriesLimitCondition: AvailableWhenConditionKey[] | false | undefined =
    isFreemium && premiumFeatures?.freemiumFeatures?.stories?.conditions;

  const initialSelectedCondition: AvailableWhenConditionKey =
    urlObj.conditionWhen ??
    urlObj.excludedWhen ??
    getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch[lang]);

  const [selectedCondition, setSelectedCondition] =
    useState<AvailableWhenConditionKey>(initialSelectedCondition);
  const [conditionValue, setConditionValue] = useState<string>(urlObj.url ?? '');
  const [override, setOverride] = useState<boolean>(
    selectedCondition ===
      getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch[lang]) &&
      !!urlObj.override,
  );

  const errorKey: ErrorType | undefined = useMemo(() => {
    if (
      conditionValue.length > 0 &&
      otherStorylinesUrls.some(
        (otherUrlObj) =>
          selectedCondition === otherUrlObj.conditionWhen && conditionValue === otherUrlObj.url,
      )
    ) {
      return 'WHEN_CONDITION_USED_BY_OTHER_SHORT';
    }

    if (
      conditionValue.length > 0 &&
      urlsJsonb.filter(
        (otherUrlObj) =>
          (selectedCondition === otherUrlObj.conditionWhen ||
            selectedCondition === otherUrlObj.excludedWhen) &&
          conditionValue === otherUrlObj.url,
      ).length > 1
    ) {
      return 'WHEN_CONDITION_USED';
    }

    if (
      selectedCondition ===
        getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch[lang]) &&
      !isValidHttpUrl(conditionValue)
    ) {
      return 'INVALID_URL';
    }

    return undefined;
  }, [conditionValue, selectedCondition, otherStorylinesUrls, urlsJsonb, lang]);

  const conditionValueValid = !errorKey;

  const updateUrlsJsonb = useCallback(
    (updatedUrlObj: UrlJsonb) => {
      const items = [...urlsJsonb];
      items[urlObj.index] = { ...updatedUrlObj, index: urlObj.index };
      setUrlsJsonb(items);
    },
    [urlsJsonb, urlObj.index, setUrlsJsonb],
  );

  const handleConditionValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setConditionValue(e.target.value);
  }, []);

  const handleConditionValueBlur = useCallback(() => {
    const updatedUrlObj = { ...urlObj, url: conditionValue };
    updateUrlsJsonb(updatedUrlObj);
  }, [conditionValue, updateUrlsJsonb, urlObj]);

  const handleSelectedConditionChange = useCallback(
    (e: SelectChangeEvent<AvailableWhenConditionKey>) => {
      const newCondition = e.target.value as AvailableWhenConditionKey;
      setSelectedCondition(newCondition);

      const updatedUrlObj = { ...urlObj };

      if (urlObj.conditionWhen) {
        updatedUrlObj.conditionWhen = newCondition;
      } else if (urlObj.excludedWhen) {
        updatedUrlObj.excludedWhen = newCondition;
      }

      if (
        newCondition !==
          getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch[lang]) &&
        updatedUrlObj.override
      ) {
        delete updatedUrlObj.override;
        setOverride(false);
      }

      updateUrlsJsonb(updatedUrlObj);
    },
    [urlObj, updateUrlsJsonb, lang],
  );

  const handleOverrideChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setOverride(isChecked);

      const updatedUrlObj = { ...urlObj };
      if (isChecked) {
        updatedUrlObj.override = true;
      } else {
        delete updatedUrlObj.override;
      }
      updateUrlsJsonb(updatedUrlObj);
    },
    [urlObj, updateUrlsJsonb],
  );

  const handleDelete = useCallback(() => {
    const items = urlsJsonb
      .filter((o) => o.index !== urlObj.index)
      .map((o, index) => ({ ...o, index }));
    setUrlsJsonb(items);
  }, [urlsJsonb, urlObj.index, setUrlsJsonb]);

  const placeholder = getPlaceholderText({ selectedConditionValue: selectedCondition, lang });
  const tooltip = getTooltipHelp(selectedCondition, lang);

  return (
    <Stack
      data-testid={`condition-editor-${indexInList}`}
      direction="row"
      spacing={1}
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Typography
        data-testid={`condition-editor-${indexInList}-label`}
        variant="titleSmall"
        noWrap
        sx={{
          fontWeight: indexInList > 0 ? 300 : 'inherit',
          minWidth: '56px',
        }}
        textAlign="right"
      >
        {indexInList === 0 ? translationsStoryline.when[lang] : translationsStoryline.or[lang]}:
      </Typography>

      {/* Website Link Label */}
      <Typography
        data-testid={`condition-editor-${indexInList}-website-link-label`}
        variant="titleSmall"
        noWrap
        sx={{
          fontWeight: indexInList > 0 ? 300 : 'inherit',
          minWidth: '72px',
        }}
      >
        {translationsStoryline.websiteLink[lang]}
      </Typography>

      {/* Condition Select */}
      <Select
        data-testid={`condition-editor-${indexInList}-select`}
        variant="outlined"
        size="small"
        value={selectedCondition}
        onChange={handleSelectedConditionChange}
        input={outlinedInput()}
        renderValue={(selected) =>
          translationsStoryline.availableWhenConditions?.[selected]?.[lang]
        }
        sx={{
          minWidth: '144px',
          backgroundColor: 'background.paper',
          '& .MuiInputBase-root': {
            border: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        {Object.keys(translationsStoryline.availableWhenConditions).map((key) => (
          <MenuItem
            data-testid={`condition-editor-${indexInList}-menu-item-${key}`}
            value={key}
            key={key}
            disabled={
              isFreemium &&
              Array.isArray(freemiumStoriesLimitCondition) &&
              !freemiumStoriesLimitCondition.includes(key as AvailableWhenConditionKey)
            }
          >
            {
              translationsStoryline.availableWhenConditions?.[key as AvailableWhenConditionKey]?.[
                lang
              ]
            }
          </MenuItem>
        ))}
      </Select>

      {/* Condition Value Input */}
      <Box sx={{ position: 'relative' }}>
        <TextField
          data-testid={`condition-editor-${indexInList}-text-field`}
          type="url"
          fullWidth
          variant="outlined"
          size="small"
          value={conditionValue}
          onChange={handleConditionValueChange}
          placeholder={placeholder}
          error={!conditionValueValid}
          onBlur={handleConditionValueBlur}
          sx={{
            width: '440px',
            backgroundColor: 'background.paper',
            '& .MuiInputBase-root': {
              ...(!conditionValueValid && errorKey ? {} : { border: 'none' }),
            },
            '& .MuiOutlinedInput-notchedOutline': {
              ...(!conditionValueValid && errorKey ? {} : { border: 'none' }),
            },
            borderRadius: '8px',
          }}
        />
        {!conditionValueValid && errorKey && (
          <Typography
            data-testid={`condition-editor-${indexInList}-error`}
            variant="titleSmall"
            color="error"
            sx={{
              position: 'absolute',
              bottom: '-20px',
              left: '0',
            }}
          >
            {translationsStoryline.errors?.[errorKey]?.[lang]}
          </Typography>
        )}
      </Box>

      {/* Override Checkbox */}
      {!!urlObj.conditionWhen && selectedCondition === 'exactMatch' && (
        <Stack alignItems="center" direction="row">
          <Checkbox
            data-testid={`condition-editor-${indexInList}-override-checkbox`}
            checked={override}
            onChange={handleOverrideChange}
          />
          <Typography
            data-testid={`condition-editor-${indexInList}-override-label`}
            variant="titleSmall"
          >
            {translationsStoryline.override[lang]}
          </Typography>
        </Stack>
      )}

      {/* Help Icon */}
      <Tooltip title={tooltip}>
        <HelpIcon
          data-testid={`condition-editor-${indexInList}-help-icon`}
          fontSize="small"
          style={{ color: 'rgb(193 197 213)' }}
        />
      </Tooltip>

      {/* Delete Icon */}
      {urlsJsonb.length > 1 && (
        <IconButton
          data-testid={`condition-editor-${indexInList}-delete-button`}
          onClick={handleDelete}
        >
          <DeleteOutlineIcon />
        </IconButton>
      )}
    </Stack>
  );
}

export default ConditionEditor;
