import React, { useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';

import hexToRGB from './hexToRGB';
import LabeledTextField from './LabeledTextField';
import ColorPreview from './ColorPreview';

export const defaultButtonBackgroundColor = '#FFFFFF';
export const defaultButtonTextColor = '#FFFFFF';
export const defaultButtonBorderColor = '#FFFFFF';

function PreviewButton({
  text,
  buttonBackgroundColor = defaultButtonBackgroundColor,
  textColor = defaultButtonTextColor,
}: {
  text: string;
  buttonBackgroundColor?: string;
  textColor?: string;
}) {
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

  const colors = useMemo(() => {
    const backgroundIsNotDefault = !(
      buttonBackgroundColor?.startsWith('#FFFFFF') || buttonBackgroundColor?.startsWith('#212121')
    );
    const rgbaButtonBackgroundColor05 = backgroundIsNotDefault
      ? hexToRGB(buttonBackgroundColor, '0.6')
      : hexToRGB(buttonBackgroundColor, '0.05');

    const rgbaButtonBackgroundColor23 = backgroundIsNotDefault
      ? hexToRGB(buttonBackgroundColor, '0.8')
      : hexToRGB(buttonBackgroundColor, '0.23');

    const rgbaButtonBorderColor38 = hexToRGB(defaultButtonBorderColor, '0.38');
    const rgbaButtonBorderColor17 = hexToRGB(defaultButtonBorderColor, '0.17');

    return {
      rgbaButtonBackgroundColor05,
      rgbaButtonBackgroundColor23,
      rgbaButtonBorderColor38,
      rgbaButtonBorderColor17,
      textColor,
    };
  }, [textColor, buttonBackgroundColor]);

  return (
    <Box sx={{ all: 'unset' }}>
      <Stack direction="row">
        <Box
          sx={{ p: 2, m: 2, mt: 3, ml: 0, backgroundColor, width: 'fit-content', borderRadius: 1 }}
        >
          <button
            type="button"
            style={{
              position: 'relative',
              cursor: 'pointer',
              width: 'auto',
              zIndex: 10,
              padding: '0',
              height: '1.875em',
              background: `linear-gradient(87deg, ${colors.rgbaButtonBackgroundColor05} 0%, ${colors.rgbaButtonBackgroundColor23} 100%)`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              WebkitBackdropFilter: 'blur(10px)',
              backdropFilter: 'blur(10px)',
              transition: 'opacity 0.5s ease',
              animationDelay: '0.5s',
              animation: 'appear 0.7s ease forwards',
              borderWidth: '0',
            }}
          >
            <span
              style={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: '10px',
                padding: '0.0625em',
                background: `linear-gradient(87deg, ${colors.rgbaButtonBorderColor38} 0%, ${colors.rgbaButtonBorderColor17} 100%)`,
                WebkitMaskImage: 'linear-gradient(black, black), linear-gradient(black, black)',
                maskImage: 'linear-gradient(black, black), linear-gradient(black, black)',
                WebkitMaskClip: 'content-box, border-box',
                maskClip: 'content-box, border-box',
                WebkitMaskComposite: 'exclude',
                maskComposite: 'exclude',
                pointerEvents: 'none',
                zIndex: 0,
              }}
            />
            <span
              style={{
                position: 'relative',
                fontFamily: '"Inter", "sans-serif"',
                fontSize: '0.875em',
                color: colors.textColor,
                whiteSpace: 'nowrap',
                padding: '0.5em 1em',
                zIndex: 10,
              }}
            >
              {text}
            </span>
          </button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Stack spacing={0}>
            <LabeledTextField
              label="Preview Background Color"
              value={backgroundColor}
              onChange={(e) => {
                setBackgroundColor(e.target.value?.toLocaleUpperCase()?.trim());
              }}
              startAdornment={
                <ColorPreview
                  color={backgroundColor}
                  onChange={(newColor) => {
                    setBackgroundColor(newColor?.toLocaleUpperCase()?.trim());
                  }}
                />
              }
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default PreviewButton;
