import React from 'react';
import LazyLoad from 'react-lazy-load';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import StorylineCard from './StorylineCard';

const cardWidth = 304;

function StorylineGrid({
  storylineObjects = [],
  handleSelected = () => {},
  basicOrgData = {},
  affectedItem = null,
  hasAccess = false,
}) {
  const isAffectedItem = (item) => {
    if (!affectedItem) return false;
    return item.type === affectedItem.type && Number(item.id) === Number(affectedItem.id);
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 2, mb: 2, ml: 3, mr: 3 }}>
      <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="baseline">
        {storylineObjects.map((storyline) => (
          <Grid item key={`${storyline.__typename}-${storyline.id}`}>
            <LazyLoad offset={600} width={cardWidth}>
              <StorylineCard
                storyline={storyline}
                handleSelected={handleSelected}
                cardWidth={cardWidth}
                basicOrgData={basicOrgData}
                affectedItem={isAffectedItem(storyline)}
                hasAccess={hasAccess}
              />
            </LazyLoad>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

StorylineGrid.propTypes = {
  storylineObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      __typename: PropTypes.string.isRequired,
    }),
  ),
  handleSelected: PropTypes.func,
  basicOrgData: PropTypes.shape({
    encodedId: PropTypes.string,
    id: PropTypes.number,
  }),
  affectedItem: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  hasAccess: PropTypes.bool,
};

export default StorylineGrid;
