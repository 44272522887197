import { Close } from '@mui/icons-material';
import { Card, CardActions, CardContent, CardHeader, Dialog, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../utils/translations';

function ConfirmPopup({
  open,
  title,
  description,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const lang = useLanguage();

  return (
    <Dialog
      maxWidth="md"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          flexDirection: 'column',
          maxHeight: '80vh',
          borderRadius: '15px',
          backgroundColor: 'background.default',
          '& .MuiPaper-root': {
            borderRadius: 2,
          },
          display: 'flex',
        }}
        style={{ position: 'relative' }}
      >
        <CardHeader
          action={
            <IconButton className="close-btn" sx={{ p: 0 }} onClick={onCancel}>
              <Close />
            </IconButton>
          }
          title={title}
          titleTypographyProps={{
            variant: 'headlineSmallBoldRecoleta',
          }}
          sx={{
            textAlign: 'left',
          }}
        />
        <CardContent sx={{ overflowY: 'auto', maxHeight: '80vh' }}>{description}</CardContent>
        <CardActions disableSpacing sx={{ mt: 'auto', alignSelf: 'end' }}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button variant="outlined" onClick={onCancel}>
              {translations.videoCard.noCancel[lang]}
            </Button>

            <Button variant="contained" color="error" disableElevation onClick={onConfirm}>
              {translations.videoCard.confirm[lang]}
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </Dialog>
  );
}

export default ConfirmPopup;
