// Permissions.tsx
import React, { useState, useCallback, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Box,
  Backdrop,
  Stack,
  TableRow,
  TableCell,
  Button,
  useMediaQuery,
  Snackbar,
  Alert,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { useLanguage } from 'src/context/LanguageContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import TablePagination from 'src/components/common/TablePagination';
import { matchSorter } from 'match-sorter';
import SearchField from 'src/components/common/SearchField/SearchField';

import translations from './translations';
import { Context, PermissionType, Order, User } from './types';
import UserRow from './UserRow';
import useOrgUsers from './useOrgUsers';
import useUpdatePermission from './useUpdatePermission';
import useCheckPermissionsAndNavigate from './CheckPermissionsAndNavigate';
import useApprovePermission from './useApprovePermission';
import useDeclinePermission from './useDeclinePermission';
import PermissionsTableHead from './PermissionsTableHead';
import InviteUserForm from './InviteUserForm';
import useOrgDomain from './useOrgDomain';
import useInviteUser from './useInviteUser';

function Permissions() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const lang = useLanguage();
  const { basicOrgData }: Context = useOutletContext<Context>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof PermissionType | keyof User | 'permission'>(
    'firstName',
  );
  const [inviteAnchorEl, setInviteAnchorEl] = useState<HTMLElement | null>(null);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  if (!basicOrgData?.premiumFeatures?.organizationPermissionsSettings === true) {
    navigate(`/${basicOrgData.orgNameSlug}/backoffice/settings`);
  }

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    isSuperAdmin,
  } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/recipients',
  });

  const {
    orgUsers,
    loading: orgUsersLoading,
    error: errorOrgUsers,
    refetch,
  } = useOrgUsers({ encodedOrgId: basicOrgData.encodedId, userDataExists: !!userData });

  const { updatePermission, loading: updatePermissionLoading } = useUpdatePermission();
  const { approvePermission, loading: approvePermissionLoading } = useApprovePermission();
  const { declinePermission, loading: declinePermissionLoading } = useDeclinePermission();
  const {
    domain: orgDomain,
    loading: domainLoading,
    error: domainError,
  } = useOrgDomain(basicOrgData.encodedId);
  const { inviteUser, loading: inviteUserLoading } = useInviteUser();

  const error = errorOrgUsers || userError;

  const maxUsers =
    basicOrgData?.premiumFeatures?.userLimitPerOrganization && orgDomain && !domainError
      ? basicOrgData.premiumFeatures.userLimitPerOrganization
      : undefined;

  const showMessage = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleInviteUser = async () => {
    try {
      const constructedEmail = `${inviteEmail}@${orgDomain}`.trim().toLowerCase();

      await inviteUser({
        variables: {
          encodedOrgId: basicOrgData.encodedId,
          email: constructedEmail,
        },
      });
      setInviteDialogOpen(false);
      setInviteAnchorEl(null);
      setInviteEmail('');
      refetch();
      showMessage(translations.successInvite[lang], 'success');
    } catch (err) {
      showMessage(translations.errorInvite[lang], 'error');
    }
  };

  const handlePermissionChange = useCallback(
    async (updateUserId: number, newPermission: PermissionType) => {
      try {
        await updatePermission({
          variables: {
            encodedOrgId: basicOrgData.encodedId,
            updateUserId,
            permission: newPermission,
          },
        });
        refetch();
        showMessage(translations.successPermission[lang], 'success');
      } catch (err) {
        showMessage(translations.errorPermission[lang], 'error');
      }
    },
    [updatePermission, basicOrgData.encodedId, refetch, lang],
  );

  const approveAccess = useCallback(
    async (userId: number) => {
      try {
        await approvePermission({
          variables: {
            encodedOrgId: basicOrgData.encodedId,
            updateUserId: userId,
          },
        });
        refetch();
        showMessage(translations.successApprove[lang], 'success');
      } catch (err) {
        showMessage(translations.errorApprove[lang], 'error');
      }
    },
    [approvePermission, basicOrgData.encodedId, refetch, lang],
  );

  const declineAccess = useCallback(
    async (userId: number) => {
      try {
        await declinePermission({
          variables: {
            encodedOrgId: basicOrgData.encodedId,
            updateUserId: userId,
          },
        });
        refetch();
        showMessage(translations.successDecline[lang], 'success');
      } catch (err) {
        showMessage(translations.errorDecline[lang], 'error');
      }
    },
    [declinePermission, basicOrgData.encodedId, refetch, lang],
  );

  const loading = orgUsersLoading || userLoading;
  const isLoading =
    userLoading ||
    orgUsersLoading ||
    updatePermissionLoading ||
    approvePermissionLoading ||
    declinePermissionLoading ||
    inviteUserLoading;

  const disablePermissionChange = useCallback(() => {
    const userPermission = userData?.me?.userPermissions?.[0]?.name;
    return !(isSuperAdmin || userPermission === 'org_admin');
  }, [userData, isSuperAdmin]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PermissionType | keyof User,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleInviteClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      setInviteDialogOpen(true);
    } else {
      setInviteAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setInviteEmail('');
    setInviteAnchorEl(null);
  };

  const filteredAndSortedUsers = useMemo(() => {
    if (!orgUsers) return [];
    let filtered = orgUsers;

    if (searchTerm) {
      filtered = matchSorter(orgUsers, searchTerm, {
        keys: ['firstName', 'lastName', 'email'],
      });
    }

    const sorted = filtered.slice().sort((a, b) => {
      if (orderBy === 'firstName' || orderBy === 'lastName' || orderBy === 'email') {
        const isAEmpty = !a[orderBy];
        const isBEmpty = !b[orderBy];

        if (order === 'asc') {
          if (isAEmpty && !isBEmpty) return 1;
          if (!isAEmpty && isBEmpty) return -1;
        } else {
          if (isAEmpty && !isBEmpty) return -1;
          if (!isAEmpty && isBEmpty) return 1;
        }

        if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
        if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
        return 0;
      }

      if (orderBy === 'permission') {
        const permissionAKey = a.userPermissions?.[0]
          ?.name as keyof typeof translations.permissionTypes;
        const permissionBKey = b.userPermissions?.[0]
          ?.name as keyof typeof translations.permissionTypes;

        const permissionA =
          permissionAKey && translations.permissionTypes[permissionAKey]
            ? translations.permissionTypes[permissionAKey][lang]
            : a.userPermissions?.[0]?.name;
        const permissionB =
          permissionBKey && translations.permissionTypes[permissionBKey]
            ? translations.permissionTypes[permissionBKey][lang]
            : b.userPermissions?.[0]?.name;

        if (permissionA < permissionB) return order === 'asc' ? -1 : 1;
        if (permissionA > permissionB) return order === 'asc' ? 1 : -1;
        return 0;
      }
      return 0;
    });

    return sorted;
  }, [orgUsers, searchTerm, orderBy, order, lang]);

  if (error || !userData || loading || !orgUsers) {
    return <LoadingIndicator data-testid="loading-indicator" />;
  }

  const currentUsers = orgUsers.length;
  const hasReachedUserLimit = !!(maxUsers && currentUsers >= maxUsers && maxUsers !== 0);

  return (
    <Box sx={{ p: 0, pt: 2, position: 'relative' }} data-testid="permissions-box">
      <Backdrop
        open={loading || isLoading}
        style={{ opacity: 0.2 }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: 1,
          zIndex: (th) => th.zIndex.drawer + 1,
        }}
        aria-label="Loading data"
      />
      {isLoading ||
        (loading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            data-testid="central-loading-indicator"
          >
            <LoadingIndicator backgroundColor="transparent" />
          </Box>
        ))}

      <Stack
        sx={{ mt: 0, mb: 0, px: 0 }}
        alignItems="center"
        justifyContent="flex-end"
        direction="row"
        spacing={3}
      >
        <SearchField
          placeholder={translations.searchUsers[lang]}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          data-testid="search-field"
          disabled={isLoading}
          sx={{ maxWidth: '300px' }}
        />
        {orgDomain && !domainError && (
          <Tooltip title={hasReachedUserLimit ? translations.userLimitReached[lang] : ''}>
            <span>
              <Button
                sx={{
                  m: 0,
                }}
                variant="contained"
                onClick={handleInviteClick}
                disabled={
                  isLoading || domainLoading || !orgDomain || !!domainError || hasReachedUserLimit
                }
                data-testid="invite-button"
              >
                {translations.inviteUser[lang]}
              </Button>
            </span>
          </Tooltip>
        )}
      </Stack>

      {orgDomain && !domainError && (
        <InviteUserForm
          isMobile={isMobile}
          inviteAnchorEl={inviteAnchorEl}
          inviteDialogOpen={inviteDialogOpen}
          inviteEmail={inviteEmail}
          lang={lang}
          onInviteEmailChange={(e) => setInviteEmail(e.target.value)}
          orgDomain={orgDomain}
          handlePopoverClose={handlePopoverClose}
          handleInviteUser={handleInviteUser}
          setInviteDialogOpen={setInviteDialogOpen}
          orgUsers={orgUsers}
        />
      )}

      <TableContainer component={Paper} sx={{ mt: 3, px: 1, py: 2 }} data-testid="table-container">
        <Table aria-label="Organization Users Table" data-testid="users-table">
          <PermissionsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody data-testid="table-body">
            {filteredAndSortedUsers.length > 0 ? (
              filteredAndSortedUsers.map((user) => (
                <UserRow
                  key={user.id}
                  user={user}
                  onPermissionChange={handlePermissionChange}
                  disableChange={disablePermissionChange() || isLoading}
                  disableApprove={hasReachedUserLimit}
                  lang={lang}
                  onApprove={approveAccess}
                  onDecline={declineAccess}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>{translations.noUsersFound[lang]}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          lang={lang}
          count={filteredAndSortedUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          data-testid="pagination-control"
          disabled={isLoading}
        />
      </TableContainer>
      {maxUsers && maxUsers !== 0 && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="flex-start"
          justifyContent="flex-end"
          sx={{
            mt: 1,
            mr: 1,
          }}
        >
          <Typography variant="bodySmall" data-testid="user-count">
            {maxUsers === Infinity
              ? `${currentUsers} ${translations.users[lang]}`
              : `${currentUsers} ${translations.outOf[lang]} ${maxUsers} ${translations.users[lang]}`}
          </Typography>
        </Stack>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Permissions;
