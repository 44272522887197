import React, { useState, useEffect, useContext, useCallback } from 'react';
import LazyLoad from 'react-lazy-load';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { pink } from '../../common/SharedStylingSettings';
import { useVideoFunnelsApi } from '../../../api/videoFunnels';
import { storylineStatuses } from '../../../utils/utils';
import { useApiUtilsStoryline } from '../../../utils/api-utils-storyline';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import StorylineCardHeader from './StorylineCardHeader';

import ThemeContext from '../../../utils/ThemeContext';
import VideoFunnelOutlinedIcon from '../../common/icons/videoFunnel/VideoFunnelOutlined';
import StorylineOutlinedIcon from '../../common/icons/storyline/StorylineOutlined';
import StoryMedia from '../StoryMedia';

export default function StorylineCard({
  storyline,
  handleSelected,
  cardWidth,
  basicOrgData,
  affectedItem = false,
  hasAccess = false,
}) {
  const theme = useContext(ThemeContext);
  const lang = useLanguage();
  const apiUtilsStoryline = useApiUtilsStoryline();
  const videoFunnelsApi = useVideoFunnelsApi();
  const storylineIsActive = storyline?.status === storylineStatuses.LIVE;

  const [storylineData, setStorylineData] = useState(storyline);

  const fetchData = useCallback(() => {
    if (
      !storylineData?.lastPullFromDatabase ||
      Math.abs(new Date() - storylineData.lastPullFromDatabase) / 1000 > 120
    ) {
      if (storyline?.type === 'videoFunnel') {
        videoFunnelsApi
          .fetchVideoFunnel({
            id: storylineData.id,
            encodedOrgId: basicOrgData.encodedId,
            orgId: basicOrgData.id,
          })
          .then((res) => {
            setStorylineData((prev) => ({
              ...prev,
              ...res.data.fetchVideoFunnel,
              lastPullFromDatabase: new Date(),
            }));
          })
          .catch(() => {});
      } else {
        apiUtilsStoryline
          .getVideoCardsByStorylineId({
            encodedOrgId: basicOrgData.encodedId,
            storylineId: storylineData.id,
            statuses: [],
          })
          .then((res) => {
            setStorylineData((prev) => ({
              ...prev,
              lastPullFromDatabase: new Date(),
              videoCards: res.data.videoCardsByStorylineIdAndTags.videoCards,
            }));
          })
          .catch(() => {});
      }
    }
  }, [apiUtilsStoryline, basicOrgData, storyline, storylineData, videoFunnelsApi]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, [fetchData]);

  const numVideos = useCallback(() => {
    if (storyline?.type === 'videoFunnel') {
      return (
        storylineData?.videoFunnelNodes?.filter((node) => !!node?.data?.videoCard?.id)?.length ?? 0
      );
    }
    return storylineData?.videoCards?.length ?? 0;
  }, [storyline, storylineData]);

  return (
    <Card
      elevation={0}
      sx={{
        width: `${cardWidth}px`,
        height: '100%',
        p: 0,
        borderRadius: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1,
        display: 'flex',
        transition: 'transform 0.2s ease-in-out',
        tabIndex: 1,
        ':hover': {
          transform: 'scale(1.03)',
          cursor: 'pointer',
        },
        ...(affectedItem && {
          border: `0.5px solid ${pink}`,
          boxShadow: `0px 0px 2px 0px ${pink}`,
        }),
      }}
      tabIndex={0}
      onKeyDown={(e) => e.key === 'Enter' && handleSelected(storyline)}
      onClick={() => handleSelected(storyline)}
    >
      <Box>
        <StorylineCardHeader
          background={
            storylineIsActive ? theme.palette.secondary.main : theme.palette.text.secondary
          }
          circle={
            storylineIsActive ? theme.palette.background.paper : theme.palette.background.default
          }
        />
        <LazyLoad offset={600} width={cardWidth} height={cardWidth * (10 / 16)}>
          <StoryMedia
            cardWidth={cardWidth}
            isActive={storylineIsActive}
            srcImage={
              storyline?.urlsJsonb?.find((urlObj) => !!urlObj?.srcImage)?.srcImage || undefined
            }
          />
        </LazyLoad>
        <CardContent
          sx={{
            p: 1,
            ':last-child': {
              paddingBottom: 1,
            },
          }}
        >
          <Grid container spacing={0} alignItems="top" justifyContent="center">
            <Grid item xs={11}>
              <Typography variant="titleMedium" color="text.primary">
                {storyline?.title || ''}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Tooltip
                title={
                  storyline?.type === 'videoFunnel'
                    ? translationsStoryline.videoFunnel.title[lang]
                    : translationsStoryline.storyline[lang]
                }
                PopperProps={{
                  modifiers: [{ name: 'offset', options: { offset: [0, -20] } }],
                }}
              >
                <Box>
                  {storyline?.type === 'videoFunnel' ? (
                    <VideoFunnelOutlinedIcon
                      fill={
                        storylineIsActive
                          ? theme.palette.secondary.main
                          : theme.palette.text.secondary
                      }
                    />
                  ) : (
                    <StorylineOutlinedIcon
                      fill={
                        storylineIsActive
                          ? theme.palette.secondary.main
                          : theme.palette.text.secondary
                      }
                    />
                  )}
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
          <Stack spacing={1} direction="row" justifyContent="space-between">
            {numVideos() > 0 ? (
              <Typography variant="bodyMedium">
                {`${numVideos()} ${numVideos() > 1 ? translationsStoryline.videos[lang] : translationsStoryline.video[lang]}`}
              </Typography>
            ) : (
              <Box />
            )}
            {hasAccess && storyline?.user?.firstName && storyline?.user?.lastName && (
              <Typography variant="bodyMedium">
                {`${storyline?.user?.firstName} ${storyline?.user?.lastName}`}
              </Typography>
            )}
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
}

StorylineCard.propTypes = {
  storyline: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    urlsJsonb: PropTypes.arrayOf(
      PropTypes.shape({
        srcImage: PropTypes.string,
      }),
    ),
    user: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
  }).isRequired,
  handleSelected: PropTypes.func.isRequired,
  cardWidth: PropTypes.number.isRequired,
  basicOrgData: PropTypes.shape({
    encodedId: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  affectedItem: PropTypes.bool,
  hasAccess: PropTypes.bool,
};
