import { useMutation } from '@apollo/client';
import { APPROVE_USER_PERMISSION } from './queries';

function useApprovePermission() {
  const [approvePermission, { data, loading, error }] = useMutation(APPROVE_USER_PERMISSION);

  return {
    approvePermission,
    data,
    loading,
    error,
  };
}

export default useApprovePermission;
