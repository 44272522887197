import { useMutation } from '@apollo/client';
import { DECLINE_USER_PERMISSION } from './queries';

function useDeclinePermission() {
  const [declinePermission, { data, loading, error }] = useMutation(DECLINE_USER_PERMISSION);

  return {
    declinePermission,
    data,
    loading,
    error,
  };
}

export default useDeclinePermission;
