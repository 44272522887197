import { gql } from '@apollo/client';
import { FREEMIUM_FEATURES_FRAGMENT } from 'src/components/admin/Organizations';

const GET_ORGANIZATION_DATA = gql`
  ${FREEMIUM_FEATURES_FRAGMENT}
  query Organization($orgNameSlug: String) {
    organization(orgNameSlug: $orgNameSlug) {
      userStatus
      organization {
        id
        orgName
        orgNameSlug
        encodedId
        lang
        contactEmail
        orgNumber
        logoPath
        tagFinderSelector
        widgetIsActive
        orgNumber
        tagFinderSelector
        widgetIsActive
        disallowSuperadmin
        doNotTrack
        storylineOverride
        customTexts {
          uploadConsent
          soMeConsent {
            text
            enable
            mandatory
          }
        }
        style {
          backgroundColor
          textColor
          btnColor
          btnTextColor
          widgetPositionLeft
          excludeWidgetLibraryBtn
          ctaLabel
          minimizedWidgetTitle
          widgetBottomMargin
          widgetSideMargin
          widgetPositionTop
          widgetProcentBasedMargin
          widgetPositionLeftMobile
          widgetBottomMarginMobile
          widgetSideMarginMobile
          widgetPositionTopMobile
          widgetProcentBasedMarginMobile
          separateDesktopMobile
          widgetHideDialogTitle
          widgetHideDialogTitleMobile
        }

        premiumFeatures {
          interactionOption
          textMessage
          subtitleAllowed
          includeSubtitle
          editableConsentTexts
          autoReminders
          autoRequests
          excludeFromVimeo
          advancedWidgetStyling
          advancedWidgetBehavior
          requestsLibraryBeta
          orgSubtitleUpload
          advancedWidgetStylingMobile
          widgetThree
          videoFunnel
          videoFunnelEndScreen
          widgetVersion
          packageVariant
          freemiumFeatures {
            ...FreemiumDetails
          }
          advStyleVidTitleDialog
          advStyleStories
          videoCollector
          organizationPermissionsSettings
          videoRequestEmailCustomization
          conversationalAvatar
          userLimitPerOrganization
          widgetVersion
        }
        promoteRecordVideoPortrait
      }
    }
  }
`;

export default GET_ORGANIZATION_DATA;
