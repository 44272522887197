import { Components } from '@mui/material/styles';

const componentsStyleDefinitions: Components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: '100px',
        padding: '10px 24px',
        gap: '8px',
        margin: '4px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:focus-visible': {
          outlineColor: 'rgb(0, 95, 204)',
          outlineStyle: 'auto',
          outlineWidth: '1px',
        },
        '& .MuiSvgIcon-root, & .MuiButton-startIcon, & .MuiButton-endIcon, & > .MuiSvgIcon-root, & > svg':
          {
            height: '20px',
            width: '20px',
            fontSize: '20px',
          },
        '& > img': {
          height: '20px',
          width: '20px',
          objectFit: 'contain',
        },
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&:focus-visible': {
          outlineColor: 'rgb(0, 95, 204)',
          outlineStyle: 'auto',
          outlineWidth: '1px',
        },
      },
    },
  },

  MuiButtonGroup: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: '100px',
        padding: '0px',
        gap: '0px',
        margin: '4px',
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        '.MuiCardHeader-avatar': {
          marginRight: 8,
        },
      },
      title: {
        typography: 'titleMedium',
      },
      subheader: {
        typography: 'bodyMedium',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 46,
        height: 27,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 0,
        margin: 2,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 22,
        height: 22,
        color: '#fff',
      },
      track: {
        borderRadius: 13,
        backgroundColor: '#787880',
        opacity: 0.3,
        transition:
          'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.30)',
        borderRadius: 16,
        fontSize: 14,
        fontFamily: 'Inter, sans-serif',
        fontWeight: '500',
        lineHeight: 20,
        letterSpacing: 0.1,
        textTransform: 'none',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        width: '48px',
        '& > img, & > svg, & > .MuiSvgIcon-root': {
          height: '22px',
          width: '22px',
        },
      },
    },
  },

  MuiCard: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          padding: '4px 8px',
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
      head: {
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
  },
};

export default componentsStyleDefinitions;
