const selectBackOfficeTranslations = {
  requestAccessTitle: {
    en: 'Looking for access?',
    sv: 'Letar du efter åtkomst?',
  },
  requestAccessText: {
    en: 'Please contact your organization administrator to request access. Or click below to request access.',
    sv: 'Kontakta din organisationsadministratör för att begära åtkomst. Eller klicka nedan för att begära åtkomst.',
  },
  requestAccessButtonTitle: {
    en: 'Request Access',
    sv: 'Begär åtkomst',
  },
  requestAccessButtonRequesting: {
    en: 'Requesting...',
    sv: 'Begär...',
  },
  requestAccessPending: {
    en: 'Your access request is awaiting approval. For any questions, please contact your system administrator.',
    sv: 'Din åtkomstförfrågan väntar på godkännande. Kontakta din systemadministratör om du har några frågor.',
  },
  selectOrganization: {
    en: 'Select Organization',
    sv: 'Välj Organisation',
  },
  pendingApprovalTitle: {
    en: 'Pending Approval',
    sv: 'Väntar på godkännande',
  },
  requestAccessFailed: {
    en: 'Failed to request access. Please try again.',
    sv: 'Det gick inte att begära åtkomst. Försök igen.',
  },
  requestAccessError: {
    en: 'An error occurred. Please try again.',
    sv: 'Ett fel inträffade. Försök igen.',
  },
  requestAccessSuccess: {
    en: 'Access requested successfully.',
    sv: 'Åtkomst begärdes framgångsrikt.',
  },
};

export default selectBackOfficeTranslations;
