import React, { useState, useMemo } from 'react';
import {
  TableCell,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Typography,
  Button,
  Box,
  Stack,
  IconButton,
} from '@mui/material';
import DeleteOutlinedIcon from 'src/components/common/icons/delete/DeleteOutlinedIcon';
import translations from './translations';
import { User, PermissionType } from './types';

export interface UserRowProps {
  user: User;
  onPermissionChange: (userId: User['id'], permission: PermissionType) => void;
  disableChange: boolean;
  lang: Language;
  onApprove: (userId: User['id']) => void;
  onDecline: (userId: User['id']) => void;
  disableApprove: boolean;
}

const getCellStyles = (isInvited: boolean) => ({
  fontStyle: isInvited ? 'italic' : 'normal',
  color: isInvited ? 'text.secondary' : 'inherit',
});

const UserRow = React.memo(
  ({
    user,
    onPermissionChange,
    disableChange,
    lang,
    onApprove,
    onDecline,
    disableApprove,
  }: UserRowProps) => {
    const [isHovered, setIsHovered] = useState(false); // Track hover state

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
      onPermissionChange(user.id, event.target.value as PermissionType);
    };

    const selectWidth = useMemo(() => {
      const longestPermission = Object.values(translations.permissionTypes)
        .map((perm) => perm[lang])
        .reduce((acc, curr) => (curr.length > acc.length ? curr : acc), '');

      const textWidth = longestPermission.length * 12;
      return `${textWidth}px`;
    }, [lang]);

    const userPermissionName = user.userPermissions?.[0]?.name ?? '';
    const isInvited = userPermissionName === 'invited_user';

    const renderUserName = () => {
      return user.firstName;
    };

    const renderUserLastName = () => {
      return user.lastName;
    };

    const renderUserEmail = () => {
      return user.email;
    };

    const renderActionButtons = () => {
      if (userPermissionName === 'requested_access') {
        return (
          <Box>
            <Button
              variant="text"
              color="primary"
              onClick={() => onApprove(user.id)}
              disabled={disableChange || disableApprove}
              data-testid={`approve-button-${user.id}`}
              sx={{ m: 0 }}
            >
              {translations.buttons.approve[lang]}
            </Button>
            <Button
              variant="text"
              color="error"
              onClick={() => onDecline(user.id)}
              disabled={disableChange}
              data-testid={`decline-button-${user.id}`}
              sx={{ m: 0, ml: 1 }}
            >
              {translations.buttons.decline[lang]}
            </Button>
          </Box>
        );
      }

      if (isInvited) {
        return (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Typography>{translations.status.pending[lang]}</Typography>
            <IconButton
              onClick={() => onDecline(user.id)}
              disabled={disableChange || !isHovered}
              data-testid={`delete-invite-button-${user.id}`}
              sx={{
                visibility: isHovered ? 'visible' : 'hidden',
                alignItems: 'center',
                justifyContent: 'center',
                m: 0,
                p: 0,
              }}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Stack>
        );
      }

      return (
        <FormControl data-testid={`form-control-permission-${user.id}`}>
          <Select
            size="small"
            value={userPermissionName}
            onChange={handleSelectChange}
            disabled={disableChange}
            data-testid={`select-permission-${user.id}`}
            displayEmpty
            aria-invalid={disableChange ? 'true' : 'false'}
            sx={{ width: selectWidth }}
            inputProps={{
              'aria-label': `${translations.ariaLabel[lang]} ${user.firstName} ${user.lastName}`,
            }}
          >
            {Object.entries(translations.permissionTypes).map(([key, value]) => (
              <MenuItem key={key} value={key} data-testid={`option-${key}-${user.id}`}>
                {value[lang]}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="srOnly" component="span" id={`permission-help-${user.id}`}>
            {translations.userRow.helpText[lang]}
          </Typography>
        </FormControl>
      );
    };

    return (
      <TableRow
        data-testid={`user-row-${user.id}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          height: '100%',
        }}
      >
        <TableCell sx={getCellStyles(isInvited)} data-testid={`user-first-name-${user.id}`}>
          {renderUserName()}
        </TableCell>
        <TableCell sx={getCellStyles(isInvited)} data-testid={`user-last-name-${user.id}`}>
          {renderUserLastName()}
        </TableCell>
        <TableCell sx={getCellStyles(isInvited)} data-testid={`user-email-${user.id}`}>
          {renderUserEmail()}
        </TableCell>
        <TableCell sx={getCellStyles(isInvited)} data-testid={`user-permission-${user.id}`}>
          {renderActionButtons()}
        </TableCell>
      </TableRow>
    );
  },
);

export default UserRow;
