import { gql } from '@apollo/client';

export const MY_ORGANIZATIONS_QUERY = gql`
  query MyOrganizations {
    myOrganizations {
      userStatus
      organizations {
        id
        encodedId
        orgName
        orgNameSlug
        logoPath
        backgroundColor
      }
    }
  }
`;

export const REQUEST_ACCESS_MUTATION = gql`
  mutation RequestAccess {
    requestAccess {
      success
      message
    }
  }
`;
