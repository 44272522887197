import { Skeleton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useLanguage } from '../../../../context/LanguageContext';
import translationsAnalytics from '../../../../utils/translationsAnalytics';
import { DataItem } from './types';

interface OverviewHeaderProps {
  impressionsData: DataItem[];
  playsData: DataItem[];
}

interface TitleStatsHighlightedProps {
  totalImpressions: number;
  totalPlays: number;
  playRate: number;
}

function TitleStatsHighlighted({
  totalImpressions,
  totalPlays,
  playRate,
}: TitleStatsHighlightedProps): JSX.Element {
  const lang = useLanguage();
  const { part1, part2, part3, part4 } = translationsAnalytics.totalImpressionsCard.title;

  return (
    <Typography variant="body1">
      <Typography variant="headlineSmall">{part1[lang]}</Typography>
      <Typography color="secondary" variant="headlineLargeBoldRecoleta">
        {totalImpressions.toFixed(0)}
      </Typography>
      <Typography variant="headlineSmall">{part2[lang]}</Typography>
      <Typography color="secondary" variant="headlineLargeBoldRecoleta">
        {totalPlays.toFixed(0)}
      </Typography>
      <Typography variant="headlineSmall">{part3[lang]}</Typography>
      <Typography color="secondary" variant="headlineLargeBoldRecoleta">
        {playRate.toFixed(0)}%
      </Typography>
      <Typography variant="headlineSmall">{part4[lang]}</Typography>
    </Typography>
  );
}

function OverviewHeader({ impressionsData, playsData }: OverviewHeaderProps) {
  const { totalImpressions } = useMemo(() => {
    const result = impressionsData.reduce(
      (acc: { total: number; max: number }, curr: DataItem) => ({
        total: acc.total + curr.count,
        max: Math.max(acc.max, curr.count),
      }),
      { total: 0, max: -Infinity },
    );

    return {
      totalImpressions: result.total,
      maxImpressions: result.max,
    };
  }, [impressionsData]);

  const { totalPlays } = useMemo(() => {
    const result = playsData.reduce(
      (acc: { total: number; max: number }, curr: DataItem) => ({
        total: acc.total + curr.count,
        max: Math.max(acc.max, curr.count),
      }),
      { total: 0, max: -Infinity },
    );

    return {
      totalPlays: result.total,
      maxPlays: result.max,
    };
  }, [playsData]);

  const playRate = useMemo(
    () => (totalImpressions > 0 ? (totalPlays / totalImpressions) * 100 : 0),
    [totalImpressions, totalPlays],
  );

  const loading = impressionsData.length === 0 && playsData.length === 0;

  if (loading) {
    return <Skeleton height="40px" width="800px" animation="wave" />;
  }

  return (
    <TitleStatsHighlighted
      totalImpressions={totalImpressions}
      totalPlays={totalPlays}
      playRate={playRate}
    />
  );
}

export default OverviewHeader;
