import { CircleRounded } from '@mui/icons-material';
import { Box, Card, CardMedia, Skeleton, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useLanguage } from '../../../../context/LanguageContext';
import ThemeContext from '../../../../utils/ThemeContext';
import translationsAnalytics from '../../../../utils/translationsAnalytics';
import { getStaticThumbnail, VideoCard } from '../../../../utils/video-card/utils';
import { sumAndFormatTotalTime, sumTotalImpressions } from './dataUtils';
import Error from './Error';
import { Question } from '../../BackOfficeTemplate/types';

interface BestPerformanceProps {
  topViewTimeVideoCardData: {
    encodedId: string;
    question: Question;
    actorName: string;
    recipient: { name: string };

    stats: {
      playback: {
        timePlayed: Array<{ timestamp: string; count: number }>;
      };
    };
  } | null;
  topImpressionsVideoCardData: {
    encodedId: string;
    question: Question;
    actorName: string;
    recipient: { name: string };
    stats: {
      playback: {
        impression: Array<{
          timestamp: string;
          count: number;
        }>;
      };
    };
  } | null;
}

function BestPerformance({
  topViewTimeVideoCardData,
  topImpressionsVideoCardData,
}: BestPerformanceProps) {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const displayData = useMemo(() => {
    return {
      topViewTime: {
        ...topViewTimeVideoCardData,
        metric: {
          metricName: translationsAnalytics.bestPerformance.metricName.totalViewTime[lang],
          value: topViewTimeVideoCardData?.stats.playback.timePlayed
            ? sumAndFormatTotalTime(topViewTimeVideoCardData?.stats.playback.timePlayed, lang)
            : 0,
          unit: '',
        },
      },
      topImpressions: {
        ...topImpressionsVideoCardData,
        metric: {
          metricName: translationsAnalytics.bestPerformance.metricName.totalImpressions[lang],
          value: topImpressionsVideoCardData?.stats.playback.impression
            ? sumTotalImpressions(topImpressionsVideoCardData.stats.playback.impression)
            : 0,
          unit: '',
        },
      },
    };
  }, [topViewTimeVideoCardData, topImpressionsVideoCardData, lang]);

  useEffect(() => {
    setLoading(true);

    setError(null);

    const timer = setTimeout(() => {
      if (!topViewTimeVideoCardData) {
        setError(translationsAnalytics.totalImpressionsCard.error?.[lang]);
      }
      setLoading(false);
    }, 10000);

    if (topViewTimeVideoCardData) {
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, [topViewTimeVideoCardData, lang]);

  if (loading) {
    return (
      <Skeleton
        sx={{ height: '284px', borderRadius: '8px' }}
        animation="wave"
        variant="rectangular"
      />
    );
  }

  return (
    <Card
      sx={{
        display: 'flex',
        boxShadow: 'none',
        borderRadius: '8px',
        maxHeight: '284px',
        position: 'relative',
      }}
    >
      {error && <Error error={error} />}

      <Box sx={{ position: 'relative', width: '100%' }}>
        <Carousel
          navButtonsProps={{
            style: {
              backgroundColor: 'transparent',
              color: theme.palette.primary.main,
              width: '100%',
            },
          }}
          indicators={false}
          autoPlay
        >
          {Object.values(displayData)?.map((videoData, index) => (
            <Box key={`${videoData.encodedId}-box`}>
              <Stack direction="row" spacing={2}>
                <Box
                  key={`${videoData.encodedId}-box-video`}
                  sx={{
                    position: 'relative',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    flex: '0 0 auto',
                    height: '284px',
                  }}
                >
                  <Typography
                    key={`${videoData.encodedId}-video-title`}
                    variant="titleSmall"
                    color="primary.contrastText"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)',
                      p: 1,
                      borderTopRightRadius: '8px',
                    }}
                  >
                    {videoData?.question && videoData?.question[lang]
                      ? videoData?.question[lang]
                      : ' '}
                  </Typography>
                  <CardMedia
                    key={`${videoData.encodedId}-video-thumbnail`}
                    image={getStaticThumbnail(
                      videoData as Pick<VideoCard, 'staticThumbnails'>,
                      'ntsc',
                    )}
                    component="img"
                    sx={{
                      borderRadius: '8px',
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                  <Typography
                    variant="labelSmall"
                    key={`${videoData.encodedId}-video-actor`}
                    color="primary.contrastText"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)',
                      p: 1,
                      borderBottomRightRadius: '8px',
                    }}
                  >
                    {videoData?.recipient?.name ?? videoData?.actorName ?? ''}
                  </Typography>
                </Box>

                <Stack
                  key={`${videoData.encodedId}-box-metrics`}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Stack key={videoData.metric.metricName} alignItems="center">
                    <Typography
                      variant="titleMedium"
                      sx={{
                        mb: 8,
                        mt: 3,
                      }}
                    >
                      {translationsAnalytics.bestPerformance?.title?.[lang]}
                    </Typography>

                    <Typography variant="titleLarge" display="block">
                      {videoData.metric.metricName}
                    </Typography>

                    <Typography variant="headlineLargeBoldRecoleta" color="secondary.main">
                      {videoData.metric.value}
                      {videoData.metric.unit}
                    </Typography>
                  </Stack>
                  <Box display="flex" gap={1} marginBottom={1}>
                    {Object.keys(displayData).map((k, dotIndex) => (
                      <CircleRounded
                        sx={{ fontSize: '10px' }}
                        key={k}
                        color={dotIndex === index ? 'primary' : 'secondary'}
                      />
                    ))}
                  </Box>
                </Stack>
              </Stack>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Card>
  );
}

export default BestPerformance;
