import React, { useState, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Position, Handle, useReactFlow } from 'reactflow';
import { useOutletContext } from 'react-router-dom';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import useConnectable from './useConnectable';
import VideoFunnelAddVideoDialog from './VideoFunnelAddVideoDialog';
import HandleToolTip from './HandleToolTip';

const maxVideo = 1;

function onMediaFallback(event) {
  event.target.src = '/images/icons/empty-video-node.svg';
}

const cardHeight = 606;
const cardWidth = cardHeight * 0.498;

function VideoFunnelEmptyVideoNode({ data, isConnectable, id }) {
  const isDragging = data?.isDragging || false;
  const isTarget = data?.isTarget || false;
  const containerRef = useRef(null);
  const [openAddVideo, setOpenAddVideo] = useState(false);
  const { getEdges, setNodes } = useReactFlow();

  const lang = useLanguage();
  const { basicOrgData } = useOutletContext();

  const hasTargetConnections = useConnectable({ id: data?.id, direction: 'target' });
  const hasSourceConnectionHandleA = useConnectable({
    id: data?.id,
    direction: 'source',
    sourceHandle: 'a',
  });
  const hasSourceConnectionHandleB = useConnectable({
    id: data?.id,
    direction: 'source',
    sourceHandle: 'b',
  });
  const hasSourceConnectionHandleC = useConnectable({
    id: data?.id,
    direction: 'source',
    sourceHandle: 'c',
  });
  const hasSourceConnectionHandleD = useConnectable({
    id: data?.id,
    direction: 'source',
    sourceHandle: 'd',
  });

  const isValidConnection = useCallback(
    (connection) => {
      if (connection.sourceHandle === 'a' && hasSourceConnectionHandleA) {
        return false;
      }
      if (connection.sourceHandle === 'b' && hasSourceConnectionHandleB) {
        return false;
      }
      if (connection.sourceHandle === 'c' && hasSourceConnectionHandleC) {
        return false;
      }
      if (connection.sourceHandle === 'd' && hasSourceConnectionHandleD) {
        return false;
      }
      return (
        connection.target !== connection.source &&
        getEdges()?.filter(
          (edge) => edge.target === connection.target && edge.source === connection.source,
        )?.length === 0
      );
    },
    [
      hasSourceConnectionHandleA,
      hasSourceConnectionHandleB,
      hasSourceConnectionHandleC,
      hasSourceConnectionHandleD,
      getEdges,
    ],
  );

  const handleOnCloseAddVideo = useCallback(
    ({ newVideoCards }) => {
      setOpenAddVideo(false);
      if (newVideoCards?.length === maxVideo) {
        const videoCard = newVideoCards[0];

        setNodes((prevNodes) => {
          const node = prevNodes?.find((n) => n.id === id);
          const newNodes = prevNodes?.filter((n) => n.id !== id);

          return [
            ...(newNodes?.length
              ? newNodes.map((n) => ({ ...n, data: { ...n?.data, shouldCheckConnections: true } }))
              : []),
            {
              ...node,
              type: 'videoFunnelVideoNode',
              data: {
                label: videoCard?.question?.[lang],
                id,
                videoCard,
                shouldCheckConnections: true,
              },
              newNode: true,
            },
          ];
        });
      }
    },
    [setOpenAddVideo, setNodes, id, lang],
  );

  const handleOnCancelAddVideo = useCallback(() => {
    setOpenAddVideo(false);
  }, []);

  const scaleFactor = useMemo(() => {
    if (isDragging) {
      return 0.95;
    }
    if (isTarget) {
      return 1.15;
    }
    return 1;
  }, [isDragging, isTarget]);

  const handleIconButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      setOpenAddVideo(true);
    },
    [setOpenAddVideo],
  );

  const handleButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      setOpenAddVideo(true);
    },
    [setOpenAddVideo],
  );

  return (
    <div
      className={`videoFunnel-video-node${hasTargetConnections ? ' hasTarget' : ''} empty${
        isTarget ? ' isTarget' : ''
      }`}
      style={{
        height: cardHeight * scaleFactor,
        width: cardWidth * scaleFactor,
        padding: isDragging ? '5px' : '0px',
        transition: 'padding 0.3s, width 0.3s, height 0.3s',
      }}
    >
      <Card style={{ width: '100%', height: '100%', position: 'relative' }}>
        <CardContent style={{ width: '100%', height: '100%' }}>
          <IconButton
            onClick={handleIconButtonClick}
            style={{
              position: 'fixed',
              width: 'auto',
              height: '23%',
              aspectRatio: '1 / 1',
              border: '2px solid',
              bottom: '50%',
              left: '50%',
              transform: 'translate(-55%, 43%)',
              padding: 0,
              zIndex: 2,
            }}
          >
            <AddRoundedIcon fontSize="large" style={{ width: 'unset', height: 'unset' }} />
          </IconButton>
          <Box
            style={{
              position: 'fixed',
              top: '57%',
              left: '50%',
              transform: 'translate(-52%, 50%)',
              padding: 0,
              zIndex: 2,
              minWidth: '80%',
            }}
          >
            <Button color="secondary" onClick={handleButtonClick}>
              {translationsStoryline.videoFunnel.clickToAddVideo[lang]}
            </Button>
          </Box>
          <CardMedia
            component="img"
            image="images/icons/empty-video-node.svg"
            sx={{ borderRadius: 5 }}
            onError={onMediaFallback}
            style={{ width: '100%', height: '100%', opacity: 0.4 }}
          />
        </CardContent>
      </Card>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        isValidConnection={isValidConnection}
      />

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable && !hasSourceConnectionHandleA}
        isConnectableStart={isConnectable && !hasSourceConnectionHandleA}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandleA}
        isValidConnection={isValidConnection}
        className={hasSourceConnectionHandleA ? 'connectionindicator pointer' : ''}
        hasSourceConnectionHandle={hasSourceConnectionHandleA}
      >
        A
      </HandleToolTip>

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable && !hasSourceConnectionHandleB}
        isConnectableStart={isConnectable && !hasSourceConnectionHandleB}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandleB}
        isValidConnection={isValidConnection}
        className={hasSourceConnectionHandleB ? 'connectionindicator pointer' : ''}
        hasSourceConnectionHandle={hasSourceConnectionHandleB}
      >
        B
      </HandleToolTip>

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="c"
        isConnectable={isConnectable && !hasSourceConnectionHandleC}
        isConnectableStart={isConnectable && !hasSourceConnectionHandleC}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandleC}
        isValidConnection={isValidConnection}
        className={hasSourceConnectionHandleC ? 'connectionindicator pointer' : ''}
        hasSourceConnectionHandle={hasSourceConnectionHandleC}
      >
        C
      </HandleToolTip>

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="d"
        isConnectable={isConnectable && !hasSourceConnectionHandleD}
        isConnectableStart={isConnectable && !hasSourceConnectionHandleD}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandleD}
        isValidConnection={isValidConnection}
        className={hasSourceConnectionHandleD ? 'connectionindicator pointer' : ''}
        hasSourceConnectionHandle={hasSourceConnectionHandleD}
      >
        D
      </HandleToolTip>
      <VideoFunnelAddVideoDialog
        open={openAddVideo}
        handleClose={handleOnCloseAddVideo}
        containerRef={containerRef}
        basicOrgData={basicOrgData}
        maxVideos={maxVideo}
      />
    </div>
  );
}

VideoFunnelEmptyVideoNode.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    isDragging: PropTypes.bool,
    isTarget: PropTypes.bool,
  }).isRequired,
  isConnectable: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default VideoFunnelEmptyVideoNode;
