import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { matchSorter } from 'match-sorter';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../../../utils/translations';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault.css-i4bv87-MuiSvgIcon-root':
    {
      disabled: {
        display: 'none',
      },
    },
  '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-qamplw-MuiInputBase-root-MuiOutlinedInput-root':
    {
      paddingTop: '0px',
      paddingRight: '9px',
      paddingBottom: '0px',
      paddingLeft: '9px',
    },
  '& .MuiAutocomplete-inputRoot': {
    backgroundColor: theme.palette.background.paper,
    borderColor: 'rgb(219, 219, 221)',
    borderWidth: '1px',
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontSize: '14px',
    letterSpacing: '-0.5px',
    minHeight: '40px',
    fontWeight: '400',

    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      padding: '0px',
      margin: '0px',
      lineHeight: '40px',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(219, 219, 221)',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(219, 219, 221)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(219, 219, 221)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(128, 189, 255)',
      borderWidth: '1px',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
    },
  },
}));

function QuestionInputField({
  questions = [],
  setQuestions,
  orgQuestions = [],
  validateQuestion,
  question = '',
}) {
  const lang = useLanguage();

  const [questionOptions, setQuestionOptions] = useState(orgQuestions ?? []);

  useEffect(() => {
    setQuestionOptions(orgQuestions);
  }, [orgQuestions]);

  const validateQuestionLength = (value) => {
    return value?.length > 0 && value?.length <= 65;
  };

  const filterOptions = useCallback(
    (options, { inputValue }) => {
      const filtered =
        questions && questions.length > 0
          ? options.filter((r) => questions.every((rec) => rec !== r.question))
          : options;
      return [
        ...matchSorter(
          questions && questions.length > 0 ? filtered : options,
          inputValue,
          { keys: ['question'] },
          { threshold: matchSorter.rankings.EQUAL },
        ),
      ];
    },
    [questions],
  );

  const handleBlur = (event) => {
    if (event.target.value === '') return;
    const enterEvent = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'Enter',
      keyCode: 13,
    });

    event.target.dispatchEvent(enterEvent);
  };

  const selectQuestionContent = useMemo(() => {
    return (
      <label htmlFor="selectQuestion">
        <Tooltip title={translations.videoRequest.publicVisibility[lang]}>
          <Typography variant="titleMedium" className="required-field">
            {translations.videoRequest.questions[lang]}
          </Typography>
        </Tooltip>
        <StyledAutocomplete
          id="selectQuestion"
          multiple
          freeSolo
          value={questions}
          filterOptions={filterOptions}
          options={questionOptions}
          isOptionEqualToValue={(option, value) => {
            return (option?.id && value?.id && option?.id === value?.id) || option === value;
          }}
          getOptionLabel={(option) => {
            return option?.question ?? option[lang] ?? option;
          }}
          onChange={(event, value, reason) => {
            if (reason === 'createOption') {
              if (
                !validateQuestionLength(question) ||
                !validateQuestionLength(event.target.value)
              ) {
                validateQuestion(question);
              } else {
                validateQuestion('');
                setQuestions(value);
              }
              return;
            }
            if (reason === 'selectOption') {
              validateQuestion('');
            }
            validateQuestion(question);
            setQuestions(value);
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                label={option?.question ?? option[lang] ?? option}
                {...getTagProps({ index })}
              />
            ));
          }}
          onInputChange={(event) => {
            validateQuestion(event.target.value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={translations.videoRequest.questionPlaceholder[lang]}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                type: 'search',
                onKeyDown: (e) => {
                  if (
                    e.key === 'Enter' &&
                    e.target.value &&
                    (!validateQuestionLength(question) || !validateQuestionLength(e.target.value))
                  ) {
                    e.stopPropagation();
                  }
                },
              }}
              value={question}
              onChange={(event) => {
                if (
                  !validateQuestionLength(question) ||
                  !validateQuestionLength(event.target.value)
                ) {
                  validateQuestion(question);
                  event.preventDefault();
                  event.stopPropagation();
                }

                validateQuestion(event.target.value);
              }}
              onBlur={handleBlur}
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option?.question, inputValue);
            const parts = parse(option?.question, matches);
            return (
              <MenuItem {...props} key={option.id}>
                {parts.map((part, index) => (
                  <span
                    key={`${option.id}-part-${index + option.id}`}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      fontSize: '14px',
                      lineHeight: '15px',
                      letterSpacing: '-0.5px',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </MenuItem>
            );
          }}
        />
      </label>
    );
  }, [questions, setQuestions, lang, validateQuestion, question, filterOptions, questionOptions]);

  return selectQuestionContent;
}

QuestionInputField.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.string,
  }),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
    }),
  ),
  setQuestions: PropTypes.func.isRequired,
  orgQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  validateQuestion: PropTypes.func.isRequired,
  question: PropTypes.string,
};

export default QuestionInputField;
