import {
  Checkbox,
  TextField,
  Stack,
  Typography,
  FormControlLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  freemiumDefaultPackageSettings,
  FreemiumFeatures,
  PackageVariant,
} from 'src/utils/freemiumSettings';
import { PremiumFeatures } from './types';
import FreemiumFeaturesCollapse from './FreemiumFeaturesCollapse';
import { inputGroups } from './inputGroups';

interface RenderInputFieldProps {
  componentKey: keyof PremiumFeatures;
  label: string;
  defaultValue: string | boolean | number;
  premiumFeatures: PremiumFeatures;
  handleCheckboxChange: (key: keyof PremiumFeatures) => void;
  handleTextFieldChange: (key: keyof PremiumFeatures, value: string) => void;
  handleNumberFieldChange: (key: keyof PremiumFeatures, value: number) => void;
  handleFreemiumFeaturesChange: (
    key: keyof PremiumFeatures,
    value: FreemiumFeatures | undefined,
  ) => void;
  handleFeaturesUpdate: (features: PremiumFeatures) => void;
  createNewOrg: boolean;
  description?: string;
  disabled: boolean;
  dependsOn?: Array<{ key: keyof PremiumFeatures; value: boolean | string | number }>;
  users: [{ id: number }];
}

function RenderInputField({
  componentKey,
  label,
  defaultValue,
  premiumFeatures,
  handleCheckboxChange,
  handleTextFieldChange,
  handleNumberFieldChange,
  handleFreemiumFeaturesChange,
  createNewOrg,
  description = '',
  disabled,
  dependsOn = undefined,
  handleFeaturesUpdate,
  users,
}: RenderInputFieldProps) {
  const isString = typeof defaultValue === 'string';
  const isNumber = typeof defaultValue === 'number';
  const isPackageVariant = componentKey === 'packageVariant';
  const isBoolean = typeof defaultValue === 'boolean';

  const findLabelForKey = (key: keyof PremiumFeatures) => {
    const foundInput = inputGroups
      .flatMap((group) => group.inputs)
      .find((input) => input.key === key);

    return foundInput ? foundInput.label : key;
  };

  const getDefaultValueForKey = (key: keyof PremiumFeatures) => {
    const foundInput = inputGroups
      .flatMap((group) => group.inputs)
      .find((input) => input.key === key);

    return foundInput ? foundInput.defaultValue : undefined;
  };

  const getPremiumFeatureValue = (key: keyof PremiumFeatures) => {
    return premiumFeatures?.[key] ?? getDefaultValueForKey(key);
  };

  const currentValue = getPremiumFeatureValue(componentKey);

  const unmetDependencies = dependsOn
    ? dependsOn.filter((dep) => getPremiumFeatureValue(dep.key) !== dep.value)
    : [];

  const areDependenciesMet = unmetDependencies.length === 0;
  const finalDisabled = disabled || !areDependenciesMet;

  const unmetDependenciesMessage = unmetDependencies
    .map((dep) => `Requires ${findLabelForKey(dep.key)} to be ${dep.value}`)
    .join(', <br />');

  let inputElement;
  if (isPackageVariant) {
    inputElement = (
      <Stack spacing={0} key={`packageVariant-${componentKey}-${label}`}>
        <Typography variant="titleSmall" key={`packageVariant-typography-${componentKey}-${label}`}>
          {label}
        </Typography>
        <Select
          value={currentValue as string}
          onChange={(e) => {
            const value = e.target.value as PackageVariant;
            if (value === 'freemium') {
              handleFreemiumFeaturesChange(
                'freemiumFeatures',
                freemiumDefaultPackageSettings.freemiumFeatures,
              );
              handleFeaturesUpdate(freemiumDefaultPackageSettings);
            } else if (value === 'none') {
              handleFreemiumFeaturesChange('freemiumFeatures', undefined);
            }

            handleTextFieldChange(componentKey, value as string);
          }}
          size="small"
          disabled={finalDisabled}
          key={`packageVariant-select-${componentKey}-${label}`}
        >
          <MenuItem key={`packageVariant-none-${componentKey}-${label}`} value="none">
            None
          </MenuItem>
          <MenuItem key={`packageVariant-freemium-${componentKey}-${label}`} value="freemium">
            Freemium
          </MenuItem>
        </Select>
        {currentValue === 'freemium' && premiumFeatures.freemiumFeatures && (
          <FreemiumFeaturesCollapse
            packageVariant={currentValue as PackageVariant}
            freemiumFeatures={premiumFeatures.freemiumFeatures}
            setFreemiumFeatures={(features) => {
              handleFreemiumFeaturesChange('freemiumFeatures', features);
            }}
            key={`packageVariant-freemiumFeaturesCollapse-${componentKey}-${label}`}
          />
        )}
      </Stack>
    );
  } else if (isString) {
    inputElement = (
      <Stack spacing={0} key={`string-stack-${componentKey}-${label}`}>
        <Typography key={`string-typography-${componentKey}-${label}`} variant="titleSmall">
          {label}
        </Typography>
        <TextField
          value={currentValue as string}
          onChange={(e) => handleTextFieldChange(componentKey, e.target.value)}
          size="small"
          disabled={finalDisabled}
          key={`string-textField-${componentKey}-${label}`}
        />
      </Stack>
    );
  } else if (isNumber) {
    inputElement = (
      <Stack spacing={0} key={`stack-number-${componentKey}-${label}`}>
        <Typography key={`typography-number-${componentKey}-${label}`} variant="titleSmall">
          {label}
        </Typography>
        <TextField
          value={currentValue as number}
          onChange={(e) => {
            const { value } = e.target;

            if (!value) {
              handleNumberFieldChange(componentKey, 0);
              return;
            }

            if (Number.isNaN(value)) {
              handleNumberFieldChange(componentKey, 0);
              return;
            }
            const parsedValue = parseInt(value, 10);

            handleNumberFieldChange(componentKey, Math.max(parsedValue, 0));
          }}
          size="small"
          disabled={finalDisabled}
          type="number"
          error={
            componentKey === 'userLimitPerOrganization' &&
            users.length > (currentValue as number) &&
            currentValue !== 0
          }
          inputProps={{
            min: 0,
          }}
          key={`number-textField-${componentKey}-${label}`}
        />
        {componentKey === 'userLimitPerOrganization' && (
          <Typography
            key={`error-message-${componentKey}-${label}`}
            variant="bodySmall"
            color={
              users.length > (currentValue as number) && currentValue !== 0
                ? 'error'
                : 'textSecondary'
            }
          >
            Current users: {users.length}
          </Typography>
        )}
      </Stack>
    );
  } else if (isBoolean) {
    inputElement = (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        key={`stack-boolean-${componentKey}-${label}`}
      >
        <Checkbox
          checked={currentValue as boolean}
          onChange={() => handleCheckboxChange(componentKey)}
          disabled={finalDisabled || (componentKey === 'excludeFromVimeo' && createNewOrg)}
          sx={{ p: 0 }}
          key={`checkbox-${componentKey}-${label}`}
        />
        <Typography key={`typography-boolean-${componentKey}-${label}`} variant="titleSmall">
          {label}
        </Typography>
      </Stack>
    );
  } else {
    inputElement = null;
  }

  return (
    <>
      <Tooltip
        title={unmetDependenciesMessage || ''}
        disableHoverListener={areDependenciesMet}
        arrow
        key={`tooltip-${componentKey}-${label}`}
      >
        <FormControlLabel
          control={<Stack spacing={0}>{inputElement}</Stack>}
          label=""
          sx={{ pl: 4, mb: 0, pb: 0 }}
          style={{ paddingBottom: 0, marginBottom: 0 }}
          key={`formControlLabel-${componentKey}-${label}`}
        />
      </Tooltip>
      {description && (
        <Typography
          key={`description-${componentKey}-${label}`}
          variant="bodySmall"
          color="textSecondary"
          sx={{ pl: 7 }}
        >
          {description}
        </Typography>
      )}
    </>
  );
}

export default RenderInputField;
