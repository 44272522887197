import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import { gql } from '@apollo/client';
import slugify from 'slugify';
import { Button } from '@mui/material';
import { useLanguage } from 'src/context/LanguageContext';
import { useAdminApolloClient } from 'src/context/ApolloClientContext';
import { frontendPath } from '../../utils/environment';
import { translations } from '../../utils/translations';
import LoadingIndicator from '../common/LoadingIndicator';
import OrgEnterpriseConnection from './OrgEnterpriseConnection';

const getOrg = (adminClient, id) => {
  return adminClient.query({
    query: gql`
        query {
          organization(id: ${id}) {
            id
            encodedId
            orgName
            orgNameSlug
            lang
          }
        }
      `,
  });
};

const updateOrgName = ({ adminClient, encodedOrgId, orgId, newOrgName }) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        updateOrgName(
          encodedOrgId: "${encodedOrgId}",
          input: { orgId: ${orgId}, 
          orgName: "${newOrgName}" }) {
          statusMessage
        }
      }
    `,
  });
};

const updateOrgNameSlug = ({ adminClient, encodedOrgId, orgId, newOrgNameSlug }) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        updateOrgNameSlug(
          encodedOrgId: "${encodedOrgId}",
          input: { orgId: ${orgId},
          orgName: "${newOrgNameSlug}"}) {
          statusMessage
        }
      }
    `,
  });
};

const initDeleteOrg = (adminClient, encodedOrgId, orgId) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        deleteOrganizationInit(
          encodedOrgId: "${encodedOrgId}",
          orgId: ${orgId} ) {
            videoCards {
              id
              videoLink {
                sv
                en
              }
            }
            organization {
              id
              orgName
              orgNameSlug
              orgNumber
              contactEmail
            }
            users {
              id
              firstName
              lastName
              email
            }
            storylineObjs {
              id
            }
            whitelistedDomains {
              domainUrl
            }
            token
        }
      }
    `,
  });
};

const vimeoDeleteOrg = (adminClient, encodedOrgId, orgId, token) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        deleteOrganizationVimeo(
          encodedOrgId: "${encodedOrgId}",
          orgId: ${orgId},
          token: "${token}")
      }
    `,
  });
};

const userDeleteOrg = (adminClient, encodedOrgId, orgId, token) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        deleteOrganizationUsers(
          encodedOrgId: "${encodedOrgId}",
          orgId: ${orgId},
          token: "${token}")
      }
    `,
  });
};

const finalizeOrgDelete = (adminClient, encodedOrgId, orgId, token) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        deleteOrganizationFinalize(
          encodedOrgId: "${encodedOrgId}",
          orgId: ${orgId},
          token: "${token}")
      }
    `,
  });
};

const encryptOrg = ({ adminClient, encodedOrgId, orgId }) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        encryptOrg(
          encodedOrgId: "${encodedOrgId}",
          orgId: ${orgId})
          {
        statusMessage
      }
      }
    `,
  });
};

const generateOrgNameSlug = (orgName) =>
  slugify(orgName, { remove: /[*+~.()'"!:@*?]/g }).toLowerCase();

export default function EditOrgSettings() {
  const lang = useLanguage();
  const adminClient = useAdminApolloClient();
  const [orgData, setOrgData] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const [orgName, setOrgName] = useState(orgData?.orgName ?? '');
  const [orgNameSlug, setOrgNameSlug] = useState(orgData?.orgNameSlug ?? '');
  const [editableSlugName, setEditableSlugName] = useState(false);

  useEffect(() => {
    const orgId = Number(params.orgid);
    getOrg(adminClient, orgId).then((res) => {
      setOrgData(res.data.organization);
      setOrgName(res.data.organization.orgName);
      setOrgNameSlug(res.data.organization.orgNameSlug);
    });
  }, [adminClient, params.orgid]);

  if (!orgData) return <LoadingIndicator />;

  let videoCardsToDelete = [];
  let org = null;
  let usersToDelete = [];
  let stObjs = [];
  let whitelistedDomainsDelete = [];

  const handleFinalDeleteOrganization = (token) => {
    const wd = whitelistedDomainsDelete.map((domain) => domain.domainUrl).join(',');
    const confirmation = window.prompt(
      `WARNING!\n\nFINAL STEP!\n\nThis cant be undone!\n\nAre you sure you want to delete "${org.orgName}"?\n${org.orgNameSlug}\n${org.orgNumber}\n${org.contactEmail}\n${wd}\n\nPlease type 'COMPLETE-DELETE' to confirm.`,
    );
    if (confirmation === 'COMPLETE-DELETE') {
      finalizeOrgDelete(adminClient, orgData.encodedId, orgData.id, token)
        .then((r) => {
          if (r.data.deleteOrganizationFinalize) {
            alert('Organization successfully deleted!');
            navigate(`/admin`);
          }
        })
        .catch((e) => {
          alert(`Something went wrong ${e.message}`);
        });
    }
  };

  const handleDeleteOrgUsers = (token) => {
    const usersString = usersToDelete.map((u) => `${u.firstName}, ${u.email}`).join(',\n');

    const confirmation = window.prompt(
      `Are you sure you want to delete all Users? This can't be undone.\nThere are: ${usersToDelete?.length} users:\n${usersString}\n\nPlease type 'DELETE-USERS' to confirm.`,
    );
    if (confirmation === 'DELETE-USERS') {
      userDeleteOrg(adminClient, orgData.encodedId, orgData.id, token)
        .then((r) => {
          handleFinalDeleteOrganization(r.data.deleteOrganizationUsers);
        })
        .catch((e) => {
          alert(`Something went wrong ${e.message}`);
        });
    }
  };

  const handleDeleteVimeoVideos = (token) => {
    const vimeoVideos = videoCardsToDelete.filter(
      (v) => v?.videoLink?.sv?.includes('vimeo') || v?.videoLink?.en?.includes('vimeo'),
    );
    const confirmation = window.prompt(
      `Are you sure you want to delete all videos from Vimeo and Video cards? This can't be undone.\nThere are: ${vimeoVideos?.length} vimeo videos\nThere are: ${videoCardsToDelete?.length} video cards\nThere are: ${stObjs?.length}Storylines.\n\nPlease type 'VIMEO-DELETE' to confirm.`,
    );
    if (confirmation === 'VIMEO-DELETE') {
      vimeoDeleteOrg(adminClient, orgData.encodedId, orgData.id, token)
        .then((r) => {
          handleDeleteOrgUsers(r.data.deleteOrganizationVimeo);
        })
        .catch((e) => {
          alert(`Something went wrong ${e.message}`);
        });
    }
  };

  const handleDeleteOrganization = () => {
    if (window.confirm('Are you sure you want to delete this organization?')) {
      initDeleteOrg(adminClient, orgData.encodedId, orgData.id)
        .then((r) => {
          const { videoCards, organization, users, storylineObjs, whitelistedDomains, token } =
            r.data.deleteOrganizationInit;
          videoCardsToDelete = videoCards;
          org = organization;
          usersToDelete = users;
          stObjs = storylineObjs;
          whitelistedDomainsDelete = whitelistedDomains;
          handleDeleteVimeoVideos(token);
        })
        .catch((err) => {
          alert(`Something went wrong ${err.message}`);
        });
    } else {
      alert('Organization not deleted');
    }
  };

  const handleCrypto = () => {
    if (window.confirm('Are you sure you want to encrypt?')) {
      encryptOrg({ adminClient, encodedOrgId: orgData.encodedId, orgId: orgData.id });
    } else {
      alert('Did not confirm crypto operation');
    }
  };

  const saveNameChanges = (event, newOrgName = orgName) => {
    if (window.confirm(`Are you sure you want to update orgName to "${newOrgName}"?`)) {
      updateOrgName({ adminClient, encodedOrgId: orgData.encodedId, orgId: orgData.id, newOrgName })
        .then(() => {
          alert('Successfully update org name');
        })
        .catch((err) => {
          alert(`Could not update orgName: error: ${err}`);
        });
    } else {
      alert('Did not confirm operation');
    }
  };

  const saveNameSlugChanges = (event, newOrgNameSlug = orgNameSlug) => {
    if (
      editableSlugName &&
      window.confirm(`Are you sure you want to update orgNameSlug to "${newOrgNameSlug}"?`)
    ) {
      updateOrgNameSlug({
        adminClient,
        encodedOrgId: orgData.encodedId,
        orgId: orgData.id,
        newOrgNameSlug,
      })
        .then(() => {
          alert('Successfully update org name slug');
        })
        .catch((err) => {
          alert(`Could not update orgNameSlug: error: ${err}`);
        });
    } else {
      alert('Did not confirm operation');
    }
  };

  return (
    <div>
      <div className="org-box">
        <h1>
          {translations.admin.Organizations.EditOrganizationSettings[lang]} #{orgData.id} (
          {orgData.orgName})
        </h1>
        <div className="org-box-inner">
          <div className="create-new-page">
            <h4>Edit Org name</h4>
            <div className="create-new-page-inner">
              <input
                type="text"
                value={orgName}
                onChange={(e) => {
                  if (e.target.value === 'EditOrgSlugName') {
                    setEditableSlugName(true);
                  } else {
                    setEditableSlugName(false);
                  }
                  setOrgName(e.target.value);
                }}
              />
            </div>
            <div>
              <Button onClick={saveNameChanges} disableElevation variant="contained">
                Save Org Name
              </Button>
            </div>
          </div>
          <div className="create-new-page">
            <h4>Edit Org Slug Name</h4>
            <span>
              Used for{' '}
              <a
                target="_blank"
                href={`${frontendPath}/${orgData?.orgNameSlug}/backoffice`}
                rel="noreferrer"
              >
                {`${frontendPath}/${orgData?.orgNameSlug}/backoffice`}
              </a>{' '}
              and email send out
            </span>
            <div className="create-new-page-inner">
              <input
                type="text"
                value={orgNameSlug}
                disabled={!editableSlugName}
                readOnly={!editableSlugName}
                onChange={(e) => {
                  if (e.target.value?.endsWith(' ')) {
                    setOrgNameSlug(e.target.value);
                  } else {
                    setOrgNameSlug(generateOrgNameSlug(e.target.value));
                  }
                }}
              />
            </div>
            <div>
              <Button
                type="button"
                onClick={saveNameSlugChanges}
                disableElevation
                disabled={!editableSlugName}
                variant="contained"
              >
                Save Org Slug Name
              </Button>
            </div>
          </div>
        </div>
        <div className="org-box">
          <div className="create-new-page">
            <div className="create-new-page-inner">
              <OrgEnterpriseConnection orgId={orgData.id} />
            </div>
          </div>
        </div>
        <div className="org-box">
          <div className="create-new-page">
            <div className="create-new-page-inner">
              <Button onClick={handleCrypto} disableElevation variant="contained">
                Crypto &quot;{orgData.orgName}&quot;
              </Button>
            </div>
          </div>
        </div>
        <div className="org-box">
          <div className="create-new-page">
            <div className="create-new-page-inner">
              <Button onClick={handleDeleteOrganization} disableElevation variant="contained">
                <img src="/images/icons/bin-icon.svg" alt="Delete organization" /> DELETE
                Organization &quot;{orgData.orgName}&quot;
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
