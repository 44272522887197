import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import _, { uniqueId } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { gql } from '@apollo/client';

import { useLanguage } from 'src/context/LanguageContext';
import { useAdminApolloClient } from 'src/context/ApolloClientContext';
import { translations } from '../../utils/translations';
import { frontendPath } from '../../utils/environment';
import WhitelistedDomainsTable from './WhitelistedDomainsTable';
import { WHITELISTED_DOMAIN_FRAGMENT } from '../../fragments/whitelistedDomains';
import LoadingIndicator from '../common/LoadingIndicator';

export const FREEMIUM_TYPE_SETTINGS_FRAGMENT = gql`
  fragment FreemiumTypeSettingsDetails on FreemiumTypeSettings {
    live
    total
    videoCards
    connectionsPerCard
    whenCondition
    conditions
    excludeWhenCondition
  }
`;

export const FREEMIUM_FEATURES_FRAGMENT = gql`
  ${FREEMIUM_TYPE_SETTINGS_FRAGMENT}
  fragment FreemiumDetails on FreemiumFeatures {
    videoFunnel {
      ...FreemiumTypeSettingsDetails
    }
    storyline {
      ...FreemiumTypeSettingsDetails
    }
    stories {
      ...FreemiumTypeSettingsDetails
    }
    videoCard {
      ...FreemiumTypeSettingsDetails
    }
    videoRequest {
      ...FreemiumTypeSettingsDetails
    }
  }
`;

const getOrgs = (adminClient) => {
  return adminClient.query({
    query: gql`
      ${FREEMIUM_FEATURES_FRAGMENT}
      query {
        organizations {
          id
          encodedId
          orgName
          orgNameSlug
          orgNumber
          contactEmail
          lang
          tagFinderSelector
          disallowSuperadmin
          doNotTrack
          premiumFeatures {
            interactionOption
            textMessage
            subtitleAllowed
            includeSubtitle
            editableConsentTexts
            autoReminders
            autoRequests
            excludeFromVimeo
            advancedWidgetStyling
            advancedWidgetBehavior
            requestsLibraryBeta
            orgSubtitleUpload
            advancedWidgetStylingMobile
            widgetThree
            videoFunnel
            videoFunnelEndScreen
            widgetVersion
            packageVariant
            freemiumFeatures {
              ...FreemiumDetails
            }
            advStyleVidTitleDialog
            advStyleStories
            videoCollector
            organizationPermissionsSettings
            videoRequestEmailCustomization
            conversationalAvatar
            userLimitPerOrganization
          }
          promoteRecordVideoPortrait
          users {
            id
            superAdmin
            email
            firstName
            lastName
            jobTitle {
              en
              sv
            }
          }
          whitelistedDomains {
            domainUrl
            orgId
            id
          }
          hubSpotContacts {
            id
            link
          }
        }
      }
    `,
  });
};

const resetPasswordForUser = (adminClient, userId) => {
  return adminClient.mutate({
    mutation: gql`
        mutation {
          resetPassword(userId: ${userId})
        }
      `,
  });
};

const addOrgDomain = (adminClient, encodedOrgId, orgId, domainUrl) => {
  return adminClient.mutate({
    mutation: gql`
      ${WHITELISTED_DOMAIN_FRAGMENT}
      mutation {
        addOrgDomain(
          encodedOrgId: "${encodedOrgId}",
          input: {orgId: ${orgId},
                  domainUrl: "${domainUrl}"}) 
          {
            ...WhitelistedDomainDetails
          }
      }
    `,
  });
};

const encryptAllOrgs = (adminClient, email) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        encryptAllOrgs(
          email: "${email}",)
          {
        statusMessage
      }
      }
    `,
  });
};

const deleteOrgDomain = (adminClient, encodedOrgId, orgId, id) => {
  return adminClient.mutate({
    mutation: gql`
      ${WHITELISTED_DOMAIN_FRAGMENT}
      mutation {
        deleteOrgDomain(
          encodedOrgId: "${encodedOrgId}",
          input: {orgId: ${orgId},
                  id: ${id},}) 
          {
            ...WhitelistedDomainDetails
          }
      }
    `,
  });
};

export default function Organizations() {
  const lang = useLanguage();
  const adminClient = useAdminApolloClient();
  const [orgs, setOrgs] = useState(null);

  const [foundOrgs, setFoundOrgs] = useState(null);

  const [widgetVersionSearch, setWidgetVersionSearch] = useState('all');

  const [encryptOrgsValid, setEncryptOrgsValid] = useState(false);

  const [searchField, setSearchField] = useState('');

  useEffect(() => {
    getOrgs(adminClient).then((res) => {
      const sortedOrgs = _.sortBy(res.data.organizations, 'orgName');
      setOrgs(sortedOrgs);
      setFoundOrgs(sortedOrgs);
    });
  }, [adminClient]);

  useEffect(() => {}, [foundOrgs]);

  useEffect(() => {}, [orgs]);

  const resetPassword = (userId) => {
    // eslint-disable-next-line no-alert
    const resetConfirmed = window.confirm('Are you sure?');
    if (resetConfirmed) {
      resetPasswordForUser(adminClient, userId)
        .then(() => {
          // eslint-disable-next-line no-alert
          window.alert('Mail is sent to user!');
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          window.alert('Something went wrong, contact support');
        });
    }
  };

  const searchForString = (text, searchString) => {
    if (!text || !searchString) {
      return false;
    }

    const textToString = text?.toString();
    return (
      textToString?.toLowerCase()?.includes(searchString) ||
      textToString?.toLowerCase()?.replace(/\s+/g, '')?.includes(searchString) ||
      textToString
        ?.toLowerCase()
        ?.replace(/\s+/g, '')
        ?.replace(/[^a-zA-Z0-9 ]/g, '')
        ?.includes(searchString)
    );
  };

  const searchOrgUsers = (org, searchString) => {
    return org.users.find((user) => {
      return (
        searchForString(user.id, searchString) ||
        searchForString(user.email, searchString) ||
        searchForString(user.firstName, searchString) ||
        searchForString(user.lastName, searchString) ||
        searchForString(`${user.firstName} ${user.lastName}`, searchString) ||
        searchForString(`${user.lastName} ${user.firstName}`, searchString)
      );
    });
  };

  const searchOrgAndUser = (org, searchString) => {
    return (
      searchForString(org.orgName, searchString) ||
      searchForString(org.id, searchString) ||
      searchForString(org.encodedId, searchString) ||
      searchForString(org.orgNameSlug, searchString) ||
      searchForString(org.orgNumber, searchString) ||
      searchForString(org.contactEmail, searchString) ||
      searchForString(org?.premiumFeatures?.packageVariant, searchString) ||
      searchOrgUsers(org, searchString)
    );
  };

  const searchStringCombo = (text) => {
    if (text) {
      const keyword = text
        ?.toString()
        ?.toLowerCase()
        ?.trim()
        ?.replace(/\s{2,}/g, ' ');
      const trimmedKeyWord = keyword.replace(/\s+/g, '');
      const noSpecial = trimmedKeyWord.replace(/[^a-zA-Z0-9 ]/g, '');

      return [keyword, trimmedKeyWord, noSpecial];
    }
    return ['', '', ''];
  };

  const orgUsesDefaultWidgetVersion = (org) => {
    return (
      org?.premiumFeatures?.widgetVersion === 'default' ||
      !org?.premiumFeatures?.widgetVersion === 'latest'
    );
  };

  const handleSetFoundOrgs = (e, widgetVersionSearchWord) => {
    if (widgetVersionSearchWord === 'all') {
      setFoundOrgs(e);
      return;
    }
    if (widgetVersionSearchWord === 'noneDefault') {
      setFoundOrgs(e?.filter((org) => !orgUsesDefaultWidgetVersion(org)));
      return;
    }
    if (widgetVersionSearchWord === 'default') {
      setFoundOrgs(e?.filter((org) => orgUsesDefaultWidgetVersion(org)));
      return;
    }

    setFoundOrgs(
      e?.filter((org) => {
        const orgWidgetVersion = org?.premiumFeatures?.widgetVersion;
        if (orgWidgetVersion === 'default' || orgWidgetVersion === 'latest') {
          if (org?.premiumFeatures?.widgetVersion) {
            return org?.premiumFeatures?.widgetVersion === widgetVersionSearchWord;
          }
          return false;
        }
        return orgWidgetVersion?.versionString === widgetVersionSearchWord;
      }),
    );
  };

  const filterOrgs = (e, widgetVersionSearchWord) => {
    const keyword = e;
    setSearchField(keyword);
    if (keyword === 'EncryptAllOrgs') {
      setEncryptOrgsValid(true);
    } else {
      setEncryptOrgsValid(false);
    }

    if (keyword?.startsWith('org:')) {
      const searchString = keyword.replace('org:', '').trim();
      const filterData = orgs?.filter((org) => {
        return searchStringCombo(searchString)?.some(
          (a) =>
            searchForString(org.orgName, a) ||
            searchForString(org.id, a) ||
            searchForString(org.encodedId, a) ||
            searchForString(org.orgNameSlug, a) ||
            searchForString(org.orgNumber, a) ||
            searchForString(org.contactEmail, a),
        );
      });

      handleSetFoundOrgs(filterData, widgetVersionSearchWord);
    } else if (keyword?.startsWith('orgName:')) {
      const searchString = keyword.replace('orgName:', '').trim();
      const filterData = orgs?.filter((org) => {
        return searchStringCombo(searchString)?.some(
          (a) => searchForString(org.orgName, a) || searchForString(org.orgNameSlug, a),
        );
      });
      handleSetFoundOrgs(filterData, widgetVersionSearchWord);
    } else if (keyword?.startsWith('orgId:')) {
      const searchString = keyword.replace('orgId:', '').trim();
      const filterData = orgs?.filter((org) => {
        return searchStringCombo(searchString)?.some(
          (a) => searchForString(org.id, a) || searchForString(org.encodedId, a),
        );
      });
      handleSetFoundOrgs(filterData, widgetVersionSearchWord);
    } else if (keyword?.startsWith('orgNumber:')) {
      const searchString = keyword.replace('orgNumber:', '').trim();
      const filterData = orgs?.filter((org) => {
        return searchStringCombo(searchString)?.some((a) => searchForString(org.orgNumber, a));
      });
      handleSetFoundOrgs(filterData, widgetVersionSearchWord);
    } else if (keyword?.startsWith('orgContact:')) {
      const searchString = keyword.replace('orgContact:', '').trim();
      const filterData = orgs?.filter((org) => {
        return searchStringCombo(searchString)?.some((a) => searchForString(org.contactEmail, a));
      });
      handleSetFoundOrgs(filterData, widgetVersionSearchWord);
    } else if (keyword?.startsWith('user:')) {
      const searchString = keyword.replace('user:', '').trim();
      const filterData = orgs?.filter((org) => {
        return searchStringCombo(searchString)?.some((a) => searchOrgUsers(org, a));
      });
      handleSetFoundOrgs(filterData, widgetVersionSearchWord);
    } else if (keyword?.startsWith('package:')) {
      const searchString = keyword.replace('package:', '').trim();
      const filterData = orgs?.filter((org) => {
        return searchStringCombo(searchString)?.some((a) => {
          return searchForString(org?.premiumFeatures?.packageVariant, a);
        });
      });
      handleSetFoundOrgs(filterData, widgetVersionSearchWord);
    } else if (keyword !== '') {
      const filterData = orgs?.filter((org) => {
        return searchStringCombo(keyword)?.some((a) => searchOrgAndUser(org, a));
      });
      handleSetFoundOrgs(filterData, widgetVersionSearchWord);
    } else {
      handleSetFoundOrgs(orgs, widgetVersionSearchWord);
      // If the text field is empty, show all users
    }
  };

  const getOrgById = (orgId) => {
    return orgs.find((o) => o.id === orgId);
  };

  const updateDomainForOrg = (org, domains) => {
    const items = [...orgs];
    const index = orgs.findIndex((x) => x.id === org.id);
    const item = { ...items[index] };
    item.whitelistedDomains = domains;
    items[index] = item;

    setOrgs(() => {
      return items;
    });

    const filterItems = [...foundOrgs];
    const filterIndex = orgs.findIndex((x) => x.id === org.id);

    if (filterIndex !== -1) {
      const filterItem = { ...filterItems[filterIndex] };
      filterItem.whitelistedDomains = domains;
      filterItems[filterIndex] = filterItem;

      setFoundOrgs(() => {
        return filterItems;
      });
    }
  };

  const handleDeleteDomainById = (domain) => {
    const org = getOrgById(domain.orgId);
    // eslint-disable-next-line no-alert
    const deleteConfirmed = window.confirm(
      `Do you really want to delete ${domain.domainUrl} for org: ${org.orgName}?`,
    );
    if (deleteConfirmed) {
      deleteOrgDomain(adminClient, org.encodedId, org.id, domain.id).then((result) => {
        const domains = result.data.deleteOrgDomain;
        updateDomainForOrg(org, domains);
      });
    }
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  const handleAddNewDomainByOrg = (org) => {
    // eslint-disable-next-line no-alert
    const domainUrl = window.prompt('Enter a domain');
    if (domainUrl) {
      if (!isValidHttpUrl(domainUrl)) {
        // eslint-disable-next-line no-alert
        window.alert('Enter a valid domain');
      } else {
        addOrgDomain(adminClient, org.encodedId, org.id, domainUrl).then((result) => {
          const domains = result.data.addOrgDomain;
          updateDomainForOrg(org, domains);
        });
      }
    }
  };

  const handleEncryptOrgs = () => {
    // eslint-disable-next-line no-alert
    const encryptConfirmed = window.confirm('Are you sure you want to encrypt all organizations?');
    if (encryptConfirmed) {
      // eslint-disable-next-line no-alert
      const confirmation = window.prompt(`Enter your email: `);
      if (confirmation !== '') {
        encryptAllOrgs(adminClient, confirmation);
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('Did not confirm crypto operation');
    }
  };

  const orgElems = foundOrgs?.map((org) => {
    const userElems =
      org?.users?.length > 0 ? (
        org?.users?.map((user) => {
          return (
            <div className="user" key={user.id}>
              <div>
                <b>ID:</b> {user.id}
              </div>
              <div>
                <b>{translations.admin.Organizations.Name[lang]}</b> {user.firstName}{' '}
                {user.lastName}
              </div>
              <div>
                <b>Email:</b> {user.email}
              </div>
              <div>
                <Button component={Link} to={`/admin/edit-user/${user.id}`}>
                  <i className="icon">edit</i>Edit user
                </Button>
                <Button component={Link} to={`/admin/delete-user/${user.id}`} color="error">
                  <i className="icon">delete</i>Delete user
                </Button>
                <Button
                  onClick={() => resetPassword(user.id)}
                  color="secondary"
                  size="small"
                  variant="outlined"
                >
                  Send password reset mail
                </Button>
              </div>
            </div>
          );
        })
      ) : (
        <div>No users</div>
      );
    const domainsElems = (
      <WhitelistedDomainsTable
        whitelistedDomains={org.whitelistedDomains}
        onDelete={handleDeleteDomainById}
        editable
      />
    );

    const orgWidgetVersionElement = () => {
      const orgWidgetVersion = org?.premiumFeatures?.widgetVersion;

      return (
        <div>
          {org?.premiumFeatures?.widgetVersion === 'default' ||
          org?.premiumFeatures?.widgetVersion === 'latest' ? (
            <>
              <b>{`Widget Version: `}</b>
              <span style={{ fontSize: '12px' }}>default</span>
            </>
          ) : (
            <b style={{ color: 'red' }}>
              {`Widget Version: NOT DEFAULT -> ${orgWidgetVersion?.versionString}${orgWidgetVersion?.toLowerCase()?.trim()?.includes('beta') ? ' (beta)' : ''}`}
            </b>
          )}
        </div>
      );
    };
    return (
      <div className="org-box" key={org.id}>
        <div className="org-box-inner">
          <div className="org-data">
            <h3>{org.orgName}</h3>
            <div>
              <b>ID:</b> {org.id}
            </div>
            <div>
              <b>Encoded ID:</b> {org.encodedId}
            </div>
            <div>
              <b>{translations.admin.Organizations.OrgNumber[lang]}</b> {org.orgNumber}
            </div>
            <div>
              <b>{translations.admin.Organizations.Contact[lang]}</b> {org.contactEmail}
            </div>
            {org?.premiumFeatures?.packageVariant &&
              org?.premiumFeatures?.packageVariant !== 'none' && (
                <div>
                  <b>Package:</b> {org?.premiumFeatures?.packageVariant}
                </div>
              )}
            {orgWidgetVersionElement()}
            {org?.hubSpotContacts?.length > 0 && (
              <div>
                <b>HubSpot: </b>
                {org.hubSpotContacts.map((contact) => (
                  <a target="_blank" href={`${contact?.link}`} rel="noreferrer" key={uniqueId()}>
                    Contact {contact?.id} (Record ID)
                  </a>
                ))}
              </div>
            )}
            <div>
              <a
                style={{
                  color: org.disallowSuperadmin ? '#ff0000' : '#000',
                  textDecoration: org.disallowSuperadmin ? 'line-through' : 'underline',
                }}
                target="_blank"
                href={`${frontendPath}/${org.orgNameSlug}/backoffice`}
                rel="noreferrer"
              >
                Backoffice
              </a>
            </div>
            <div>
              <a target="_blank" href={`${frontendPath}/${org.orgNameSlug}`} rel="noreferrer">
                {translations.admin.Organizations.PublicLibrary[lang]}
              </a>
            </div>
            <div>
              <Button
                size="small"
                color="secondary"
                component={Link}
                to={`/admin/edit-org-settings/${org.id}`}
                variant="outlined"
              >
                <i className="icon">edit</i>Edit {org.orgName} Settings
              </Button>
            </div>
            <div>
              <Button
                size="small"
                color="secondary"
                component={Link}
                to={`/admin/edit-org/${org.id}`}
                variant="outlined"
              >
                <i className="icon">edit</i>Edit Premium & Beta
              </Button>
            </div>
          </div>
          <div className="user-data">
            <h4>{translations.admin.Organizations.Users[lang]}</h4>
            <div>
              <Button
                component={Link}
                to={`/admin/new-org-user/${org.id}`}
                color="secondary"
                variant="outlined"
              >
                <i className="icon">person_add</i>
                {translations.admin.Organizations.createUser[lang]}
              </Button>
            </div>
            <br />
            {userElems}
          </div>
          <div className="domain-data">
            <h4>Whitelisted Domains </h4>
            {domainsElems}
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleAddNewDomainByOrg(org)}
              startIcon={<AddIcon />}
            >
              Add new domain
            </Button>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="org-page">
      <div className="header-row">
        <h1>
          {translations.admin.header.allOrganizations[lang]} (#{orgs && orgs.length})
        </h1>
        <Link to="/admin/new-org" className="btn action-btn">
          <i className="icon">apartment</i>
          {translations.admin.header.createOrganization[lang]}
        </Link>
        <Link to="/admin/subtitlerequest" target="_blank" className="btn action-btn">
          <i className="icon">subtitles</i>
          {translations.subtitleBtn[lang]}
        </Link>
      </div>
      <div>
        <input
          type="search"
          value={searchField}
          onChange={(e) => filterOrgs(e.target.value, widgetVersionSearch)}
          onKeyDown={(e) => {
            if (encryptOrgsValid && e.key === 'Enter') {
              handleEncryptOrgs();
            }
          }}
          className="input"
          placeholder="search"
        />
        <select
          value={widgetVersionSearch}
          onChange={(e) => {
            setWidgetVersionSearch(e.target.value);
            filterOrgs(searchField, e.target.value);
          }}
        >
          <option value="all">All versions</option>
          <option value="noneDefault">All none Default</option>

          <option value="default">Default</option>
        </select>
        {orgs && orgs?.length && orgElems?.length < orgs?.length && (
          <b>found : {orgElems?.length}</b>
        )}
      </div>
      <div>{orgElems ?? <LoadingIndicator />}</div>
    </div>
  );
}
