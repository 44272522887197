// translations.ts

const translations = {
  enterAnEmail: {
    en: 'Please enter an email address',
    sv: 'Ange en e-postadress',
  },
  invalidAtEmail: {
    en: "Please enter a valid email address without '@'",
    sv: 'Ange en giltig e-postadress utan "@"',
  },
  emailExists: {
    en: 'Email already exists',
    sv: 'E-postadressen finns redan',
  },
  invalidEmail: {
    en: 'Please enter a valid email address',
    sv: 'Ange en giltig e-postadress',
  },
  sendInvite: {
    en: 'Send Invite',
    sv: 'Skicka inbjudan',
  },
  declineInvite: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
  inviteUser: {
    en: 'Invite User',
    sv: 'Bjud in användare',
  },
  inviteNewUser: {
    en: 'Invite new user',
    sv: 'Bjud in ny användare',
  },
  searchUsers: {
    en: 'Search users...',
    sv: 'Sök användare...',
  },
  permissions: {
    en: 'Permissions',
    sv: 'Behörigheter',
  },
  firstName: {
    en: 'First Name',
    sv: 'Förnamn',
  },
  lastName: {
    en: 'Last Name',
    sv: 'Efternamn',
  },
  email: {
    en: 'Email',
    sv: 'E-post',
  },
  permission: {
    en: 'Permission',
    sv: 'Behörighet',
  },
  noUsersFound: {
    en: 'No users found',
    sv: 'Inga användare hittades',
  },
  buttons: {
    approve: {
      en: 'Approve',
      sv: 'Godkänn',
    },
    decline: {
      en: 'Decline',
      sv: 'Avvisa',
    },
  },
  permissionTypes: {
    org_admin: {
      en: 'Organization Admin',
      sv: 'Organisationsadministratör',
    },
    self_editor: {
      en: 'Self Editor',
      sv: 'Självredaktör',
    },
  },
  userRow: {
    helpText: {
      en: 'Select to change user permission level. Currently disabled.',
      sv: 'Välj för att ändra användarens behörighetsnivå. För närvarande inaktiverad.',
    },
  },
  ariaLabel: {
    en: 'Change permission for user',
    sv: 'Ändra behörighet för användare',
    sortBy: {
      en: 'Sort by',
      sv: 'Sortera efter',
    },
  },
  successInvite: {
    en: 'User invited successfully.',
    sv: 'Användaren inbjuden.',
  },
  errorInvite: {
    en: 'Failed to invite user.',
    sv: 'Kunde inte bjuda in användaren.',
  },
  successPermission: {
    en: 'Permission updated.',
    sv: 'Behörighet uppdaterad.',
  },
  errorPermission: {
    en: 'Permission update failed.',
    sv: 'Kunde inte uppdatera behörigheten.',
  },
  successApprove: {
    en: 'Permission approved.',
    sv: 'Behörighet godkänd.',
  },
  errorApprove: {
    en: 'Failed to approve permission.',
    sv: 'Kunde inte godkänna behörigheten.',
  },
  successDecline: {
    en: 'Permission declined.',
    sv: 'Behörighet avslogs.',
  },
  errorDecline: {
    en: 'Failed to decline permission.',
    sv: 'Kunde inte avslå behörigheten.',
  },
  status: {
    pending: {
      en: 'Pending',
      sv: 'Väntar',
    },
  },
  userLimitReached: {
    en: 'User limit reached',
    sv: 'Användargräns nådd',
  },
  users: {
    en: 'users',
    sv: 'användare',
  },
  outOf: {
    en: 'out of',
    sv: 'av',
  },
};

export default translations;
