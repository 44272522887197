import styled from '@emotion/styled';
import { Grid, Stack, TextareaAutosize, TextField, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLanguage } from '../../../context/LanguageContext';
import { translations } from '../../../utils/translations';
import { Recipient } from '../BackOfficeTemplate/types';

const StyledGridContainer = styled(Grid)({
  paddingLeft: 0,
});

const StyledTextField = styled(TextField)(({ theme }: { theme: Theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '& .MuiInputBase-root': {
    border: 'none', // Removes the border for input
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none', // Removes the outline
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.primary.light,
    fontStyle: 'italic',
  },
  backgroundColor: theme.palette.background.paper,
}));

const StyledGridItem = styled(Grid)({
  paddingLeft: 0,
});

const StyledTextarea = styled(TextareaAutosize)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.light,
  fontStyle: 'italic',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(1),
  '&:disabled': {
    WebkitTextFillColor: theme.palette.primary.light,
    backgroundColor: theme.palette.background.paper,
  },
}));

function formatPhoneNumber(phoneNumber: string | undefined): string {
  if (!phoneNumber) return '';

  const digits = phoneNumber.replace(/\D/g, '');
  let formattedNumber;
  if (digits.length > 10) {
    formattedNumber = digits.replace(/^(\d{1,2})(\d{3})(\d{3})(\d{0,})$/, '+$1 $2 $3 $4');
  } else if (digits.length === 10) {
    formattedNumber = digits.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1 $2 $3');
  } else {
    formattedNumber = digits.replace(/(\d{1,3})(\d{0,3})(\d{0,4})/, '$1 $2 $3').trim();
  }

  return formattedNumber.trim();
}

export default function ActorDetails({
  recipient,
  message,
}: {
  recipient: Recipient | null | undefined;
  message: string | null | undefined;
}) {
  const lang = useLanguage();
  const theme = useTheme();

  return (
    <StyledGridContainer container rowSpacing={2} sx={{ float: 'left' }}>
      <StyledGridItem item xs={12} md={6}>
        <Stack>
          <Typography variant="titleMedium">{translations.videoRequest.name[lang]}</Typography>
          <StyledTextField
            theme={theme}
            value={`${recipient?.firstName} ${recipient?.lastName}`}
            disabled
            sx={{
              [theme.breakpoints.up('md')]: {
                mr: 2,
              },
            }}
          />
        </Stack>
      </StyledGridItem>

      <StyledGridItem item xs={12} md={6}>
        <Stack>
          <Typography variant="titleMedium"> {translations.videoRequest.jobTitle[lang]}</Typography>
          <StyledTextField theme={theme} value={recipient?.title} disabled />{' '}
        </Stack>
      </StyledGridItem>

      <StyledGridItem item xs={12}>
        <Stack>
          <Typography variant="titleMedium">{translations.videoCard.email[lang]}</Typography>
          <StyledTextField theme={theme} value={recipient?.email} disabled />{' '}
        </Stack>
      </StyledGridItem>

      <StyledGridItem item xs={12}>
        <Stack>
          <Typography variant="titleMedium"> {translations.videoCard.phoneNumber[lang]}</Typography>
          <StyledTextField
            theme={theme}
            value={formatPhoneNumber(recipient?.phoneNumber)}
            disabled
          />{' '}
        </Stack>
      </StyledGridItem>
      {message && (
        <StyledGridItem item xs={12}>
          <Stack>
            <Typography variant="titleMedium">
              {translations.videoCard.messageToTeamMember[lang]}
            </Typography>
            <StyledTextarea theme={theme} value={message} disabled />
          </Stack>
        </StyledGridItem>
      )}
    </StyledGridContainer>
  );
}
