import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { useLanguage } from 'src/context/LanguageContext';
import { Order, PermissionType, User } from './types';
import translations from './translations';

interface PermissionsTableHeadProps {
  order: Order;
  orderBy: keyof PermissionType | keyof User | 'permission';
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof PermissionType) => void;
}

interface ColumnConfig {
  id: 'firstName' | 'lastName' | 'email' | 'permission';
  label: string;
}

const columns = (lang: Language): ColumnConfig[] => [
  {
    id: 'firstName',
    label: translations.firstName[lang],
  },
  {
    id: 'lastName',
    label: translations.lastName[lang],
  },
  {
    id: 'email',
    label: translations.email[lang],
  },
  {
    id: 'permission',
    label: translations.permission[lang],
  },
];

function PermissionsTableHead({ order, orderBy, onRequestSort }: PermissionsTableHeadProps) {
  const lang = useLanguage();

  const createSortHandler =
    (property: keyof PermissionType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns(lang).map((column) => (
          <TableCell
            key={column.id}
            sortDirection={orderBy === column.id ? order : false}
            style={{ padding: '16px 12px' }}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id as keyof PermissionType)}
              aria-label={`${translations.ariaLabel.sortBy[lang]} ${column.label}`}
              data-testid={`sort-${column.id}`}
            >
              <Typography variant="subtitle2" component="div">
                {column.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default PermissionsTableHead;
