import { createContext, useContext } from 'react';

export interface User {
  id: number;
  encodedId: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
}

const CurrentUserContext = createContext<User | null>(null);

export function CurrentUserProvider({
  currentUser,
  children,
}: {
  currentUser: User;
  children: React.ReactNode;
}) {
  return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>;
}

export const useCurrentUser = () => {
  const currentUser = useContext(CurrentUserContext);
  if (currentUser == null) {
    throw new Error('must be within CurrentUserProvider');
  }
  return currentUser;
};
