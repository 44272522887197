import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

interface BackOfficeCardProps {
  orgName: string;
  orgLogo: string | null;
  orgBgColor: string | null;
  onClick: () => void;
}

function BackOfficeCard({ orgName, orgLogo, orgBgColor, onClick }: BackOfficeCardProps) {
  const logoHeight = 96;
  const logoBgColor = orgBgColor ?? 'white';
  return (
    <Card variant="outlined">
      <CardActionArea onClick={onClick}>
        {orgLogo ? (
          <CardMedia
            component="img"
            sx={{ height: logoHeight, background: logoBgColor }}
            image={orgLogo}
            alt={`${orgName} logo`}
          />
        ) : (
          <Box sx={{ height: logoHeight, background: logoBgColor }} />
        )}
        <CardContent>
          <Typography variant="titleSmall" component="p" sx={{ overflowWrap: 'break-word' }}>
            {orgName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default BackOfficeCard;
