import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';

import ThemeContext from 'src/utils/ThemeContext';
import { useLanguage } from 'src/context/LanguageContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import LanguageSelector from 'src/components/admin/ConversationalAvatar/LanguageSelector';
import translations from './SelectBackOfficeTranslations';
import { PermissionType } from '../settings/Permissions/types';
import BackOfficeCard from './BackOfficeCard';
import RequestAccess from './RequestAccess';
import PendingAccess from './PendingAccess';
import { MY_ORGANIZATIONS_QUERY, REQUEST_ACCESS_MUTATION } from './queries';

export type MinimalOrganization = {
  id: number;
  encodedId: string;
  orgName: string;
  orgNameSlug: string;
  logoPath: string | null;
  backgroundColor: string | null;
};

type MyOrganizationsData = {
  organizations: MinimalOrganization[];
  userStatus?: PermissionType;
};

type MyOrganizationsResult = {
  myOrganizations: MyOrganizationsData;
};

const getBackOfficePath = (organization: MinimalOrganization) =>
  `/${organization.orgNameSlug}/backoffice`;

function SelectBackoffice() {
  const theme = useContext(ThemeContext);
  const lang = useLanguage();
  const navigate = useNavigate();
  const {
    data,
    error: queryError,
    loading,
  } = useQuery<MyOrganizationsResult>(MY_ORGANIZATIONS_QUERY);
  const [requestAccess, { loading: mutationLoading }] = useMutation(REQUEST_ACCESS_MUTATION);
  const [userRequestStatus, setUserRequestStatus] = useState<PermissionType | undefined>(undefined);

  // Snackbar state for notifications
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const myOrganizations = useMemo(() => {
    if (data?.myOrganizations?.organizations) {
      return [...data.myOrganizations.organizations].sort((orgA, orgB) => {
        const nameA = orgA.orgName.toLocaleLowerCase();
        const nameB = orgB.orgName.toLocaleLowerCase();
        return nameA.localeCompare(nameB);
      });
    }
    return null;
  }, [data]);

  const userStatus = userRequestStatus || data?.myOrganizations?.userStatus;

  useEffect(() => {
    if (data?.myOrganizations?.userStatus) {
      setUserRequestStatus(data.myOrganizations.userStatus);
    }
  }, [data]);

  useEffect(() => {
    if (
      !loading &&
      (queryError ||
        (myOrganizations?.length === 0 &&
          !(userStatus === 'can_request_access' || userStatus === 'requested_access')))
    ) {
      navigate('/login', { replace: true });
    }

    if (myOrganizations?.length === 1) {
      navigate(getBackOfficePath(myOrganizations[0]), { replace: true });
    }
  }, [queryError, myOrganizations, navigate, userStatus, loading]);

  const handleRequestAccess = async () => {
    try {
      const response = await requestAccess();
      if (response.data?.requestAccess.success) {
        setSnackbarMessage(translations.requestAccessSuccess[lang]);
        setSnackbarSeverity('success');
        setUserRequestStatus('requested_access');
      } else {
        setSnackbarMessage(translations.requestAccessFailed[lang]);
        setSnackbarSeverity('error');
      }
    } catch (err) {
      setSnackbarMessage(translations.requestAccessError[lang]);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const renderOrganizations = () => (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      {myOrganizations?.map((organization) => (
        <Grid item xs={12} md={6} xl={4} key={organization.id}>
          <BackOfficeCard
            onClick={() => navigate(getBackOfficePath(organization))}
            orgName={organization.orgName}
            orgLogo={organization.logoPath}
            orgBgColor={organization.backgroundColor}
          />
        </Grid>
      ))}
    </Grid>
  );

  const renderContent = () => {
    if (myOrganizations == null || myOrganizations.length === 1) {
      return <LoadingIndicator />;
    }

    if (userStatus === 'can_request_access') {
      return (
        <RequestAccess
          lang={lang}
          loading={mutationLoading}
          handleRequestAccess={handleRequestAccess}
        />
      );
    }

    if (userStatus === 'requested_access') {
      return <PendingAccess lang={lang} />;
    }

    return renderOrganizations();
  };

  const getTitle = () => {
    if (userStatus === 'can_request_access') {
      return translations.requestAccessTitle[lang];
    }
    if (userStatus === 'requested_access') {
      return translations.pendingApprovalTitle[lang];
    }
    return translations.selectOrganization[lang];
  };

  return (
    <>
      <Box sx={{ background: theme.palette.primary.main, minHeight: '100vh' }}>
        <Grid container sx={{ p: 3, pb: 2 }}>
          <Grid item xs={6}>
            <img src="/lifeinside_logo_horizontal-326x52.svg" width="184" alt="Life Inside" />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <LanguageSelector />
            </Box>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            sm={8}
            justifyContent="center"
            alignItems="center"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Stack justifyContent="center" alignItems="center" spacing={0} maxWidth="sm">
              <Card
                sx={{
                  border: '0px',
                  borderRadius: '16px',
                  ml: 1,
                  mr: 1,
                  p: 2,
                  pt: 3,
                }}
                elevation={0}
              >
                <CardHeader
                  title={getTitle()}
                  component="h1"
                  sx={{
                    pt: 0,
                    pb: 1,
                    mt: 0,
                    mb: 0,
                  }}
                  titleTypographyProps={{
                    variant: 'headlineLargeBoldRecoleta',
                    align: 'center',
                  }}
                />
                <CardContent sx={{ mb: 0, pb: 0 }}>{renderContent()}</CardContent>
              </Card>
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ p: 2 }}>
          <Typography
            variant="bodySmall"
            color="text.light"
            component="p"
            sx={{
              textAlign: 'center',
            }}
          >
            © {new Date().getFullYear()} Life Inside
          </Typography>
        </Box>
      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SelectBackoffice;
