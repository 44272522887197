// LabeledTextField.tsx
import React from 'react';
import { TextField, Typography, Stack, TextFieldProps, InputAdornment } from '@mui/material';

interface LabeledTextFieldProps extends Omit<TextFieldProps, 'label'> {
  label: string;
  startAdornment?: React.ReactNode;
}

function LabeledTextField({
  label,
  startAdornment = undefined,
  ...textFieldProps
}: LabeledTextFieldProps) {
  return (
    <Stack
      spacing={0.5}
      sx={{
        maxWidth: 300,
      }}
    >
      <Typography variant="subtitle2">{label}</Typography>
      <TextField
        {...textFieldProps}
        fullWidth
        slotProps={{
          input: {
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : undefined,
          },
        }}
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 1,
          '& fieldset': {
            borderColor: 'transparent',
          },
        }}
      />
    </Stack>
  );
}

export default LabeledTextField;
