import React, { useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import { useQuery, gql, useMutation } from '@apollo/client';

import { useLanguage } from 'src/context/LanguageContext';
import VideoCollectorHeader from './VideoCollectorHeader';
import VideoCollectorLibrary from './VideoCollectorLibrary';
import translations from './VideoCollectorTranslations';

import { VideoCollector as VideoCollectorData, VideoCollectorInput } from './types';
import useUserData from '../settings/userDataHook/userDataHook';

export interface CreateVideoCollectorResponse {
  createVideoCollector: Pick<VideoCollectorData, 'id' | 'encodedId'>;
}

export const CREATE_VIDEO_COLLECTOR_MUTATION = gql`
  mutation CreateVideoCollector($encodedOrgId: String!, $input: VideoCollectorInput!) {
    createVideoCollector(encodedOrgId: $encodedOrgId, input: $input) {
      id
      encodedId
    }
  }
`;

type GetAllVideoCollectorsResponse = {
  getAllVideoCollectors: null | VideoCollectorData[];
};

export const GET_ALL_VIDEO_COLLECTORS_QUERY = gql`
  query GetAllVideoCollectors($encodedOrgId: String!) {
    getAllVideoCollectors(encodedOrgId: $encodedOrgId) {
      id
      encodedId
      title
      status
      createdBy {
        id
        firstName
        lastName
      }
      responsible {
        id
        firstName
        lastName
        email
      }
      videoCards {
        id
        staticThumbnails {
          FHD
          HD
          qHD
          nHD
          ntsc
          qcif
          sqcif
        }
      }
    }
  }
`;

// Initial values for a new VideoCollector.
const initialVideoCollectorInput = (lang: Language) => {
  const input: VideoCollectorInput = {
    title: '',
    description: '',
    status: 'EDIT',
    password: '',
    usePassword: false,
    thankYouEmailContent: translations.thankYouEmailDefaultContent[lang],
    approvedEmailContent: translations.approvedEmailDefaultContent[lang],
    allowVideoLibrary: true,
    allowVideoLibraryNotReviewed: false,
    allowUserEnteredQuestions: false,
    saveName: true,
    saveNameRequired: true,
    saveTitle: true,
    saveTitleRequired: true,
    saveEmail: true,
    saveEmailRequired: true,
    savePhone: false,
    savePhoneRequired: false,
    startDate: null,
    endDate: null,
    videoCardIds: [],
    orgQuestionIds: [],
    customQuestions: [],
    responsibleId: null,
  };
  return input;
};

interface RouteOutletContext {
  basicOrgData: {
    encodedId: string;
    id: number;
  };
}

function VideoCollector() {
  const navigate = useNavigate();
  const lang = useLanguage();
  const { basicOrgData } = useOutletContext<RouteOutletContext>();
  const { encodedId: encodedOrgId } = basicOrgData;
  const [searchField, setSearchField] = useState('');

  const { data: userData, isSuperAdmin } = useUserData(basicOrgData.encodedId);
  const hasAccess =
    isSuperAdmin ||
    !!userData?.me?.userPermissions?.find(
      (up) => basicOrgData.id === up.orgId && up.name === 'org_admin',
    );

  const { data, loading: getAllLoading } = useQuery<GetAllVideoCollectorsResponse>(
    GET_ALL_VIDEO_COLLECTORS_QUERY,
    {
      variables: { encodedOrgId },
      fetchPolicy: 'cache-and-network',
    },
  );
  const videoCollectors = data?.getAllVideoCollectors;

  const [createVideoCollector, { loading: createLoading }] =
    useMutation<CreateVideoCollectorResponse>(CREATE_VIDEO_COLLECTOR_MUTATION);

  const anyLoading = getAllLoading || createLoading;

  const handleOnAddClicked = () => {
    createVideoCollector({
      variables: { encodedOrgId, input: initialVideoCollectorInput(lang) },
      onCompleted: (createData) => {
        const vc = createData.createVideoCollector;
        navigate(`${vc.encodedId}`);
      },
    });
  };

  const handleCardClick = (vc: VideoCollectorData) => {
    navigate(`${vc.encodedId}`);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
      sx={{
        pt: 2,
        pl: 3,
        pr: 2,
      }}
      role="grid"
    >
      <VideoCollectorHeader
        searchField={searchField}
        setSearchField={setSearchField}
        disabled={anyLoading}
        onAddClicked={handleOnAddClicked}
      />
      {videoCollectors != null && (
        <VideoCollectorLibrary
          searchField={searchField}
          videoCollectors={videoCollectors}
          onCardClick={handleCardClick}
          hasAccess={hasAccess}
        />
      )}
    </Grid>
  );
}
export default VideoCollector;
