import { useMemo } from 'react';
import { gql } from '@apollo/client/index';

import { ApolloClient, useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { VIDEO_FUNNEL_DETAILS_FRAGMENT } from '../fragments/videoFunnels';

export class StorylinesApi {
  private backOfficeClient: ApolloClient;

  constructor(backOfficeClient: ApolloClient) {
    this.backOfficeClient = backOfficeClient;
  }

  getStorylines(encodedOrgId: string) {
    return this.backOfficeClient.query({
      query: gql`
        query OrgStorylines($encodedOrgId: String!) {
          orgStorylines(encodedOrgId: $encodedOrgId) {
            users {
              id
              firstName
              lastName
            }
            urls
            storylines {
              excludeWidgetLibraryBtn
              id
              user {
                id
                firstName
                lastName
              }
              orgId
              status
              urls
              title
              urlsJsonb {
                url
                srcImage
                imageUpdatedAt
                srcImageFullPage
                conditionWhen
                excludedWhen
                storylineId
                override
              }
            }
          }
        }
      `,
      variables: {
        encodedOrgId,
      },
    });
  }

  getStorylinesExtended(encodedOrgId: string) {
    return this.backOfficeClient.query({
      query: gql`
        query OrgStorylinesExtended($encodedOrgId: String!) {
          orgStorylinesExtended(encodedOrgId: $encodedOrgId) {
            users {
              id
              firstName
              lastName
            }
            urls
            urlsJsonb {
              url
              srcImage
              imageUpdatedAt
              srcImageFullPage
              conditionWhen
              excludedWhen
              storylineId
              override
            }
          }
        }
      `,
      variables: {
        encodedOrgId,
      },
    });
  }

  getStorylinesMinimal(encodedOrgId: string) {
    return this.backOfficeClient.query({
      query: gql`
        ${VIDEO_FUNNEL_DETAILS_FRAGMENT}
        query OrgStorylinesMinimal($encodedOrgId: String!) {
          orgStorylinesMinimal(encodedOrgId: $encodedOrgId) {
            storylines {
              excludeWidgetLibraryBtn
              id
              user {
                id
                firstName
                lastName
              }
              userId
              orgId
              status
              urls
              title
              urlsJsonb {
                url
                srcImage
                imageUpdatedAt
                srcImageFullPage
                conditionWhen
                excludedWhen
                storylineId
                override
              }
            }
            videoFunnels {
              ...VideoFunnelDetails
            }
          }
        }
      `,
      variables: {
        encodedOrgId,
      },
    });
  }

  getStorylinesForVideoCardSettings({
    encodedOrgId,
    videoCardId,
  }: {
    encodedOrgId: string;
    videoCardId: number;
  }) {
    return this.backOfficeClient.query({
      query: gql`
        query VideoCardStorylines($encodedOrgId: String!, $videoCardId: Int!) {
          videoCardStorylines(encodedOrgId: $encodedOrgId, videoCardId: $videoCardId) {
            storylines {
              id
              status
              title
            }
            includedStorylineIds
          }
        }
      `,
      variables: {
        encodedOrgId,
        videoCardId,
      },
    });
  }

  getStorylinesForVideoRequestSettings({
    encodedOrgId,
    videoRequestId,
  }: {
    encodedOrgId: string;
    videoRequestId: number;
  }) {
    return this.backOfficeClient.query({
      query: gql`
        query VideoRequestStorylines($encodedOrgId: String!, $videoRequestId: Int!) {
          videoRequestStorylines(encodedOrgId: $encodedOrgId, videoRequestId: $videoRequestId) {
            storylines {
              id
              status
              title
            }
            includedStorylineIds
          }
        }
      `,
      variables: {
        encodedOrgId,
        videoRequestId,
      },
    });
  }
}

export const useStorylinesApi = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new StorylinesApi(backOfficeClient), [backOfficeClient]);
};
