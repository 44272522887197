import React, { useContext, useMemo, useCallback, useId } from 'react';
import TextField, { TextFieldVariants } from '@mui/material/TextField';

import SearchOutlinedIcon from '../icons/search/SearchOutlined';
import ThemeContext from '../../../utils/ThemeContext';

interface SearchFieldProps {
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: Record<string, unknown>;
  variant?: TextFieldVariants;
  autoComplete?: string;
  ariaLabel?: string;
  disabled?: boolean;
}

function SearchField({
  placeholder = '',
  value,
  onChange,
  sx = {},
  variant = 'outlined',
  autoComplete = 'off',
  ariaLabel = '',
  disabled = false,
}: SearchFieldProps) {
  const theme = useContext(ThemeContext);
  const uniqueId = useId();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    },
    [onChange],
  );

  const inputProps = useMemo(
    () => ({
      startAdornment: <SearchOutlinedIcon fill={theme.palette.navbar.button} />,
      style: {
        borderRadius: 50,
        paddingRight: 12,
        backgroundColor: theme.palette.background.paper,
      },
    }),
    [theme],
  );

  return (
    <TextField
      fullWidth
      variant={variant}
      placeholder={placeholder ?? ''}
      value={value}
      onChange={handleChange}
      autoComplete={autoComplete}
      InputProps={inputProps}
      disabled={disabled}
      data-testid="search-text-field"
      type="search"
      role="search"
      name={uniqueId}
      aria-label={ariaLabel || placeholder}
      id={uniqueId}
      sx={{
        background: 'transparent',
        padding: 0,
        minWidth: '300px',
        ...sx,
      }}
    />
  );
}
export default SearchField;
