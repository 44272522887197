import { useQuery } from '@apollo/client';
import { GET_ORG_DOMAIN } from './queries'; // Adjust the path as necessary

interface UseOrgDomainResult {
  domain?: string;
  loading: boolean;
  error?: Error;
}

function useOrgDomain(encodedOrgId: string): UseOrgDomainResult {
  const { data, loading, error } = useQuery(GET_ORG_DOMAIN, {
    variables: { encodedOrgId },
  });

  const domain = data?.getOrgDomainEnterpriseConnection?.connectionDomain ?? null;

  return {
    domain,
    loading,
    error,
  };
}

export default useOrgDomain;
