import { format } from 'date-fns';

export const translations = {
  deleteInVideoFunnel: {
    en: ({ videoFunnelNodeCount, videoFunnelEdgeCount, videoFunnelTitles, videoFunnelCount }) => [
      `I'm sorry, but it seems that there was an error while deleting the Video Card. The Video Card you are trying to delete is currently being used in the following contexts:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      videoFunnelCount > 0 ? `It is used in ${videoFunnelCount} Video Funnel(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelNodeCount > 0 ? `It is used as ${videoFunnelNodeCount} Video Funnel card(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelEdgeCount > 0
        ? `It is connected in ${videoFunnelEdgeCount} connection(s) within those Video Funnel(s).`
        : '',
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Deleting the Video Card will break the functionality of the Video Funnels where it is used, and they will not work as expected. Please consider the impact of removing the Video Card before proceeding with the deletion.`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Here are the titles of the Video Funnels where the Video Card is used:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      (videoFunnelTitles?.length > 0 ? videoFunnelTitles : [])?.flatMap((title) => [
        title,
        <br key={_.uniqueId()} />,
      ]),
      <br key={_.uniqueId()} />,
      `Do you still want to proceed with the deletion of this Video Card?`,
    ],
    sv: ({ videoFunnelNodeCount, videoFunnelEdgeCount, videoFunnelTitles, videoFunnelCount }) => [
      `Tyvärr uppstod ett fel när du försökte ta bort Videon. Videon du försöker ta bort används för närvarande i följande sammanhang:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      videoFunnelCount > 0 ? `Den används i ${videoFunnelCount} Video Funnel(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelNodeCount > 0 ? `Den används som ${videoFunnelNodeCount} Video Funnel kort.` : '',
      <br key={_.uniqueId()} />,
      videoFunnelEdgeCount > 0
        ? `Den är ansluten i ${videoFunnelEdgeCount} anslutning(ar) inom dessa Video Funnel(s).`
        : '',
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Att ta bort Videon kommer att bryta funktionaliteten för de videotunnlar där den används, och de kommer inte att fungera som förväntat. Var vänlig och beakta konsekvenserna av att ta bort Videon innan du fortsätter med borttagningen.`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Här är titlarna på de Video Funnels där Videon används:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      (videoFunnelTitles?.length > 0 ? videoFunnelTitles : [])?.flatMap((title) => [
        title,
        <br key={_.uniqueId()} />,
      ]),
      <br key={_.uniqueId()} />,
      `Vill du fortfarande fortsätta med borttagningen av denna Videon?`,
    ],
  },

  confirmDeleteTitle: {
    en: 'Confirm delete Video Card',
    sv: 'Bekräfta borttagning av Video',
  },
  deleteConfirmBtnText: {
    en: 'Delete!',
    sv: 'Ta bort!',
  },
  deleteCancelBtnText: {
    en: 'Cancel',
    sv: 'Avbryt',
  },

  confirmDeactivateTitle: {
    en: 'Confirm update Video Card status',
    sv: 'Bekräfta uppdatering av Video status',
  },
  deactivateConfirmBtnText: {
    en: 'Update',
    sv: 'Uppdatera',
  },
  deleteCancelBtnText: {
    en: 'Cancel',
    sv: 'Avbryt',
  },

  deactivateInVideoFunnel: {
    en: ({ videoFunnelNodeCount, videoFunnelEdgeCount, videoFunnelTitles, videoFunnelCount }) => [
      `The video you are trying to update is currently being used in the following contexts:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      videoFunnelCount > 0 ? `It is used in ${videoFunnelCount} Video Funnel(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelNodeCount > 0 ? `It is used as ${videoFunnelNodeCount} Video Funnel card(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelEdgeCount > 0
        ? `It is connected in ${videoFunnelEdgeCount} connection(s) within those Video Funnel(s).`
        : '',
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `By updating the status of the video, it will break the functionality of the Video Funnels where it is used, and they will not work as expected. Please consider the impact of deactivating the video before proceeding.`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Here are the titles of the Video Funnels where the video is used:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      (videoFunnelTitles?.length > 0 ? videoFunnelTitles : [])?.flatMap((title) => [
        title,
        <br key={_.uniqueId()} />,
      ]),
      <br key={_.uniqueId()} />,
      `Do you still want to proceed with the deactivation of this End Screen?`,
    ],
    sv: ({ videoFunnelNodeCount, videoFunnelEdgeCount, videoFunnelTitles, videoFunnelCount }) => [
      `Videon du försöker uppdatera används för närvarande i följande sammanhang:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      videoFunnelCount > 0 ? `Den används i ${videoFunnelCount} Video Funnel(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelNodeCount > 0 ? `Den används som ${videoFunnelNodeCount} Video Funnel kort.` : '',
      <br key={_.uniqueId()} />,
      videoFunnelEdgeCount > 0
        ? `Den är ansluten i ${videoFunnelEdgeCount} anslutning(ar) inom dessa Video Funnel(s).`
        : '',
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Att uppdatera videons status kommer innebära att funktionaliteten bryts för de Video Funnels där den används, och de kommer inte att fungera som förväntat. Var vänlig och beakta konsekvenserna av att inaktivera videon innan du fortsätter`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Här är titlarna på de Video Funnels där videon används:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      (videoFunnelTitles?.length > 0 ? videoFunnelTitles : [])?.flatMap((title) => [
        title,
        <br key={_.uniqueId()} />,
      ]),
      <br key={_.uniqueId()} />,
      `Vill du fortfarande fortsätta med uppdatera statusen för denna videon?`,
    ],
  },

  search: {
    en: 'Search',
    sv: 'Sök',
  },
  premiumFeature: {
    en: 'Premium Feature',
    sv: 'Premiumfunktion',
  },
  savedSuccess: {
    en: 'Successfully saved',
    sv: 'Sparat och klart',
  },
  savedError: {
    en: 'Something went wrong - contact Support.',
    sv: 'Något gick fel - kontakta supporten.',
  },
  hiAgain: {
    en: 'Hello',
    sv: 'Hej igen',
  },
  loginToContinue: {
    en: 'Login to continue to Life Inside',
    sv: 'Logga in för att fortsätta till Life Inside',
  },
  from: {
    en: 'from',
    sv: 'från',
  },
  UserConsents: {
    en: 'User Consents',
    sv: 'Användaren godkänner',
  },
  defaultUploadConsent: {
    en: 'I consent to and authorize the public display of my video, name, and title on the company’s website.',
    sv: 'Jag samtycker till och godkänner att min video, namn och titel visas offentligt på företagets webbplats.',
  },
  noSoMeConsent: {
    en: 'Does not consent to sharing on Social Media',
    sv: 'Godkänner EJ delning i sociala medier',
  },
  link: {
    en: 'Link: ',
    sv: 'Länk: ',
  },
  timeline: {
    selectTimePeriod: {
      en: 'Select time period',
      sv: 'Välj tidsperiod',
    },
    past7Days: {
      en: 'Past 7 days',
      sv: 'Senaste 7 dagarna',
    },
    past30Days: {
      en: 'Past 30 days',
      sv: 'Senaste 30 dagarna',
    },
    thisMonth: {
      en: 'This month',
      sv: 'Denna månaden',
    },
    lastMonth: {
      en: 'Previous month',
      sv: 'Förra månaden',
    },
    thisYear: {
      en: 'This year',
      sv: 'Detta året',
    },
    lastYear: {
      en: 'Last year',
      sv: 'Förra året',
    },
    custom: {
      en: 'Custom',
      sv: 'Välj datum',
    },
  },
  admin: {
    header: {
      conversationalAvatar: {
        en: 'Conversational Avatar',
        sv: 'Conversational Avatar',
      },
      killSwitches: {
        en: 'Kill switches',
        sv: 'Kill switches',
      },
      widgetVersions: {
        en: 'Widget versions',
        sv: 'Widget versioner',
      },
      updates: {
        en: 'Updates',
        sv: 'Uppdateringar',
      },
      monthlyReport: {
        en: 'Monthly-email',
        sv: 'Monthly-email',
      },
      questionSuggestion: {
        en: 'Questions',
        sv: 'Frågor',
      },
      allOrganizations: {
        en: 'Organizations',
        sv: 'Organisationer',
      },
      createOrganization: {
        en: 'Create organization',
        sv: 'Skapa organisation',
      },
      createSuperAdmin: {
        en: 'Create super-admin',
        sv: 'Skapa super-admin',
      },
      superAdmins: {
        en: 'Super admins',
        sv: 'Superadmins',
      },
      onboardingStatistics: {
        en: 'Statistics',
        sv: 'Statistik',
      },
    },
    Organizations: {
      UserPhoneNumber: {
        en: 'Phone Number',
        sv: 'Telefonnummer',
      },
      Name: {
        en: 'Name:',
        sv: 'Namn:',
      },
      Users: {
        en: 'Users',
        sv: 'Användare',
      },
      createUser: {
        en: 'Create a new User',
        sv: 'Lägg till användare',
      },
      createnewUser: {
        en: 'Create new user for organization',
        sv: 'Skapa ny anvädare för organisation ',
      },
      create: {
        en: 'Create User',
        sv: 'Skapa användare',
      },
      UserTel: {
        en: 'Phone Number *',
        sv: 'Telefonnummer *',
      },
      UserFirstName: {
        en: 'First Name *',
        sv: 'Förnamn *',
      },
      UserLastName: {
        en: 'Last Name',
        sv: 'Efternamn',
      },
      OrgNumber: {
        en: 'OrgNumber:',
        sv: 'OrgNummer:',
      },
      Contact: {
        en: 'Contact:',
        sv: 'Kontakt:',
      },
      SelectedLanguage: {
        en: 'Selected Language:',
        sv: 'Valt språk:',
      },
      TagSelector: {
        en: 'TagSelector:',
        sv: 'Taggselektor:',
      },
      PublicLibrary: {
        en: 'Public library',
        sv: 'Publikt bibliotek',
      },
      OrganizationName: {
        en: 'Organization name',
        sv: 'Organisationsnamn',
      },
      Language: {
        en: 'Language (en/sv)',
        sv: 'Språk (en/sv)',
      },
      TagOption: {
        en: 'Selector for Tag',
        sv: 'Selector för taggar',
      },
      ContactEmail: {
        en: 'Contact Email',
        sv: 'Epost till kontakt',
      },
      whitelistedDomain: {
        en: 'Whitelisted Domain',
        sv: 'Whitelisted Domain',
      },
      widgetIsActiveLabel: {
        en: 'Widget is active',
        sv: 'Widget är aktiv',
      },
      EditOrganizationSettings: {
        en: 'Edit Organization Settings',
        sv: 'Redigera organisations inställningar',
      },
      defaultLanguage: {
        en: 'Default language',
        sv: 'Standard språk',
      },
      defaultLanguageInfo: {
        en: 'The default language for the organization, ONLY "en" & "sv" are supported',
        sv: "Standard språk för organisationen, endast 'en' & 'sv' stöds",
      },
      defaultLanguageError: {
        en: "Not a supported language, please use 'en' or 'sv'",
        sv: "Ogiltigt språk, använd 'en' eller 'sv'",
      },
      defaultLanguageVerification: {
        en: 'Are you sure you want to change the default language? Enter the language you want to change to below:',
        sv: 'Är du säker på att du vill ändra standardspråket? Ange det språk du vill ändra till nedan:',
      },
      defaultLanguageVerificationError: {
        en: 'The language you entered is NOT the same as the one you entered',
        sv: 'Språket du angav är INTE samma som det som du angav',
      },
      defaultLanguageVerificationChange: {
        en: 'Are you sure you want to change the default language? Enter the YES to confirm:',
        sv: 'Är du säker på att du vill ändra standardspråket? Ange YES för att bekräfta:',
      },
    },
    onboardingStatistics: {
      header: {
        en: 'Onboarding statistics',
        sv: 'Onboarding statistik',
      },
      table: {
        orgName: {
          en: 'Name',
          sv: 'Namn',
        },
        adminNumber: {
          en: 'No. of company admins',
          sv: 'Antal administratörer',
        },
        suggestionsNumber: {
          en: 'No. of video suggestions',
          sv: 'No. of video suggestions',
        },
        pendingNumber: {
          en: 'No. of pending videos',
          sv: 'No. of pending videos',
        },
        reviewNumber: {
          en: 'No. of videos to review',
          sv: 'No. of videos to review',
        },
        approvedNumber: {
          en: 'No. of approved videos',
          sv: 'No. of approved videos',
        },
        liveNumber: {
          en: 'No. of live videos',
          sv: 'No. of live videos',
        },
        isWidgetLive: {
          en: 'Is widget live',
          sv: 'Is widget live',
        },
        analytics: {
          en: 'Analytics',
          sv: 'Statistik',
        },
      },
    },
  },
  prevPeriod: {
    en: 'Prev. period',
    sv: 'Föreg. period.',
  },
  yes: {
    en: 'Yes',
    sv: 'Ja',
  },
  no: {
    en: 'No',
    sv: 'Nej',
  },
  addTag: {
    en: 'Add tag',
    sv: 'Ny tagg',
  },
  createTag: {
    en: 'Create tag',
    sv: 'Skapa tagg',
  },
  library: {
    en: 'Public library',
    sv: 'Publikt biblioteket',
  },
  libraryIcon: {
    en: 'Video Library Icon',
    sv: 'Video Biblioteket Ikon',
  },
  demo: {
    en: 'Demo',
    sv: 'Demo',
  },
  demoIcon: {
    en: 'Widget Demo Icon',
    sv: 'Widget Demo Ikon',
  },
  showPublic: {
    en: 'Public library',
    sv: 'Publika biblioteket',
  },
  widgetDemo: {
    en: 'Widget demo',
    sv: 'Widget-demo',
  },
  companyLogoIcon: {
    en: 'Life Inside Company Logo Icon',
    sv: 'Life Inside Företags loga  Icon',
  },
  requests: {
    en: 'Requests',
    sv: 'Förfrågningar',
  },
  requestsIcon: {
    en: 'Requests Icon',
    sv: 'Förfrågningar Ikon',
  },
  dashboard: {
    en: 'Dashboard',
    sv: 'Dashboard',
  },
  home: {
    en: 'Home',
    sv: 'Hem',
  },
  dashboardIcon: {
    en: 'Dashboard Icon',
    sv: 'Dashboard Ikon',
  },
  videoLibrary: {
    en: 'Library',
    sv: 'Bibliotek',
  },
  videoPlanner: {
    en: 'Planner',
    sv: 'Planerare',
  },
  planner: {
    en: 'Planner',
    sv: 'Planerare',
  },

  videoPlannerIcon: {
    en: 'Planner Icon',
    sv: 'Planerare Ikon',
  },
  videoLibraryIcon: {
    en: 'Library Icon',
    sv: 'Bibliotek Ikon',
  },
  storyline: {
    en: 'Storylines',
    sv: 'Storylines',
  },
  stories: {
    en: 'Stories',
    sv: 'Stories',
  },
  videoCollector: {
    en: 'Video Collector',
    sv: 'Video Collector',
  },
  storiesIcon: {
    en: 'Stories Icon',
    sv: 'Stories Ikon',
  },
  storylineIcon: {
    en: 'Storylines Icon',
    sv: 'Storylines Ikon',
  },
  endScreens: {
    en: 'End Screens',
    sv: 'End Screens',
  },
  endScreensIcon: {
    en: 'End Screens Icon',
    sv: 'End Screens Ikon',
  },
  analytics: {
    en: 'Analytics',
    sv: 'Statistik',
  },
  analyticsCount: {
    en: 'Count',
    sv: 'Antal',
  },
  analyticsIcon: {
    en: 'Analytics Icon',
    sv: 'Statistik Ikon',
  },
  past30days: {
    en: 'The past 30 days',
    sv: 'De senaste 30 dagarna',
  },
  filter: {
    en: 'Filter',
    sv: 'Filtrera',
  },
  backOffice: {
    header: {
      helpLink: {
        en: 'http://www.lifeinside.io/help/',
        sv: 'http://www.lifeinside.io/help/se/',
      },
      helpLabel: {
        en: 'Help',
        sv: 'Hjälp',
      },
      helpLabelIcon: {
        en: 'Help Icon',
        sv: 'Hjälp Ikon',
      },
    },
    ChangeLogo: {
      en: 'Change logo',
      sv: 'Ändra logga',
    },
    SelectLogo: {
      en: 'Select logo',
      sv: 'Välj logga',
    },
    UploadLogo: {
      en: 'Upload logo',
      sv: 'Ladda upp logga',
    },
  },
  myPages: {
    en: 'My page',
    sv: 'Min sida',
  },
  myPagesIcon: {
    en: 'My page Icon',
    sv: 'Min sida Ikon',
  },
  orgSettings: {
    en: 'Settings',
    sv: 'Inställningar',
  },
  orgSettingsIcon: {
    en: 'Settings Icon',
    sv: 'Inställningar Ikon',
  },
  login: {
    en: 'Log in',
    sv: 'Logga in',
  },
  newLoginSystem: {
    en: 'New Login System',
    sv: 'Nytt Inloggningssystem',
  },
  newLoginSystemDescription: {
    en: 'The Life Inside platform has been upgraded to use Auth0 as our login system. Press "Log in" below to continue to the new login screen. Your credentials are still the same as before.',
    sv: 'Vi har uppgraderat Life Inside platformen till att använda Auth0 för inloggningar. Klicka på "Logga in" för att komma tilll den nya inloggningsskärmen. Dina inloggningsuppgifter är fortfarande samma.',
  },
  adminLogin: {
    en: 'Admin login',
    sv: 'Admin login',
  },
  resetPassword: {
    en: 'Reset password',
    sv: 'Återställ lösenord',
  },
  newPassword: {
    en: 'New password',
    sv: 'Nytt lösenord',
  },
  confirmPassword: {
    en: 'Confirm password',
    sv: 'Bekräfta lösenord',
  },
  loginDescription: {
    en: 'Login to continue',
    sv: 'Logga in för att fortsätta',
  },
  adminLoginDescription: {
    en: 'Admin login to continue',
    sv: 'Admin login för att fortsätta',
  },
  enterMfa: {
    en: 'Enter the code we sent to your phone',
    sv: 'Ange den kod som skickats till din telefon',
  },
  mfaDescription: {
    en: 'The code is valid for 2 minutes.',
    sv: 'Koden är giltig i 2 minuter.',
  },
  cancel: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
  sendAgain: {
    en: 'Send code again',
    sv: 'Skicka kod igen',
  },
  loginAndConfirm: {
    en: 'Log in',
    sv: 'Logga in',
  },
  forgotPassword: {
    en: 'Forgot password?',
    sv: 'Glömt ditt lösenord?',
  },
  logout: {
    en: 'Log out',
    sv: 'Logga ut',
  },
  logoutIcon: {
    en: 'Log out Icon',
    sv: 'Logga ut Ikon',
  },
  videoCardSettings: {
    en: 'Video cards settings',
    sv: 'Videokort inställningar',
  },
  videoPlannerViewTitle: {
    en: 'Video planner',
    sv: 'Video planerare',
  },
  allVideos: {
    en: 'Videos',
    sv: 'Videos',
  },
  chooseFilters: {
    en: 'Choose filters',
    sv: 'Välj filter',
  },
  applyButton: {
    en: 'APPLY',
    sv: 'TILLÄMPA',
  },
  resetButton: {
    en: 'RESET',
    sv: 'ÅTERSTÄLL',
  },
  newRequest: {
    en: 'Request',
    sv: 'Förfrågan',
  },
  directVideoUploadError: {
    en: "Failed to upload video, please try again later. If the problem persists contact system support. Sorry for the inconvenience. We're working on it!",
    sv: 'Misslyckades med att ladda upp video, försök igen senare. Om problemet kvarstår kontakta system support. Vi beklagar besväret. Vi jobbar på det!',
  },
  newVideo: {
    en: 'Upload Video',
    sv: 'Ladda upp video',
  },
  user: {
    en: 'User',
    sv: 'Användare',
  },
  update: {
    en: 'Update',
    sv: 'Uppdatera',
  },
  showArchived: {
    en: 'Show archived',
    sv: 'Visa arkiverade',
  },
  hideArchived: {
    en: 'Hide archived',
    sv: 'Göm arkiverade',
  },
  allUsers: {
    en: 'All users',
    sv: 'Alla Användare',
  },
  selectUser: {
    en: 'Select User',
    sv: 'Välj Användare',
  },
  videoCardFilterUserOptions: {
    en: 'Filtering on user on video cards',
    sv: 'Filtrering på user på video korten ',
  },
  videoNotReady: {
    en: 'The video is waiting to be transcoded. Check back in later!',
    sv: 'Videon väntar på att komprimeras. Kolla in senare!',
  },
  fileUploaded: {
    en: 'Video has been successfully uploaded! The video is not fully transcoded yet. Check back in later!',
    sv: 'Videon har laddats upp! Videon är inte helt färdig formaterad ännu. Kolla in senare!',
  },

  loginToContinue: {
    en: 'Log in to continue',
    sv: 'Logga in för att fortsätta',
  },
  email: {
    en: 'E-mail',
    sv: 'E-mail',
  },
  password: {
    en: 'Password',
    sv: 'Lösenord',
  },
  organization: {
    en: 'Organization',
    sv: 'Organisation',
  },
  selectOrganization: {
    en: 'Select Organization',
    sv: 'Välj Organisation',
  },
  userOrganizationSeveral: {
    en: 'The email account is linked to several organizations. Please select one organization from the list.',
    sv: 'E-postkontot är kopplat till flera organisationer. Välj en organisation från listan.',
  },
  menu: {
    en: 'Menu',
    sv: 'Meny',
  },
  previousPeriod: {
    en: 'Previous period',
    sv: 'Föregående period',
  },
  videoCard: {
    publicVisibility: {
      en: 'Displayed on published video',
      sv: 'Visas på publicerad video',
    },
    openVideoCard: {
      en: 'Open',
      sv: 'Öppna',
    },
    generatingThumbnails: {
      en: 'Generating thumbnails...',
      sv: 'Genererar visningsbilder...',
    },
    orderSubtitle: {
      en: 'Order Subtitle',
      sv: 'Beställ undertext',
    },
    SubtitleProgress: {
      en: 'Subtitle in Progress',
      sv: 'Undertext pågår',
    },
    manageSubtitle: {
      en: 'Manage Subtitles',
      sv: 'Hantera undertexter',
    },
    add: {
      en: 'Add',
      sv: 'Lägg till',
    },
    createNew: {
      en: 'Create and send video request',
      sv: 'Skapa och skicka video förfrågan',
    },
    editVideoRequest: {
      en: 'Edit video request',
      sv: 'Redigera video förfrågan',
    },
    editVideo: {
      en: 'Edit video',
      sv: 'Redigera video',
    },
    uploadingVideo: {
      en: 'Uploading video...',
      sv: 'Laddar upp video...',
    },
    uploadingVideoText: {
      en: 'Page will reload when video is uploaded',
      sv: 'Sidan kommer att laddas om när video är uppladdad',
    },
    createNewVideo: {
      en: 'Create and send video request',
      sv: 'Skapa och skicka video förfrågan',
    },
    createNewVideoToSendInfo: {
      en: 'Create new video request and send it to your recipient',
      sv: 'Skapa en ny videoförfrågan och skicka den till din mottagare',
    },
    editVideoInfo: {
      en: 'Edit details of the video card',
      sv: 'Redigera detaljerna på videokortet',
    },
    newVideo: {
      en: 'New video',
      sv: 'Ny video',
    },
    question: {
      en: 'Question',
      sv: 'Fråga',
    },
    videoTitle: {
      en: 'Video title',
      sv: 'Videotitel',
    },
    videoTitlePublicVisibility: {
      en: 'This title text is generated from the video request question that was asked.',
      sv: 'Denna titel text genereras från den video förfrågan som ställdes.',
    },
    questionPlaceholder: {
      en: 'Enter a question or select one from the list',
      sv: 'Ange en fråga eller välj en från listan',
    },
    approveVideo: {
      en: 'Approve video',
      sv: 'Godkänn video',
    },
    publicVisibilityTooltip: {
      en: 'This information will be prominently displayed on the published video, allowing it to be visible to viewers. It serves as an important element to provide context or convey specific details related to the content of the video.',
      sv: 'Denna information kommer att visas tydligt på den publicerade videon och gör den synlig för tittare. Den fungerar som en viktig faktor för att ge sammanhang eller förmedla specifika detaljer relaterade till videons innehåll.',
    },
    internalVisibility: {
      en: 'Only displayed internally',
      sv: 'Visas endast internt',
    },
    internalVisibilityTooltip: {
      en: 'Visible for internal use only',
      sv: 'Endast synlig internt',
    },
    internalVisibilityPhone: {
      en: 'Only displayed internally and used for video requests and reminders',
      sv: 'Visas endast internt och används för förfrågningar och påminnelser',
    },
    rollLeader: {
      en: 'Role',
      sv: 'Roll',
    },
    leader: {
      en: 'Leadership position',
      sv: 'Ledande position',
    },
    internalVisibilityLeader: {
      en: 'Only displayed internally and used for requests for recipients in a leadership position',
      sv: 'Visas endast internt och används för förfrågningar för mottagare i en ledande position',
    },
    automation: {
      en: 'Automation',
      sv: 'Automatisering',
    },
    receiveAutoRequest: {
      en: 'Receive Auto Request.',
      sv: 'Ta emot automatiska förfrågningar.',
    },
    teamMember: {
      en: 'Recipient',
      sv: 'Mottagare',
    },
    waitingOnCollegue: {
      en: 'Waiting on video response...',
      sv: 'Väntar på videosvar...',
    },
    selectRecipient: {
      en: 'Recipient(s)',
      sv: 'Mottagare',
    },
    selectRecipientOrCreateNew: {
      en: 'or create a new',
      sv: 'eller skapa en ny',
    },
    createNewRecipient: {
      en: 'Create recipient',
      sv: 'Skapa mottagare',
    },
    createRecipient: {
      en: 'Create Recipient',
      sv: 'Skapa Mottagare',
    },
    deleteRecipientTitle: {
      en: 'Delete Recipient',
      sv: 'Ta bort Mottagare',
    },
    deleteRecipientDescription: {
      en: 'Are you sure you want to delete this recipient?',
      sv: 'Är du säker på att du vill ta bort denna mottagare?',
    },
    saveRecipient: {
      en: 'Save',
      sv: 'Spara',
    },
    createAndAddRecipient: {
      en: 'Create & Add Recipient',
      sv: 'Skapa & Lägg till Mottagare',
    },
    editRecipient: {
      en: 'Edit Recipient',
      sv: 'Redigera Mottagare',
    },
    whoIsTheMainChar: {
      en: 'Who is in the video?',
      sv: 'Vem är med i videon?',
    },
    directUploadConsent: {
      en: 'Give consent to direct upload',
      sv: 'Ge samtycke till direktuppladdning',
    },
    goToUpload: {
      en: 'Go to upload page',
      sv: 'Gå till uppladdning',
    },
    copyUploadLink: {
      en: 'Copy upload link',
      sv: 'Kopiera uppladdningslänk',
    },
    downloadThumbnail: {
      en: 'Thumbnail',
      sv: 'Thumbnail',
    },
    name: {
      en: 'Name',
      sv: 'Namn',
    },
    firstName: {
      en: 'First name',
      sv: 'Förnamn',
    },
    lastName: {
      en: 'Last name',
      sv: 'Efternamn',
    },
    jobTitle: {
      en: 'Title',
      sv: 'Titel',
    },
    hideFromLibrary: {
      en: 'Hide from Video library',
      sv: 'Dölj från Videobibliotek',
    },
    hideFromLibraryInfo: {
      en: 'Hide this video from the Video library',
      sv: 'Dölj denna video från Videobibliotek',
    },
    uploadLink: {
      en: 'Link for video upload',
      sv: 'Länk för videouppladdning',
    },
    email: {
      en: 'E-mail',
      sv: 'E-mail',
    },
    phoneNumber: {
      en: 'Phone number',
      sv: 'Telefonnummer',
    },
    sentToEmail: {
      en: 'Sent to e-mail',
      sv: 'Skickat till e-post',
    },
    messageToTeamMember: {
      en: 'Message to recipient',
      sv: 'Meddelande till mottagaren',
    },
    describeIntentions: {
      en: 'Please describe the ambition / intention with this video to the recipient',
      sv: 'Vänligen beskriv ambitionen / syftet med videon till mottagaren',
    },
    reminderSent: {
      en: ({ date, userName }) =>
        `Reminder sent ${format(date, 'yyyy-MM-dd HH:mm')} by ${userName}.`,
      sv: ({ date, userName }) =>
        `Påminnelse skickad ${format(date, 'yyyy-MM-dd HH:mm')} av ${userName}.`,
    },
    reminderSentDateTime: {
      en: ({ date }) => `Sent ${format(date, 'yyyy-MM-dd HH:mm')}`,
      sv: ({ date }) => `Skickad ${format(date, 'yyyy-MM-dd HH:mm')}`,
    },
    reminderSentByUser: {
      en: ({ userName }) => `By ${userName}`,
      sv: ({ userName }) => `Av ${userName}`,
    },
    reminderSentBySystem: {
      en: `By system automation`,
      sv: `Av systemautomation`,
    },
    reminderNotSent: {
      en: 'Reminder not sent.',
      sv: 'Påminnelse är inte skickad.',
    },
    createdByUser: {
      en: ({ userName }) => `Created by ${userName}`,
      sv: ({ userName }) => `Skapad av ${userName}`,
    },
    reminderHeader: {
      en: 'Reminder',
      sv: 'Påminnelse',
    },
    notYetSentOut: {
      en: 'Request not yet sent',
      sv: 'Förfrågan är inte skickad än',
    },
    requestSent: {
      en: 'Request sent',
      sv: 'Förfrågan skickad',
    },
    requestSentBy: {
      en: ({ userName }) => `By ${userName}`,
      sv: ({ userName }) => `Av ${userName}`,
    },
    selectTags: {
      en: 'Select tags',
      sv: 'Välj taggar',
    },
    selectTagsInfo: {
      en: 'Select the tags that suit the video',
      sv: 'Välj de taggar som passar bäst för videon',
    },
    selectStoryline: {
      en: 'Add to existing storyline',
      sv: 'Lägg till på befintlig storyline',
    },
    selectStorylineTooltip: {
      en: 'Video card needs to be approved and then published for the video to be displayed in selected storylines.',
      sv: 'Videokortet måste först godkännas och sedan publiceras för att videon ska visas på valda storylines.',
    },
    selectStorylineInfo: {
      en: 'Select the Storylines that suit the video',
      sv: 'Välj de Storylines som passar bäst för videon',
    },
    video: {
      en: 'Video',
      sv: 'Video',
    },

    videoStats: {
      en: 'Statistics',
      sv: 'Statistik',
    },
    past30days: {
      en: 'The past 30 days',
      sv: 'De senaste 30 dagarna',
    },
    downloadVideo: {
      en: 'Video',
      sv: 'Video',
    },
    downloadVideoQuality: {
      FHD: {
        en: '1080p',
        sv: '1080p',
      },
      HD: {
        en: '720p',
        sv: '720p',
      },
      qHD: {
        en: '540p',
        sv: '540p',
      },
      nHD: {
        en: '360p',
        sv: '360p',
      },
      ntsc: {
        en: '240p',
        sv: '240p',
      },
      originalVideo: {
        en: 'Original Video',
        sv: 'Original Video',
      },
    },
    delete: {
      en: 'Delete',
      sv: 'Ta bort',
    },
    closeBeforeSave: {
      en: 'Close before save?',
      sv: 'Stäng utan att spara?',
    },
    closeAriaLabel: {
      en: 'close',
      sv: 'stäng',
    },
    areYouSureClose: {
      en: 'Are you sure you want to close without saving?',
      sv: 'Är du säker på att du vill stänga utan att spara?',
    },
    missingQuestion: {
      en: 'Question is missing',
      sv: 'Fråga saknas',
    },
    deleteItem: {
      en: 'Delete',
      sv: 'Ta bort',
    },
    deleteItemUpload: {
      en: 'Video has not finished uploading. Are you sure you want to delete the video card?',
      sv: 'Videon har inte laddats upp. Är du säker på att du vill ta bort videokortet?',
    },
    duplicateItem: {
      en: 'Duplicate',
      sv: 'Duplicera',
    },
    areYouSureDelete: {
      en: 'Are you sure you want to delete this item? It cannot be undone and all associated data will be removed',
      sv: 'Är du säker att du vill ta bort videokortet? Det kan inte ångras och associerad videodata kommer tas bort.',
    },
    areYouSureDeleteUpload: {
      en: 'Are you sure you want to cancel upload and delete this item? It cannot be undone and all associated data will be removed',
      sv: 'Är du säker att du vill avbryta uppladdning ta bort videokortet? Det kan inte ångras och associerad videodata kommer tas bort.',
    },
    cancel: {
      en: 'Cancel',
      sv: 'Avbryt',
    },
    save: {
      en: 'Save',
      sv: 'Spara',
    },
    sendReminder: {
      en: 'Send reminder',
      sv: 'Skicka påminnelse',
    },
    sendEmailReminder: {
      en: 'Send e-mail reminder',
      sv: 'Skicka påminnelsemail',
    },
    sendTextMessageReminder: {
      en: 'Send sms reminder',
      sv: 'Skicka SMS påminnelse',
    },
    sendTextMessageEmailReminder: {
      en: 'Send sms & e-mail reminder',
      sv: 'Skicka sms & e-mail påminnelse',
    },
    saveDraft: {
      en: 'Save as draft',
      sv: 'Spara som utkast',
    },
    goLiveVideo: {
      en: 'Publish to library',
      sv: 'Publicera till bibliotek',
    },
    archive: {
      en: 'Archive',
      sv: 'Arkivera',
    },
    unarchive: {
      en: 'Unarchive',
      sv: 'Avarkivera',
    },
    unpublish: {
      en: 'Remove from library',
      sv: 'Ta bort från biblioteket',
    },
    sendToTeamMember: {
      en: 'Send request',
      sv: 'Skicka förfrågan',
    },
    sendEmailToTeamMember: {
      en: 'Send e-mail request',
      sv: 'Skicka e-mail förfrågan',
    },
    sendSMSToTeamMember: {
      en: 'Send SMS request',
      sv: 'Skicka SMS förfrågan',
    },
    sendSMSEmailToTeamMember: {
      en: 'Send SMS & e-mail request',
      sv: 'Skicka SMS & e-mail förfrågan',
    },
    noVideoYet: {
      en: 'There is no video connected to this video card yet.',
      sv: 'Det finns ingen video kopplad till detta videokort än.',
    },
    waiting: {
      en: 'Waiting for a video response',
      sv: 'Väntar på ett videosvar',
    },
    approve: {
      en: 'Approve',
      sv: 'Godkänn',
    },
    approveAndPublish: {
      en: 'Approve & Publish to library',
      sv: 'Godkänn & Publicera till biblioteket',
    },
    confirm: {
      en: 'Confirm',
      sv: 'Bekräfta',
    },
    deleteConfirm: {
      en: 'Delete',
      sv: 'Ta bort',
    },
    noCancel: {
      en: 'Cancel',
      sv: 'Avbryt',
    },
    interactionTools: {
      en: 'End Screen',
      sv: 'End Screen',
    },
    interactionToolsSelect: {
      en: 'Activate lead generation on video',
      sv: 'Aktivera leadsgenerering på video',
    },
    contactForm: {
      en: 'Contact Form',
      sv: 'Kontakt Formulär',
    },
    callToAction: {
      en: 'Call To Action',
      sv: 'Call To Action',
    },
    interactionToolInfo: {
      en: 'This will be shown after video ended',
      sv: 'Detta kommer att visas efter videon slutat spela',
    },
    msgTextContactForm: {
      en: 'Message',
      sv: 'Meddelande',
    },
    emailContactForm: {
      en: 'E-mail to be contacted at',
      sv: 'E-mail för återkoppling',
    },
    nameContactForm: {
      en: 'Name Field',
      sv: 'Fält för namn',
    },
    btnTextContactForm: {
      en: 'Button Text',
      sv: 'Knappt Text',
    },
    msgTextCallToAction: {
      en: 'Message',
      sv: 'Meddelande',
    },
    urlCallToAction: {
      en: 'Link',
      sv: 'Länk',
    },
    btnTextCallToAction: {
      en: 'Button text',
      sv: 'Knapp text',
    },
    contactFormContactUs: {
      en: 'Contact us',
      sv: 'Kontakta oss',
    },
    emailPlaceholder: {
      en: 'Email',
      sv: 'Email',
    },
    namePlaceholder: {
      en: 'Name',
      sv: 'Namn',
    },
    contactFormBtnText: {
      en: 'Send',
      sv: 'Skicka',
    },
    textAreaPlaceholder: {
      en: 'Your message...',
      sv: 'Ditt meddelande...',
    },
    contactFormThankYou: {
      en: 'Thank you!',
      sv: 'Tack för ditt meddelande!',
    },
    contactFormThankMsg: {
      en: "We'll be in touch soon.",
      sv: 'Vi hörs av inom kort.',
    },
    previewInteraction: {
      en: 'Preview Lead Generation',
      sv: 'Förhandsgranska Leadsgenerering',
    },
    interactionToolsPremium: {
      en: 'The lead generation feature allows you to collect leads and sign-ups on your videos from visitors. Please contact Life Inside support for activation.',
      sv: 'Leadsgenerering gör det möjligt att generera leads och sign-ups på dina videos från besökare. Kontakta Life Inside support för att aktivera tjänsten.',
    },
    deleteSuccessMessage: {
      en: 'Successfully deleted item',
      sv: 'Lyckades ta bort videokortet ',
    },
    deleteErrorMessage: {
      en: 'Error deleting item',
      sv: 'Kunde inte ta bort videokortet',
    },
  },
  tag: {
    add: {
      en: 'Add',
      sv: 'Lägg till',
    },
    createNew: {
      en: 'Create new video',
      sv: 'Skapa ny video',
    },
    deleteItem: {
      en: 'Are you sure that you want to delete this tag?',
      sv: 'Är du säker på att du vill radera taggen?',
    },
    areYouSureDelete: {
      en: 'In that case, the current tag will be removed from your video library and its connection to all videos that have the tag will disappear.',
      sv: 'Aktuell tagg kommer i så fall att tas bort från ert videobibliotek och dess koppling till samtliga videos som har den försvinna.',
    },
    cancel: {
      en: 'Cancel',
      sv: 'Avbryt',
    },
    confirm: {
      en: 'Confirm',
      sv: 'Bekräfta',
    },
    noCancel: {
      en: 'Cancel',
      sv: 'Avbryt',
    },
  },
  upload: {
    userAcceptedUploadConsentText: {
      en: ({ userName }) => [
        'I ',
        userName,
        ' certify that those participating in the film have agreed to the following: ',
      ],
      sv: ({ userName }) => [
        'Jag ',
        userName,
        ' intygar att de som medverkar i filmen har samtyckt till följande: ',
      ],
    },

    continueUpload: {
      en: 'Continue upload',
      sv: 'Fortsätt ladda upp',
    },
    uploadVideoForQuestion: {
      en: 'Submit your video response',
      sv: 'Skicka in ditt videosvar',
    },
    uploadVideoForMultiQuestions: {
      en: 'Choose a question from the list and upload your video response',
      sv: 'Välj en fråga från listan och ladda upp ditt videosvar:',
    },
    selectQuestion: {
      en: 'Pick your question',
      sv: 'Välj din fråga',
    },
    bePatient: {
      en: 'Please be patient',
      sv: 'Laddar upp - vänligen vänta',
    },
    bePatientInfo: {
      en: 'The video upload might take a while, thank you for not closing the window.',
      sv: 'Videouppladdningen kan ta en stund, tack för att du inte stänger fönstret.',
    },
    noLongerValid: {
      en: 'This link is no longer valid. You will be redirected to the start page.',
      sv: 'Länken är tyvärr inte längre giltig. Du kommer bli omdirigerad till startsidan.',
    },
    dragOrClick: {
      en: 'Click or drag to this area to upload',
      sv: 'Klicka eller dra hit för att ladda upp',
    },
    clickToSelect: {
      en: 'Click this area to select file',
      sv: 'Klicka för att välja fil',
    },
    greatVideo: {
      en: 'Great video!',
      sv: 'Snygg video!',
    },
    wereExcited: {
      en: 'We are excited to see the results.',
      sv: 'Vi är spända inför att se resultatet.',
    },
    terms: {
      en: 'I understand and approve that my video, name and title will be shown publicly on the company’s website',
      sv: 'Jag förstår och godkänner att min video, namn och titel kommer visas publikt på företagets hemsida.',
    },
    thankYouUpload: {
      en: 'Thank you for your video reply! Great job! The company admin will now review the video. All the best!',
      sv: 'Tack för ditt videosvar! Bra jobbat! En företagsadministratör kommer nu granska videon. Ha det fint!',
    },
    step1Title: {
      en: 'Record your video',
      sv: 'Spela in din video',
    },
    step2Title: {
      en: 'Select your video',
      sv: 'Välj din video',
    },
    step3Title: {
      en: 'Upload your video',
      sv: 'Ladda upp din video',
    },
    uploadVideo: {
      en: 'Upload video',
      sv: 'Ladda upp video',
    },
    preStep: {
      en: 'Select Video',
      sv: 'Välj Video',
    },
    threeSimpleSteps: {
      en: 'Share your story in video',
      sv: 'Dela din berättelse i video',
    },
    threeSimpleSteps2: {
      en: 'Do you have insights to share? Make them heard with a video! Just follow these simple steps to get started:',
      sv: 'Har du insikter att dela med dig av? Låt dem höras med en video! Följ dessa enkla steg för att komma igång:',
    },
    step1: {
      en: (
        <span>
          Grab your smartphone, open the camera app, and record yourself in{' '}
          <strong>portrait mode.</strong>
        </span>
      ),
      sv: (
        <span>
          Ta fram din smartphone, öppna kameraappen och spela in dig själv i{' '}
          <strong>porträttläge</strong>.
        </span>
      ),
    },
    step1NoPortrait: {
      en: <span>Grab your smartphone, open the camera app, and record yourself.</span>,
      sv: <span>Ta fram din smartphone, öppna kameraappen och spela in dig själv.</span>,
    },
    step2: {
      en: (
        <span>
          After filming, come back to this page. Select the video you wish to upload by clicking on{' '}
          <em>'Select Video'</em>.
        </span>
      ),
      sv: (
        <span>
          Efter att du har filmat, återvänd till den här sidan. Välj den video du vill ladda upp
          genom att klicka på <em>'Välj Video'</em>.
        </span>
      ),
    },
    step3: {
      en: (
        <span>
          Simply check the box to agree to the terms, and hit <em>'Upload video'</em>.<br /> Great
          job – you've done it!
        </span>
      ),
      sv: (
        <span>
          Markera rutan för att godkänna villkoren och klicka på <em>'Ladda upp video'</em> <br />
          Bra jobbat – du har gjort det!
        </span>
      ),
    },
    needSomeTips: {
      en: (
        <span>
          Need help?{' '}
          <a
            href="https://www.lifeinside.io/help/upload-a-video-reply"
            target="_blank"
            rel="noreferrer"
          >
            Check our guide!
          </a>
        </span>
      ),
      sv: (
        <span>
          Inspelningstips?{' '}
          <a
            href="https://www.lifeinside.io/help/ladda-upp-ett-videosvar"
            target="_blank"
            rel="noreferrer"
          >
            Kolla vår guide!
          </a>
        </span>
      ),
    },
  },
  status: {
    SUGGESTION: {
      en: 'Draft',
      sv: 'Utkast',
    },
    PENDING: {
      en: 'Waiting for reply',
      sv: 'Väntar på svar',
    },
    REVIEW: {
      en: 'Review',
      sv: 'Granska',
    },
    APPROVED: {
      en: 'Ready to publish',
      sv: 'Redo att publicera',
    },
    LIVE: {
      en: 'Library',
      sv: 'Bibliotek',
    },
    LIVE_SELECTED: {
      en: 'Library & Selected',
      sv: 'Bibliotek & Valda',
    },
    ARCHIVED: {
      en: 'Archived',
      sv: 'Arkiverade',
    },
    UPLOADING_VIDEO: {
      en: 'Uploading video',
      sv: 'Laddar upp video',
    },
  },
  errors: {
    emailAlreadyExists: {
      en: 'This email is already in use',
      sv: 'Denna e-mail används redan',
    },
    NO_QUESTION: {
      en: 'You must enter a Question',
      sv: 'Du måste ange Fråga',
    },
    TEXT_TOO_LONG: {
      en: 'The text is too long',
      sv: 'Texten är för lång',
    },
    INVALID_EMAIL: {
      en: 'Invalid e-mail',
      sv: 'Ogiltig e-mail',
    },
    NO_RECIPIENTS: {
      en: 'Recipients missing',
      sv: 'Mottagare saknas',
    },
    NO_INTERNAL_NOTE: {
      en: 'You must enter a message',
      sv: 'Du måste ange ett meddelande',
    },
    NO_TAGS: {
      en: 'You must select at least one tag',
      sv: 'Du måste välja minst en tagg',
    },
    NO_ACTOR_NAME: {
      en: 'You must enter a name',
      sv: 'Du måste ange ett namn',
    },
    NO_ACTOR_TITLE: {
      en: 'You must enter a title',
      sv: 'Du måste ange en titel',
    },
    NO_VIDEO: {
      en: 'There is no video - contact support.',
      sv: 'Det finns ingen video - kontakta supporten.',
    },
    NO_UPLOAD_LINK: {
      en: 'Something went wrong with sending the email request - contact support.',
      sv: 'Något gick fel med att maila förfrågan - kontakta supporten',
    },
    INVALID_EMAIL_CONTACT_FORM: {
      en: 'Invalid e-mail in Contact Form',
      sv: 'Ogiltig e-mail i Kontakt Formulär',
    },
    INVALID_URL: {
      en: 'Invalid URL',
      sv: 'Ogiltig URL',
    },
    NO_CTA_MSG: {
      en: 'You must enter a CTA message',
      sv: 'Du måste ange ett CTA meddelande',
    },
    NO_CTA_BTN: {
      en: 'You must enter a Button text',
      sv: 'Du måste ange en Knapp text ',
    },
    NO_PHONE_NUMBER: {
      en: 'You must enter a phone number',
      sv: 'Du måste ange ett telefonnummer',
    },
    INVALID_PHONE_NUMBER: {
      en: 'You must enter a valid phone number',
      sv: 'Du måste ange ett giltig telefonnummer',
    },
    phoneNumber: {
      PHONE_NUMBER_ALREADY_EXISTS: {
        en: 'Phone number already exists in the organization',
        sv: 'Telefonnummer finns redan i organisationen',
      },
      INVALID_COUNTRY: {
        en: 'You must enter a valid country code',
        sv: 'Du måste ange en giltig landskod',
      },
      NOT_A_NUMBER: {
        en: 'You must enter a number',
        sv: 'Du måste ange ett nummer',
      },
      TOO_SHORT: {
        en: 'Phone number is too short',
        sv: 'Telefonnumret är för kort',
      },
      TOO_LONG: {
        en: 'Phone number is too long',
        sv: 'Telefonnumret är för långt',
      },
      INVALID_LENGTH: {
        en: 'Phone number is invalid length',
        sv: 'Telefonnumret är ogiltigt längd',
      },
      INVALID_PHONE_NUMBER: {
        en: 'Phone number is invalid',
        sv: 'Telefonnumret är ogiltigt',
      },
      NOT_POSSIBLE_PHONE_NUMBER: {
        en: 'Phone number is not possible',
        sv: 'Telefonnumret är inte möjligt',
      },
    },
  },
  eventTypesDesciptions: {
    CREATE_VIDEO_CARD: {
      en: 'Created video cards',
      sv: 'Skapade videokort',
    },
    APPROVED_VIDEO_CARD: {
      en: 'Approved video cards',
      sv: 'Godkända videokort',
    },
    REVIEW_VIDEO_CARD: {
      en: 'aa',
      sv: 'aa',
    },
    LIVE_VIDEO_CARD: {
      en: 'Published video cards',
      sv: 'Publicerade videokort',
    },
    ARCHIVED_VIDEO_CARD: {
      en: 'Archived video cards',
      sv: 'Arkiverade videokort',
    },
    DELETE_VIDEO_CARD: {
      en: 'Deleted video cards',
      sv: 'Borttagna videokort',
    },
    EDIT_VIDEO_CARD: {
      en: 'Edited video cards',
      sv: 'Redigerade videokort',
    },
  },
  statsTypesInfo: {
    LOADED: {
      en: 'The number of videos presented to candidates, including the videos candidates actively chosen to watch.',
      sv: 'Antal videos som presenterats för kandidater, inklusive de videos som kandidater aktivt valt att kolla på.',
    },
    PLAY: {
      en: 'The number of videos candidates have actively chosen to watch.',
      sv: 'Antal videos kandidater aktivt valt att kollat på.',
    },
    END: {
      en: 'The number of videos candidates have actively chosen to watch from start to finnish.',
      sv: 'Antal videos kandidater aktivt valt att kolla klart på, från början till slut.',
    },
    CLICK_NEXT: {
      en: 'The number of clicks on "Next"',
      sv: 'Antal klick på “Nästa”.',
    },
    REWIND: {
      en: 'The number of clicks to watch videos from the beginning.',
      sv: 'Antal klick för att spela video från början.',
    },
    CLICK_MODAL: {
      en: 'The number of clicks on “Meet our co-workers”, leading to the video library.',
      sv: ' Antal klick på “Träffa våra medarbetare” för att visa alla videos i biblioteket.',
    },
    PAUSE: {
      en: 'The number of times videos have been paused.',
      sv: 'Antal gånger samtliga videos pausats.',
    },
    CLICK_MINIMIZE: {
      en: 'The number of clicks to minimize the widget.',
      sv: 'Antal klick på minimera widget.',
    },
  },
  statsTypes: {
    LOADED: {
      en: 'Presented videos',
      sv: 'Presenterade videos',
    },
    PLAY: {
      en: 'Played videos',
      sv: 'Spelade videos',
    },
    PAUSE: {
      en: 'Paused videos',
      sv: 'Pausade videos',
    },
    END: {
      en: 'Completed videos',
      sv: 'Färdigspelade videos',
    },
    CLICK_MINIMIZE: {
      en: 'Minimize widget',
      sv: 'Widget minimerad',
    },
    REWIND: {
      en: 'Watch again',
      sv: 'Se igen',
    },
    CLICK_NEXT: {
      en: 'Show next',
      sv: 'Visa nästa',
    },
    CLICK_MODAL: {
      en: 'Open library',
      sv: 'Öppna biblioteket',
    },
  },
  myPage: {
    stats: {
      en: 'Statistics',
      sv: 'Statistik',
    },
    statsDescription: {
      en: 'Some data about what I have contributed to.',
      sv: 'Lite data om vad jag har bidragit med.',
    },
    aboutMe: {
      en: 'About me',
      sv: 'Om mig',
    },
    userId: {
      en: 'ID',
      sv: 'ID',
    },
    email: {
      en: 'Email',
      sv: 'Email',
    },
    name: {
      en: 'Name',
      sv: 'Namn',
    },
  },
  successFeedback: {
    REMINDER: {
      en: 'The reminder has been sent!',
      sv: 'Påminnelsen är skickad!',
    },
    PENDING: {
      en: 'Nice! Your video request is sent!',
      sv: 'Snyggt! Din videoförfrågan är skickad!',
    },
    APPROVED: {
      en: "Nice! You've approved the video, it's no ready to publish!",
      sv: 'Snyggt! Du har godkänt videon för publicering.',
    },
    LIVE: {
      en: 'Great! The video is approved and published to library!',
      sv: 'Grymt! Videon är godkänd och publicerad till biblioteket!',
    },
  },
  accountSettings: {
    resetPassword: {
      en: 'Reset password',
      sv: 'Återställ lösenord',
    },
    resetPasswordSuccess: {
      en: 'An email has been sent with information on how to reset your password',
      sv: 'Ett e-mail har skickats med information om hur du återställer ditt lösenord',
    },
  },
  emailPreference: {
    emailPreferenceHeader: {
      en: 'Email Preferences',
      sv: 'Email Inställningar',
    },
    updatePreferenceBtn: {
      en: 'Update Preferences',
      sv: 'Uppdatera Inställningar',
    },
    newsletterProductNews: {
      en: 'Newsletter & Product News',
      sv: 'Nyhetsbrev & Produktnyheter',
    },
    successUpdate: {
      en: 'Preferences Updated!',
      sv: 'Inställningar Uppdaterade',
    },
  },
  subtitleSuccess: {
    en: 'Request successfully sent!',
    sv: 'Förfrågan skickad!',
  },
  subtitleError: {
    en: 'This Video Subtitle already requested',
    sv: 'Denna video undertext har redan begärts',
  },
  subtitleBtn: {
    en: 'Subtitle orders',
    sv: 'Undertextbeställningar',
  },
  videoRequest: {
    editVideoRequest: {
      en: 'Edit video request',
      sv: 'Redigera videoförfrågan',
    },
    videoRequest: {
      en: 'Video request',
      sv: 'Videoförfrågan',
    },
    createNewRequest: {
      en: 'Create request',
      sv: 'Skapa förfrågan',
    },
    selectQuestion: {
      en: 'Select question(s)',
      sv: 'Välj fråga(or)',
    },
    questions: {
      en: 'Question(s)',
      sv: 'Fråga(or)',
    },
    publicVisibility: {
      en: 'Displayed on published video',
      sv: 'Visas på publicerad video',
    },
    questionPlaceholder: {
      en: 'Enter a question or select one from the list',
      sv: 'Ange en fråga eller välj en från listan',
    },
    recipient: {
      en: 'Recipient',
      sv: 'Mottagare',
    },
    jobTitle: {
      en: 'Title',
      sv: 'Titel',
    },
    name: {
      en: 'Name',
      sv: 'Namn',
    },
  },
};
