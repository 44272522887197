import { useMemo } from 'react';
import { gql } from '@apollo/client';

import { ApolloClient, useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { VIDEO_CARD_DETAILS_FRAGMENT } from '../../../fragments/videoCards';
import { VIDEO_FUNNEL_DETAILS_FRAGMENT } from '../../../fragments/videoFunnels';

const TODO_DETAILS_FRAGMENT = gql`
  fragment ToDoDetails on ToDo {
    id
    orgId
    userId
    meta {
      ids
      daysSinceThreshold
      urlsSuggestions {
        url
        count
      }
    }
    createdAt
    updatedAt
    action
    completed
  }
`;

type meResult = {
  me: {
    id: number;
    encodedId: string;
    firstName: string | null;
    lastName: string | null;
    superAdmin: boolean;
    email: string;
  } | null;
};

export class DashboardApi {
  private backOfficeClient: ApolloClient;

  constructor(backOfficeClient: ApolloClient) {
    this.backOfficeClient = backOfficeClient;
  }

  getUserData(encodedOrgId: string) {
    return this.backOfficeClient.query<meResult>({
      query: gql`
        query Me($encodedOrgId: String) {
          me(encodedOrgId: $encodedOrgId) {
            id
            encodedId
            firstName
            lastName
            superAdmin
            email
          }
        }
      `,
      variables: { encodedOrgId },
    });
  }

  getToDoViewCardStats(encodedOrgId: string) {
    return this.backOfficeClient.query({
      query: gql`
        ${TODO_DETAILS_FRAGMENT}
        query ToDoViewCardStats($encodedOrgId: String) {
          toDoViewCardStats(encodedOrgId: $encodedOrgId) {
            videosToBeReviewed
            toDos {
              ...ToDoDetails
            }
          }
        }
      `,
      variables: { encodedOrgId },
    });
  }

  dismissToDoItem(encodedOrgId: string, toDoId: number) {
    return this.backOfficeClient.mutate({
      mutation: gql`
        ${TODO_DETAILS_FRAGMENT}
        mutation DismissToDoItem($encodedOrgId: String!, $toDoId: Int!) {
          dismissToDoItem(encodedOrgId: $encodedOrgId, toDoId: $toDoId) {
            videosToBeReviewed
            toDos {
              ...ToDoDetails
            }
          }
        }
      `,
      variables: { encodedOrgId, toDoId },
    });
  }

  getDashboardData(encodedOrgId: string) {
    return this.backOfficeClient.query({
      query: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        ${VIDEO_FUNNEL_DETAILS_FRAGMENT}
        fragment stats on AnalyticsValue {
          current
          currentMonthDaily {
            date
            count
          }
        }
        query AllDashboardData($encodedOrgId: String) {
          allDashboardData(encodedOrgId: $encodedOrgId) {
            helloCardData {
              videosUploadedAllTime
              videosUploadedThisWeek
              videosUploadedThisYear
              videosUploadedThisMonth
              videosUploadedLastTwentyEightDays
            }
            popularVideosCardData {
              endDate
              startDate
              videoCards {
                ...VideoCardDetails
              }
            }
            popularVideoFunnelCardData {
              endDate
              startDate
              videoFunnel {
                ...VideoFunnelDetails
              }
            }
            dataCardsData {
              videosLive
              videosPlayed
              storylineLive
              videoFunnelLive
              endDate
              startDate
            }
            viewsChartCardData {
              endDate
              startDate
              analytics {
                presentedVideos {
                  ...stats
                }
                playedVideos {
                  ...stats
                }
                pausedVideos {
                  ...stats
                }
                completedVideos {
                  ...stats
                }
                minimizedWidget {
                  ...stats
                }
                watchAgain {
                  ...stats
                }
                showNext {
                  ...stats
                }
                openLibrary {
                  ...stats
                }
              }
            }
          }
        }
      `,
      variables: { encodedOrgId },
    });
  }
}

export const useDashboardApi = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new DashboardApi(backOfficeClient), [backOfficeClient]);
};
