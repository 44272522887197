import { Collapse } from '@mui/material';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';

import PropTypes from 'prop-types';
import { matchSorter } from 'match-sorter';
import { useEndScreensApi } from '../../../api/endScreens';
import EndScreenColumns from './EndScreenColumns';

import { storylineStatuses } from '../../../utils/utils';
import DataGridTable from '../../common/DataGridTable';

export function EndScreenTable({
  hoveredRow,
  setHoveredRow,
  setEditEndScreenDialog,
  setDeleteEndScreenDialog,
  checkboxSelection = false,
  searchField,
  allEndScreen,
  setAllEndScreens,
  setSelectedEndScreens,
  hasAccess = false,
}) {
  const endScreensApi = useEndScreensApi();
  const { basicOrgData, endScreens: endScreenContext } = useOutletContext();

  const saveEndScreens = useCallback((endScreens) => {
    endScreenContext.current = endScreens;
    setAllEndScreens(endScreens);
  }, []);

  useEffect(() => {
    if (allEndScreen?.length === 0 && endScreenContext.current?.length === 1) {
      saveEndScreens([]);
    } else {
      saveEndScreens(allEndScreen?.length > 0 ? allEndScreen : endScreenContext.current ?? []);
    }
  }, [allEndScreen]);

  const getEndScreens = useCallback(() => {
    endScreensApi
      .getAllEndScreens({
        encodedOrgId: basicOrgData.encodedId,
        orgId: basicOrgData.id,
        ...(checkboxSelection ? { statusToRead: [storylineStatuses.LIVE] } : null),
      })
      .then((response) => {
        saveEndScreens(response?.data?.getAllEndScreens ?? []);
      })
      .catch((error) => {});
  }, [basicOrgData.encodedId, basicOrgData.id, endScreensApi, saveEndScreens]);

  useEffect(() => {
    getEndScreens();
  }, [getEndScreens]);

  const endScreens = useMemo(() => {
    if (!searchField) {
      return [...(allEndScreen ?? [])]?.sort((a, b) =>
        a?.meta?.title?.localeCompare(b?.meta?.title),
      );
    }

    const keys = hasAccess
      ? [
          'meta.title',
          (item) => item.user?.firstName ?? '',
          (item) => item.user?.lastName ?? '',
          (item) => item.user?.email ?? '',
        ]
      : ['meta.title'];

    return matchSorter(allEndScreen ?? [], searchField, {
      keys,
      threshold: matchSorter.rankings.CONTAINS,
    });
  }, [searchField, allEndScreen, hasAccess]);

  const onMouseEnterRow = (event) => {
    const rowId = Number(event.currentTarget.dataset.id);
    const row = endScreens?.find((el) => el.id === rowId);
    setHoveredRow(row.id);
  };

  const onMouseLeaveRow = () => {
    setHoveredRow(null);
  };

  const columnsToUse = EndScreenColumns({
    hoveredRow,
    setEditEndScreenDialog,
    setDeleteEndScreenDialog,
    checkboxSelection,
    hasAccess,
  })?.filter((column) => {
    if (column.field === 'actions' && (!onMouseEnterRow || !onMouseLeaveRow)) {
      return false;
    }
    return true;
  });

  return (
    <TransitionGroup>
      <CSSTransition timeout={500} classNames="item">
        <Collapse timeout={500} in={!!endScreens}>
          <DataGridTable
            rows={endScreens}
            columns={columnsToUse}
            pageSize={12}
            initialState={{ pinnedColumns: { right: ['actions'] } }}
            slotProps={
              hoveredRow !== undefined && setHoveredRow && onMouseEnterRow && onMouseLeaveRow
                ? {
                    row: {
                      onMouseEnter: onMouseEnterRow,
                      onMouseLeave: onMouseLeaveRow,
                    },
                  }
                : null
            }
            checkboxSelection={checkboxSelection}
            disableRowSelectionOnClick={false}
            onRowSelectionModelChange={(newSelection) => {
              if (checkboxSelection && setSelectedEndScreens) {
                setSelectedEndScreens(allEndScreen?.filter((el) => newSelection.includes(el.id)));
              }
            }}
          />
        </Collapse>
      </CSSTransition>
    </TransitionGroup>
  );
}

PropTypes.EndScreenTable = {
  endScreens: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onMouseEnterRow: PropTypes.func,
  onMouseLeaveRow: PropTypes.func,
  checkboxSelection: PropTypes.bool,
  hasAccess: PropTypes.bool,
};

export default EndScreenTable;
