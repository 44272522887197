import { gql } from '@apollo/client';
import { VIDEO_FUNNEL_DETAILS_FRAGMENT } from 'src/fragments/videoFunnels';

export const FETCH_VIDEO_FUNNEL_QUERY = gql`
  ${VIDEO_FUNNEL_DETAILS_FRAGMENT}
  query FetchVideoFunnel($encodedOrgId: String!, $input: VideoFunnelInput) {
    fetchVideoFunnel(encodedOrgId: $encodedOrgId, input: $input) {
      ...VideoFunnelDetails
    }
  }
`;

export const CREATE_VIDEO_FUNNEL_MUTATION = gql`
  ${VIDEO_FUNNEL_DETAILS_FRAGMENT}
  mutation CreateVideoFunnel($encodedOrgId: String!, $input: VideoFunnelInput) {
    createVideoFunnel(encodedOrgId: $encodedOrgId, input: $input) {
      ...VideoFunnelDetails
    }
  }
`;

export const UPDATE_VIDEO_FUNNEL_MUTATION = gql`
  mutation UpdateVideoFunnel($encodedOrgId: String!, $input: VideoFunnelInput) {
    updateVideoFunnel(encodedOrgId: $encodedOrgId, input: $input) {
      statusMessage
    }
  }
`;

export const DELETE_VIDEO_FUNNEL_MUTATION = gql`
  mutation DeleteVideoFunnel($encodedOrgId: String!, $input: VideoFunnelInput) {
    deleteVideoFunnel(encodedOrgId: $encodedOrgId, input: $input) {
      statusMessage
    }
  }
`;
