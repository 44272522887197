import { useMutation } from '@apollo/client';
import { INVITE_USER } from './queries';

function useInviteUser() {
  const [inviteUser, { data, loading, error }] = useMutation(INVITE_USER);

  return {
    inviteUser,
    data,
    loading,
    error,
  };
}

export default useInviteUser;
