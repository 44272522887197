import React, { useState, useEffect } from 'react';
import { HexAlphaColorPicker, HexColorPicker } from 'react-colorful';
import { Box, Popover, ClickAwayListener } from '@mui/material';

interface ColorPreviewProps {
  color: string;
  onChange: (newColor: string) => void;
  allowAlpha?: boolean;
}

function ColorPreview({ color, onChange, allowAlpha = false }: ColorPreviewProps) {
  const [hexA, setHexA] = useState(color);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Sync state with the incoming color prop
  useEffect(() => {
    setHexA(color);
  }, [color]);

  const handleColorChange = (newColor: string) => {
    setHexA(newColor);
    onChange(newColor);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-picker-popover' : undefined;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <Box
        onClick={handleClick}
        sx={{
          width: 24,
          height: 24,
          borderRadius: '50%',
          backgroundColor: hexA,
          border: '1px solid #ccc',
          cursor: 'pointer',
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box p={2}>
            {allowAlpha ? (
              <HexAlphaColorPicker color={hexA} onChange={handleColorChange} />
            ) : (
              <HexColorPicker color={hexA} onChange={handleColorChange} />
            )}
          </Box>
        </ClickAwayListener>
      </Popover>
    </Box>
  );
}

export default ColorPreview;
