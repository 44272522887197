import { alpha, Box, Grid, LinearProgress, Skeleton, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { useLanguage } from '../../../../context/LanguageContext';
import translationsAnalytics from '../../../../utils/translationsAnalytics';
import { Question } from '../../VideoCollector/types';
import Error from './Error';

interface ChartRowProps {
  title: string;
  level: number;
  maxLevel: number;
  display: 'none' | 'stack-labels' | 'stack-yAxis' | 'row-labels';
  label: string | number;
  color: string;
  width: number;
  fullBar?: boolean;
}

interface TopChartProps {
  titleType: string;
  data: Array<{
    question: Question;
    title: string;
    stats: { playback: { impression: Array<{ timestamp: string; count: number }> } };
  }>;
  display: 'none' | 'stack-labels' | 'stack-yAxis' | 'row-labels';
  barColor: string;
  fullBar?: boolean;
}

function ChartRow({
  title,
  level,
  maxLevel,
  label,
  display = 'none',
  color,
  width,
  fullBar = false,
}: ChartRowProps) {
  const value = useMemo(() => {
    return maxLevel > 0 ? (level / maxLevel) * 100 : 0;
  }, [level, maxLevel]);

  return (
    <Stack sx={{ height: '45px' }}>
      {(display === 'stack-yAxis' || display === 'stack-labels') && (
        <Typography
          variant="titleMedium"
          sx={{
            textWrap: 'nowrap',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>
      )}
      <Grid sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
        {display === 'row-labels' && (
          <Typography
            variant="titleMedium"
            sx={{
              mr: 2,
              width: `${+width * 10}px`,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
        )}

        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            sx={{
              backgroundColor: fullBar ? alpha(color, 0.2) : 'transparent',
              borderRadius: '10px',
              height: '8px',
              '.MuiLinearProgress-barColorPrimary': {
                backgroundColor: color,
                borderRadius: '10px',
              },
            }}
            variant="determinate"
            value={value}
          />
        </Box>
        {(display === 'stack-labels' || display === 'row-labels') && (
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {label}
            </Typography>
          </Box>
        )}
      </Grid>
    </Stack>
  );
}

interface YAxisProps {
  min: number;
  max: number;
  steps: number;
}

function YAxis({ min, max, steps }: YAxisProps) {
  const safeSteps = steps > 1 ? steps : 2;

  const stepValue = (max - min) / (safeSteps - 1);

  const range = Array.from({ length: safeSteps }, (_, i) => {
    const value = min + i * stepValue;
    if (i !== 0 && i !== safeSteps - 1) {
      return Math.round(value / 5) * 5;
    }
    return value;
  });

  return (
    <Box display="flex" justifyContent="space-between" height="300px" sx={{ marginTop: 2 }}>
      {range.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={`y-axis-${index}`} variant="bodyMedium" color="text.secondary">
          {value.toFixed(0)}
        </Typography>
      ))}
    </Box>
  );
}

function TopChart({ data, titleType, display = 'none', barColor, fullBar = false }: TopChartProps) {
  const lang = useLanguage();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const translationTopCharts = translationsAnalytics.topCharts as {
    [titleType: string]: { en: string; sv: string };
  };

  const displayData = useMemo(() => {
    if (data) {
      const processedData = data
        ?.map((dataPoint) => {
          const title = dataPoint?.question?.[lang] || dataPoint?.title || '';
          return {
            dataPoint: title,
            value: dataPoint?.stats?.playback?.impression?.reduce((acc, cur) => acc + cur.count, 0),
          };
        })
        .sort((a, b) => b.value - a.value);

      setLoading(false);
      setError('');
      return processedData;
    }

    return [];
  }, [data, lang]);

  const layout = useMemo(() => {
    return displayData.reduce(
      (acc, curr) => {
        if (curr.value > acc.maxLevel) {
          acc.maxLevel = curr.value;
        }
        if (curr.dataPoint.length > acc.width) {
          acc.width = curr.dataPoint.length;
        }

        return acc;
      },
      { maxLevel: 0, width: 0 },
    );
  }, [displayData]);

  useEffect(() => {
    setLoading(true);
    setError('');

    const timer = setTimeout(() => {
      if (!displayData || displayData.length === 0) {
        setError(translationsAnalytics.topCharts.error?.[lang]);
      }
      setLoading(false);
    }, 10000);

    if (displayData.length > 0) {
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, [displayData, lang]);

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '8px',
      }}
    >
      {error && <Error error={error} />}

      <Stack spacing={2}>
        <Typography variant="headlineSmallBoldRecoleta" gutterBottom>
          {translationTopCharts?.[titleType]?.[lang]}
        </Typography>
        {loading ? (
          <Stack>
            <Skeleton height="26px" width="250px" animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton height="26px" width="250px" animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton height="26px" width="250px" animation="wave" />
            <Skeleton animation="wave" />
          </Stack>
        ) : (
          <Stack justifyContent="space-between">
            {displayData.map((d) => {
              return (
                <ChartRow
                  key={d.value}
                  title={d.dataPoint}
                  level={d.value}
                  maxLevel={layout.maxLevel}
                  label={d.value}
                  display={display}
                  color={barColor}
                  width={layout.width}
                  fullBar={fullBar}
                />
              );
            })}
            {!loading && display === 'stack-yAxis' && (
              <YAxis max={layout.maxLevel} min={0} steps={4} />
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default TopChart;
