/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

// GraphQL query
export const GET_ORG_USERS = gql`
  query GetOrgUsers($encodedOrgId: String!) {
    getOrgUsers(encodedOrgId: $encodedOrgId) {
      id
      email
      firstName
      lastName
      userPermissions {
        id
        name
        description
      }
    }
  }
`;

export const UPDATE_USER_PERMISSION = gql`
  mutation UpdateUserPermission(
    $encodedOrgId: String!
    $updateUserId: Int!
    $permission: PermissionType!
  ) {
    updateUserPermission(
      encodedOrgId: $encodedOrgId
      updateUserId: $updateUserId
      permission: $permission
    ) {
      id
      email
      firstName
      lastName
      userPermissions {
        id
        name
        description
        orgId
      }
    }
  }
`;

export const APPROVE_USER_PERMISSION = gql`
  mutation ApproveUserPermission($encodedOrgId: String!, $updateUserId: Int!) {
    approveUserPermission(encodedOrgId: $encodedOrgId, updateUserId: $updateUserId) {
      id
      email
      firstName
      lastName
      userPermissions {
        id
        name
        description
      }
    }
  }
`;

export const DECLINE_USER_PERMISSION = gql`
  mutation DeclineUserPermission($encodedOrgId: String!, $updateUserId: Int!) {
    declineUserPermission(encodedOrgId: $encodedOrgId, updateUserId: $updateUserId) {
      id
      email
      firstName
      lastName
      userPermissions {
        id
        name
        description
      }
    }
  }
`;

export const GET_ORG_DOMAIN = gql`
  query GetOrgDomainEnterpriseConnection($encodedOrgId: String!) {
    getOrgDomainEnterpriseConnection(encodedOrgId: $encodedOrgId) {
      connectionDomain
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser($encodedOrgId: String!, $email: String!) {
    inviteUser(encodedOrgId: $encodedOrgId, email: $email) {
      id
      email
      firstName
      lastName
      userPermissions {
        id
        name
        description
      }
    }
  }
`;
