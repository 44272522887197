import { gql, useMutation, useQuery } from '@apollo/client';
import { CopyAllOutlined, DeleteOutline } from '@mui/icons-material';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLanguage } from '../../../context/LanguageContext';
import { VIDEO_REQUEST_DETAILS_FRAGMENT } from '../../../fragments/videoRequests';
import { translations } from '../../../utils/translations';
import { isValidEmail } from '../../../utils/utils';
import ConfirmPopup from '../../common/ConfirmPopup';
import {
  BasicOrgData as OrgData,
  Question,
  Recipient,
  VideoRequest as VideoRequestType,
} from '../BackOfficeTemplate/types';
import Popup from '../Popup';
import RecipientInputField from '../Recipient/VideoCardRecipientInputField';
import QuestionInputField from '../VideoCardSettings/QuestionsInput/VideoQuestionInputField';
import ActorDetails from './ActorDetails';
import VideoRequestSendButtons from './VideoRequestSendButtons';

export interface DisabledButtons {
  reminder: boolean;
  saveAndSend: boolean;
  saveDraft: boolean;
  duplicate: boolean;
}

export interface ConfirmPopup {
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
}

interface VideoRequestProps {
  open: boolean;
  handleClose: () => void;
  setInfo: (info: string) => void;
  setError: (err: string | null) => void;
  item: VideoRequestType | undefined;
  orgData: OrgData;
  setAffected: (id: number) => void;
  suggestedQuestions: Question[];
  duplicateItem?: VideoRequestType;
  handleUpdateBoard: () => void;
  orgRecipients: Recipient[];
  setVideoRequest: (item: Partial<VideoRequestType>) => void;
  handleDuplicateItemClick: (duplicatedData: Partial<VideoRequestType>) => void;
}

const VIDEO_REQUEST_QUERY = gql`
  ${VIDEO_REQUEST_DETAILS_FRAGMENT}
  query VideoRequest($encodedOrgId: String!, $videoRequestId: Int!) {
    videoRequest(encodedOrgId: $encodedOrgId, id: $videoRequestId) {
      ...VideoRequestDetails
    }
  }
`;

const CREATE_VIDEO_REQUEST_MUTATION = gql`
  ${VIDEO_REQUEST_DETAILS_FRAGMENT}
  mutation createVideoRequest($encodedOrgId: String, $input: VideoRequestInput) {
    createVideoRequest(encodedOrgId: $encodedOrgId, input: $input) {
      ...VideoRequestDetails
    }
  }
`;

const UPDATE_VIDEO_REQUEST_MUTATION = gql`
  ${VIDEO_REQUEST_DETAILS_FRAGMENT}
  mutation updateVideoRequest($encodedOrgId: String, $input: VideoRequestInput) {
    updateVideoRequest(encodedOrgId: $encodedOrgId, input: $input) {
      ...VideoRequestDetails
    }
  }
`;

const DELETE_VIDEO_REQUEST_MUTATION = gql`
  mutation DeleteVideoRequest($encodedOrgId: String, $input: VideoRequestInput) {
    deleteVideoRequest(encodedOrgId: $encodedOrgId, input: $input) {
      statusMessage
    }
  }
`;

const SEND_REMINDER_VIDEO_REQUEST_MUTATION = gql`
  mutation SendVideoRequestReminderLink($encodedOrgId: String, $input: VideoRequestReminderInput) {
    sendVideoRequestReminderLink(encodedOrgId: $encodedOrgId, input: $input) {
      statusMessage
    }
  }
`;

function VideoRequest({
  open,
  handleClose,
  setInfo,
  setError,
  item,
  orgData,
  setAffected,
  suggestedQuestions,
  handleDuplicateItemClick,
  handleUpdateBoard,
  orgRecipients,
  setVideoRequest,
}: VideoRequestProps) {
  const lang = useLanguage();

  const {
    premiumFeatures: { textMessage, autoRequests },
  } = orgData;

  const INITIAL_STATE = {
    id: item?.id,
    internalNote: {
      en: item?.internalNote?.en ?? '',
      sv: item?.internalNote?.sv ?? '',
    },
    questions: item?.questions ?? [],
    question: { sv: '', en: '' },
    recipients: [],
    recipient: item?.recipient,
    status: item?.status ?? 'SUGGESTION',
  };

  const [tempQuestions, setTempQuestions] = useState<string[]>(
    INITIAL_STATE.questions.map((q) => q[lang]),
  );

  const [videoRequestData, setVideoRequestData] =
    useState<Partial<VideoRequestType>>(INITIAL_STATE);

  const [confirmPopData, setConfirmPopData] = useState<ConfirmPopup | null>(null);

  const {
    data: existingData,
    loading: loadingData,
    error: queryError,
  } = useQuery<{ videoRequest: VideoRequestType }>(VIDEO_REQUEST_QUERY, {
    variables: { encodedOrgId: orgData.encodedId, videoRequestId: item?.id },
    skip: !item?.id,
  });

  const [createVideoRequest, { loading: createLoading, error: createError }] = useMutation(
    CREATE_VIDEO_REQUEST_MUTATION,
    {
      onCompleted: () => setInfo('Request has been updated'),
      onError: (err) => setError(`Error updating: ${err.message}`),
    },
  );

  const [editVideoRequest, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_VIDEO_REQUEST_MUTATION,
    {
      onCompleted: () => setInfo('Request has been updated'),
      onError: (err) => setError(`Error updating: ${err.message}`),
    },
  );
  const [deletedVideoRequest, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_VIDEO_REQUEST_MUTATION,
    {
      onCompleted: () => setInfo('Request has been deleted'),
      onError: (err) => setError(`Error deleting request: ${err.message}`),
    },
  );

  const [sendVideoRequestReminderLink, { loading: sendReminderLoading, error: reminderError }] =
    useMutation(SEND_REMINDER_VIDEO_REQUEST_MUTATION, {
      onCompleted: () => setInfo('Reminder has been sent'),
      onError: (err) => setError(`Error sending reminder: ${err.message}`),
    });

  const recipients = useMemo(() => {
    switch (videoRequestData.status) {
      case 'SUGGESTION':
        return existingData?.videoRequest?.recipients
          ? existingData?.videoRequest?.recipients
          : item?.recipients ?? [];

      case 'PENDING':
        return existingData?.videoRequest?.recipient
          ? [existingData?.videoRequest?.recipient]
          : (item?.recipient && [item?.recipient]) ?? [];

      default:
        return [];
    }
  }, [
    videoRequestData.status,
    existingData?.videoRequest?.recipient,
    existingData?.videoRequest?.recipients,
    item?.recipient,
    item?.recipients,
  ]);

  useEffect(() => {
    if (existingData) {
      setVideoRequestData((prev) => ({
        ...prev,
        id: existingData?.videoRequest.id,
        status: existingData?.videoRequest.status,
        questions:
          existingData?.videoRequest?.question &&
          existingData?.videoRequest.questions.map((q) => {
            return {
              sv: q?.sv ?? '',
              en: q?.en ?? '',
            };
          }),
        question: {
          sv: '',
          en: '',
        },
        internalNote: {
          en: existingData?.videoRequest.internalNote?.en ?? '',
          sv: existingData?.videoRequest.internalNote?.sv ?? '',
        },
        recipients,
        recipient: existingData?.videoRequest?.recipient,
      }));
      setTempQuestions(existingData?.videoRequest.questions.map((q) => q[lang]) ?? []);
    }
  }, [existingData, item, lang, recipients]);

  useEffect(() => {
    setVideoRequestData((prev) => {
      return {
        ...prev,
        questions: tempQuestions.map((q) => {
          return {
            sv: q,
            en: q,
          };
        }),
      };
    });
  }, [tempQuestions]);

  useEffect(() => {
    const error = queryError ?? createError ?? updateError ?? deleteError ?? reminderError;
    if (error) {
      setError(error.message);
    } else {
      setError('');
    }
  }, [queryError, createError, updateError, deleteError, reminderError, setError]);

  const loading = useMemo(
    () => loadingData || createLoading || updateLoading || deleteLoading || sendReminderLoading,
    [loadingData, createLoading, updateLoading, deleteLoading, sendReminderLoading],
  );

  const validateInputs = (status: VideoRequestType['status']): void => {
    if (!videoRequestData.question || !videoRequestData?.questions) {
      throw new Error(translations.errors.NO_QUESTION[lang]);
    }
    if (videoRequestData.questions && videoRequestData.questions.length === 0) {
      throw new Error(translations.errors.NO_QUESTION[lang]);
    }
    switch (status) {
      case 'SUGGESTION':
        break;
      case 'PENDING':
        if (videoRequestData.recipient && !isValidEmail(videoRequestData.recipient.email)) {
          throw new Error(translations.errors.INVALID_EMAIL[lang]);
        }
        if (videoRequestData.recipients?.length === 0 && !videoRequestData.recipient) {
          throw new Error(translations.errors.NO_RECIPIENTS[lang]);
        }

        break;

      default:
        break;
    }
  };

  const closeDialog = () => {
    setVideoRequest(videoRequestData);
    setVideoRequestData(INITIAL_STATE);
    setTempQuestions([]);
    handleUpdateBoard();
    handleClose();
  };

  const saveVideoRequest = async ({
    newStatus,
    saveDraft,
    sendText = false,
  }: {
    newStatus: VideoRequestType['status'];
    saveDraft: boolean;
    sendText?: boolean;
  }) => {
    try {
      const recipientIds = videoRequestData.recipients
        ? videoRequestData.recipients.map((r) => r.id)
        : [];
      if (!saveDraft && recipientIds.length === 0) return;

      validateInputs(newStatus);

      if (!videoRequestData.id) {
        await createVideoRequest({
          variables: {
            encodedOrgId: orgData.encodedId,
            input: {
              status: newStatus,
              orgId: orgData.id,
              questions: videoRequestData.questions,
              internalNote: videoRequestData.internalNote,
              recipientIds,
              sendText,
            },
          },
        });
      } else {
        await editVideoRequest({
          variables: {
            encodedOrgId: orgData.encodedId,
            input: {
              status: newStatus,
              id: videoRequestData.id,
              orgId: orgData.id,
              questions: videoRequestData.questions,
              internalNote: videoRequestData.internalNote,
              recipientIds,
              sendText,
            },
          },
        });
      }

      if (!createError || !updateError) {
        if (videoRequestData.id) {
          setAffected(videoRequestData.id);
        }
        closeDialog();
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Failed to save video request');
      }
    }
  };

  const deleteVideoRequest = async () => {
    await deletedVideoRequest({
      variables: {
        encodedOrgId: orgData.encodedId,
        input: {
          id: videoRequestData.id,
          orgId: orgData.id,
        },
      },
    });

    if (!deleteError) {
      if (videoRequestData.id) {
        setAffected(videoRequestData.id);
      }
      closeDialog();
    }
  };

  const sendReminder = async ({
    sendTextReminder,
    sendEmailReminder,
  }: {
    sendTextReminder: boolean;
    sendEmailReminder: boolean;
  }) => {
    if (!videoRequestData.recipient) return;
    if (!videoRequestData.id) return;

    await sendVideoRequestReminderLink({
      variables: {
        encodedOrgId: orgData.encodedId,
        input: {
          videoRequestId: videoRequestData.id,
          orgId: orgData.id,
          sendTextReminder,
          sendEmailReminder,
        },
      },
    });

    if (!reminderError) {
      if (videoRequestData.id) {
        setAffected(videoRequestData.id);
      }
      closeDialog();
    }
  };

  const deleteItemConfirm = () => {
    setConfirmPopData({
      title: translations.videoCard.deleteItem[lang],
      description: translations.videoCard.areYouSureDelete[lang],
      onCancel: () => setConfirmPopData(null),
      onConfirm: deleteVideoRequest,
    });
  };

  const disabledButtons: DisabledButtons = useMemo(() => {
    let buttons = {
      reminder: false,
      saveDraft: false,
      duplicate: false,
      saveAndSend: false,
    };

    if (videoRequestData.questions?.length === 0) {
      buttons = { ...buttons, saveDraft: true };
    }
    if (
      !videoRequestData.recipients ||
      videoRequestData.recipients.length === 0 ||
      videoRequestData.questions?.length === 0
    ) {
      buttons = { ...buttons, saveAndSend: true };
    }

    if (videoRequestData.status === 'PENDING' && !isValidEmail(videoRequestData.recipient?.email)) {
      buttons = { ...buttons, saveAndSend: true };
    }
    if (
      videoRequestData.status === 'SUGGESTION' &&
      videoRequestData?.recipients &&
      videoRequestData.recipients.some((r) => !isValidEmail(r.email))
    ) {
      buttons = { ...buttons, saveAndSend: true };
    }

    if (!videoRequestData.recipients || videoRequestData.recipients.length === 0) {
      buttons = { ...buttons, reminder: true };
    }
    if (!videoRequestData.id) {
      buttons = { ...buttons, duplicate: true };
    }

    return buttons;
  }, [videoRequestData]);

  const dirtyInputs = () => {
    const prevRecipients =
      videoRequestData?.status === 'SUGGESTION'
        ? item?.recipients?.map((r) => r.email) ?? []
        : [item?.recipient];

    const prevState = {
      internalNote: item?.internalNote ? item.internalNote[lang] : '',
      recipients: prevRecipients,
      questions: item?.questions
        ? item.questions.map((q) => {
            return { [lang]: q[lang] };
          })
        : [],
    };

    const currRecipients =
      videoRequestData.status === 'SUGGESTION' && videoRequestData?.recipients
        ? videoRequestData.recipients.map((r) => r.email) ?? []
        : [videoRequestData?.recipient];

    const currState = {
      internalNote: videoRequestData?.internalNote ? videoRequestData.internalNote[lang] : '',
      recipients: currRecipients,
      questions: videoRequestData?.questions
        ? videoRequestData.questions.map((q) => {
            return { [lang]: q[lang] };
          })
        : [],
    };

    if (JSON.stringify(prevState) !== JSON.stringify(currState)) {
      return true;
    }

    return false;
  };

  const dirtyInputsConfirm = () => {
    if (!dirtyInputs()) {
      closeDialog();
      return;
    }

    setConfirmPopData({
      title: translations.videoCard.closeBeforeSave[lang],
      description: translations.videoCard.areYouSureClose[lang],
      onCancel: () => setConfirmPopData(null),
      onConfirm: () => {
        handleClose();
        setConfirmPopData(null);
      },
    });
  };

  return (
    <Popup
      open={open}
      handleClose={() => dirtyInputsConfirm()}
      title={translations.videoRequest.videoRequest[lang]}
      actions={
        <Box display="flex" gap={2}>
          <Button
            variant="text"
            sx={{ display: 'flex', gap: 1 }}
            onClick={() => {
              if (videoRequestData.id) {
                deleteItemConfirm();
              } else {
                handleClose();
              }
            }}
            disabled={loading}
          >
            <DeleteOutline /> {translations.videoCard.delete[lang]}
          </Button>

          {videoRequestData?.id && (
            <Button
              variant="text"
              disabled={loading || disabledButtons.duplicate}
              onClick={() => {
                const { internalNote, question, questions } = videoRequestData;
                handleDuplicateItemClick({
                  internalNote,
                  question,
                  questions,
                  status: 'SUGGESTION',
                });
              }}
            >
              <CopyAllOutlined /> {translations.videoCard.duplicateItem[lang]}
            </Button>
          )}

          {videoRequestData.status === 'SUGGESTION' && (
            <Button
              variant="text"
              onClick={() => {
                saveVideoRequest({
                  newStatus: 'SUGGESTION',
                  saveDraft: true,
                });
              }}
              disabled={loading || disabledButtons.saveDraft}
            >
              {translations.videoCard.saveDraft[lang]}
            </Button>
          )}

          {videoRequestData.status && (
            <VideoRequestSendButtons
              disabledButtons={disabledButtons}
              loading={loading}
              saveVideoRequest={saveVideoRequest}
              sendReminder={sendReminder}
              status={videoRequestData.status}
              textMessageAllowed={textMessage}
            />
          )}
        </Box>
      }
    >
      <Stack height="100%" spacing={2} sx={{ overflow: 'hidden' }}>
        {videoRequestData.status === 'SUGGESTION' && (
          <QuestionInputField
            questions={tempQuestions}
            setQuestions={(val) => {
              setTempQuestions(
                val.reduce((acc: string[], curr: string | { question: string }) => {
                  if (typeof curr === 'string') {
                    return [...acc, curr];
                  }
                  return [...acc, curr.question];
                }, []),
              );
            }}
            question={videoRequestData?.question && videoRequestData.question[lang]}
            orgQuestions={suggestedQuestions}
            validateQuestion={(newInputValue: string) => {
              setVideoRequestData({
                ...videoRequestData,
                ...(videoRequestData?.question
                  ? {
                      question: {
                        ...videoRequestData.question,
                        sv: newInputValue,
                        en: newInputValue,
                      },
                    }
                  : {}),
              });
            }}
          />
        )}
        {videoRequestData.status === 'SUGGESTION' && (
          <RecipientInputField
            videoCard={videoRequestData}
            recipients={videoRequestData?.recipients}
            setRecipients={(rpts: Recipient[]) => {
              setVideoRequestData({ ...videoRequestData, recipients: rpts });
            }}
            orgRecipients={orgRecipients}
            textMessageAllowed={textMessage}
            basicOrgData={orgData}
            hasAutoRequest={autoRequests}
            internalNote={videoRequestData?.internalNote ? videoRequestData.internalNote[lang] : ''}
            setInternalNote={(value: string) =>
              setVideoRequestData({
                ...videoRequestData,
                ...(videoRequestData?.internalNote
                  ? { internalNote: { ...videoRequestData.internalNote, en: value, sv: value } }
                  : { internalNote: { en: '', sv: '' } }),
              })
            }
          />
        )}
        {videoRequestData.status === 'PENDING' && (
          <Stack spacing={1}>
            <Typography variant="titleMedium" className="">
              {translations.videoRequest.questions[lang]}
            </Typography>
            <Box display="flex" gap={2} flexWrap="wrap" maxWidth="500px">
              {videoRequestData.questions?.map((q) => {
                return <Chip label={q[lang]} />;
              })}
            </Box>
          </Stack>
        )}

        {videoRequestData.status === 'PENDING' && (
          <ActorDetails
            recipient={videoRequestData?.recipient}
            message={videoRequestData?.internalNote && videoRequestData.internalNote[lang]}
          />
        )}
      </Stack>
      {confirmPopData && <ConfirmPopup {...confirmPopData} open={!!confirmPopData} />}
    </Popup>
  );
}

export default VideoRequest;
