import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button } from '@mui/material';
import { gql } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';

import { useAdminApolloClient } from 'src/context/ApolloClientContext';
import './editSuggestedQuestions.scss';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { isValidEmail } from '../../utils/utils';

const updateMonthlyReportSettings = (adminClient, sendMonthlyReport) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        updateMonthlyReportSettings(sendMonthlyReport: ${sendMonthlyReport}) {
          statusMessage
          events {
            id
            orgId
            eventTime
            success
            userId
          }
          organizations {
            id
            orgName
          }
          monthlyReportSettings {
            sendMonthlyReport
          }
        }
      }
    `,
  });
};

const getAllMonthlyEvents = (adminClient) => {
  return adminClient.query({
    query: gql`
      query {
        monthlyReportEvents {
          events {
            id
            orgId
            eventTime
            success
            userId
          }
          organizations {
            id
            orgName
          }
          monthlyReportSettings {
            sendMonthlyReport
          }
        }
      }
    `,
  });
};

const resendMonthlyReport = (adminClient) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        resendMonthlyReport {
          events {
            id
            orgId
            eventTime
            success
            userId
          }
          organizations {
            id
            orgName
          }
          monthlyReportSettings {
            sendMonthlyReport
          }
        }
      }
    `,
  });
};

const sendTestMonthly = (adminClient, orgId, email) => {
  return adminClient.mutate({
    mutation: gql`
          mutation {
            sendTestMonthlyReport(orgId: ${orgId}, email: "${email}"){
              statusMessage
            }
          }
        `,
  });
};

export default function MonthlyReport() {
  const adminClient = useAdminApolloClient();
  const [events, setEvents] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [org, setOrg] = useState('');
  const [selectedOrg, setSelectedOrg] = useState('');
  const [testEmail, setTestEmail] = useState('');
  const [sendMonthlyReport, setSendMonthlyReport] = useState(false);
  const [orgIdToNameMap, setOrgIdToNameMap] = useState({});
  const [rows, setRows] = useState([]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'orgId',
      headerName: 'Organization',
      width: 200,
      valueGetter: (params) => orgIdToNameMap[params] || params,
    },
    {
      field: 'eventTime',
      headerName: 'Event Time',
      width: 300,
      valueFormatter: (params) => {
        const date = new Date(Number(params));
        return `${date.toDateString()} ${date.toLocaleTimeString()}`;
      },
    },
    {
      field: 'success',
      headerName: 'Success',
      width: 120,
      valueFormatter: (params) => (params ? 'Yes' : 'No'),
    },
    { field: 'userId', headerName: 'User ID', width: 150 },
  ];

  useEffect(() => {
    getAllMonthlyEvents(adminClient).then((res) => {
      const { monthlyReportEvents } = res.data;

      if (monthlyReportEvents?.organizations && monthlyReportEvents?.organizations?.length > 0) {
        const orgMap = {};
        monthlyReportEvents.organizations.forEach((org) => {
          orgMap[org.id] = org.orgName;
        });
        setOrgIdToNameMap(orgMap);
        setOrganizations(_.sortBy(monthlyReportEvents.organizations, 'orgName'));
      } else {
        setOrganizations([]);
        setOrgIdToNameMap({});
      }

      if (monthlyReportEvents?.events && monthlyReportEvents?.events?.length > 0) {
        setEvents([...monthlyReportEvents.events]);
        const formattedRows = monthlyReportEvents.events.map((event) => ({
          id: event.id,
          orgId: event.orgId,
          eventTime: event.eventTime,
          success: event.success,
          userId: event.userId,
        }));
        setRows(formattedRows);
      } else {
        setEvents([]);
        setRows([]);
      }

      setSendMonthlyReport(monthlyReportEvents?.monthlyReportSettings?.sendMonthlyReport);
    });
  }, [adminClient]);

  const handleResendMonthlyReport = () => {
    resendMonthlyReport(adminClient)
      .then((res) => {
        const { resendMonthlyReport } = res.data;
        alert('Successfully resent monthly report');

        if (resendMonthlyReport?.organizations && resendMonthlyReport?.organizations?.length > 0) {
          const orgMap = {};
          resendMonthlyReport.organizations.forEach((org) => {
            orgMap[org.id] = org.orgName;
          });
          setOrgIdToNameMap(orgMap);
          setOrganizations(_.sortBy(resendMonthlyReport.organizations, 'orgName'));
        } else {
          setOrganizations([]);
          setOrgIdToNameMap({});
        }

        if (resendMonthlyReport?.events && resendMonthlyReport?.events?.length > 0) {
          setEvents([...resendMonthlyReport.events]);
          const formattedRows = resendMonthlyReport.events.map((event) => ({
            id: event.id,
            orgId: event.orgId,
            eventTime: event.eventTime,
            success: event.success,
            userId: event.userId,
          }));
          setRows(formattedRows);
        } else {
          setEvents([]);
          setRows([]);
        }

        setSendMonthlyReport(resendMonthlyReport?.monthlyReportSettings?.sendMonthlyReport);
      })
      .catch((err) => {
        alert('Error', err);
      });
  };

  const handleSendTestMonthly = () => {
    if (!(testEmail && isValidEmail(testEmail))) {
      alert('Please enter a valid email');
    } else if (!selectedOrg) {
      alert('Please select an organization');
    } else {
      sendTestMonthly(adminClient, selectedOrg.id, testEmail)
        .then(() => {
          alert('Success');
        })
        .catch((err) => {
          alert('FAILED ', err);
        });
    }
  };

  const saveChanges = () => {
    updateMonthlyReportSettings(adminClient, sendMonthlyReport)
      .then((res) => {
        const { updateMonthlyReportSettings } = res.data;

        if (
          updateMonthlyReportSettings?.organizations &&
          updateMonthlyReportSettings?.organizations?.length > 0
        ) {
          setOrganizations(_.sortBy(updateMonthlyReportSettings.organizations, 'orgName'));
        } else {
          setOrganizations([]);
        }

        if (
          updateMonthlyReportSettings?.events &&
          updateMonthlyReportSettings?.events?.length > 0
        ) {
          setEvents([...updateMonthlyReportSettings.events]);
          const formattedRows = updateMonthlyReportSettings.events.map((event) => ({
            id: event.id,
            orgId: event.orgId,
            eventTime: event.eventTime,
            success: event.success,
            userId: event.userId,
          }));
          setRows(formattedRows);
        } else {
          setEvents([]);
          setRows([]);
        }

        setSendMonthlyReport(updateMonthlyReportSettings?.monthlyReportSettings?.sendMonthlyReport);

        if (updateMonthlyReportSettings?.statusMessage === 'SUCCESS') {
          alert('Saved!');
        } else {
          alert(updateMonthlyReportSettings?.statusMessage);
        }
      })
      .catch((e) => {
        alert(`Something went wrong ${e.message}`);
      });
  };

  return (
    <div>
      <div className="topView">
        <fieldset style={{ marginBottom: '40px' }}>
          <h4>Monthly Report Settings</h4>
          <div className="create-new-page-inner" style={{ marginBottom: '10px' }}>
            <label>
              <input
                type="checkbox"
                checked={sendMonthlyReport}
                onChange={() => setSendMonthlyReport(!sendMonthlyReport)}
              />
              Send Monthly Report Each Month (1st of the month 8am GMT+1)
            </label>
          </div>
          <div>
            <Button onClick={saveChanges} type="submit" variant="contained" disableElevation>
              Save Monthly Settings
            </Button>
          </div>
        </fieldset>
        <fieldset>
          <Autocomplete
            options={organizations}
            onChange={(event, newValue) => {
              setOrg(newValue?.orgName || '');
              setSelectedOrg(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setOrg(newInputValue || '');
            }}
            inputValue={org}
            getOptionLabel={(option) => option?.orgName || ''}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input type="text" value={org} name="org" {...params.inputProps} />
              </div>
            )}
          />

          <input
            type="email"
            name="email"
            value={testEmail}
            onChange={(e) => setTestEmail(e.target.value)}
            placeholder="example@oddwork.com"
          />
          <Button
            onClick={handleSendTestMonthly}
            type="submit"
            variant="contained"
            disableElevation
          >
            <i className="icon">mail</i> Send test email
          </Button>
        </fieldset>
      </div>
      <div className="list-view">
        <div style={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
          />
        </div>
      </div>
      <Button onClick={handleResendMonthlyReport} variant="contained" disableElevation>
        <i className="icon">mail</i> Re-send Monthly Report
      </Button>
    </div>
  );
}
