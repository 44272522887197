import { gql } from '@apollo/client';

export const UPDATE_ORG_SETTINGS_STYLE = gql`
  mutation UpdateOrgSettingsStyle($encodedOrgId: String!, $input: OrganizationInput!) {
    updateOrgSettingsStyle(encodedOrgId: $encodedOrgId, input: $input) {
      statusMessage
    }
  }
`;

export const UPDATE_ORG_LOGO = gql`
  mutation UpdateOrgLogo($encodedOrgId: String!, $file: Upload!) {
    updateOrgLogo(encodedOrgId: $encodedOrgId, file: $file) {
      logoPath
    }
  }
`;
