import AddIcon from '@mui/icons-material/Add';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import { map } from 'lodash';
import { matchSorter, rankings } from 'match-sorter';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import LazyLoad from 'react-lazy-load';

import { useLanguage } from 'src/context/LanguageContext';
import { mediaUploadPath } from '../../utils/environment';
import { translations } from '../../utils/translations';
import { getDefaultState, statuses } from '../../utils/utils';
import FilterDrawer from '../common/FilterDrawer';
import UploadVideoDialog from './UploadVideoDialog';
import VideoCardSettings from './VideoCardSettings';
import VideoCardStory from './VideoCardStory';
import VideoRequest from './VideoRequest/VideoRequest';
import VideoRequestStory from './VideoRequestStory';
import { useActions } from './actions';
import './back-office.scss';

import ThemeContext from '../../utils/ThemeContext';
import ConfirmPopup from '../common/ConfirmPopup';
import DraftOutlinedIcon from '../common/icons/draft/DraftOutlined';
import LibraryOutlinedIcon from '../common/icons/library/LibraryOutlined';
import ReadyToPublishOutlinedIcon from '../common/icons/readyToPublish/ReadyToPublishOutlined';
import ReviewOutlinedIcon from '../common/icons/review/ReviewOutlined';
import WaitingForReplyOutlinedIcon from '../common/icons/waitingForReply/WaitingForReplyOutlined';

const titleIcons = (st, fill) => {
  if (st === statuses.SUGGESTION) {
    return <DraftOutlinedIcon fill={fill} />;
  }
  if (st === statuses.PENDING) {
    return <WaitingForReplyOutlinedIcon fill={fill} />;
  }

  if (st === statuses.REVIEW) {
    return <ReviewOutlinedIcon fill={fill} />;
  }

  if (st === statuses.APPROVED) {
    return <ReadyToPublishOutlinedIcon fill={fill} />;
  }

  if (st === statuses.LIVE) {
    return <LibraryOutlinedIcon fill={fill} />;
  }

  return null;
};

export default function ProductionBoard({
  videoCards,
  setVideoCards,
  videoRequests,
  orgData,
  suggestedQuestions,
  users,
  handleUpdateBoard,
  orgRecipients,
  updateVideoCard,
  updateVideoRequest,
}) {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);
  const actions = useActions();

  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [createUploadNewIsOpen, setCreateUploadNewIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [createNewVideoRequestIsOpen, setCreateNewVideoRequestIsOpen] = useState(false);

  const [showArchived, setShowArchived] = useState(false);
  const [affectedItem, setAffectedItem] = useState(null);
  const [duplicateItem, setDuplicateItem] = useState(null);
  const [duplicateRequestItem, setDuplicateRequestItem] = useState(null);
  const [info, setInfo] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [searchField, setSearchField] = useState('');
  const [selectLocation, setSelectLocation] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState([]);
  const [selectTheme, setSelectTheme] = useState([]);
  const [selectedUser, setSelectedUser] = useState(getDefaultState('user', lang));

  const [uploadComplete, setUploadComplete] = useState(false);
  const [error, setError] = useState(null);
  const [tagsToFilter, setTagsToFilter] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [isApply, setIsApply] = useState(false);

  const [newTagName, setNewTagName] = useState('');
  const [confirmPopData, setConfirmPopData] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [removeFilter, setRemoveFilter] = useState(null);

  const handleDrawerToggle = (status) => {
    setIsDrawerOpen(status);
    setDrawer({ ...drawer, right: status });
  };

  const handleUpdateFilterState = (type, data) => {
    const {
      tagsToFilter: _tagsToFilter,
      location,
      department,
      theme,
      updateuser,
      archive,
      delTag,
    } = data;
    setRemoveFilter(null);
    switch (type) {
      case 'TAGS_TO_FILTER':
        setTagsToFilter(_tagsToFilter);
        break;
      case 'LOC_DEP_THE':
        setSelectLocation(location);
        setSelectDepartment(department);
        setSelectTheme(theme);
        break;
      case 'UPDATE_USER':
        setSelectedUser(updateuser);
        break;
      case 'SHOW_ARCHIVE':
        setShowArchived(archive);
        break;
      case 'LOCATION':
        setSelectLocation(location);
        break;
      case 'DEPARTMENT':
        setSelectDepartment(department);
        break;
      case 'THEME':
        setSelectTheme(theme);
        break;
      case 'DELETE_TAG':
        setConfirmPopData(delTag);
        break;
      case 'DELETE_LOC_CHIP':
        setTagsToFilter(_tagsToFilter);
        setSelectLocation(location);
        break;
      case 'DELETE_DEP_CHIP':
        setTagsToFilter(_tagsToFilter);
        setSelectDepartment(department);
        break;
      case 'DELETE_THEME_CHIP':
        setTagsToFilter(_tagsToFilter);
        setSelectTheme(theme);
        break;
      case 'DELETE_USER_CHIP':
        setSelectedUser(updateuser);
        break;
      default:
        break;
    }
  };

  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const checkCounts = (arg) => {
    if (arg.length > 2) {
      const len = arg.length - 2;
      const temp = arg.slice(0, 2).join(', ');
      return `${temp} +${len}`;
    }
    if (arg.length) {
      return arg.join(', ');
    }
    return null;
  };

  const handleDeleteChip = (type) => {
    switch (type) {
      case 'location': {
        setRemoveFilter({ type: 'location' });
        break;
      }
      case 'department': {
        setRemoveFilter({ type: 'department' });
        break;
      }
      case 'theme': {
        setRemoveFilter({ type: 'theme' });
        break;
      }
      case 'user':
        setRemoveFilter({ type: 'user' });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (drawer.right) {
      setSelectLocation(selectLocation.filter((item) => tagsToFilter.includes(item.id)));
      setSelectDepartment(selectDepartment.filter((item) => tagsToFilter.includes(item.id)));
      setSelectTheme(selectTheme.filter((item) => tagsToFilter.includes(item.id)));
    }
  }, [drawer]);

  useEffect(() => {
    if (
      selectLocation?.length > 0 ||
      selectDepartment?.length > 0 ||
      selectTheme?.length > 0 ||
      showArchived !== false ||
      selectedUser?.value !== 'ALL_USERS' ||
      tagsToFilter?.length > 0
    ) {
      setIsApply(true);
    } else {
      setIsApply(false);
    }
  }, [selectLocation, selectDepartment, selectTheme, selectedUser, showArchived]);

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 220,
      },
    },
  };

  useEffect(() => {
    if (info) {
      setTimeout(() => {
        setInfo(null);
      }, 4000);
    }
  }, [info]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 8000);
    }
  }, [error]);

  useEffect(() => {
    const newOptions = map(users.current, (user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }));
    setUserOptions([
      {
        value: 'ALL_USERS',
        label: `${translations.allUsers[lang]}`,
      },
      ...newOptions,
    ]);
  }, [users, lang]);

  const cardsByStatusColumn = useMemo(() => {
    const list = [
      ...(orgData?.premiumFeatures?.requestsLibraryBeta
        ? [statuses.SUGGESTION, statuses.PENDING]
        : [statuses.SUGGESTION, statuses.PENDING]),
      statuses.REVIEW,
      statuses.APPROVED,
      statuses.LIVE,
    ];
    if (showArchived) {
      list.push(statuses.ARCHIVED);
    }
    return list;
  }, [orgData, showArchived]);

  const VideoCardHasUser = (vc, userId) => {
    return userId === 'ALL_USERS' || (Number(userId) && vc?.user?.id === Number(userId));
  };

  const shouldIncludeInFilter = useCallback(
    (item) => {
      if (tagsToFilter?.length === 0) {
        return VideoCardHasUser(item, selectedUser?.value);
      }

      return (
        VideoCardHasUser(item, selectedUser?.value) &&
        tagsToFilter?.every((tag) => item?.tags?.some((t) => tag == t.id))
      );
    },
    [tagsToFilter, selectedUser],
  );

  const filteredCards = useMemo(() => {
    if (!searchField) {
      return [...videoCards, ...videoRequests]?.filter((item) => shouldIncludeInFilter(item));
    }
    const searchedCards = matchSorter([...videoCards, ...videoRequests], searchField, {
      keys: [
        `question.${lang}`,
        `internalNote.${lang}`,
        `questions.*.${lang}`,
        'actorName',
        'actorTitle',
        'actorEmail',
        'actorPhoneNumber',
        'recipient.name',
        'recipient.firstName',
        'recipient.lastName',
        'recipient.title',
        'recipient.email',
        'recipient.phoneNumber',
        'recipients.*.name',
        'recipients.*.firstName',
        'recipients.*.lastName',
        'recipients.*.title',
        'recipients.*.email',
        'recipients.*.phoneNumber',
        'user.firstName',
        'user.lastName',
        `tags.*.name.${lang}`,
      ],
      threshold: rankings.CONTAINS,
    });
    return searchedCards.filter((item) => shouldIncludeInFilter(item));
  }, [videoCards, videoRequests, shouldIncludeInFilter, searchField, lang]);

  const mappedAndFilteredCardsByStatus = useMemo(() => {
    return cardsByStatusColumn.reduce((acc, col) => {
      const column = typeof col === 'string' ? col : col;
      acc[column] = {
        count: filteredCards.filter((item) => item.status === column).length,
        items: filteredCards.filter((item) => item.status === column),
      };
      return acc;
    }, {});
  }, [cardsByStatusColumn, filteredCards]);

  const handleDuplicateItemClick = (item) => {
    const itemDuplicate = {
      hideFromLibrary: item?.hideFromLibrary ?? false,
      internalNote: item?.internalNote,
      questions: [{ sv: item?.question?.sv, en: item?.question?.en }],
      tags: item?.tags ?? [],
      status: statuses.SUGGESTION,
      interactionOption: item?.interactionOption ?? null,
    };
    setDuplicateRequestItem(itemDuplicate);
    setCreateNewVideoRequestIsOpen(true);
  };

  const handleDuplicateRequestItemClick = (item) => {
    const RequestItemDuplicate = {
      hideFromLibrary: item?.hideFromLibrary ?? false,
      internalNote: item?.internalNote,
      questions: item?.questions,
      tags: item?.tags ?? [],
      status: statuses.SUGGESTION,
      interactionOption: item?.interactionOption ?? null,
    };
    setDuplicateRequestItem(RequestItemDuplicate);
    setCreateNewVideoRequestIsOpen(false);
  };

  const createNewModal =
    createNewIsOpen && !duplicateItem && !createUploadNewIsOpen ? (
      <VideoCardSettings
        item={null}
        close={() => {
          setCreateNewIsOpen(false);
          setCreateUploadNewIsOpen(null);
          setDuplicateItem(null);
        }}
        orgData={orgData}
        setInfo={setInfo}
        setAffected={(id) => setAffectedItem(id)}
        suggestedQuestions={suggestedQuestions}
        duplicateItem={duplicateItem}
        handleUpdateBoard={(e) => handleUpdateBoard(e)}
        orgRecipients={orgRecipients}
      />
    ) : null;

  const createUploadNewModal =
    createUploadNewIsOpen && !createNewIsOpen && !duplicateItem ? (
      <VideoCardSettings
        item={createUploadNewIsOpen}
        close={() => {
          setCreateNewIsOpen(false);
          setCreateUploadNewIsOpen(null);
          setDuplicateItem(null);
        }}
        orgData={orgData}
        setInfo={setInfo}
        setAffected={(id) => setAffectedItem(id)}
        suggestedQuestions={suggestedQuestions}
        handleUpdateBoard={(e) => handleUpdateBoard(e)}
        orgRecipients={orgRecipients}
        directUpload
        uploadComplete={uploadComplete}
      />
    ) : null;

  const createDuplicateModal =
    createNewIsOpen && duplicateItem && !createUploadNewIsOpen ? (
      <VideoCardSettings
        item={duplicateItem}
        close={() => {
          setCreateNewIsOpen(false);
          setCreateUploadNewIsOpen(null);
          setDuplicateItem(null);
        }}
        orgData={orgData}
        setInfo={setInfo}
        setAffected={(id) => setAffectedItem(id)}
        suggestedQuestions={suggestedQuestions}
        duplicateItem={duplicateItem}
        handleUpdateBoard={(e) => handleUpdateBoard(e)}
        orgRecipients={orgRecipients}
      />
    ) : null;

  const fileInput = useRef();

  const prepareSubmitUpload = ({ acceptedUploadConsent, acceptedSoMeConsent }) => {
    const form = document.getElementById('upload-form-production-board');

    actions
      .createVideoCardAndPrepareVideoUpload(
        orgData.encodedId,
        lang,
        orgData.id,
        statuses.REVIEW,
        null,
        '',
        '',
        '',
        '',
        [],
        null,
        null,
        acceptedUploadConsent,
        acceptedSoMeConsent,
      )
      .then((result) => {
        const videoCardItem = result.data.createVideoCardAndPrepareVideoUpload.videoCard;
        const { token } = result.data.createVideoCardAndPrepareVideoUpload;

        if (token && videoCardItem) {
          const formUrl = `${mediaUploadPath}/${orgData.encodedId}-${
            videoCardItem.encodedId
          }?token=${encodeURIComponent(token)}&some_consent=${!!acceptedSoMeConsent}`;

          form.action = formUrl;

          setCreateUploadNewIsOpen(videoCardItem);
          const request = new XMLHttpRequest();

          request.open('POST', formUrl, true);

          request.onload = function () {
            if (request.status === 200) {
              setUploadComplete(true);
              handleUpdateBoard();
            }
          };

          request.onerror = (error) => {
            setCreateNewIsOpen(false);
            setCreateUploadNewIsOpen(null);
            setDuplicateItem(null);
            setError(translations.directVideoUploadError[lang]);
            setTimeout(() => {
              setError(null);
            }, 10000);

            actions
              .deleteVideoCard(orgData.encodedId, videoCardItem.id, orgData.id)
              .then((result) => {
                handleUpdateBoard();
              })
              .catch((error) => {
                handleUpdateBoard();
              });
          };

          request.send(new FormData(form));
        }
      })
      .catch((err) => {});
  };

  const infoMsg = <div className="info-pop">{info}</div>;
  const errorMsg = <div className="error-pop">{error}</div>;

  const handleClose = ({ acceptedUploadConsent, acceptedSoMeConsent }) => {
    if (acceptedUploadConsent) {
      prepareSubmitUpload({ acceptedUploadConsent, acceptedSoMeConsent });
    }
    setOpenDialog(false);
  };

  return (
    <div
      className="admin-library"
      style={{
        padding: '8px 24px 16px 20px',
      }}
    >
      {infoMsg}
      {errorMsg}
      {openDialog && (
        <UploadVideoDialog open={openDialog} onClose={handleClose} orgData={orgData} />
      )}
      <div className="board-header">
        <div>
          <Stack direction="row" spacing={2}>
            <Typography variant="headlineLargeBoldRecoleta">
              {translations.videoPlannerViewTitle[lang]}
            </Typography>

            <Button
              variant="text"
              disableElevation
              startIcon={<AddIcon />}
              onClick={() => setCreateNewVideoRequestIsOpen(true)}
            >
              {translations.newRequest[lang]}
            </Button>
            <Button
              variant="text"
              disableElevation
              startIcon={<FileUploadOutlinedIcon />}
              onClick={() => {
                fileInput.current.click();
              }}
            >
              {translations.newVideo[lang]}
            </Button>
          </Stack>
          <form
            method="POST"
            id="upload-form-production-board"
            action=""
            encType="multipart/form-data"
            hidden
            onSubmit={prepareSubmitUpload}
          >
            <input
              ref={fileInput}
              type="file"
              style={{ display: 'none' }}
              aria-label="Upload Video"
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenDialog(true);
              }}
              required
              accept="video/mp4,video/x-m4v,video/*"
              name="file_data"
              id="file"
              hidden
            />
          </form>
        </div>

        <div className="board-header-filters">
          <TextField
            fullWidth
            variant="outlined"
            placeholder=""
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            autoComplete="off"
            InputProps={{
              startAdornment: <SearchIcon />,
              style: {
                borderRadius: 50,
                paddingRight: 12,
                backgroundColor: theme.palette.background.paper,
              },
            }}
            name={`userInput_${Math.random().toString(36).substring(2, 9)}`} // Dynamic field name
            sx={{
              background: 'transparent',
              padding: '0',
              minWidth: '300px',
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="text"
              disableElevation
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => handleDrawerToggle(true)}
            >
              {translations.filter[lang]}
            </Button>

            {selectLocation?.filter(
              (item) => item !== 'create_tag' && tagsToFilter?.includes(item.id),
            )?.length > 0 && (
              <ListItem>
                <Chip
                  sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                  label={checkCounts(
                    selectLocation
                      ?.filter((item) => item !== 'create_tag' && tagsToFilter?.includes(item.id))
                      ?.map((item) => item.name[lang]),
                  )}
                  onDelete={() => handleDeleteChip('location')}
                />
              </ListItem>
            )}

            {selectDepartment?.filter(
              (item) => item !== 'create_tag' && tagsToFilter.includes(item.id),
            ).length > 0 && (
              <ListItem>
                <Chip
                  sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                  label={checkCounts(
                    selectDepartment
                      ?.filter((item) => item !== 'create_tag' && tagsToFilter?.includes(item.id))
                      ?.map((item) => item.name[lang]),
                  )}
                  onDelete={() => handleDeleteChip('department')}
                />
              </ListItem>
            )}

            {selectTheme?.filter((item) => item !== 'create_tag' && tagsToFilter?.includes(item.id))
              .length > 0 && (
              <ListItem>
                <Chip
                  sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                  label={checkCounts(
                    selectTheme
                      ?.filter((item) => item !== 'create_tag' && tagsToFilter?.includes(item.id))
                      ?.map((item) => item.name[lang]),
                  )}
                  onDelete={() => handleDeleteChip('theme')}
                />
              </ListItem>
            )}

            {selectedUser?.value !== 'ALL_USERS' && (
              <ListItem>
                <Chip
                  sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                  label={selectedUser?.label}
                  onDelete={() => handleDeleteChip('user')}
                />
              </ListItem>
            )}
          </div>

          {isDrawerOpen && (
            <FilterDrawer
              anchor="right"
              isOpen={isDrawerOpen}
              onClose={() => handleDrawerToggle(false)}
              orgData={orgData}
              handleUpdateBoard={handleUpdateBoard}
              users={users.current}
              handleUpdateFilterState={handleUpdateFilterState}
              removeFilter={removeFilter}
            />
          )}
        </div>
      </div>
      <div className={`production-board ${showArchived && 'show-archive'}`}>
        {cardsByStatusColumn?.map((col) => {
          const column = typeof col === 'string' ? col : [...col[0]];
          const { count, items } = mappedAndFilteredCardsByStatus[column];
          return (
            <div key={`${column}`} className={`column column-${column}`}>
              <div className="col-header">
                <b>
                  {titleIcons(column, theme.palette.primary.main)}
                  <Typography variant="titleMedium" sx={{ pl: 1 }}>
                    {translations.status[column][lang]}
                  </Typography>
                  <Typography variant="titleMedium" className="count" color="text.secondary">
                    {count}
                  </Typography>
                </b>
              </div>
              <div className="inner-column">
                {items.map((item) => {
                  return (
                    <LazyLoad key={`lazy-load-${item.id}`} offset={600} height={100}>
                      {item?.type === 'video_request' ? (
                        <VideoRequestStory
                          key={`video_request-${item.id}`}
                          item={item}
                          orgData={orgData}
                          setInfo={setInfo}
                          affectedId={affectedItem}
                          setAffectedItem={setAffectedItem}
                          suggestedQuestions={suggestedQuestions}
                          handleDuplicateItemClick={(itemToDuplicate) => {
                            handleDuplicateRequestItemClick(itemToDuplicate);
                          }}
                          handleUpdateBoard={(e) => {
                            handleUpdateBoard(e);
                          }}
                          orgRecipients={orgRecipients}
                          updateVideoRequest={(e) => updateVideoRequest(e)}
                          setError={(e) => {
                            setError(e);
                          }}
                        />
                      ) : (
                        <VideoCardStory
                          key={`video_card-${item.id}`}
                          item={item}
                          orgData={orgData}
                          setInfo={setInfo}
                          affectedId={affectedItem}
                          setAffectedItem={setAffectedItem}
                          suggestedQuestions={suggestedQuestions}
                          handleDuplicateItemClick={(itemToDuplicate) => {
                            handleDuplicateItemClick(itemToDuplicate);
                          }}
                          handleUpdateBoard={(e) => {
                            handleUpdateBoard(e);
                          }}
                          orgRecipients={orgRecipients}
                          updateVideoCard={(e) => {
                            updateVideoCard(e);
                          }}
                          setError={(e) => {
                            setError(e);
                          }}
                        />
                      )}
                    </LazyLoad>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {createNewVideoRequestIsOpen && (
        <VideoRequest
          item={undefined}
          open={createNewVideoRequestIsOpen}
          handleClose={() => setCreateNewVideoRequestIsOpen(false)}
          setInfo={setInfo}
          setError={setError}
          orgRecipients={orgRecipients}
          suggestedQuestions={suggestedQuestions}
          orgData={orgData}
          handleUpdateBoard={handleUpdateBoard}
          setAffected={(id) => setAffectedItem(id)}
          setVideoRequest={(newItem) => {
            updateVideoRequest({ ...newItem, lastPullFromDatabase: new Date() });
          }}
        />
      )}
      {duplicateRequestItem && (
        <VideoRequest
          item={duplicateRequestItem}
          open={!!duplicateRequestItem}
          handleClose={() => {
            setDuplicateRequestItem(null);
          }}
          setInfo={setInfo}
          setError={setError}
          orgRecipients={orgRecipients}
          suggestedQuestions={suggestedQuestions}
          orgData={orgData}
          handleUpdateBoard={handleUpdateBoard}
          setAffected={(id) => setAffectedItem(id)}
          setVideoRequest={(newItem) => {
            updateVideoRequest({ ...newItem, lastPullFromDatabase: new Date() });
          }}
        />
      )}
      {createNewModal}
      {createDuplicateModal}
      {createUploadNewModal}
      {confirmPopData && (
        <ConfirmPopup
          open={!!confirmPopData}
          title={confirmPopData.title}
          description={confirmPopData.description}
          onCancel={() => setConfirmPopData(null)}
          onConfirm={confirmPopData.onConfirm}
        />
      )}{' '}
    </div>
  );
}
