import { Close } from '@mui/icons-material';
import { Card, CardActions, CardContent, CardHeader, Dialog, IconButton } from '@mui/material';

interface VideoRequestProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
  actions: React.ReactNode;
}

function Popup({ open, handleClose, title, actions, children }: VideoRequestProps) {
  return (
    <Dialog
      maxWidth="md"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiPaper-root': {
          borderRadius: 2,
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          flexDirection: 'column',
          maxHeight: '80vh',
          borderRadius: '15px',
          backgroundColor: 'background.default',
          display: 'flex',
        }}
        style={{ position: 'relative' }}
      >
        <CardHeader
          action={
            <IconButton className="close-btn" sx={{ p: 0 }} onClick={handleClose}>
              <Close />
            </IconButton>
          }
          title={title}
          titleTypographyProps={{
            variant: 'headlineSmallBoldRecoleta',
          }}
          sx={{
            textAlign: 'left',
          }}
        />
        <CardContent sx={{ overflowY: 'auto', maxHeight: '80vh' }}>{children}</CardContent>
        <CardActions disableSpacing sx={{ mt: 'auto', display: 'flex', justifyContent: 'end' }}>
          {actions}
        </CardActions>
      </Card>
    </Dialog>
  );
}

export default Popup;
