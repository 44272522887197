/* eslint-disable */

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  // Select,
  // MenuItem,
  Collapse,
  Select,
  MenuItem,
  TextField,
  // TextField,
} from '@mui/material';
// import { uniqueId } from 'lodash';
// import { freemiumDefaultPackageSettings } from 'src/utils/utils';
// import { translationsStoryline } from 'src/utils/translationsStoryline';

import {
  PackageVariant,
  FreemiumFeatures as FreemiumFeaturesTypes,
  FreemiumTypeSettings,
  freemiumDefaultPackageSettings,
} from 'src/utils/freemiumSettings';
import translationsStoryline from 'src/utils/translationsStoryline';

interface FreemiumFeaturesProps {
  packageVariant: PackageVariant;
  freemiumFeatures: FreemiumFeaturesTypes;
  setFreemiumFeatures: (features: FreemiumFeaturesTypes) => void;
}

function FreemiumFeaturesCollapse({
  freemiumFeatures,
  setFreemiumFeatures,
  packageVariant,
}: FreemiumFeaturesProps) {
  const [expandFreemiumFeatures, setExpandFreemiumFeatures] = useState<boolean>(false);

  const handleSelectChange = (
    featureKey: keyof FreemiumFeaturesTypes,
    key: keyof FreemiumTypeSettings,
    value: string[],
  ) => {
    setFreemiumFeatures({
      ...freemiumFeatures,
      [featureKey]: {
        ...freemiumFeatures[featureKey],
        [key]: value,
      },
    });
  };

  const handleTextFieldChange = (
    featureKey: keyof FreemiumFeaturesTypes,
    key: keyof FreemiumTypeSettings,
    value: number,
  ) => {
    setFreemiumFeatures({
      ...freemiumFeatures,
      [featureKey]: {
        ...freemiumFeatures[featureKey],
        [key]: value,
      },
    });
  };

  const hasConditions = (key: keyof FreemiumTypeSettings) => key === 'conditions';

  return (
    <Collapse in={packageVariant === 'freemium'}>
      <Box
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: 'pink',
          borderRadius: '16px',
          p: 2,
        }}
      >
        <Typography variant="h6">
          Freemium Features (Limits):
          <Button
            sx={{ textTransform: 'none' }}
            disableRipple
            onClick={() => setExpandFreemiumFeatures(!expandFreemiumFeatures)}
          >
            {expandFreemiumFeatures ? 'Collapse' : 'Expand'}
          </Button>
        </Typography>

        <Collapse in={expandFreemiumFeatures}>
          <Box>
            {Object.keys(freemiumDefaultPackageSettings.freemiumFeatures).map((featureKey) => (
              <Box
                key={`freemiumFeatures-${featureKey}-container`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  backgroundColor: 'pink',
                  borderRadius: '16px',
                  p: 2,
                }}
              >
                <Typography variant="caption" key={`freemiumFeatures-${featureKey}-label`}>
                  {featureKey}:
                </Typography>
                {Object.keys(
                  freemiumDefaultPackageSettings.freemiumFeatures[
                    featureKey as keyof FreemiumFeaturesTypes
                  ],
                ).map((key) =>
                  key === 'conditions' ? (
                    <Select
                      key={`freemiumFeatures-${featureKey}-${key}`}
                      value={
                        Array.isArray(
                          freemiumFeatures?.[featureKey as keyof FreemiumFeaturesTypes]?.[
                            key as keyof FreemiumTypeSettings
                          ],
                        )
                          ? (
                              freemiumFeatures?.[featureKey as keyof FreemiumFeaturesTypes]?.[
                                key as keyof FreemiumTypeSettings
                              ] as string[]
                            )?.length > 0
                            ? freemiumFeatures?.[featureKey as keyof FreemiumFeaturesTypes]?.[
                                key as keyof FreemiumTypeSettings
                              ]
                            : freemiumDefaultPackageSettings.freemiumFeatures[
                                featureKey as keyof FreemiumFeaturesTypes
                              ][key]
                          : freemiumDefaultPackageSettings.freemiumFeatures[
                              featureKey as keyof FreemiumFeaturesTypes
                            ][key]
                      }
                      multiple
                      onChange={(e) => {
                        setFreemiumFeatures({
                          ...freemiumFeatures,
                          [featureKey]: {
                            ...freemiumFeatures[featureKey as keyof FreemiumFeaturesTypes],
                            [key]: e.target.value,
                          },
                        });
                      }}
                    >
                      {Object.keys(translationsStoryline.availableWhenConditions)?.map((option) => (
                        <MenuItem value={option} title={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <TextField
                      label={key}
                      key={`freemiumFeatures-${featureKey}-${key}`}
                      value={
                        !Number.isNaN(
                          freemiumFeatures?.[featureKey as keyof FreemiumFeaturesTypes]?.[
                            key as keyof FreemiumTypeSettings
                          ],
                        ) &&
                        Number.isInteger(
                          freemiumFeatures?.[featureKey as keyof FreemiumFeaturesTypes]?.[
                            key as keyof FreemiumTypeSettings
                          ],
                        ) &&
                        Number(
                          freemiumFeatures?.[featureKey as keyof FreemiumFeaturesTypes]?.[
                            key as keyof FreemiumTypeSettings
                          ],
                        ) >= 0
                          ? freemiumFeatures?.[featureKey as keyof FreemiumFeaturesTypes]?.[
                              key as keyof FreemiumTypeSettings
                            ]
                          : freemiumDefaultPackageSettings.freemiumFeatures[
                              featureKey as keyof FreemiumFeaturesTypes
                            ][key as keyof FreemiumTypeSettings]
                      }
                      type="number"
                      onChange={(e) => {
                        setFreemiumFeatures({
                          ...freemiumFeatures,
                          [featureKey]: {
                            ...freemiumFeatures[featureKey as keyof FreemiumFeaturesTypes],
                            [key]: Number(e.target.value),
                          },
                        });
                      }}
                    />
                  ),
                )}

                <Divider />
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
    </Collapse>
  );
}

export default FreemiumFeaturesCollapse;
