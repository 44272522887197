import { Box, Button } from '@mui/material';
import { useLanguage } from '../../../context/LanguageContext';
import { translations } from '../../../utils/translations';
import SplitButton from '../../common/SplitButton';
import { VideoRequest } from '../BackOfficeTemplate/types';
import { DisabledButtons } from './VideoRequest';

export default function VideoRequestSendButtons({
  status,
  loading,
  sendReminder,
  saveVideoRequest,
  disabledButtons,
  textMessageAllowed,
}: {
  status: VideoRequest['status'];
  loading: boolean;
  sendReminder: ({
    sendEmailReminder,
    sendTextReminder,
  }: {
    sendEmailReminder: boolean;
    sendTextReminder: boolean;
  }) => void;
  saveVideoRequest: ({
    newStatus,
    saveDraft,
    sendText,
  }: {
    newStatus: VideoRequest['status'];
    saveDraft: boolean;
    sendText: boolean;
  }) => void;
  disabledButtons: DisabledButtons;
  textMessageAllowed: boolean;
}) {
  const lang = useLanguage();

  const OPTIONS_REMINDER = [
    {
      label: translations.videoCard.sendTextMessageEmailReminder[lang],
      type: 'sms-email',
      onClick: () => {
        sendReminder({ sendEmailReminder: true, sendTextReminder: true });
      },
      disabled: false,
    },
    {
      label: translations.videoCard.sendEmailReminder[lang],
      type: 'email',
      onClick: () => {
        sendReminder({ sendEmailReminder: true, sendTextReminder: false });
      },
      disabled: false,
    },
    {
      label: translations.videoCard.sendTextMessageReminder[lang],
      type: 'sms',
      onClick: () => {
        sendReminder({ sendEmailReminder: false, sendTextReminder: true });
      },
      disabled: false,
    },
  ];

  const OPTIONS_UPLOAD_LINK = [
    {
      label: translations.videoCard.sendSMSEmailToTeamMember[lang],
      type: 'sms-email',
      onClick: () => {
        saveVideoRequest({
          newStatus: 'PENDING',
          saveDraft: false,
          sendText: true,
        });
      },
      disabled: false,
    },
    {
      label: translations.videoCard.sendEmailToTeamMember[lang],
      type: 'email',
      onClick: () => {
        saveVideoRequest({
          newStatus: 'PENDING',
          saveDraft: false,
          sendText: false,
        });
      },
      disabled: false,
    },
  ];
  if (textMessageAllowed) {
    return (
      <Box>
        {status === 'PENDING' && (
          <SplitButton
            variant="contained"
            color="primary"
            disableElevation
            options={OPTIONS_REMINDER}
            menuPlacement="top-end"
            disabled={loading || disabledButtons.reminder}
            handleClick={(option) => {
              if (option?.type === 'email') {
                sendReminder({ sendEmailReminder: true, sendTextReminder: false });
              }
              if (option?.type === 'sms') {
                sendReminder({ sendEmailReminder: false, sendTextReminder: true });
              }
              if (option?.type === 'sms-email') {
                sendReminder({ sendEmailReminder: true, sendTextReminder: true });
              }
            }}
          />
        )}

        {status === 'SUGGESTION' && (
          <SplitButton
            variant="contained"
            color="primary"
            disableElevation
            options={OPTIONS_UPLOAD_LINK}
            menuPlacement="top-end"
            disabled={loading || disabledButtons.saveAndSend}
            handleClick={(option) => {
              if (option?.type === 'email') {
                saveVideoRequest({
                  newStatus: 'PENDING',
                  saveDraft: false,
                  sendText: false,
                });
              }
              if (option?.type === 'sms-email') {
                saveVideoRequest({
                  newStatus: 'PENDING',
                  saveDraft: false,
                  sendText: true,
                });
              }
            }}
          />
        )}
      </Box>
    );
  }

  return (
    <Box>
      {status === 'PENDING' && (
        <Button
          variant="contained"
          disabled={loading || disabledButtons.reminder}
          onClick={() => {
            sendReminder({ sendEmailReminder: true, sendTextReminder: false });
          }}
        >
          {translations.videoCard.sendReminder[lang]}
        </Button>
      )}

      {status === 'SUGGESTION' && (
        <Button
          variant="contained"
          disabled={loading || disabledButtons.saveAndSend}
          onClick={() => {
            saveVideoRequest({ newStatus: 'PENDING', saveDraft: false, sendText: false });
          }}
        >
          {translations.videoCard.sendEmailToTeamMember[lang]}
        </Button>
      )}
    </Box>
  );
}
