import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLanguage } from '../../../../context/LanguageContext';
import translationsAnalytics from '../../../../utils/translationsAnalytics';
import { formatTime } from '../../../../utils/utils';
import Error from './Error';
import { DataItem } from './types';

interface TimeCardProps {
  titleType: 'total' | 'interactions' | 'average';
  data: DataItem[] | null;
  selectedDuration: string;
  type: 'time' | 'interactions';
}

function TimeCard({ titleType, data, selectedDuration, type }: TimeCardProps) {
  const lang = useLanguage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const totalValueFormat = useMemo(() => {
    if (!data) return null;
    if (type === 'interactions') {
      return data.reduce((total, currentValue) => total + currentValue.count, 0);
    }
    return formatTime(
      data.reduce((total, currentValue) => total + currentValue.count, 0),
      lang,
    );
  }, [data, lang, type]);

  useEffect(() => {
    setLoading(true);
    setError('');

    const timer = setTimeout(() => {
      if (!data || data.length === 0) {
        setError(translationsAnalytics.totalImpressionsCard.error[lang]);
      }
      setLoading(false);
    }, 10000);

    if (data && data.length > 0) {
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, [data, selectedDuration, lang]);

  if (loading)
    return (
      <Skeleton
        sx={{ height: '114px', borderRadius: '8px' }}
        animation="wave"
        variant="rectangular"
        data-testid="loading-skeleton"
      />
    );

  return (
    <Card
      sx={{
        width: '100%',
        maxHeight: '114px',
        padding: '24px 22px',
        borderRadius: '8px',
        background: 'white',
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2,
        display: 'flex',
        position: 'relative',
      }}
      elevation={0}
    >
      {error && <Error error={error} />}

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 0,
          m: 0,
        }}
      >
        <Typography noWrap variant="titleMedium" align="center" sx={{ mb: 1 }}>
          {translationsAnalytics.timeCard?.[titleType]?.[lang]}
        </Typography>
        <Typography color="secondary.main" variant="headlineSmallBoldRecoleta">
          {totalValueFormat}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default TimeCard;
